export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';
export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const RESET_USER = 'RESET_USER';

export const GET_COUNT = 'GET_COUNT';
export const INCREMENT_COUNT = 'INCREMENT_COUNT';

export const FETCH_PAGE = 'FETCH_PAGE';
export const GET_PAGE = 'GET_PAGE';
export const UPDATE_PAGE = 'UPDATE_PAGE';

export const SITE_OPTIONS = 'SITE_OPTIONS';
export const GET_SITE_OPTIONS = 'GET_SITE_OPTIONS';
export const UPDATE_SITE_OPTIONS = 'UPDATE_SITE_OPTIONS';
export const GET_CURRENCY = 'GET_CURRENCY';

export const REGISTER = 'REGISTER';
export const LOGIN = 'LOGIN';
export const OATH_LOGIN = 'OATH_LOGIN';
export const LOGOUT = 'LOGOUT';

export const GET_FREQUINCY_LIST = 'GET_FREQUINCY_LIST';
export const FETCH_FREQUINCY_LIST = 'FETCH_FREQUINCY_LIST';
export const DELETE_FREQUINCY_LIST = 'DELETE_FREQUINCY_LIST';
export const FIND_FREQUINCY_LIST = 'FIND_FREQUINCY_LIST';
export const CREATE_FREQUINCY_LIST = 'CREATE_FREQUINCY_LIST';
export const UPDATE_FREQUINCY_LIST = 'UPDATE_FREQUINCY_LIST';

export const UPDATE_FREQUINCY_LIST_STATE = 'UPDATE_FREQUINCY_LIST_STATE';

export const FETCH_FREQUINCY_LIST_ITEMS = 'FETCH_FREQUINCY_LIST_ITEMS';
export const CLEAR_FREQUINCY_LIST_ITEMS = 'CLEAR_FREQUINCY_LIST_ITEMS';
export const DELETE_FREQUINCY_LIST_ITEMS = 'DELETE_FREQUINCY_LIST_ITEMS';
export const CREATE_FREQUENCY_LIST_ITEM = 'CREATE_FREQUENCY_LIST_ITEM';
export const DELETE_FREQUINCY_LIST_ITEM = 'DELETE_FREQUINCY_LIST_ITEM';
export const UPDATE_FREQUINCY_LIST_ITEM = 'UPDATE_FREQUINCY_LIST_ITEM';

export const GET_SHOW_LIST_POPUP = 'SHOW_LIST_POPUP';
export const TOGGLE_LIST_POPUP = 'TOGGLE_LIST_POPUP';
export const GET_LIST_POPUP_PRODUCT_ID = 'GET_LIST_POPUP_PRODUCT_ID';
export const SET_LIST_POPUP_PRODUCT_ID = 'SET_LIST_POPUP_PRODUCT_ID';

export const GET_SHOW_LIST_POPUP_FORM = 'GET_SHOW_LIST_POPUP_FORM';
export const GET_LIST_POPUP_FORM_ID = 'GET_SHOW_LIST_POPUP_FORM_ID';
export const TOGGLE_LIST_POPUP_FORM = 'TOGGLE_LIST_POPUP_FORM';
export const GET_LIST_POPUP_FORM_TYPE = 'GET_LIST_POPUP_FORM_TYPE';
export const SET_LIST_POPUP_FORM_TYPE = 'LIST_POPUP_FORM_TYPE';
export const SET_LIST_POPUP_FORM_ID = 'SET_LIST_POPUP_FORM_ID';

export const TOGGLE_ADD_TO_CART_NOTIF = 'SHOW_ADD_TO_CART_NOTIF';
export const GET_SHOW_ADD_TO_CART_NOTIF = 'GET_SHOW_ADD_TO_CART_NOTIF';
export const GET_ADD_TO_CART_NOTIF_ITEMS = 'GET_ADD_TO_CART_NOTIF_ITEMS';
export const SET_ADD_TO_CART_NOTIF_ITEMS = 'SET_ADD_TO_CART_NOTIF_ITEMS';

export const GET_CART = 'GET_CART';
export const SYNC_CART = 'SYNC_CART';
export const SET_CART = 'SET_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const CLEAR_CART = 'CLEAR_CART';

export const PROCEED_TO_CHECKOUT = 'PROCEED_TO_CHECKOUT';

export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';

export const GET_USER_AGE = 'GET_USER_AGE';
export const CALCULATE_USER_AGE = 'CALCULATE_USER_AGE';
export const SET_USER_AGE = 'SET_USER_AGE';

export const GET_CHECKOUT_TEXTS = 'GET_CHECKOUT_TEXTS';
export const GET_CHECKOUT_TEXT = 'GET_CHECKOUT_TEXT';

export const FETCH_STORE_LOCATION = 'FETCH_STORE_LOCATION';
export const GET_STORE_LOCATION = 'GET_STORE_LOCATION';

export const GET_SITE_SEO = 'GET_SITE_SEO';
export const GET_SEO = 'GET_SEO';

export const FB_INIT = 'FB_INIT';
export const SET_FB = 'SET_FB';
export const GET_FB = 'GET_FB';

export const SET_REGISTERED = 'SET_REGISTERED';
export const GET_REGISTERED = 'GET_REGISTERED';
