<template>
    <b-modal 
        id="cart-popup"
        hide-footer
        hide-header
        centered
        content-class="border-0"
        size="lg"
    >
        <div class="p-4" >
            <div class="d-flex align-items-center">
                <div class="font-xxlarge font-sf-medium">Your Cart</div>
                <router-link :to="{ name: 'cart' }" class="font-xxsmall font-green ml-3"><i class="far fa-pencil"></i> Edit</router-link>
                <i 
                    class="far fa-times-circle clickable ml-auto"
                    @click="hideCart"
                ></i>
            </div>
            <div class="cart-items mt-3 mb-5">
                <div 
                    class="cart-item mb-3 font-small"
                    v-for="(item, index) in orderData.items"
                    :key="index"
                >
                    <div class="d-flex align-items-center">
                        <div 
                            class="image-container mr-4" 
                            :style="{ width: '45px' }"
                        >
                            <Imager
                                :src="item.product.image_paths"
                                :extract="true"
                            />
                        </div>
                        <div>
                            <div class="font-xxsmall font-green">{{ getProductBrand(item.product.vendor) }}</div>
                            <div>{{ parseFloat(item.quantity) }} x {{ item.product.title }}</div>
                            <div 
                                v-if="item.product.variant_options && item.product.variant_options.length > 0"
                                class="font-xxsmall font-sf-medium font-gray"
                            >
                                <span
                                    v-for="(variant, index) in item.product.variant_options"
                                    :key="index"
                                    class="mr-1"
                                >{{ variant.option + ': ' + variant.name }}</span>
                            </div>
                        </div>
                        <span class="ml-auto">
                            <Price
                                :price="item.subtotal"
                                :originalPrice="parseFloat(item.orig_price)"
                            />
                        </span>
                    </div>
                    <div v-if="item.add_ons && item.add_ons.length > 0"
                        class="ml-5 mt-3 p-3 pb-0 border border-green rounded"    
                    >
                        <div 
                            :class="`d-flex align-items-center ${ (index + 1) != item.add_ons.length ? 'mb-3' : '' }`"
                            v-for="(addOn, index) in item.add_ons"
                            :key="index"
                        >
                            <div 
                                class="image-container mr-4" 
                                :style="{ width: '45px' }"
                            >
                                <Imager
                                    :src="addOn.info.asset"
                                    :extract="true"
                                />
                            </div>
                            <div>
                                {{ parseFloat(addOn.qty) }} x {{ addOn.info.name }}
                            </div>
                            <div class="ml-auto">
                                <Price
                                    :price="addOn.subtotal"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="total mt-5 border-top border-black">
                <div class="d-flex align-items-center justify-content-between flex-wrap font-xxlarge font-sf-medium mt-3">
                    <span>Total {{ orderData && orderData.items ? orderData.items.length : 0 }} Items</span>
                    <span>
                        <Price
                            :price="orderData && orderData.total ? orderData.total : 0"
                        />
                    </span>
                </div>
                <div class="text-right font-xxsmall font-gray font-sf-medium">
                    Exclusive of Delivery Fee, Service fee and Promo code
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import { connect } from '@api';

    export default {
        props: ['orderData'],
        data() {
            return {
                brands: []
            }
        },
        mounted() {
            this.getBrands();
        },
        methods: {
            getProductBrand(brand_id) {

                let brand = this.brands.find(brand => brand.id == brand_id);

                return brand ? brand.title : ''
            },
            getBrands() {

                connect().get('brands', {})
                    .then(res => {
                        if (res.status == 200) {
                            this.brands = res.data.data
                        }
                    })
                    .catch(err => {
                        console.error(err.data.message);
                    })
            },
            hideCart() {
                this.$bvModal.hide('cart-popup');
            }
        }
    }
</script>