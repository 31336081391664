<template>
	<div class="not-found-page d-flex justify-content-center py-5">
		<div class="font-xlarge px-2 pt-5">
			<div class="font-oversized">
				Sorry, we couldn't find that page
			</div>
			<div class="font-gray">
				Try searching or go to
				<router-link to="/" class="font-green">QuincyBuyBoy's home page</router-link>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style></style>
