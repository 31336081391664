<template>
  <div :class="`cart-table-row-grid order px-2 py-2 ${ className ? className : '' }`">
    <div class="image-column text-center px-sm-4 px-2">
        <Imager 
            :src="item.info"
            :extract="true"
            :alt="item.info.name"
        />
    </div>
    <div class="description-column">
        <div class="py-2">
            <!-- <div v-if="item.product.brand && item.product.brand.title" class="font-xsmall font-green">{{item.product.brand.title}}</div> -->
            <div class="font-small">{{item.info.name}}</div>
            <div class="d-flex align-items-center flex-wrap">
                <div 
                    v-if="item.product && item.product.variant_options"
                    class="d-flex align-items-center"
                >
                    <span 
                        class="bg-gray-2 font-xsmall font-sf-medium p-1 px-2 rounded font-green"
                        v-for="(variant, index) in item.product.variant_options"
                        :key="index"
                    >
                        {{variant.option}}: {{variant.name}}
                        <!-- <i class="fas fa-caret-down ml-1 font-gray"></i> -->
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="total-column text-center">
        <div class="font-sf-medium ml-n5" >
            <Price
                :price="total"
            />
        </div>
    </div>
  </div>
</template>

<script>
    export default {
        props: ['item', 'selectedAddons', 'className'],
        data() {
            return {
                quantity: this.item.qty ? this.item.qty : 1
            }
        },
        computed: {
            total() {
                let total = 0;

                if (this.item && this.item.info) {
                    total += ( parseFloat(this.item.info.price) * this.item.qty );
                }

                return total;
            },
        },
    }
</script>