<template>
    <div class="cart-table-row-grid frequincy">
        <div class="checkbox-column text-center">
            <div class="custom-control custom-checkbox">
                <input 
                    type="checkbox" 
                    class="custom-control-input" 
                    :id="`cart-item-${item.id}`"
                    :checked="isChecked"
                    @change="toggleSelect(item.id)"
                >
                <label class="custom-control-label" :for="`cart-item-${item.id}`"></label>
            </div>
        </div>
        <div class="image-column text-center px-sm-4 px-2">
            <Imager 
                :src="item.product && item.product.asset ? 
                        item.product 
                        : 
                        item.product.parent_product && item.product.parent_product.asset ? 
                            item.product.parent_product
                            :
                            ''
                        "
                :extract="true"
                :alt="item.product.title"
            />
        </div>
        <div class="description-column">
            <div class="py-2">
                <div 
                    v-if="productBrand && productBrand.title" 
                    class="font-xsmall font-green clickable"
                    @click="redirectToBrand(productBrand)"
                >{{productBrand.title}}</div>

                <div 
                    class="font-small clickable"
                    @click="redirectToProduct(product)"
                >
                {{ product.parent_product ? product.parent_product.title : product.title }}
                </div>

                <div class="d-flex align-items-center flex-wrap" >
                    <div v-if="product.variant_options && product.variant_options.length > 0" >
                        <span 
                            :class="`font-xsmall font-sf-medium p-1 px-2 rounded mr-1 ${ isOutOfStock ? 'bg-gray-1 font-gray' : 'bg-gray-2 font-green' }`" 
                            v-for="(option, index) in product.variant_options"
                            :key="index"
                        >
                            {{option.option}}: {{option.name}}
                        </span>
                    </div>
                    <div v-if="isOutOfStock"  >
                        <span class="bg-red-dark-transparent font-xsmall font-sf-medium p-1 px-2 rounded font-red">Out of Stock</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="price-column text-center">
            <ProductPrice
                :product="item.product"
                priceClass="font-sf-medium"
                originalPriceClass="font-gray-light"
            />
        </div>
        <div class="quantity-column text-center px-4">
            <div class="row align-items-center">
                <div class="col-lg-6 col-12">
                    <div class="input-group quantity-input mb-2">
                        <div class="input-group-prepend border">
                            <div 
                                class="input-group-text clickable"
                                @click="handleQuantityChange('subtract')"
                            ><i class="fas fa-minus font-green"></i></div>
                        </div>
                        <input 
                            type="text"
                            min="1"
                            class="form-control bg-white border border-right-0 text-center no-spinner px-0"
                            v-model="quantity"
                            @change="handleQuantityChange()"
                            pattern="\d*"
                            maxlength="4"
                            :readonly="isOutOfStock"
                        >
                        <div class="input-group-prepend border">
                            <div 
                                class="input-group-text clickable"
                                @click="handleQuantityChange('add')"
                            ><i class="fas fa-plus font-green"></i></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <button 
                        class="btn btn-clear btn-sm border border-green font-green rounded-pill"
                        :disabled="isOutOfStock || inCart || addedToCart"
                        @click="addToCart"
                    >
                        <span v-if="inCart || addedToCart" >
                            Added to Cart
                        </span>
                        <span v-else >
                            Add to My Cart
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="total-column text-center">
            <button 
                :class="`btn btn-clear btn-sm font-xsmall font-green ${ !category ? 'disabled' : '' }`"
                :disabled="!category"
                @click="redirectToCategory(category)"
            >Find Similar Product</button>
        </div>
        <div class="remove-column text-sm-right text-center">
            <button 
                class="btn btn-clear btn-sm font-xsmall font-gray"
                @click="removeItem(item)"
            >Remove</button>
        </div>
    </div>
</template>

<script>
    import { Price } from '@components/common';
    import * as cartHelper from '@helpers/cart.helper';

    export default {
        props: ['item', 'selectedItems'],
        data() {
            return {
                addedToCart: false,
                quantity: 1
            }
        },
        computed: {
            isChecked() {
                let selectedItems = this.selectedItems;

                let found = selectedItems.find(id => {
                    return id == this.item.id;
                });

                return found ? true : false
            },
            inCart: {
                get() {
                    let inCart = false;
                    let { item } = this;
                    let cart = cartHelper.getCart();
    
                    if (cart && cart.items && cart.items.length > 0) {
    
                        cart.items.map(cartItem => {
                            if (item.product_id == cartItem.product_id) {
                                inCart = true;
                            }
                        })
                    }
    
                    return inCart;
                }
            },
            product() {
                return this.item && this.item.product ? this.item.product : null
            },
            category() {

                let product = this.product;
                let category = null;

                if (product.parent_product && product.parent_product.categories && product.parent_product.categories.length > 0 ) {
                    category = product.parent_product.categories[0].category;
                } else {
                    if (product.categories && product.categories.length > 0) {
                        category = product.categories[0].category;
                    }
                }

                return category;
            },
            productPrice() {
                let price = {
                    orig_price: 0,
                    price: this.product.price
                };

                if (this.product && this.product.parent_product && parseFloat(this.product.parent_product.orig_price) != 0 ) {
                    price.orig_price = this.product.parent_product.orig_price;
                }

                return price;
            },
            productAsset() {

                let asset = '';

                if (this.product.asset && this.product.asset.path) {
                    return asset = this.product;
                }
                if (this.product.parent_product.asset && this.product.parent_product.asset.path) {
                    return asset = this.product.parent_product;
                }

                return asset;
            },
            isOutOfStock() {
                let outOfStock = true;
                let { item } = this;

                if (item.product) {
                    
                    if (item.product.enable_inventory > 0) {
                        outOfStock = item.product.inventory > 0 ? false : true;
                    } else {
                        outOfStock = false;
                    }
                }

                return outOfStock;
            },
            productBrand() {
                let { product } = this.item;
                let { brand } = product;

                if (product.parent_product && product.parent_product.brand) {
                    brand = product.parent_product.brand;
                } 
                return brand;
            }
        },
        mounted() {
        },
        methods: {
            toggleSelect(id) {
                this.$emit('toggle-select', id);
            },
            removeItem() {
                this.$emit('remove-item', this.item);
            },
            addToCart() {
                this.addedToCart = true;
                this.$emit('add-to-cart', this.item);
            },
            handleQuantityChange(method = null) {

                if (this.isOutOfStock) {
                    return false;
                }

                let { item, quantity } = this;
                let { product } = item;

                quantity = parseInt(quantity);

                if (product.enable_inventory > 0 && product.inventory <= 0) return;

                if (method == 'add') {

                    if (product.enable_inventory > 0) {
                        if (quantity < product.inventory) {
                            quantity += 1;
                        }
                    } else {
                        quantity += 1;
                    }
                } else if (method == 'subtract') {
                    if (quantity > 1) {
                        quantity -= 1;
                    } else {
                        this.removeItem();
                    }
                } else {
                    if (quantity >= 1) {
                        if (product.enable_inventory > 0 && quantity > product.inventory) quantity = parseInt(product.inventory);
                    } else {
                        quantity = 1;
                        this.removeItem();
                    }
                }

                this.quantity = quantity;

                if (this.quantity > 0) {
                    this.$emit('change-quantity', item, quantity);
                }
            },
            redirectToProduct(product) {

                let urlParams = {
                    name: 'products.view',
                    params: {
                        slug: product.slug
                    }
                };

                if (product.parent_product) {
                    urlParams.params.slug = product.parent_product.slug;
                    urlParams.query = {
                        variant: product.options_combination
                    }
                } 

                this.$router.push(urlParams);
            },
            redirectToCategory(category) {

                if (!category) return;

                this.$router.push({
                    name: 'products',
                    query: {
                        category: category.slug
                    }
                })
            },
            redirectToBrand(brand) {

                let urlParams = {
                    name: 'products',
                    query: {
                        brands: [brand.slug]
                    }
                }
                this.$router.push(urlParams)
            }
        }
    }
</script>