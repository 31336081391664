<template>
    <div class="orders-page">
        <div class="container">
            <div class="col-lg-10 col-12 mx-auto py-5">
                <div class="d-flex align-items-center flex-wrap justify-content-between mb-4">
                    <div class="font-giantsize font-sf-bold">My Orders</div>
                    <div class="font-small font-sf-medium">
                        <span class="font-gray">Need help?</span>
                        <router-link
                            :to="{ name: 'contact' }"
                            class="font-green ml-1"
                        > Contact us
                        </router-link>
                    </div>
                </div>
                <div class="row row-extra-thin status-container mb-4">
                    <div class="col-sm-6 col-md-3 col-12 mb-2 mb-md-0">
                        <div 
                            class="h-100 bg-gray-2 rounded py-4 px-2 flex-fill d-flex align-items-center justify-content-center clickable" 
                            @click="goToStatus('pending')"
                        >
                            <div class="d-flex" >
                                <div :class="['rounded-circle p-3 text-center', status == 'pending' ? 'bg-green' : 'bg-gray-light']">
                                    <img src="@assets/img/order-placed-icon.png" alt="">
                                </div>
                            </div>
                            <div class="font-xsmall font-sf-medium mt-2 ml-3 ml-md-2">Order Placed</div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-12 mb-2 mb-md-0">
                        <div 
                            class="h-100 bg-gray-2 rounded py-4 px-2 flex-fill d-flex align-items-center justify-content-center clickable"
                            @click="goToStatus('processing')"
                        >
                            <div>
                                <div :class="['rounded-circle p-3 text-center ', status == 'processing' ? 'bg-green' : 'bg-gray-light']">
                                    <img src="@assets/img/order-preparing-icon.png" alt="">
                                </div>
                            </div>
                            <div class="font-xsmall font-sf-medium mt-2 ml-3 ml-md-2">Preparing your Order</div>
                        </div>
                    </div>
                    <div 
                        class="col-sm-6 col-md-3 col-12 mb-2 mb-md-0"
                    >
                        <div 
                            class="h-100 bg-gray-2 rounded py-4 px-2 flex-fill d-flex align-items-center justify-content-center clickable" 
                            @click="goToStatus('delivery')"
                        >
                            <div>
                                <div :class="['rounded-circle p-3 text-center ', status == 'delivery' ? 'bg-green' : 'bg-gray-light']">
                                    <img src="@assets/img/order-delivery-icon.png" alt="">
                                </div>
                            </div>
                            <div class="font-xsmall font-sf-medium mt-2 ml-3 ml-md-2">Driver is on the way</div>
                        </div>
                    </div>
                    <div 
                        class="col-sm-6 col-md-3 col-12 mb-2 mb-md-0 "
                        @click="goToStatus('completed')"
                    >
                        <div class="h-100 bg-gray-2 rounded py-4 px-2 flex-fill d-flex align-items-center justify-content-center clickable" >
                            <div>
                                <div :class="['rounded-circle p-3 text-center ', status == 'completed' ? 'bg-green' : 'bg-gray-light']">
                                    <img src="@assets/img/order-complete-icon.png" alt="">
                                </div>
                            </div>
                            <div class="font-xsmall font-sf-medium mt-2 ml-3 ml-md-2">Order Complete</div>
                        </div>
                    </div>
                </div>
                <div v-if="showLoader" class="my-5 py-5 text-center" >
                    <i class="fas fa-spinner fa-spin fa-3x font-gray"></i>
                </div>
                <div v-else >
                    <div v-if="items && items.length > 0" >
                        <div
                            v-for="(item, index) in items"
                            :key="index"
                        >
                            <OrderCard
                                :order="item"
                                :status="status"
                                :toggleDepositModal="toggleDepositModal"
                                :handleDepositModalFileChange="handleDepositModalFileChange"
                                :handleDepositModalSubmit="handleDepositModalSubmit"
                                :handleRemoveUploadedFile="handleRemoveUploadedFile"
                            />
                        </div>
                    </div>
                    <div v-else >
                        <div class="my-5 py-5 text-center font-xlarge font-sf-bold">No orders found</div>
                    </div>
                </div>
            </div>
        </div>
        <DepositModal
            :show="showDepositModal"
            :toggleDepositModal="toggleDepositModal"
            :depositForm="depositForm"
            :depositFormErrors="depositFormErrors"
            :handleDepositModalFileChange="handleDepositModalFileChange"
            :handleDepositModalSubmit="handleDepositModalSubmit"
            :uploadedFile="uploadedFile"
            :handleRemoveUploadedFile="handleRemoveUploadedFile"
            :extractAsset="extractAsset"
            :uploading="uploading"
        />
    </div>
</template>

<script>
    import { connect } from '@api';
    import OrderCard from './components/orderCard';
    import validate from '@helpers/validation.helper';
    import DepositModal from './components/depositModal';

    const initialDepositForm = {
        imageData: null,
        account_name: '',
        payment_date: '',
        amount_paid: '',
        reference_number: ''
    }

    const initialDepositFormErrors = {
        imageData: '',
        account_name: '',
        payment_date: '',
        amount_paid: '',
        reference_number: ''
    }

    export default {   
        components: {
            OrderCard,
            DepositModal
        },
        data() {
            return {
                status: 'pending',
                items: [],
                orderData: null,
                showDepositModal: false,
                depositForm: initialDepositForm,
                depositFormErrors: initialDepositFormErrors,
                extractAsset: false,
                uploadedFile: null,
                uploading: false,
                showLoader: true,
                formValues: {
                    deposit: null
                }
            }
        },
        mounted() {
            this.getOrders();
        },
        methods: {
            goToStatus(status) {
                this.status = status;
                this.getOrders();
            },
            async getOrders() {
                this.items = [];
                this.showLoader = true;

                let res = await connect().get(`customers/get-customer-order?status=${ this.status }`, {})

                if (res.status == 200) {
                    this.items = res.data.data;
                }
                
                this.showLoader = false;
            },
            toggleDepositModal(orderData = null) {
                this.showDepositModal = !this.showDepositModal;
                this.orderData = orderData;

                this.depositForm = initialDepositForm;
                this.uploadedFile = null;
                this.depositForm.imageData = null;
                
                if (orderData != null) {

                    if (orderData.deposit_data) {
                        let depositData = JSON.parse(orderData.deposit_data);
                        this.depositForm = {...this.depositForm, ...depositData};
                    }
                    if (orderData.deposit_slip) {
                        this.extractAsset = true;
                        this.uploadedFile = orderData.deposit_slip;
                    }
                }
            },
            handleDepositModalFileChange(fileList) {
                
                if (!fileList.length) return;
                
                this.extractAsset = false;
                this.uploadedFile = URL.createObjectURL(fileList[0]);

                this.depositForm.imageData = fileList[0];
            },
            handleRemoveUploadedFile() {
                this.uploadedFile = null;
                this.depositForm.imageData = null;
            },
            async validateDepositForm() {
                const schema = {
                    properties: {
                        account_name: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            messages: {
								allowEmpty: 'Please enter account name',
								required: 'Please enter account name',
							}
                        },
                        payment_date: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            messages: {
                                allowEmpty: 'Please enter the date of payment',
                                required: 'Please enter the date of payment',
                            }
                        },
                        amount_paid: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            messages: {
                                allowEmpty: 'Please enter the total amount paid',
                                required: 'Please enter the total amount paid',
                            }
                        },
                        reference_number: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            messages: {
                                allowEmpty: 'Please enter the transaction reference number',
                                required: 'Please enter the transaction reference number',
                            }
                        },
                    }
                };

                const validateResults = validate(this.depositForm, schema, undefined, true);

                this.depositFormErrors = validateResults.errors;

                return validateResults.valid;
            },
            async handleDepositModalSubmit() {

                let { depositForm, orderData } = this;
                this.uploading = true;

                if (!depositForm.imageData) {
                    if (!(await this.validateDepositForm())) {
                        this.uploading = false;
                        return;
                    }
                } else {
                    let params = {
                        order_id: orderData.id,
                        deposit_slip: depositForm.imageData
                    }
    
                    let depositFormData = new FormData();
                    depositFormData.append('order_id', orderData.id);
                    depositFormData.append('deposit_slip', depositForm.imageData);
    
                    if (depositForm.imageData) {

                        let response = await connect().post('orders/upload-attachments', depositFormData).then(res => res).catch(err => err);
                        if (response.status == 200) {
                            this.orderData.deposit_data = response.data.data.deposit_data;
                        } else {
                            this.uploading = false;
                            this.depositFormErrors.imageData = 'Uploading failed';
                            return;
                        }
                    }
                }


                this.formValues.deposit = JSON.stringify(depositForm);

                let params = {
                    order_id: orderData.id,
                    deposit: this.formValues.deposit
                };

                let response = await connect().post('orders/update-deposit-data', params).then(res => res).catch(err => err);

                if (response.status == 200) {
                    this.uploading = false;
                    this.uploadedFile = null;
                    this.depositFormErrors = initialDepositFormErrors;
                    this.getOrders();
                    this.toggleDepositModal();
                    return;
                } else {
                    this.uploading = false;
                    this.depositFormErrors.reference_number = response.data.message;
                    return;
                }
            },
        }
}
</script>