<template>
	<div class="position-relative">
		<button class="btn btn-clear btn-sm font-gray" @click="handleBack">
			<i class="fas fa-chevron-left mr-2" /> Back
		</button>

		<div class="px-2 mx-sm-4 mb-4 pb-4 mt-5 text-center">
			<div class="font-sf-bold px-3">
				Enter your email to receive latest offers and promos from QuincyBuyBoy
			</div>
			<div class="font-xsmall font-gray px-5">
				We also send all your order confirmations and transactions via email
			</div>
		</div>

		<div>
			<div class="form-group font-xsmall mb-2">
				<label class="font-gray">Email</label>
				<div class="input-group">
					<input 
						type="email" 
						class="form-control font-xsmall" 
						placeholder="Email" 
						v-model="email"
						@keyup="handleUpdateEmail"
						@keydown.enter="startTimer"
						:disabled="showResend"
					/>
					<div class="input-group-append">
						<button
							class="btn btn-clear btn-sm"
							type="button"
							v-bind:disabled="showResend || !email"
							@click="startTimer"
						>
							SEND CODE
						</button>
					</div>
				</div>
			</div>
			<div class="form-group font-xsmall mb-1">
				<input
					type="text"
					class="form-control font-xsmall"
					v-model="code"
					placeholder="Enter code sent via email"
					@change="handleUpdateEmailCode"
					:disabled="!validEmail"
				/>
			</div>
			<div class="font-small font-red font-sf-medium" v-if="error != ''" >{{error}}</div>
		</div>
		<div class="mt-0" v-if="showResend">
			<button
				class="btn btn-clear btn-sm font-green pl-0"
				v-bind:disabled="resendDisabled"
				@click="startTimer"
			>
				<span class="font-gray">
					Resend code
				</span>
				{{ timer ? timer + 's' : '' }}
			</button>
		</div>
		<div class="font-xsmall font-red font-sf-medium" v-if="emailCodeError" >{{ emailCodeError }}</div>
		<button
			class="btn btn-green btn-block btn-radius-3 mt-5 py-2"
			@click="handleNext"
			v-bind:disabled="email == '' || code == ''"
		>
			Next
		</button>
		<!-- <div class="text-center">
			<button class="btn btn-clear btn-sm mt-3 font-green" @click="handleNext">
				Skip email verification for now
			</button>
		</div> -->
	</div>
</template>

<script>
import { connect } from '@api';

export default {
	name: 'emailverification',
	props: ['handleNext', 'handleBack', 'nextDisabled', 'emailValue', 'emailCode', 'emailCodeError'],
	data() {
		return {
			timer: 0,
			resendDisabled: true,
			showResend: false,
			email: this.emailValue,
			code: this.emailCode,
			validEmail: false,
			error: ''
		};
	},
	methods: {
		async startTimer() {

			if (!this.email) {
				this.error = 'Please enter your email';
				return;
			}

			await this.validateEmail();
			
			if (this.validEmail) {
				
				await this.sendEmailCode();

				this.showResend = true;
				this.timer = 120;
				this.resendDisabled = true;
				this.error = '';

				let interval = setInterval(() => {
					this.timer--;
					if (this.timer == 0) {
						clearInterval(interval);
						this.resendDisabled = false;
					}
				}, 1000);
			} 
		},
		async sendEmailCode() {
			await this.$emit('sendEmailCode');
		},
		async validateEmail() {
			await connect()
				.post('/guest/email/validate', { email: this.email })
				.then(res => {
					if (res.status == 200) {
						this.validEmail = true;
					}
				})
				.catch(err => {
					console.error(err.data.message);
					this.error = err.data.message;
				})
		},
		handleUpdateEmailCode() {
			this.$emit('updateEmailCode', this.code);
		},
		handleUpdateEmail() {
			this.$emit('updateEmail', this.email);
		},
	},
};
</script>

<style></style>
