<template>
    <div class="fixed-bottom shadow bg-white" :style="{ position: position, zIndex: zIndex }">
        <div class="container">
            <div class="d-none d-lg-flex align-items-center flex-column flex-md-row py-3">
                <div class="flex-grow-1 mr-0 mr-md-5 row text-center text-md-left">
                    <div class="col-lg-3 col-6">
                        <div class="font-upsized font-sf-medium">{{selectedItems.length}}</div>
                        <div class="font-xsmall font-gray font-sf-medium">Selected Items</div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="font-upsized font-sf-medium">{{availableItems}}</div>
                        <div class="font-xsmall font-gray font-sf-medium">Total Available Items</div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="font-upsized font-sf-medium">{{outOfStockItems}}</div>
                        <div class="font-xsmall font-gray font-sf-medium">Out of Stock</div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="font-upsized font-sf-medium">
                            <Price
                                :price="subTotal"
                            />
                        </div>
                        <div class="font-xsmall font-gray font-sf-medium">Subtotal</div>
                    </div>
                </div>
                <button 
                    class="btn btn-sm bg-green rounded-pill font-white w-25 w-xs-100 mt-4 mt-md-0"
                    @click="$emit('proceed-to-checkout')"
                >Check Out</button>
            </div>
            <div class="d-block d-lg-none justify-content-between align-items-center p-3">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="font-xsmall flex-fill">
                        <span class="font-sf-medium mr-2">{{selectedItems.length}}</span>
                        <span class="font-gray">Selected Items</span>
                    </div>
                    <div class="font-xsmall flex-fill text-center">
                        <span class="font-sf-medium mr-2">{{availableItems}}</span>
                        <span class="font-gray">Available Items</span>
                    </div>
                    <div class="font-xsmall flex-fill text-right">
                        <span class="font-sf-medium mr-2">{{outOfStockItems}}</span>
                        <span class="font-gray">Out of Stock</span>
                    </div>
                </div>
                <div>
                    <div class="text-center my-1 font-upsized">
                        <Price
                            :price="subTotal"
                        />
                    </div>
                    <div class="text-center">
                        <button 
                            class="btn btn-sm bg-green rounded-pill font-white col-md-6 col-12"
                            @click="$emit('proceed-to-checkout')"
                        >Check Out</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['selectedItems', 'items', 'subTotal'],
        data() {
            return {
                windowWidth: 1366,
                position: 'fixed',
                footerOffset: 0,
                scrollPosition: 0,
                zIndex: 1030
            }
        },
        mounted() {

            this.windowWidth = window.outerWidth;

            this.footerOffset = document.getElementById('footer').offsetTop;

            window.addEventListener("resize", () => {
                this.windowWidth = window.outerWidth;
            });
            window.addEventListener("scroll", () => {
                this.scrollPosition = window.pageYOffset;

                if (this.windowWidth < 1366) {
                    if (this.scrollPosition > this.footerOffset || this.scrollPosition == window.outerHeight) {
                        this.position = 'relative';
                        this.zIndex = 1;
                    } else {
                        this.position = 'fixed';
                        this.zIndex = 1030;
                    }
                }
            })
        },
        computed: {
            outOfStockItems() {
                let { items } = this;
                let outOfStockItems = 0;

                if (items.length > 0) {
                    items.map(item => {
                        if (item.product && item.product.inventory <= 0) {
                            outOfStockItems += 1;
                        }
                    })
                }

                return outOfStockItems;
            },
            availableItems() {
                let { items } = this;
                let availableItems = 0;

                if (items.length > 0) {
                    items.map(item => {
                        if (item.product && item.product.inventory > 0) {
                            availableItems += 1;
                        }
                    })
                }

                return availableItems;
            }
        }
    }
</script>
