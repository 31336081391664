<template>
    <div :class="containerClass" :style="{ pointerEvents: disabled ? `none` : `auto` }">
        <label v-if="label" class="font-gray">{{ label }}</label>
        <div class="position-relative bb-form-group">
            <textarea
                :class="`${ inputClass } bb-text-input bb-text-area form-control font-xsmall font-sf-regular`"
                :placeholder="placeholder"
                :value="value" 
                @input="$emit('input', $event.target.value)"
            />
        </div>
        <div v-if="error" class="text-danger font-sm">
            {{ error }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: String,
        containerClass: String,
        inputClass: String,
        placeholder: String,
        label: String,
        disabled: Boolean,
        error: String
    },
}
</script>