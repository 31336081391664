<template>
    <b-modal 
        id="list-modal"
        v-model="showPopup"
        hide-footer
        hide-header
        centered
        body-class="p-0"
        content-class="border-0"
        size="sm"
    >
        <ul class="list-group font-small">
            <li class="list-group-item text-center">
                <span class="font-sf-bold">Select which FreQuincy List you wish to add the product</span>
            </li>
            <div v-if="frequincyList && frequincyList.length > 0" >
                <li 
                    class="list-group-item text-center clickable"
                    v-for="(list, index) in frequincyList"
                    :key="index"
                    @click="handleAddWishlist(list.id)"
                >
                    <span class="font-sf-medium">
                        {{ list.name }}
                    </span>
                </li>
            </div>
            <li 
                class="list-group-item text-center clickable font-sf-medium font-gray"
                v-if="frequincyList.length < 3"
                @click="handleCreateList"
            >
                <i class="fas fa-plus font-green"></i> Add New List
            </li>
        </ul>
    </b-modal>
</template>

<script>
    import { mapMutations, mapGetters, mapActions } from 'vuex'
    import * as types from '../../store/mutation-types';
    import { isLoggedIn } from '@helpers/session.helper';
    import { showAlert } from '@helpers/notification.helper';

    let errorSwalSettings = {
        html: 'This product is already on this list',
        customClass: {
            confirmButton: 'btn-block mx-5'
        }
    };

    let successSwalSettings = {
        html: 'Product successfully added to list'
    };

    export default {
        computed: {
            showPopup: {
                get() {
                    return this.getShowPopup()
                },
                set(newValue) {}
            },
            popupProductID() {
                return this.getPopupProductID();
            },
            frequincyList() {
                return this.getList();
            },
            user() {
                return this.getUser();
            },
        },
        mounted() {
            this.$root.$on('bv::modal::hide', (bvEvent, modalID) => {
				if (bvEvent.type == 'hide') {
					if (modalID == 'list-modal') {
                        this.toggleListPopup(false);
					}
				}
			})
        },
        methods: {
            ...mapGetters({
                getList: types.GET_FREQUINCY_LIST,
                getShowPopup: types.GET_SHOW_LIST_POPUP,
                getPopupProductID: types.GET_LIST_POPUP_PRODUCT_ID,
                getUser: types.GET_USER
            }),
            ...mapActions({
                addItem: types.CREATE_FREQUENCY_LIST_ITEM,
            }),
            ...mapMutations({
                toggleListPopup: types.TOGGLE_LIST_POPUP,
                toggleListPopupForm: types.TOGGLE_LIST_POPUP_FORM,
                updateUser: types.UPDATE_USER
            }),
            handleCreateList() {
                this.toggleListPopupForm();
            },
            handleAddWishlist(listID) {

                let params = {
                    customer_id: this.user.customer_id,
                    product_id: this.popupProductID,
                    frequincy_list_id: listID
                };

                let selectedList = this.frequincyList.find(list => {
                    return list.id == listID;
                });
                let { items } = selectedList;

                this.addItem({...params})
                    .then(res => {
                        showAlert(successSwalSettings)
                            .then(swal => {
                                let user = this.user;

                                user.wishlist = res.data.data;
                                this.updateUser(user);
                            })
                        this.toggleListPopup(false);
                    })
                    .catch(err => {
                        console.error(err.message);
                    })
            }
        }
    }
</script>