<template>
	<div class="bundle-card">
		<div class="image-container">
			<img :src="extractAssetObj(bundle, ['asset', 'path'])" alt=""/>
		</div>

		<div class="d-flex justify-content-between align-items-center mt-2">
			<h6 class="font-medium font-sf-medium">
				{{ bundle.name }}
			</h6>

			<div>
				<button class="btn btn-green-light btn-circle"><i class="fal fa-plus"></i></button>
			</div>
		</div>
	</div>
</template>

<script>
import { extractAssetObj } from '@helpers/caboodle.helper';
export default {
	props: ["bundle"],
	methods: { extractAssetObj }
};
</script>

<style></style>
