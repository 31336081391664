<script>
import imager from './image'
import price from './price'
import rating from './rating';
import verified from './verified';
import avatar from './avatar';
import cardLoader from './cardLoader';
import buyboyRating from './buyboyRating';
import productPrice from './productPrice';

export const Imager = imager
export const Price = price
export const CustomerRating = rating
export const Verified = verified
export const Avatar = avatar
export const CardLoader = cardLoader
export const BuyboyRating = buyboyRating
export const ProductPrice = productPrice

export default {
    name: 'Common',
    setup() {
        
    },
}
</script>