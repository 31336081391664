import { connect } from '../../helpers/api.helper';
import * as types from '../mutation-types';
import * as cartHelper from '@helpers/cart.helper';

const defaultState = {
	user: {}
};

const state = defaultState;

// used to get state
const getters = {
	[types.GET_USER]: (state) => state.user,
};

// same as react actions
const actions = {
	// [types.FETCH_USER]: async ({ commit }) => {
	// 	const response = await axios.get('');
	// 	commit('setUser', response.data);
	// },

	[types.LOGIN]: ({commit}, params) => {
		return connect().post(`/auth/login`, params);
	},

	[types.OATH_LOGIN]: ({ commit }, params) => {
		return connect().post('/oauth/login', params);
	},

	[types.REGISTER]: async (params) => {
		await connect().post(`/auth/register`, params);
	},

	[types.LOGOUT]: ({commit}) => {

		// connect().get('auth/logout').then(res => console.log(res)).catch(err => console.error(err));

		commit(types.RESET_USER);
		localStorage.removeItem('buyboy-cart');
		commit(types.CLEAR_CART);
		commit(types.SET_USER_AGE, { data: null });
	},

	[types.FETCH_USER]: ({commit}, params) => {
		return connect().post('auth/get_user', params);
	} 
};

// same as react reducer
const mutations = {
	// SET USER
	[types.SET_USER]: (state, user) => { 
		// state.user = { ...state.user, ...user};
		state.user = {...user};
		localStorage.removeItem('user');
		localStorage.setItem(
			'user',
			JSON.stringify(user)
		);
	},

	// RESET USER
	[types.RESET_USER]: (state) => {
		Object.assign(state, defaultState);
		localStorage.removeItem('user');
	},

	// UPDATE USER
	[types.UPDATE_USER]: (state, user) => { 
		state.user = {...state.user, ...user} 
	}
};

export default {
	state,
	getters,
	actions,
	mutations,
};
