<template>
	<div id="app" class="d-flex flex-column">

		<!-- OVERLAY -->
		<div 
			id="overlay"
			@click="() => {
				if (windowWidth < 1200) {
					toggleMobileSidebar()
				} else {
					hideCategoryPopup()
				}
			}"
		></div>

		<!-- NAV -->
		<Nav 
			:showProfilePopup="showProfilePopup"
			:showCategoryPopup="showCategoryPopup"
			:popupType="popupType"
			:navbarHeight="navbarHeight"
			:toggleMobileSidebar="toggleMobileSidebar"
			:showMobileSideBar="showMobileSideBar"
			:categories="categories"
			:vendors="vendors"
			@toggle-popup="toggleProfilePopup"
			@toggle-category-popup="toggleCategoryPopup"
			@nav-mounted="getNavbarHeight"
		/>

		<!-- ROUTER VIEW -->
		<div 
			class="page-container flex-grow-1 d-flex flex-column"
			:style="{ marginTop: navbarHeight + 'px' }"
		>
			<router-view
				:categories="categories"
				:vendors="vendors"
				:style="{minHeight: minHeight + 'px' }"
				:seo="seo"
			/>
		</div>
		
		<!-- FOOTER -->
		<Footer
			@footerMounted="getFooterHeight"
		/>

		<!-- POPUPS AND MODALS -->
		<list-popup />
		<list-popup-form />

		<div v-if="$route.name != 'cart' && $route.name != 'checkout' && $route.name != 'frequincy' && $route.name != 'contact'" class="fixed-bottom d-flex contact-us-overlay-container">
			<span 
				:class="`contact-us-overlay clickable bg-green ml-auto mr-4 font-xsmall p-2 text-center font-white d-none d-lg-inline-block`"
				@click="() => { $router.push({ name: 'contact' }) }"

			>
				<div class="mx-3 d-xs-none d-lg-block">Have a question? Contact us</div>
			</span>
			<span 
				:class="`contact-us-overlay clickable bg-green ml-auto mr-4 font-xsmall p-2 text-center font-white d-xs-inline-block d-lg-none mobile`"
				@click="() => { $router.push({ name: 'contact' }) }"

			>
				<div class="mx-3"><i class="fas fa-envelope fa-lg"></i></div>
			</span>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapMutations, mapGetters } from 'vuex';
	import * as types from './store/mutation-types';
	import Nav from '@components/nav/Nav';
	import Footer from '@components/footer';
	import { isLoggedIn, getUser } from '@helpers/session.helper';
	import * as cartHelper from '@helpers/cart.helper';
	import { connect } from '@api';
	import { showAlert } from '@helpers/notification.helper';
	import moment from 'moment';

	export default {
		components: {
			Nav,
			Footer,
		},
		data() {
			return {
				showProfilePopup: false,
				showCategoryPopup: false,
				popupType: 'categories',
				navbarHeight: 0,
				footerHeight: 0,
				popupProduct: {},
				showMobileSideBar: false,
				categories: [],
				vendors: [],
				seo: {
					title: '',
					description: '',
					asset: null
				},
				windowWidth: 0
			}
		},
		computed: {
			minHeight() {

				let height = window.innerHeight;

				if (this.footerHeight) {
					height = height - this.footerHeight
				}
				if (this.navbarHeight) {
					height = height - this.navbarHeight
				}

				return height;
			},
			registered() {
				return this.getRegistered() ? true : false
			}
		},
		metaInfo() {
			return {
				title: this.seo.title,
				meta: [
					{
						vmid: 'description',
						name: 'description',
						content: this.seo.description
					},
					{
						vmid: 'og:title',
						property: 'og:title',
						name: 'og:title',
						content: this.seo.title
					},
					{
						vmid: 'og:description',
						property: 'og:description',
						name: 'og:description',
						content: this.seo.description
					},
					{
						vmid: 'og:image',
						property: 'og:image',
						name: 'og:image',
						content: this.seo.asset && this.seo.asset.path ? this.seo.asset.path : ''
					},
					{
						vmid: 'twitter:title',
						property: 'twitter:title',
						name: 'twitter:title',
						content: this.seo.title
					},
					{
						vmid: 'twitter:description',
						property: 'twitter:description',
						name: 'twitter:description',
						content: this.seo.description
					},
					{
						vmid: 'twitter:image',
						property: 'twitter:image',
						name: 'twitter:image',
						content: this.seo.asset && this.seo.asset.path ? this.seo.asset.path : ''
					},
				]
			}
		},
		mounted () {

			const swalSettings = {
					title: 'Congratulations!',
					text: 'You have successfully created an account', 
					confirmButtonText: 'Start Shopping',
					customClass: {
						confirmButton: 'flex-fill mx-5'
					}
				}

			if (this.registered) {
				showAlert(swalSettings)
				this.setRegistered({ data: false })
			}

			this.getPageSeo(this.$route.fullPath, true)
				.then(res => {
					let seo = res.data.data;
					this.seo = {...this.seo, ...seo};
				})
			this.getCurrency();
			this.getCheckoutTexts();
			this.fetchStoreLocation();

			this.windowWidth = window.outerWidth;

			document.addEventListener('resize', () => {

				let newWidth = window.outerWidth;

				this.windowWidth = newWidth;

				if (newWidth > 1200) this.showMobileSidebar = false;
				if (newWidth < 1200) this.showCategoryPopup = false;

				let navbar = document.getElementById('custom-nav');

				if (navbar) {
					this.getNavbarHeight(navbar.clientHeight);
				}
			});

			if (isLoggedIn()) {
				this.fetchFrequincyList();

				let user = getUser();

				this.calculateUserAge(user);

				if (user && user.id) {
					
					connect().post('/auth/get_user', { token: user.token })
						.then(res => {
							if (res.status == 200) {
								user = res.data.data;

								let status = user.status;

								if (status == 'blocked') {

									let swalSettings = {
										title: 'You have been logged out',
										allowOutsideClick: false
									};

									showAlert(swalSettings, 'error')
										.then(swal => {
											this.logout();
											this.$router.push({name: 'login'});
											return;
										})
								}

								this.setUser(user);



								if (user.cart) {
									let cartParams = {...user.cart};
									this.syncCart(cartParams);
								} 
							}
						})
						.catch(err => {
							console.error(err);
						})
				}
			} else {

				let cart = cartHelper.getCart();
				if (cart) {
					cart.customer_id = 0;
					this.syncCart(cart);
				}
				this.setUserAge({ data: null });
			}

			this.$root.$on('bv::modal::hide', (bvEvent, modalID) => {
				if (bvEvent.type == 'hide') {
					if (modalID == 'list-modal-form') {
						this.toggleListPopupForm(false);
					}
					if (modalID == 'list-modal') {
						this.toggleListPopup(false);
					}
				}
			})
			this.getCategories();
			this.getVendors();
		},
		methods: {
			...mapMutations({
				toggleListPopup: types.TOGGLE_LIST_POPUP,
				toggleListPopupForm: types.TOGGLE_LIST_POPUP_FORM,
				setUser: types.SET_USER,
				updateUser: types.UPDATE_USER,
				setUserAge: types.SET_USER_AGE,
				setRegistered: types.SET_REGISTERED
			}),
			...mapActions({
				fetchFrequincyList: types.FETCH_FREQUINCY_LIST,
				getCurrency: types.GET_CURRENCY,
				syncCart: types.SYNC_CART,
				fetchUser: types.FETCH_USER,
				logout: types.LOGOUT,
				calculateUserAge: types.CALCULATE_USER_AGE,
				getCheckoutTexts: types.GET_CHECKOUT_TEXTS,
				fetchStoreLocation: types.FETCH_STORE_LOCATION,
				getSiteOption: types.GET_SITE_OPTIONS
			}),
			...mapGetters({
				getSEO: types.GET_SEO,
				getRegistered: types.GET_REGISTERED
			}),

			getPageSeo(url, initialLoad = false) {
				if (initialLoad) {
					this.seo.title = 'Loading';
				}
				return connect().get(`get-site-seo?url=${url}`);
			},
			// NAV METHODS
			toggleProfilePopup() {
				this.showProfilePopup = !this.showProfilePopup;
			},
			toggleCategoryPopup(type) {
				
				if (!this.showCategoryPopup) {
					this.showCategoryPopup = true;
				}

				this.popupType = type;

				document.getElementById('overlay').style.display = this.showCategoryPopup ? 'block' : '';
			},
			hideCategoryPopup() {
				this.showCategoryPopup = false;
				document.getElementById('overlay').style.display = '';
			},
			toggleMobileSidebar() {
				this.showMobileSideBar = !this.showMobileSideBar;
				
				document.getElementById('overlay').style.display = this.showMobileSideBar ? 'block' : '';
				document.getElementById('overlay').style.zIndex = this.showMobileSideBar ? 1400 : 1000;
			},
			getNavbarHeight(navbarHeight) {
				this.navbarHeight = navbarHeight;
			},
			getFooterHeight(footerHeight) {
				this.footerHeight = footerHeight;
			},
			getCategories() {
				connect().get("/categories/tree", {})
					.then(res => {
						if (res.status == 200) {
							this.categories = res.data.data;
						}
					})
			},
			getVendors() {
				connect().get("/brands", {})
					.then(res => {
						if (res.status == 200 && res.data) {
							this.vendors = res.data.data;
						}
					});
			},
		},
		watch: {
			registered(registered) {
				const swalSettings = {
					title: 'Congratulations!',
					text: 'You have successfully created an account', 
					confirmButtonText: 'Start Shopping',
					customClass: {
						confirmButton: 'flex-fill mx-5'
					}
				}

				if (registered) {
					showAlert(swalSettings)
					this.setRegistered({ data: false })
				}
			},
			$route(to) {
				window.scrollTo({top: 0, behavior: 'smooth'});
				this.hideCategoryPopup();
				this.showMobileSideBar = false;
				document.getElementById('overlay').style.display = '';

				// console.log(to);
				this.getPageSeo(to.fullPath)
				.then(res => {
					let seo = res.data.data;
					this.seo = {...this.seo, ...seo};
				})

				if (isLoggedIn()) {
					let user = getUser();

					this.fetchUser({token: user.token})
						.then(res => {
							if (res.status == 200) {
								let user = res.data.data;
								let status = user.status;
								this.updateUser(user);

								if (status == 'blocked') {

									let swalSettings = {
										title: 'You have been logged out',
										allowOutsideClick: false
									};

									showAlert(swalSettings, 'error')
										.then(swal => {
											this.logout();
											this.$router.push({name: 'login'})
										})
								}
							}
						})
				}
			},
		}
	};
</script>
