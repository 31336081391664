import home from './home.route';
import about from './about.route';
import login from './login.route';
import registration from './registration.route';
import bbdoit from './bbdoit.route';
import contact from './contact.route';
import account from './account.route';
import products from './products.route';
import brand from './brand.route';
import cart from './cart.route';
import frequincy from './frequincy.route';
import bbdoitView from './bbdoit-view.route';
import checkout from './checkout.route';
import orders from './orders.route';
import ordersView from './orders-view.route';
import page from './page.route';
import verify from './verify.route';
import blogs from './blog.route';
import blogView from './blog-view.route';
import productView from './product.route';
import activate from './activate.route';
import password from './password.route';
import newPassword from './new-password.route';

export default [
    home,
    about,
    login,
    registration,
    bbdoit,
    contact,
    account,
    products,
    productView,
    brand,
    cart,
    frequincy,
    bbdoitView,
    checkout,
    orders,
    ordersView,
    verify,
    blogs,
    blogView,
    activate,
    password,
    newPassword,

    //KEEP THIS ROUTE AT LAST
    page
]