<template>
    <div>
        <div class="card">
            <div class="card-body px-4">
                <div class="font-xlarge font-sf-medium mb-4">Phone Verification</div>
                <div v-if="!smsVerified" >
                    <div class="font-small font-gray my-3">
                        A verification code was sent to <b>{{verificationForm.phone}}</b>
                    </div>
                    <div class="form-group font-xsmall mb-1">
                        <input
                            type="text"
                            :class="`form-control font-xsmall ${verificationFormErrors.code ? 'border-danger' : ''}`"
                            placeholder="Enter code"
                            v-model="verificationForm.code"
                        />
                    </div>
                    <div class="mt-0" v-if="showResend">
                        <button
                            class="btn btn-clear btn-sm font-green pl-0"
                            v-bind:disabled="resendDisabled"
                            @click="sendVerificationCode"
                        >
                            <span class="font-gray">
                                Resend code
                            </span>
                            {{ timer ? timer + 's' : '' }}
                        </button>
                    </div>
                    <div v-if="verificationFormErrors.code" class="mt-5" >
                        <span class="font-xsmall font-gray clickable" @click="handleNext(1)" >Return to information</span>
                    </div>
                </div>
                <div v-else >
                    <div class="font-small font-gray my-3">
                        Your phone is already verified. Please proceed to the next step.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'userForm',
            'verificationForm',
            'verificationFormErrors',
            'sendSmsCode',
            'handleNext',
            'smsCode',
            'smsVerified'
        ],
        mounted() {
            if (!this.smsVerified) {
                this.sendVerificationCode();
            }
        },
        data() {
            return {
                timer: 0,
                resendDisabled: true,
                showResend: false,
                processing: false,
                sendingCode: false,
            }
        },
        methods: {
            sendVerificationCode() {
                this.sendSmsCode();
                this.startTimer();
            },
            startTimer() {
                this.showResend = true;
                this.timer = 120;
                this.resendDisabled = true;
                let interval = setInterval(() => {
                    this.timer--;
                    if (this.timer == 0) {
                        clearInterval(interval);
                        this.resendDisabled = false;
                    }
                    if (this.verificationFormErrors.code != '') {
                        clearInterval(interval);
                        this.showResend = true;
                        this.resendDisabled = false;
                        this.timer = 0;
                    }
                }, 1000);
            },
        }
    }
</script>