<template>
    <div class="row">
        <h3 class="font-xxlarge mb-3 text-center text-md-left font-sf-medium col-lg-3 col-12">
            {{ activeFilter }}
        </h3>

        <div class="d-flex flex-wrap align-items-center flex-column flex-md-row mb-3 col">
            <span class="mr-1 font-gray font-xsmall ml-0 ml-lg-auto col-lg-1 px-0 mb-3 mb-md-0">Sort by:</span>
            <button
                v-for="(filter, index) in filters"
                :key="`secondaryFilter${ index }`"
                :class="`btn btn-sm btn-white btn-radius-sm btn-sm w-xs-100 mb-2 mb-md-0 ml-0 ml-md-2 ${ filter.toggleValue ? (activeSort == filter.value || activeSort == filter.toggleValue) ? 'active' : ''  : (activeSort === filter.value ? `active` : '') }`"
                @click="() => {
                    if (filter.toggleValue) {
                        setSort(filter.value != activeSort ? filter.value : filter.value == activeSort ? filter.toggleValue : filter.value);
                    } else {
                        setSort(filter.value)
                    }
                }"
            >
                <div v-if="filter.iconText" class="d-inline-block bg-green font-white font-xxsmall">{{ filter.iconText }}</div>
                <img v-if="filter.iconImage" class="img-small" :src="require(`@assets/img/${ filter.iconImage }`)" alt="" />
                <i v-if="filter.icon" :class="filter.icon" />
                <span
                    v-if="filter.toggleValue"
                >
                    {{ (filter.value != activeSort && filter.toggleValue != activeSort) ? filter.label : filter.value == activeSort ? filter.label : filter.toggleLabel }}
                </span>
                <span v-else >
                    {{ filter.label }}
                </span>
                <i v-if="filter.withArrow" class="fas fa-long-arrow-alt-down ml-1" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        activeFilter: String,
        activeSort: String,
        setSort: Function
    },
    data() {
        return {
            filters: [
                {
                    label: "Hot Deals",
                    iconImage: "hot.png",
                    value: "hot-deals:desc"
                },
                {
                    label: "Latest",
                    iconText: "NEW",
                    value: "latest:desc"
                },
                {
                    label: "Top Sales",
                    iconImage: "hot.png",
                    value: "sales:desc"
                },
                {
                    label: "Trending",
                    iconImage: "trending.png",
                    value: "trending:desc"
                },
                {
                    label: "Alphabetical A-Z",
                    toggleLabel: 'Alphabetical Z-A',
                    withArrow: true,
                    value: "alpha:asc",
                    toggleValue: 'alpha:desc'
                },
                {
                    label: "Price L-H",
                    toggleLabel: 'Price H-L',
                    withArrow: true,
                    value: "price:asc",
                    toggleValue: 'price:desc'
                }
            ]
        }
    },
    setup() {
        
    },
}
</script>