<template>
    <b-modal
        id="iframe-modal"
        :centered="true"
        v-model="show"
        :hide-footer="true"
        :hide-header="true"
        :no-close-on-backdrop="true"
    >
        <iframe :src="src" class="w-100" :style="{minHeight: '500px'}" />
    </b-modal>
</template>

<script>
export default {
    props: ['src', 'show']
}
</script>