<template>
    <div class="phone-number-page py-4">
        <div class="row">
            <div class="col-lg-8 col-12 d-flex flex-column">
                <div>
                    <span @click="prevStep(step)" class="font-xxlarge font-sf-medium d-flex align-items-center clickable" >
                        <i class="fas fa-chevron-left font-small mr-2"></i> <span>Change phone number</span>
                    </span>
                </div>
                <div class="font-medium font-gray font-sf-medium my-1">Please follow steps to change your phone number</div>

                <div v-if="step == 1">
                    <div class="mb-5">
                        <label class="mb-0 mt-3 font-xsmall font-gray" >Enter Buyboy Password</label>
                        <div class="input-group append">
                            <input
                                :type="passwordToggled ? 'text' : 'password'"
                                class="form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black"
                                v-model="formValues.password"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    <i 
                                        :class="['fas font-gray clickable', passwordToggled ? 'fa-eye-slash': 'fa-eye' ]"
                                        @click="() => { passwordToggled = !passwordToggled }"
                                    ></i>
                                </span>
                            </div>
                        </div>
                        <div v-if="formErrors.password"  class="font-small font-red">{{ formErrors.password }}</div>
                    </div>
                </div>
                <div v-else>
                    <!-- <div>
                        <label class="mb-0 mt-3 font-xsmall font-gray" >Old Phone Number</label>
                        <input
                            type="text"
                            class="form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black"
                            v-model="formValues.current_phone"
                        />
                        <div v-if="formErrors.current_phone" class="font-xsmall font-red">{{ formErrors.current_phone }}</div>
                    </div> -->

                    <div>
                        <label class="mb-0 mt-3 font-xsmall font-gray" >New Phone Number</label>
                        <!-- <input
                            type="text"
                            class="form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black"
                            v-model="formValues.new_phone"
                        /> -->
                        <the-mask 
                            :mask="['###########']"
                            placeholder="ex. 09xxxxxxxxx"
                            :value="formValues.new_phone"
                            :class="`form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black ${ formErrors.new_phone ? '' : '' }`"
                            type="text"
                        />
                        <div v-if="formErrors.new_phone" class="font-small font-red">{{ formErrors.new_phone }}</div>
                    </div>
                </div>
                <!-- <div v-else >
                    <label class="mb-0 mt-3 font-xsmall font-gray" >Enter the verification code sent to your new phone number</label>
                    <input
                        type="text"
                        class="form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black mb-5"
                    />
                </div> -->

                <button 
                    @click="nextStep(step)"
                    class="btn btn-success my-4 py-2 font-small bg-green btn-block rounded-pill font-white"
                    :disabled="processing"
                >{{ processing ? (step == 1 ? 'Validating...' : 'Saving...') : 'Continue' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { connect } from '@api';
import { mapMutations } from 'vuex';
import * as types from '../../store/mutation-types';

export default {
    props: {
        user: Object
    },
    data() {
        return {
            step: 1,
            formValues: {
                password: '',
                // current_phone: '',
                new_phone: ''
            },
            formErrors: {
                password: '',
                // current_phone: '',
                new_phone: ''
            },
            passwordToggled: false,
            processing: false,
            smsCode: ''
        }
    },
    mounted() {
        if (this.$route.query) {
            this.step = this.$route.query.step ? this.$route.query.step : 1;
            this.formValues.new_phone = this.$route.query.phone ? this.$route.query.phone : ''
        }
    },
    methods: {
        ...mapMutations({
            updateUser: types.UPDATE_USER
        }),
        async validateNumbers() {

            this.reset('formErrors');

            let {new_phone} = this.formValues;
            // let phoneRegex = /^(09|\+639)\d{9}$/; // PH Phone Regex
            let phoneRegex = /^09[0-9]{9}$|^0?2[0-9]{7}$|^0?32[0-9]{7}$/;
            let hasErrors = false;

            if (new_phone == '') {
                this.formErrors.new_phone = 'Please enter your new phone number';
                hasErrors = true;
            }
            if (!phoneRegex.test(new_phone)) {
                this.formErrors.new_phone = 'Please enter a valid phone number';
                hasErrors = true;
            }

            if (hasErrors) {
                this.processing = false;
                return false;
            }

            return true;
        },
        reset(type = null) {
            let keys = Object.keys(this.formValues);

            for (let key of keys) {
                if (type == null) {
                    this.formValues[key] = '';
                    this.formErrors[key] = '';
                } else {
                    this[type][key] = '';
                }
            }
        },
        async nextStep(currentStep) {

            this.processing = true;

            if (currentStep == 1) {

                if (this.formValues.password == '') {
                    this.formErrors.password = 'Please enter your password';
                    return;
                }
                
                await connect().post('customers/validate-password', { current_password: this.formValues.password })
                                .then(res => {
                                    
                                    this.step = currentStep + 1;
                                    this.processing = false;
                                    
                                    this.reset();
                                })
                                .catch(err => {
                                    this.formErrors.password = err.data.message;
                                    this.processing = false;
                                    return false;
                                })
            }

            if (currentStep == 2) {

                if (!(await this.validateNumbers())) {
                    this.processing = false;
                    return;
                } else {
                    this.$router.push({ 
                        name: 'verify.phone',
                        query: {
                            phone: this.formValues.new_phone,
                        } 
                    });
                }
            }
        },
        prevStep(currentStep) {

            if (currentStep == 1) {
                this.$router.push('/account/profile');
                return;
            }

            if (this.$route.query && this.$route.query.phone) {
                this.$router.push('/account/profile');
                return;
            }

            this.step = currentStep - 1;
        },
    }
}
</script>