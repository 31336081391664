<template>
    <div :class="['checkout-page ', currentStep < (user && user.id ? 4 : 5) ? 'bg-gray-2 pb-5' : '']">
        <div :class="['overlay-loader ', processing ? 'show' : '']">
            <div class="bg-green p-3 rounded">
                <div class="font-upsized font-sf-semibold text-center font-white">Processing...</div>
                <div class="font-large text-center font-white">Please do not refresh or reload this page.</div>
            </div>
        </div>
        <Breadcrumbs
            v-if="currentStep < (user && user.id ? 4 : 5)"
            :currentStep="currentStep"
            :handleNext="handleNext"
            :user="user && user.id ? user : false"
        />
        <div v-else >
            <div class="bg-mint-green mb-4">
                <div class="container">
                    <div class="d-flex align-items-center justify-content-center py-5">
                        <div class="text-center col-lg-5 col-10" >
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="fa-stack fa-2x mr-3">
                                    <i class="fas fa-certificate font-yellow fa-stack-2x"></i>
                                    <i class="fas fa-check font-mint-green fa-stack-1x"></i>
                                </div>
                                <div class="font-xxlarge font-sf-medium">Your order has been placed</div>
                            </div>
                            <div class="my-4 font-small font-sf-medium font-gray">
                                #{{ orderData.reference_number }} is placed<span v-if="paymentMethod && (paymentMethod.slug == 'bdo' || paymentMethod.slug == 'unionbank')" >. {{confirmationText ? confirmationText : ''}}</span>
                            </div>
                            <router-link
                                :to="{ name: 'home' }"
                                class="btn btn-sm py-3 btn-green rounded-pill px-5"
                            >Back to Home</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-12 mb-4">
                    <Information
                        v-if="currentStep == 1"
                        :deliveryOption="formValues.delivery_option"
                        :changeDeliveryOption="changeDeliveryOption"
                        :provinces="provinces"
                        :cities="cities"
                        :addressForm="addressForm"
                        :addressFormErrors="addressFormErrors"
                        :getCities="getCities"
                        :user="user"
                        :userForm="userForm"
                        :userFormErrors="userFormErrors"
                        :checkoutType="checkoutType"
                        :showUserForm="showUserForm"
                        :showAddressForm="showAddressForm"
                        :toggleUserForm="toggleUserForm"
                        :toggleAddressForm="toggleAddressForm"
                        :updateAddress="updateAddress"
                        :updateUserDetails="updateUserDetails"
                        :goToLogin="goToLogin"
                        :mapCoords="mapCoords"
                        :setPlace="setPlace"
                        :validYears="validYears"
                    />
                    <Verification
                        v-if="user.id == undefined && currentStep == 2"
                        :userForm="userForm"
                        :verificationForm="verificationForm"
                        :verificationFormErrors="verificationFormErrors"
                        :handleNext="handleNext"
                        :smsCode="smsCode"
                        :sendSmsCode="sendSmsCode"
                        :smsVerified="smsVerified"
                    />
                    <Schedule 
                        v-if="currentStep == (user && user.id ? 2 : 3)"
                        :schedule="schedule"
                        :deliveryOption="formValues.delivery_option"
                        :times="times"
                        :dates="dates"
                        :setDate="setDate"
                        :setTime="setTime"
                        :scheduleError="scheduleError"
                        :subtext="subtext"
                        :blockOffDates="blockOffDates"
                    />
                    <Payment
                        v-if="currentStep == (user && user.id ? 3 : 4)" 
                        :paymentMethods="paymentMethods"
                        :deliveryOption="formValues.delivery_option"
                        :selectedMethod="formValues.payment_type_id"
                        :setPaymentMethod="setPaymentMethod"
                        :bankAccounts="bankAccounts"
                        :orderContactEmail="orderContactEmail"
                        :toggleDepositModal="toggleDepositModal"
                        :cardForm="cardForm"
                        :cardFormErrors="cardFormErrors"
                        :paymongoValues="paymongoValues"
                        :paymentText="paymentText"
                        :subtotal="calculatedSubtotal"
                        :paymentError="paymentError"
                    />
                    <OrderDetails
                        v-if="currentStep > (user && user.id ? 3 : 4)"
                        :schedule="schedule"
                        :orderData="orderData"
                        :depositForm="depositForm"
                        :depositFormErrors="depositFormErrors"
                        :toggleDepositModal="toggleDepositModal"
                        :handleDepositModalFileChange="handleDepositModalFileChange"
                        :handleDepositModalSubmit="handleDepositModalSubmit"
                        :uploadedFile="uploadedFile"
                        :handleRemoveUploadedFile="handleRemoveUploadedFile"
                        :formValues="formValues"
                        :bankAccounts="bankAccounts"
                        :orderContactEmail="orderContactEmail"
                        :paymentMethods="paymentMethods"
                        :paymentText="paymentText"
                    />
                </div>
                <div class="col-lg-4 col-12">
                    <div class="card border border-gray-1 rounded">
                        <div class="card-body">
                            <div class="font-xxlarge font-sf-medium">
                                <span v-if="currentStep < (user && user.id ? 4 : 5)" >Checking Out</span>
                                <span v-else >Your Total</span>
                            </div>
                            <div 
                                class="font-xxsmall font-green my-2 clickable"
                                @click="showCart"
                            >View ({{ orderData && orderData.items ? orderData.items.length : 0 }}) item{{ orderData && orderData.items && orderData.items.length > 1 ? 's' : '' }} you checked out</div>
                            <div class="price-breakdown font-small font-sf-medium font-gray">
                                <div class="d-flex align-items-center justify-content-between my-3">
                                    <div>Subtotal</div>
                                    <Price
                                        :price="orderData && orderData.total ? orderData.total : 0"
                                    />
                                </div>
                                <div v-if="formValues.shipping_amount > 0" >
                                    <div class="d-flex align-items-center justify-content-between my-3">
                                        <div>Shipping Fee</div>
                                        <Price
                                            :price="formValues.shipping_amount"
                                        />
                                    </div>
                                </div>
                                <div v-if="discounts.length > 0" >
                                    <div 
                                        class="d-flex align-items-center justify-content-between my-3"
                                        v-for="(discount, index) in discounts"
                                        :key="index"
                                    >
                                        <div>{{discount.name}}</div>
                                        <Price
                                            :price="discount.value"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="border-top border-bottom py-3" v-if="currentStep < (user && user.id ? 4 : 5)" >
                                <input 
                                    type="text" 
                                    :class="`form-control placeholder-green font-green font-xsmall ${ couponCodeError ? 'border-danger' : '' }`" 
                                    placeholder="Apply promo code"
                                    v-model="couponCode"
                                    @keypress.enter="applyCoupon"
                                >
                                <!-- <div class="font-xsmall font-red" v-if="couponCodeError">{{couponCodeError}}</div> -->
                            </div>
                            <div class="d-flex align-items-center justify-content-between font-xlarge font-sf-medium py-2">
                                <span>Total</span>
                                <Price
                                    :price="calculatedSubtotal"
                                />
                            </div>
                            <div v-if="formValues.amount_paid != ''" class="d-flex align-items-center justify-content-between font-xlarge font-sf-medium py-2">
                                <span>Amount Paid</span>
                                <Price
                                    :price="formValues.amount_paid"
                                />
                            </div>
                            <button 
                                v-if="currentStep < (user && user.id ? 4 : 5)"
                                class="btn py-2 btn-block btn-green mt-3"
                                @click="handleNext( currentStep + 1 )"
                                :disabled="processing"
                            >
                                <span v-if="processing" >Processing</span>
                                <span v-else v-html="currentStep < (user && user.id ? 3 : 4) ? 'Next' : 'Checkout'" ></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-gray-2 py-5" v-if="currentStep > (user && user.id ? 3 : 4)" >
            <div class="container">
                <h3 class="font-xxlarge mb-3 text-center text-md-left font-sf-medium">
                    Recommended for You
                </h3>
                <div v-if="products && products.length > 0" class="row row-thin mb-5">
                    <div 
                        v-for="product in products"
                        :key="`productsCard${product.id}`"
                        class="col-xl-3 col-sm-6 col-12 mb-3"
                    >
                        <ProductCard
                            :product="product"
                            @toggle-list-modal="$emit('toggle-list-modal')"
                            className="bg-white"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="orderData && orderData.items && orderData.items.length > 0" >
            <Cart 
                :orderData="orderData"
            />
        </div>
        <DepositModal
            :show="showDepositModal"
            :toggleDepositModal="toggleDepositModal"
            :depositForm="depositForm"
            :depositFormErrors="depositFormErrors"
            :handleDepositModalFileChange="handleDepositModalFileChange"
            :handleDepositModalSubmit="handleDepositModalSubmit"
            :uploadedFile="uploadedFile"
            :handleRemoveUploadedFile="handleRemoveUploadedFile"
            :uploading="uploading"
        />
        <IframeModal
            :show="showIframeModal"
            :src="IframeModalSrc"
        />
    </div>
</template>

<script>
    import Breadcrumbs from './components/breadcrumbs';
    import Cart from './components/cart';
    import DepositModal from './components/depositModal';
    import Information from './information';
    import Payment from './payment';
    import Schedule from './schedule';
    import Verification from './verification.vue';
    import Placed from './placed';
    import OrderDetails from './orderDetails';
    import IframeModal from './components/iframeModal';
    import * as sessionHelper from '@helpers/session.helper';
    import * as cartHelper from '@helpers/cart.helper';
    import { mapMutations, mapActions, mapGetters } from 'vuex';
    import * as types from '../../store/mutation-types';
    import { connect } from '@api';
    import moment from 'moment';
    import validate from '@helpers/validation.helper';
    import * as Paymongo from '@helpers/paymongo.helper';
    import ProductCard from '@components/productCard/ProductCard'
    import { getPage } from '@helpers/caboodle.helper';
    import { gmapApi } from 'vue2-google-maps';
    import { getValidYears, validateBirthday } from '@helpers/date.helper';
    import toastr from 'toastr';

    const initialUserForm = {
        fullname: '',
        email: '',
        phone: '',
        gender: 'male',
        birthday: ''
    }

    const initialUserFormErrors = {
        fullname: '',
        email: '',
        phone: '',
        gender: '',
        birthday: ''
    }

    const initialDepositForm = {
        imageData: null,
        account_name: '',
        payment_date: '',
        amount_paid: '',
        reference_number: ''
    }

    const initialDepositFormErrors = {
        imageData: '',
        account_name: '',
        payment_date: '',
        amount_paid: '',
        reference_number: ''
    }

    const initialAddressForm = {
        address: '',
        customer_id: 0,
        email: '',
        name: '',
        phone: '',
        country_id: 1,
        province_id: '',
        city_id: '',
        zip: '',
        custom_area: '',
        address_line_1: '',
        isGift: false,
        city: '',
        custom_province: '',
        lat: 0,
        lng: 0
    }

    const initialAddressFormErrors = {
        address: '',
        customer_id: '',
        email: '',
        name: '',
        phone: '',
        country_id: '',
        province_id: '',
        city_id: '',
        zip: '',
        custom_area: '',
        address_line_1: '',
        isGift: '',
        city: '',
        custom_province: '',
        lat: 0,
        lng: 0
    }

    const initialCardFormValues = {
        card_number: '',
        expiration_month: '',
        expiration_year: '',
        cvc: ''
    }

    const initialCardFormErrors = {
        card_number: '',
        expiration_month: '',
        expiration_year: '',
        cvc: ''
    }

    export default {
        components: {
            Breadcrumbs,
            Cart,
            DepositModal,
            Information,
            Payment,
            Schedule,
            OrderDetails,
            IframeModal,
            ProductCard,
            Verification
        },
        data() {
            return {
                formValues: {
                    delivery_option: 'delivery',
                    items: [],
                    shipping_address: 0,
                    billing_address: 0,
                    payment_type_id: 0,
                    date_delivery: null,
                    coupon_id: 0,
                    deposit: '',
                    amount_paid: '',
                    shipping_amount: 0
                },
                schedule: {
                    date: null,
                    time: null
                },
                depositForm: initialDepositForm,
                depositFormErrors: initialDepositFormErrors,
                discounts: [],
                couponCode: '',
                couponCodeError: '',
                scheduleError: '',
                bankAccounts: [],
                paymentMethods: [],
                checkoutType: '',
                addressForm: initialAddressForm,
                addressFormErrors: initialAddressFormErrors,
                userForm: initialUserForm,
                userFormErrors: initialUserFormErrors,
                provinces: [],
                cities: [],
                currentStep: 1,
                processing: false,
                times: [],
                orderData: null,
                showUserForm: false,
                showAddressForm: false,
                orderContactEmail: '',
                showDepositModal: false,
                uploadedFile: null,
                uploading: false,
                cardForm: initialCardFormValues,
                cardFormErrors: initialCardFormErrors,
                paymongoValues: {
                    processing: false,
                    paymentMethod: null,
                    paymentIntent: null,
                    success: false,
                    errors: null,
                    authSuccess: false
                },
                showIframeModal: false,
                IframeModalSrc: '',
                checkoutTexts: '',
                subtext: '',
                paymentText: '',
                confirmationText: '',
                products: [],
                blockOffDates: [],
                mapCoords: {
                    lat: 14.6091,
                    lng: 121.0223
                },
                paymentError: '',
                emailVerified: false,
                verificationForm: {
                    email: '',
                    code: '',
                    phone: ''
                },
                verificationFormErrors: {
                    email: '',
                    code: '',
                    phone: ''
                },
                smsCode: '',
                smsVerified: ''
            }
        },
        computed: {
            validYears() {
                return getValidYears()
            },
            google() {
                return gmapApi
            },
            user() {
                return sessionHelper.getUser();
            },
            dates() {
                let dates = [];

                let today = moment().startOf('day').toDate();
                dates.push(today);
                
                let size = 6;
                let current = moment();
                while (size > 0) {
                    current = current.add(1,'days');
                    let temp = current.startOf('day').toDate();
                    dates.push(temp);
                    size--;
                }

                return dates;
            },
            paymentMethod() {

                let { paymentMethods, formValues } = this;
                let paymentMethod = null;

                paymentMethod = paymentMethods.find(method => method.id == formValues.payment_type_id);

                return paymentMethod ? paymentMethod : false;
            },
            calculatedSubtotal() {
                let value = 0;
                let { orderData, discounts } = this;
                let shippingAmount = this.formValues.shipping_amount;

                if (orderData) {
                    value = parseFloat(orderData.subtotal);

                    if (discounts.length > 0) {
                        discounts.map(discount => {
                            value = value - parseFloat(discount.value);
                        })
                    }
                }

                if (shippingAmount > 0) {
                    value += parseFloat(shippingAmount)
                }

                return value;
            },
            toastrOptions() {
                return {
                    timeOut: 5000,
                    positionClass: 'toast-bottom-right',
                }
            },
        },
        created() {
            this.getProvinces();
            this.getTimes();
            this.getPaymentMethods();
            this.getBankAccounts();
            this.getProducts();
            this.getBlockOffDates();

            if (this.$route.query) {
                
                if (this.$route.query.token) {
                    this.getCheckoutData(this.$route.query.token)
                        .then(res => {
                            if (res.status == 200) {
                                this.orderData = res.data.data;
                            }
                        })
                        .catch(err => {
                            console.error(err.data.message)
                        })
                }
            }
        },
        mounted() {

            this.checkoutTexts = this.getCheckoutText();
            this.displayTexts('delivery');

            this.getSiteOption({slug: 'order-deposit-slip-email'})
                .then(res => {
                    if (res.data.data) {
                        this.orderContactEmail = res.data.data.order_deposit_slip_email
                    }
                })
                .catch(err => {
                    console.error(err)
                })

            this.initializeForms();

            if (this.user && this.user.id) {

                let { user } = this;

                let userFormValues = {
                    fullname: user.customer && user.customer.fullname ? user.customer.fullname : '',
                    email: user.email ? user.email : '',
                    phone: user.customer && user.customer.phone ? user.customer.phone : '',
                    birthday: user.customer && user.customer.birthday ? user.customer.birthday : ''
                };
                this.userForm = { ...this.userForm, ...userFormValues };

                if (user.customer && user.customer.primary_address) {

                    let address = { ...this.addressForm, ...user.customer.primary_address };
        
                    // this.getCities(address.province_id);

                    this.addressForm = {...this.addressForm, ...address }

                    if (address.lat != 0 && address.lng != 0) {
                        this.mapCoords = {  
                            ...this.mapCoords,
                            lat: address.lat,
                            lng: address.lng
                        }
                    }

                    let formatted_address = [address.address_line_1, address.city, address.custom_area, address.custom_province]
                                        .filter(e => {
                                            return e != ''
                                        })
                                        .join(', ');

                    this.addressForm.address = formatted_address;

                    this.addressForm.name = this.userForm.fullname;
                    this.addressForm.phone = this.userForm.phone
                    this.addressForm.email = this.userForm.email;
                } else {

                    this.showAddressForm = true;
                }
            } else {
                this.showAddressForm = true;
                this.showUserForm = true;
            }

            window.addEventListener(
                'message',
                async (ev) => {
                    this.showIframeModal = false;


                    if (ev.data == '3DS-authentication-complete') {
                        console.log('3DS-authentication-complete', ev);
                        
                        let { paymentIntent } = this.paymongoValues;

                        paymentIntent = await Paymongo.getPaymentIntent(paymentIntent.id).then(res => res.data.data).catch(err => err.data);

                        if (paymentIntent.errors) {
                            this.processing = false;
                            return;
                        }

                        let paymentIntentStatus = paymentIntent.attributes.status;

                        if (paymentIntentStatus == 'succeeded') {

                            let amount = String(paymentIntent.attributes.amount);
                            
                            this.formValues.amount_paid = this.calculatePaymongoAmountPaid(amount);
                            this.paymongoValues.success = true;

                            this.placeOrder();

                        } else {
                            this.paymongoValues.errors = [
                                {
                                    detail: 'Payment failed'
                                }
                            ];
                            this.processing = false;
                            return;
                        }

                    }
                },
                false
            )
        },
        methods: {
            ...mapGetters({
                getCheckoutText: types.GET_CHECKOUT_TEXT
            }),
            ...mapMutations({
                setUser: types.SET_USER
            }),
            ...mapActions({
                getSiteOption: types.GET_SITE_OPTIONS,
                syncCart: types.SYNC_CART
            }),
            showErrors(errors) {
                toastr.options = this.toastrOptions;

                // If errors parameter is an object
                if (typeof errors === 'object') {

                    Object.keys(errors).map(errorKey => {
                        if (errors[errorKey] != '') {
                            let message = errors[errorKey];
                            if (typeof message === 'object') {
                                toastr.error(`<span class="font-sf-regular">An Error has occured.</span>`);
                            } else {
                                toastr.error(`<span class="font-sf-regular">${errors[errorKey]}</span>`);
                            }
                        }
                    });

                    return;
                }

                // If errors paramater is an array
                if (typeof errors === Array) {
                    errors.map(error => {
                        if (error != '') {
                            toastr.error(`<span class="font-sf-regular" >${error}</span>`);
                        }
                    })

                    return;
                }

                // If errors parameter is not an object nor an array
                if (errors != '') {
                    toastr.error(`<span class="font-sf-regular" >${errors}</span>`);
                }
            },
            initializeForms() {
                this.userForm = {
                    fullname: '',
                    email: '',
                    phone: '',
                    gender: 'male',
                    birthday: ''
                }
                this.addressForm = {
                    address: '',
                    customer_id: 0,
                    email: '',
                    name: '',
                    phone: '',
                    country_id: 1,
                    province_id: '',
                    city_id: '',
                    zip: '',
                    custom_area: '',
                    address_line_1: '',
                    isGift: false,
                    city: '',
                    custom_province: '',
                    lat: 0,
                    lng: 0
                };
                this.cardForm = {
                    card_number: '',
                    expiration_month: '',
                    expiration_year: '',
                    cvc: ''
                };
                this.depositForm = {
                    imageData: null,
                    account_name: '',
                    payment_date: '',
                    amount_paid: '',
                    reference_number: ''
                },
                this.emailVerified = false;
            },
            async getBlockOffDates() {
                
                let response = await connect().get('/checkout/get_block_off_dates').then(res => res).catch(err => err);

                if (response.status == 200) {
                    this.blockOffDates = response.data.data;
                }
            },
            async getProducts() {

                let params = {
                    page_size: 12,
                    sort: "relevance:desc",
                    tags: "recommended"
                };

                let response = await connect().get('products/get', { params: params }).then(res => res).catch(err => err);

                if (response.status == 200) {
                    this.products = response.data.products.data;
                } else {
                    return false;
                }
            },
            displayTexts(deliveryOption) {

                let { checkoutTexts } = this;

                this.subtext = checkoutTexts.subtext[deliveryOption];
                this.paymentText = checkoutTexts.payment[deliveryOption];
                this.confirmationText = checkoutTexts.confirmation[deliveryOption];
            },
            async validateCardForm() {
                const schema = {
                    properties: {
                        card_number: {
                            required: true,
                            allowEmpty: false,
                            messages: {
                                allowEmpty: 'Please enter your card number',
                                required: 'Please enter your card number',
                            }
                        },
                        expiration_month: {
                            required: true,
                            allowEmpty: false,
                            messages: {
                                allowEmpty: 'Please enter your card expiration month',
                                required: 'Please enter your card expiration month',
                            }
                        },
                        expiration_year: {
                            required: true,
                            allowEmpty: false,
                            messages: {
                                allowEmpty: 'Please enter your card expiration year',
                                required: 'Please enter your card expiration year',
                            }
                        },
                        cvc: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            messages: {
                                allowEmpty: 'Please enter your card security code',
                                required: 'Please enter your card security code',
                            }
                        }
                    }
                };

                const validateResults = validate(this.cardForm, schema, undefined, true);

                this.cardFormErrors = validateResults.errors;

                return validateResults.valid;
            },
            async processPaymongoPayment() {
                
                this.paymongoValues.errors = null;
                let { cardForm, addressForm, userForm, cities, provinces, orderData} = this;

                if (!(await this.validateCardForm())) {
                    this.showErrors(this.cardFormErrors);
                    return false;
                }

                let city = cities.find(city => city.id == addressForm.city_id);
                let province = provinces.find(province => province.id == addressForm.province_id);
                let string_subtotal = Number(this.calculatedSubtotal).toFixed(2).toString();
                let formatted_subtotal = Number(string_subtotal.replace('.', ''));

                // CREATE PAYMENT METHOD
                let paymentMethodParams = {
                    attributes: {
                        type: 'card',
                        details: {
                            card_number: cardForm.card_number,
                            exp_month: parseInt(cardForm.expiration_month),
                            exp_year: parseInt(cardForm.expiration_year),
                            cvc: cardForm.cvc
                        },
                        billing: {
                            address: {
                                line1: addressForm.address_line_1,
                                city: addressForm.city,
                                state: addressForm.province_name,
                                country: 'PH'
                            },
                            name: userForm.fullname,
                            email: userForm.email,
                            phone: userForm.phone
                        },
                        metadata: {
                            reference_number: orderData.reference_number
                        }
                    },
                };

                let paymentMethod = await Paymongo.createPaymentMethod(paymentMethodParams).then(res => res.data.data).catch(err => err.data);

                console.log('paymentMethod', paymentMethod);


                if (paymentMethod.errors) {
                    this.paymongoValues.errors = paymentMethod.errors;
                    this.showErrors(this.paymongoValues.errors);
                    return false;
                }

                // CREATE PAYMENT INTENT
                let paymentIntentParms = {
                    attributes: {
                        amount: formatted_subtotal,
                        payment_method_allowed: ['card'],
                        payment_method_options: {
                            card: {
                                request_three_d_secure: 'any'
                            }
                        },
                        description: `Order #${orderData.reference_number}`,
                        statement_descriptor: 'QuincyBuyboy',
                        currency: 'PHP',
                        metadata: {
                            reference_number: orderData.reference_number
                        }
                    }
                };

                let paymentIntent = await Paymongo.createPaymentIntent(paymentIntentParms).then(res => res.data.data).catch(err => err.data);

                console.log('paymentIntent', paymentIntent);

                if (paymentIntent.errors) {
                    this.paymongoValues.errors = paymentIntent.errors;
                    this.showErrors(this.paymongoValues.errors);
                    return false;
                }

                // ATTACH PAYMENT METHOD TO PAYMENT INTENT TO CREATE PAYMENT
                let intentParams = {
                    attributes: {
                        payment_method: paymentMethod.id
                    }
                }

                let attachPaymentIntent = await Paymongo.attachPaymentIntent(paymentIntent.id, intentParams).then(res => res.data.data).catch(err => err.data);
                console.log('attachPaymentIntent', attachPaymentIntent);

                if (attachPaymentIntent.errors) {
                    this.paymongoValues.errors = attachPaymentIntent.errors;
                    this.showErrors(this.paymongoValues.errors);
                    return false;
                }

                paymentIntent = attachPaymentIntent;

                let paymentIntentStatus = paymentIntent.attributes.status;

                this.paymongoValues.paymentMethod = paymentMethod;
                this.paymongoValues.paymentIntent = paymentIntent;

                if (paymentIntentStatus == 'succeeded') {

                    let amount = String(paymentIntent.attributes.amount);
                    
                    this.formValues.amount_paid = this.calculatePaymongoAmountPaid(amount);
                    this.paymongoValues.success = true;
                    this.placeOrder();
                    return true;

                }  else if (paymentIntentStatus == 'awaiting_next_action') {
                    
                    this.IframeModalSrc = paymentIntent.attributes.next_action.redirect.url;
                    this.showIframeModal = true;
                }

                return true;
            },
            async processPaymongoSecureCheckout(ev) {
                this.showIframeModal = false;

                console.log('processPaymongoSecureCheckout', ev);

                if (ev.data == '3DS-authentication-complete') {
                    
                    let { paymentIntent } = this.paymongoValues;

                    paymentIntent = await Paymongo.getPaymentIntent(paymentIntent.id).then(res => res.data.data).catch(err => err.data);

                    if (paymentIntent.errors) {
                        this.processing = false;
                        return;
                    }

                    let paymentIntentStatus = paymentIntent.attributes.status;

                    if (paymentIntentStatus == 'succeeded') {

                        let amount = String(paymentIntent.attributes.amount);
                        
                        this.formValues.amount_paid = this.calculatePaymongoAmountPaid(amount);
                        this.paymongoValues.success = true;

                        this.placeOrder();

                    } else {
                        this.paymongoValues.errors = [
                            {
                                detail: 'Payment failed'
                            }
                        ];
                        this.processing = false;
                        return;
                    }

                }
            },
            calculatePaymongoAmountPaid(amount) {

                let cents = amount.slice(-2);
                amount = amount.slice(0, (amount.length - cents.length));
                let amount_paid = amount + '.' + cents;
                amount_paid = parseFloat(amount_paid).toFixed(2);

                return amount_paid;
            },
            async getShippingFee() {
                let { mapCoords, schedule, formValues, paymentMethod } = this;

                if (formValues.delivery_option == 'delivery') {
                    let res = await connect()
                                    .get(`lalamove/shipping-cost/check?lng=${mapCoords.lng}&lat=${mapCoords.lat}&date=${moment(schedule.date).format('YYYY-MM-DD')}&time=${schedule.time.start_time}${paymentMethod && paymentMethod.slug == 'cod' ? '&cod=1' : ''}`)
                                    .then(res => res)
                                    .catch(err => {
                                        this.formValues.shipping_amount = 0;
                                        console.error(err)
                                    });

                    if (res.status == 200) {
                        this.formValues.shipping_amount = parseFloat(res.data.data.shipping_cost)
                    }
                } else {
                    this.formValues.shipping_amount = 0;
                }
            },
            async handleNext(step = null) {

                if (step > this.currentStep) {
                    if (this.currentStep == 1) {
    
                        if (this.formValues.delivery_option == 'delivery') {
    
                            if (!( await this.validateAddressForm() )) {
                                this.showErrors(this.addressFormErrors);
                                return;
                            }

                            if (this.user && this.user.id) {
                                if (!this.addressForm.isGift && this.showAddressForm) {
                                    this.addressFormErrors.address_line_1 = "Please save your address";
                                    this.showErrors(this.addressFormErrors);
                                    return;
                                }
                            }
                        }
    
                        if (!( await this.validateUserForm() )) {
                            this.showErrors(this.userFormErrors);
                            return;
                        }
                    }

                    // STEPS WITH NO VERIFICATION
                    if (this.user && this.user.id) {

                        if (this.currentStep == 2) {
                            if (!this.validateDateTime()) {
                                this.showErrors(this.scheduleError);
                                return;
                            }

                            this.getShippingFee();
                            this.formValues.payment_type_id = 0;
                        }

                        if (this.currentStep == 3) {
                            
                            let { paymentMethods, formValues } = this;
                            let paymentType = paymentMethods.find(method => method.id == formValues.payment_type_id);

                            if (!paymentType) {
                                this.paymentError = 'Please select a payment method';
                                this.showErrors(this.paymentError);
                                return false;
                            }

                            this.processing = true;

                            if (paymentType.slug == 'paymongo') {
                                if (!(await this.processPaymongoPayment())) {
                                    this.processing = false;
                                    return false;
                                }
                            } else {
                                await this.placeOrder();
                            }

                            return;
                        }
                    } else {

                        // WITH VERIFICATION
                        if (this.currentStep == 2) {
                            if (!this.validateSmsCode()) {
                                this.showErrors(this.verificationFormErrors.code);
                                return
                            }
                        }

                        if (this.currentStep == 3) {
                            if (!this.validateDateTime()) {
                                this.showErrors(this.scheduleError);
                                return;
                            }

                            this.getShippingFee();
                            this.formValues.payment_type_id = 0;
                        }

                        if (this.currentStep == 4) {
                            
                            let { paymentMethods, formValues } = this;
                            let paymentType = paymentMethods.find(method => method.id == formValues.payment_type_id);

                            if (!paymentType) {
                                this.paymentError = 'Please select a payment method';
                                this.showErrors(this.paymentError);
                                return false;
                            }

                            this.processing = true;

                            if (paymentType.slug == 'paymongo') {
                                if (!(await this.processPaymongoPayment())) {
                                    this.processing = false;
                                    return false;
                                }
                            } else {
                                await this.placeOrder();
                            }

                            return;
                        }
                    }

                }

                this.currentStep = step;
            },
            setPlace(place) {
                if (!place) return;

               

                let self = this;

                // REVERSE GEOCODE
                if (place.formatted_address) {

                    this.mapCoords = {
                        ...this.mapCoords,
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    };

                    this.setAddress(place);
                } else {

                    this.mapCoords = {
                        ...this.mapCoords,
                        lat: place.latLng.lat(),
                        lng: place.latLng.lng()
                    };

                    let latLng = {lat: parseFloat(place.latLng.lat()), lng: parseFloat(place.latLng.lng())};

                    let google = new this.google();
                    let geocoder = new google.maps.Geocoder();

                    geocoder.geocode({'location': latLng}, function(results, status) {
                        if (status == 'OK') {
                            place = results[0];

                            self.setAddress(place);
                        } else {
                            console.error('No location found');
                        }
                    })
                }
            },
            setAddress(place) {

                let address = {
                    address_line_1: "",
                    city: "",
                    custom_area: "",
                    custom_province: "",
                    zip: "",
                    lat: 0,
                    lng: 0
                }

                // address.address_line_1 = place.name;
                address.lat = place.geometry.location.lat();
                address.lng = place.geometry.location.lng();

                // GET VALUES FROM ADDRESS_COMPONENTS
                let address_line = [];
                let city = [];
                let province = [];
                let zip = '';
                let custom_area = '';
                place.address_components.map(address => {

                    // GET STREET NUMBER
                    if (address.types.includes('street_number')) {
                        address_line.push(address.long_name);
                    }

                    // GET STREET
                    if (address.types.includes('route')) {
                        address_line.push(address.long_name);
                    }

                    // GET BRGY
                    if (address.types.includes('neighborhood')) {
                        custom_area.push(address.long_name);
                    }
                    // GET CITY
                    if (address.types.includes('locality') || address.types.includes('sublocality')) {
                        city.push(address.long_name);
                    }
                    // GET PROVINCE
                    if (address.types.includes('administrative_area_level_1') || address.types.includes('administrative_area_level_2')) {
                        province.push(address.long_name);
                    }
                    // GET ZIP
                    if (address.types.includes('postal_code')) {
                        zip = address.long_name;
                    }
                })

                address.address_line_1 = address_line.join(' ');
                address.city = city.join(', ');
                address.custom_province = province.join(', ');
                address.zip = zip;
                address.custom_area = custom_area;

                let formatted_address = [address.address_line_1, address.city, address.custom_area, address.custom_province]
                                        .filter(e => {
                                            return (e != null && e != '')
                                        })
                                        .join(', ');

                this.addressForm.address = formatted_address;
                this.addressForm = {...this.addressForm, ...address};
            },
            async placeOrder() {

                this.processing = true;

                let { orderData, formValues, schedule, depositForm, uploadedFile, paymentMethods, user, userForm, addressForm, discounts} = this;

                let paymentType = paymentMethods.find(method => method.id == formValues.payment_type_id);

                let formattedDeliveryDate = moment(schedule.date).format('YYYY-MM-DD');
                let deliveryDate = formattedDeliveryDate + " " + schedule.time.start_time;

                let params = {
                    id: orderData.id,
                    customer_id: user && user.customer_id ? user.customer_id : 0,
                    payment_type: paymentType.slug,
                    delivery_option: formValues.delivery_option,
                    date_delivery: deliveryDate,
                    coupon_id: formValues.coupon_id,
                    lat: addressForm.lat,
                    lng: addressForm.lng,
                    shipping_amount: parseFloat(formValues.shipping_amount)
                }

                if (addressForm.isGift || (user && !user.id)) {

                    let addressData = addressForm;

                    let fullname = userForm.fullname.split(' ');

                    addressData.firstname = fullname[0];
                    addressData.lastname = fullname.slice(1).join(' ');
                    addressData.phone = userForm.phone;
                    addressData.email = userForm.email;

                    params.guestAddress = addressData;

                    if (addressForm.isGift) {
                        params.is_gift = addressForm.isGift;
                    }
                } else {
                    params.billing_address_object = user && user.customer && user.customer.primary_address ? user.customer.primary_address : null;
                    params.billing_address = user && user.customer && user.customer.primary_address ? user.customer.primary_address.id : null;
                    params.shipping_address = user && user.customer && user.customer.primary_address ? user.customer.primary_address.id : null;
                }

                if (discounts && discounts.length > 0) {

                    let bankDiscount = discounts.find(discount => discount.name == 'Bank transfer discount');

                    if (bankDiscount) {
                        params.bank_discount_amount = parseFloat(bankDiscount.value);
                    }
                }

                if (formValues.delivery_option != 'delivery') {
                    
                    let fullname = userForm.fullname.split(' ');

                    params.pickup_firstname = fullname[0];
                    params.pickup_lastname = fullname.slice(1).join(' ');
                    params.pickup_contact = userForm.phone;
                    params.email_address = userForm.email;
                }

                if (formValues.deposit) {
                    params.deposit_data = formValues.deposit;
                }

                if (this.paymongoValues.success && this.paymongoValues.success) {
                    let { paymentIntent } = this.paymongoValues;

                    paymentIntent = await Paymongo.getPaymentIntent(paymentIntent.id).then(res => res.data.data).catch(err => err.data);

                    if (paymentIntent.errors) {
                        this.paymongoValues.errors = paymentIntent.errors;
                        return false;
                    }

                    this.paymongoValues.paymentIntent = paymentIntent;

                    params.paymongo = JSON.stringify(this.paymongoValues);
                }

                connect().post('orders/place', params)
                    .then(res => {
                        if (res.status == 200) {
                            this.processing = false;
                            this.currentStep = this.user && this.user.id ? 4 : 5;
                            this.initializeForms();

                            // remove items from cart
                            let newItems = [];
                            let cart = cartHelper.getCart();

                            let cartParams = {
                                customer_id: user && user.customer_id ? user.customer_id : 0,
                                items: [],
                                source: 'website',
                                last_device: 'laptop',
                            };

                            cart.items.map(item => {

                                let existingItem = orderData.items.find(orderItem => orderItem.reference_id == item.product_id);

                                if (!existingItem) {
                                    newItems.push(item);
                                }
                            });

                            cartParams.items = newItems;

                            this.syncCart(cartParams);
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        this.processing = false;
                    })

            },
            toggleDepositModal() {
                this.showDepositModal = !this.showDepositModal;
            },
            handleDepositModalFileChange(fileList) {
                
                if (!fileList.length) return;
                
                this.uploadedFile = URL.createObjectURL(fileList[0]);

                this.depositForm.imageData = fileList[0];
            },
            handleRemoveUploadedFile() {
                this.uploadedFile = null;
                this.depositForm.imageData = null;
            },
            async handleDepositModalSubmit() {

                let { depositForm, orderData } = this;
                this.uploading = true;

                if (!depositForm.imageData) {
                    if (!(await this.validateDepositForm())) {
                        this.uploading = false;
                        this.showErrors(this.depositFormErrors)
                        return;
                    }
                } else {
                    let params = {
                        order_id: orderData.id,
                        deposit_slip: depositForm.imageData
                    }
    
                    let depositFormData = new FormData();
                    depositFormData.append('order_id', orderData.id);
                    depositFormData.append('deposit_slip', depositForm.imageData);
    
                    if (depositForm.imageData) {

                        let response = await connect().post('orders/upload-attachments', depositFormData).then(res => res).catch(err => err);
                        if (response.status != 200) {
                            this.uploading = false;
                            this.depositFormErrors.imageData = 'Uploading failed';
                            return;
                        }
                    }
                }


                this.formValues.deposit = JSON.stringify(depositForm);
                this.depositFormErrors = initialDepositFormErrors;

                let params = {
                    order_id: orderData.id,
                    deposit: this.formValues.deposit
                };

                let response = await connect().post('orders/update-deposit-data', params).then(res => res).catch(err => err);

                if (response.status == 200) {
                    this.uploading = false;
                    this.depositFormErrors = initialDepositFormErrors;
                    this.toggleDepositModal();
                    return;
                } else {
                    this.uploading = false;
                    this.depositFormErrors.reference_number = response.data.message;
                    this.showErrors(this.depositFormErrors.reference_number);
                    return;
                }
            },
            goToLogin() {   

                let { items } = this.orderData;
                let sessionCart = cartHelper.getCart();

                let selectedItems = [];
                let sessionCartItems = sessionCart.items;
                if (items && items.length > 0) {
                    items.map(item => {
                        let cartItem = sessionCartItems.find(cartItem => item.reference_id == cartItem.product_id);

                        if (cartItem) {
                            selectedItems.push(cartItem.id);
                        }
                    })
                }

                let routeParams = {
                    name: 'login',
                    query: {
                        redirect: {
                            name: 'checkout'
                        },
                        token: this.orderData.token,
                        items: selectedItems
                    }
                };

                this.$router.push(routeParams);
            },
            changeDeliveryOption(option) {

                this.formValues.shipping_amount = 0;
                this.formValues.delivery_option = option;
                this.displayTexts(option);
            },
            setPaymentMethod(methodID) {

                let { paymentMethods, discounts, orderData, calculatedSubtotal } = this;
                let discountValue = 0;

                this.paymentError = '';

                discounts = discounts.filter(discount => discount.name != 'Bank transfer discount');

                let paymentMethod = paymentMethods.find(method => method.id == methodID);

                if (paymentMethod) {
                    
                    if (calculatedSubtotal < 100 && paymentMethod.slug == 'paymongo') {
                        return;
                    }

                    if (String(paymentMethod.name).includes('BDO') || String(paymentMethod.name).includes('Union')) {

                        discountValue = (parseFloat(orderData.subtotal)/100) * 0.5;

                        let discountItem = {
                            name: 'Bank transfer discount',
                            value: discountValue
                        };

                        discounts.push(discountItem);

                    }

                }
                this.discounts = discounts;
                this.formValues.payment_type_id = methodID;
                this.getShippingFee();
            },
            setDate(date) {
                this.schedule.time = null;
                this.scheduleError = '';

                this.schedule.date = date;
            },
            setTime(time) {
                this.schedule.time = time
            },
            toggleAddressForm() {
                this.showAddressForm = !this.showAddressForm;
            },
            toggleUserForm() {
                this.showUserForm = !this.showUserForm;
            },
            applyCoupon() {

                let { couponCode, orderData, discounts } = this;

                this.couponCodeError = '';

                if (this.couponCode == '') {
                    this.couponCodeError = 'Please input a promo code';
                    return;
                }

                let params = {
                    code: couponCode,
                    checkout_token: orderData.token
                }

                connect().post('coupon/apply', params)
                    .then(res => {

                        if (res.data.data) {

                            let coupon = res.data.data;

                            this.formValues.coupon_id = coupon.id;

                            let couponValue = 0;

                            if (coupon.value_type == 'percentage') {
                                couponValue = (parseFloat(orderData.subtotal)/100) * parseFloat(coupon.value);
                            } else {
                                couponValue = parseFloat(coupon.value)
                            }

                            let discountItem = {
                                name: coupon.code,
                                value: couponValue
                            }

                            discounts.push(discountItem);

                            this.discounts = discounts;
                        }
                    })
                    .catch(err => {
                        if (err.data && err.data.message) {
                            this.couponCodeError = err.data.message;
                            this.showErrors(this.couponCodeError);
                        }
                        console.error(err)
                    })
            },
            updateOrder(params) {

                let { orderData } = this;

                let orderParams = {
                    reference_number: orderData.reference_number,
                    ...params
                }

                connect().post('orders/checkout', orderParams)
                    .then(res => {
                        this.orderData = res.data.data;
                    })
                    .catch(err => {
                        console.error(err.data.message);
                    })

            },
            async validateDepositForm() {
                const schema = {
                    properties: {
                        account_name: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            messages: {
                                allowEmpty: 'Please enter your account name',
                                required: 'Please enter your account name',
                            }
                        },
                        payment_date: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            messages: {
                                allowEmpty: 'Please enter the date of payment',
                                required: 'Please enter the date of payment',
                            }
                        },
                        amount_paid: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            messages: {
                                allowEmpty: 'Please enter the total amount paid',
                                required: 'Please enter the total amount paid',
                            }
                        },
                        reference_number: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            messages: {
                                allowEmpty: 'Please enter the transaction reference number',
                                required: 'Please enter the transaction reference number',
                            }
                        },
                    }
                };

                const validateResults = validate(this.depositForm, schema, undefined, true);

                this.depositFormErrors = validateResults.errors;

                return validateResults.valid;
            },
            async validateAddressForm() {
                const schema = {
                    properties: {
                        address: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            messages: {
                                allowEmpty: 'Please enter your address',
                                required: 'Please enter your address'
                            }
                        },
                    }
                };

                const validateResults = validate(this.addressForm, schema, undefined, true);

                this.addressFormErrors = validateResults.errors;

                if (this.addressForm.lat == 0 || this.addressForm.lng == 0) {
                    this.addressFormErrors.address = "A valid address is required";
                    validateResults.valid = false;
                }

                return validateResults.valid;
            },
            async validateUserForm() {

                const schema = {
                    properties: {
                        fullname: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            messages: {
                                allowEmpty: 'Please enter your full name',
                                required: 'Please enter your full name',
                            }
                        },
                        email: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            messages: {
                                allowEmpty: 'Please enter your email',
                                required: 'Please enter your email',
                            }
                        },
                        phone: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            pattern: /^09[0-9]{9}$|^0?2[0-9]{7}$|^0?32[0-9]{7}$/,
                            messages: {
                                allowEmpty: 'Please enter your phone number',
                                required: 'Please enter your phone number',
                                pattern: 'Please enter a valid phone number'
                            }
                        },
                        birthday: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            format: 'date',
                            messages: {
                                allowEmpty: 'Please enter your birthday',
                                required: 'Please enter your birthday',
                                format: 'Please enter a valid date format YYYY-MM-DD of your birthday'
                            }
                        },
                    }
                };

                const validateResults = validate(this.userForm, schema, undefined, true);
                this.userFormErrors = validateResults.errors;
                
                // BIRTHDAY VALIDATION
                if (!validateBirthday(this.userForm.birthday)) {
                    this.userFormErrors.birthday = 'Please enter a valid date of your birthday. Allowed age must be 15 to 100 years old.';
                    validateResults.valid = false;
                }

                return validateResults.valid;
            },
            sendEmailCode() {

                this.verificationForm.email = this.userForm.email;
                this.verificationFormErrors.code = '';

                connect().post('/customers/checkout-validate-email-and-send-code', { email: this.verificationForm.email })
                        .then(res => {
                            console.log('Success', res);
                        })
                        .catch(err => {
                            this.verificationFormErrors.code = err.data.message ?? 'There was a problem while sending the email. Please try again later';
                            this.showErrors(this.verificationFormErrors.code);
                        })
            },
            async sendSmsCode() {
                this.verificationForm.phone = this.userForm.phone;
                this.verificationFormErrors.code = '';

                this.smsCode = Math.floor(100000 + Math.random() * 900000);
                let text = `Your phone verification code is: ${this.smsCode}`;

                let params = {
                    text,
                    phone: this.verificationForm.phone
                };

                let response = await connect().post('mysms/send', params);

                return response.status == 200 ? true : false;
            },
            validateSmsCode() {

                this.verificationFormErrors.code = '';

                if (this.verificationForm.code == '') {
                    this.verificationFormErrors.code = 'Code is required';
                    return false;
                }

                if (this.verificationForm.code != this.smsCode) {
                    this.verificationFormErrors.code = 'Invalid verification code';
                    return false;
                }

                this.smsVerified = true;
                return true;
            },
            async validateEmailCode() {

                if (this.verificationForm.code == '') {
                    this.verificationFormErrors.code = "Code is required";
                    return false;
                }

                if (!this.emailVerified) {
                    let response = await connect().post('/customers/validate-email-code', { code: this.verificationForm.code, email: this.verificationForm.email })
                            .then(res => {
                                this.verificationFormErrors.code = '';
                                return true;
                            })
                            .catch(err => {
                                this.verificationFormErrors.code = err.data.message;
                                return false;
                            })

                    this.emailVerified = response;
                }

                return this.emailVerified;
            },
            validateDateTime() {
                let { schedule } = this;

                this.scheduleError = '';

                if (!schedule.date || !schedule.time) {
                    
                    this.scheduleError = 'Please set your prefered date and time';
                    return false;
                }

                let time = schedule.time.start_time;
                let date = moment(schedule.date).format('YYYY-MM-DD');
                let dateTime = (date + 'T' + time);

                this.formValues.date_delivery = dateTime;
                return true;
            },
            async updateUserDetails() {

                let userParams = {...this.userForm};

                if (!(await this.validateUserForm())) {
                    this.showErrors(this.userFormErrors)
                    return;
                }
                
                connect().post('customers/update-details', userParams)
                    .then(res => {
                        if (res.status == 200) {
                            this.setUser(res.data.data);
                        }
                    })
                    .catch(err => console.error(err));

                this.toggleUserForm();
            },
            async updateAddress(update = false) {
                
                if (!(await this.validateAddressForm())) {
                    this.showErrors(this.addressFormErrors)
                    return;
                }

                let addressParams = {...this.addressForm};
                let user = this.user;
                

                if (user && user.customer && user.customer.primary_address && user.customer.primary_address.id) {

                    addressParams.id = user.customer.primary_address.id;
    
                    connect().post('/customers/update-address', addressParams)
                        .then(res => {
                            if (res.status == 200) {

                                user.customer.primary_address = res.data.data;
                                this.setUser(user);
                            }
                        })
                        .catch(err => {
                            if (err.data.message) {
                                console.error(err.data.message);
                            } else {
                                console.error(err);
                            }
                        })
                } else {

                    addressParams.firstname = user.customer.firstname;
                    addressParams.lastname = user.customer.lastname;
                    addressParams.email = user.email
                    addressParams.customer_id = user.customer.id;
                    addressParams.phone = user.customer.phone;

                    connect().post('customers/create-address', addressParams)
                        .then(res => {
                            if (res.status == 200) {

                                user.customer.primary_address = res.data.data;
                                this.setUser(user);
                            }
                        })
                        .catch(err => {
                            if (err.data.message) {
                                console.error(err.data.message);
                            } else {
                                console.error(err);
                            }
                        })
                }

                this.toggleAddressForm();
            },
            getCheckoutData(token) {
                return connect().post('/checkout/find', { token: token })
            },
            getBankAccounts() {
                connect().get('/checkout/bank_details', {})
                    .then(res => {
                        if (res.status == 200) {
                            this.bankAccounts = res.data.data;
                        }
                    })
                    .catch(err => {
                        console.error(err.data.message);
                    })
            },
            getPaymentMethods() {

                connect().get('checkout/get-payment-types', {})
                    .then(res => {
                        if (res.status == 200) {
                            this.paymentMethods = res.data.data;

                            this.formValues.payment_type_id = this.paymentMethods[0];
                        }
                    })
                    .catch(err => {
                        console.error(err.data.message);
                    })
            },
            getProvinces() {

                connect().get('/options/address', {})
                    .then(res => {
                        if (res.status == 200) {
                        this.provinces = res.data.data.selected.province;
                        }
                    })
            },
            getCities(province_id) {

                this.addressForm.city_id = '';

                connect().get(`/options/cities/${province_id}`, {})
                    .then(res => {
                        if (res.status == 200) {
                        this.cities = res.data.data;
                        }
                    })
            },
            getTimes() {
                
                connect().get('checkout/get_delivery_times', {}) 
                    .then(res => {
                        if (res.status == 200) {
                            this.times = res.data.data;
                        }
                    })
                    .catch(err => {
                        console.error(err.data.message);
                    })
            },
            showCart() {
                this.$bvModal.show('cart-popup');
            },
        },
    }
</script>