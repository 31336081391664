<script>
import mainilters from './mainFilters'
import secondaryFilters from './secondaryFilters'

export const MainFilters = mainilters
export const SecondaryFilters = secondaryFilters

export default {
    setup() {
        
    },
}
</script>