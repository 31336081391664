<template>
    <div id="contactMapWrapper" class="flex-grow-1">
        <div class="address-info" v-if="storeAddress" >
            <div class="card">
                <div class="card-body font-xsmall font-sf-medium">
                    <div class="mb-2" >Main Office</div>
                    <div class="font-gray">{{ storeAddress }}</div>
                    <div class="font-gray mt-2" v-if="address.contact_number != '' || address.contact_mobile != ''" >
                        <div class="mb-2" v-if="address.contact_number != ''" >{{address.contact_number}}</div>
                        <div v-if="address.contact_mobile != ''" >{{address.contact_mobile}}</div>
                    </div>
                </div>
            </div>
        </div>
        <GmapMap 
            :center="{...mapCoordinates}"
            :zoom="14"
            :options="{
                disableDefaultUI: true
            }"
        >
            <GmapMarker
                :position="{...mapCoordinates}"
            />
        </GmapMap>
    </div>
</template>

<script>
export default {
    props: ['address'],
    data() {
        return {
            markers: []
        }
    },
    computed: {
        lat() {
            return parseFloat(this.address.lat)
        },
        lng() {
            return parseFloat(this.address.lng)
        },
        mapCoordinates() {
            return {
                lat: this.lat,
                lng: this.lng
            }
        },
        storeAddress() {
            return this.address.address
        },
    }
}
</script>

<style scoped>
</style>