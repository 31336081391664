<template>
    <div class="ellipsis-loader">
        <div
            v-for="(dot, index) in [...Array(3)]"
            :key="index"
            class="mx-20"
            :style="{
                animationDelay: `${ index * 0.33}s`
            }"
        />
    </div>
</template>

<script>
    export default {
        setup() {
            
        },
    }
</script>