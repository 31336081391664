<template>
    <div class="password-form">
        <div class="row">
            <div class="col-lg-8 col-12">
                <div class="font-giantsize font-sf-bold my-3">Change Password</div>
                
                <!-- CURRENT PASSWORD FIELD -->
                <div>
                    <label class="mb-0 mt-3 font-xsmall font-gray" >Current Password*</label>
                    <input
                        type="password"
                        class="form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black"
                        v-model="formValues.current_password"
                    />
                    <div class="font-xsmall font-red" v-if="formErrors.current_password" >{{formErrors.current_password}}</div>
                    <div>
                        <router-link :to="{ name: 'password.reset' }" class="font-small font-green">Forgot password?</router-link>
                    </div>
                </div>

                <!-- NEW PASSWORD FIELD -->
                <div>
                    <label class="mb-0 mt-3 font-xsmall font-gray" >New Password*</label>
                    <input
                        type="password"
                        class="form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black"
                        v-model="formValues.new_password"
                    />
                    <div class="font-small font-red" v-if="formErrors.new_password" >{{formErrors.new_password}}</div>
                </div>

                <!-- CONFIRM PASSWORD FIELD -->
                <div>
                    <label class="mb-0 mt-3 font-xsmall font-gray" >Confirm Password*</label>
                    <input
                        type="password"
                        class="form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black"
                        v-model="formValues.confirm_password"
                    />
                    <div class="font-small font-red" v-if="formErrors.confirm_password" >{{formErrors.confirm_password}}</div>
                </div>
                
                <button 
                    type="submit" class="btn btn-success my-4 py-2 font-small bg-green btn-block rounded-pill font-white"
                    @click="handleSubmit"
                    :disabled="processing"
                >{{ processing ? 'Saving...' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>
<script>
    import { showAlert } from '@helpers/notification.helper';
    import validate from '@helpers/validation.helper';
    import { connect } from '@api';
    import { mapMutations, mapActions } from 'vuex';
    import * as types from '../../store/mutation-types';

    let swalSettings = {
        title: 'Congratulations!',
        html: 'You successfully changed your password <br/> Please login using your new credentials'
    };

    export default {
        props: {
            user: Object
        },
        data() {
            return {
                formValues: {
                    current_password: '',
                    new_password: '',
                    confirm_password: ''
                },
                formErrors: {
                    current_password: '',
                    new_password: '',
                    confirm_password: ''
                },
                processing: false
            }
        },
        methods: {
            ...mapMutations({
                updateUser: types.UPDATE_USER
            }),
            ...mapActions({
                logout: types.LOGOUT
            }),
            reset(type = null) {

                let keys = Object.keys(this.formValues);

                for (let key of keys) {
                    if (type == null) {
                        this.formValues[key] = '';
                        this.formErrors[key] = '';
                    } else {
                        this[type].key = '';
                    }
                }

            },
            async validateForm() {

                this.reset('formErrors');

                const schema = {
                    properties: {
                        current_password: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            messages: {
                                allowEmpty: 'Please enter your current password',
                                required: 'Please enter your current password',
                            }
                        },
                        new_password: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            minLength: 8,
                            messages: {
                                allowEmpty: 'Please enter your new password',
                                required: 'Please enter your new password',
                                minLength: 'New password must contain at least 8 characters',
                            }
                        },
                        confirm_password: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            minLength: 8,
                            conform: (confirm_password) => {
                                if (confirm_password == this.formValues.new_password) return true;
                                return false
                            },
                            messages: {
                                conform: 'Passwords do not match',
                                allowEmpty: 'Please confirm your new password',
                                required: 'Please confirm your new password',
                            }
                        },
                    }
                }

                const validateResults = validate(this.formValues, schema, undefined, true);

                this.formErrors = validateResults.errors;

                return validateResults.valid;

            },
            async handleSubmit() {
                this.processing = true;

                if (!( await this.validateForm())) {
                    this.processing = false;
                    return;
                }

                this.validateCurrentPassword()
                    .then(res => {
                        connect().post('/customers/update-password', this.formValues)
                            .then(res => {
                                this.processing = false;
                                if (res.status == 200) {

                                    showAlert(swalSettings)
                                        .then(res => {
                                            this.logout();
                                            this.$router.push({ name: 'login' });
                                            // this.$router.push({ name: 'account.profile' })
                                        })
                                }
                            })
                            .catch(err => {
                                this.processing = false;
                                
                                if (err.data) {
                                    if (err.data.field) {
                                        this.formErrors[err.data.field] = err.data.message;
                                    }
                                    else {
                                        this.formErrors.new_password = err.data.message[0];
                                    }
                                }

                                return;
                            })
                    })
                    .catch(err => {
                        this.formErrors.current_password = 'Invalid password';
                        this.processing = false;
                        return;
                    })
            },
            validateCurrentPassword() {

                let params = {
                    current_password: this.formValues.current_password
                }

                return connect().post('customers/validate-password', params);
            }
        }
    }
</script>