<template>
	<div class="footer-section" id="footer" >
		<div class="bg-green pt-5 pb-5 font-white">
			<div class="container bottom-footer mb-5 text-center text-sm-left">
				<div class="arr-container">
					<div class="mb-5">
						<div class="font-sf-medium">
							© 2021 QuincyBuyBoy
						</div>
						<div class="font-sf-medium">
							All Rights Reserved.
						</div>
					</div>
					<div class="mb-5 mb-md-0">
						Design by Think Sumo
					</div>
				</div>
				<div v-if="isPublished('about')" >
					<h6 class="font-sf-medium">
						COMPANY
					</h6>
					<ul class="style-none">
						<li v-if="isPublished('about')" >
							<router-link
								:to="{ name: 'about' }"
							> About Us
							</router-link>
						</li>
					</ul>
				</div>
				<div v-if="hasContactUs || isPublished('return-policy') || isPublished('delivery') || isPublished('faq')" >
					<h6 class="font-sf-medium">
						CUSTOMER SERVICE
					</h6>
					<ul class="style-none">
						<li>
							<router-link 
								:to="{ name: 'contact' }"
							> Contact Us
							</router-link>
						</li>
						<li v-if="isPublished('return-policy')" >
							<router-link 
								:to="{
									name: 'page',
									params: {
										slug: 'return-policy'
									}
								}"
							> Return Policy
							</router-link>
						</li>
						<li v-if="isPublished('delivery')" >
							<router-link 
								:to="{
									name: 'page',
									params: {
										slug: 'delivery'
									}
								}"
							> Delivery
							</router-link>
						</li>
						<li v-if="isPublished('faq')" >
							<router-link 
								:to="{ 
									name: 'page',
									params: {
										slug: 'faq'
									}
								}"
							> FAQ
							</router-link>
						</li>
					</ul>
				</div>
				<div v-if="isPublished('terms-and-conditions') || isPublished('privacy-policy') || isPublished('cookies')" >
					<h6 class="font-sf-medium">
						POLICIES
					</h6>
					<ul class="style-none">
						<li v-if="isPublished('terms-and-conditions')" >
							<router-link 
								:to="{
									name: 'page',
									params: {
										slug: 'terms-and-conditions'
									}
								}"
							> Terms & Conditions
							</router-link>
						</li>
						<li v-if="isPublished('privacy-policy')" >
							<router-link 
								:to="{
									name: 'page',
									params: {
										slug: 'privacy-policy'
									}
								}"
							> Privacy Policy
							</router-link>
						</li>
						<li v-if="isPublished('cookies')" >
							<router-link 
								:to="{
									name: 'page',
									params: {
										slug: 'cookies'
									}
								}"
							> Cookies
							</router-link>
						</li>
					</ul>
				</div>
				<div v-if="urls.facebook_page || urls.instagram_page" >
					<h6 class="font-sf-medium">
						CONNECT WITH US
					</h6>
					<ul class="style-none">
						<li v-if="urls.facebook_page" >
							<a 
								:href="urls.facebook_page" 
								target="_blank"
							>
								Facebook
							</a>
						</li>
						<li v-if="urls.instagram_oage" >
							<a 
								:href="urls.instagram_page" 
								target="_blank"
							>
								Instagram
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { connect } from '@api';

	export default {
		data() {
			return {
				urls: {
					instagram_page: '',
					facebook_page: ''
				},
				pages: [],
				hasContactUs: true
			}
		},
		mounted() {
			this.getSiteOption(['instagram-page', 'facebook-page']);

			let footer = document.getElementById('footer');

			if (footer) {
				this.$emit('footer-mounted', footer.clientHeight);
			}

			this.getPublishedPages();
		},
		methods: {
			getSiteOption(slug) {
				connect().post('get_site_option', { slug: slug })
					.then(res => {
						if (res.data) {
							this.urls = {...this.urls, ...res.data.data};
						}
					})
			},
			async getPublishedPages() {

				let response = await connect().get('pages/get-published', {});

				if (response.status == 200) {
					this.pages = response.data.data
				} else {
					this.pages = []
				}
			},
			isPublished(slug) {
				
				let exists = this.pages.find(page => page.slug == slug);

				return exists ? true : false;
			}
		}
	};
</script>

<style></style>
