<template>
    <div>
        <div 
            v-if="carouselArticles && carouselArticles.length > 0" 
            class="bbdoit-carousel py-4 bg-gray-2 px-5 px-md-0"
        >
            <div class="container">
                <carousel
                    :perPageCustom="[[300, 1], [768, 3], [1200, 5]]"
                    :paginationEnabled="false"
                    :navigationEnabled="true"
                    navigationNextLabel="
                        <div class='fa-stack shadow rounded-circle'>
                            <i class='fas fa-circle fa-stack-2x font-white'></i>
                            <i class='fas fa-chevron-right fa-stack-1x font-gray font-xsmall'></i>
                        </div>
                    "
                    navigationPrevLabel="
                        <div class='fa-stack shadow rounded-circle'>
                            <i class='fas fa-circle fa-stack-2x font-white'></i>
                            <i class='fas fa-chevron-left fa-stack-1x font-gray font-xsmall'></i>
                        </div>
                    "
                >
                    <slide
                        v-for="article in carouselArticles"
                        :key="article.slug"
                        class="mx-1"
                    >
                        <BbdoitCard
                            :mini="true"
                            :article="article"
                        />
                    </slide>
                </carousel>
            </div>
        </div>
        <div class="container">
            <div class="bbdoit-inner p-2 p-md-5 pb-4 mx-lg-5 mx-0" v-if="article" >
                <div class="bbdoit-title font-giantsize font-sf-bold">
                    {{ article.name }}
                </div>
                <div class="bbdoit-title d-flex align-items-center flex-column flex-md-row mb-4 w-xs-100">
                    <div class="flex-grow-1 d-flex align-items-center flex-column flex-md-row font-small font-sf-medium w-xs-100 ml-3">
                        <div class="mr-4 text-left w-xs-100" v-if="article.total_time" ><i class="far fa-clock font-green mr-1"></i> Total time: {{ timeFormat(article.total_time) }}</div>
                        <div class="mr-4 text-left w-xs-100" ><i class="fas fa-tag font-green mr-1" :style="{ transform: 'rotate(90deg)' }" ></i> {{ article.category.name }}</div>
                        <div class="mr-4 text-left w-xs-100" v-if="likes > 0" ><i class="fas fa-thumbs-up font-green mr-1"></i> {{ likes }} Like{{ likes > 1 ? 's' : '' }}</div>
                        <div class="mr-4 text-left w-xs-100" ><i class="fas fa-comment font-green mr-1"></i> {{ article.comments_count }} Comment{{ article.comments_count > 1 ? 's' : '' }}</div>
                    </div>
                    <button 
                        v-if="isLoggedIn" 
                        class="btn btn-sm btn-green rounded-lg mr-auto ml-md-auto mt-4 mt-md-0"
                        @click="like"
                    >
                        <i class="fas fa-thumbs-up mr-1"></i> {{ isLiked ? 'Liked' : 'Like' }}
                    </button>
                </div>
                <div v-if="article.asset && article.asset.path" >
                    <Imager
                        :src="article.asset"
                        :extract="true"
                        className="d-block mx-auto"
                    />
                </div>
                <div class="bbdoit-content row py-5">
                    <div class="bbdoit-instructions col-lg-9 col-12">
                        <div class="font-xxlarge font-sf-medium mb-3">Instruction</div>
                        <div 
                            v-if="article.content" 
                            v-html="article.content"
                            class="font-gray font-sf-medium font-small"
                        ></div>
                    </div>
                    <div 
                        class="bbdoit-ingredients col-lg-3 col-12"
                        v-if="article.products && article.products.length > 0"
                    >
                        <div class="font-xxlarge font-sf-medium">Ingredients</div>
                        <div  >
                            <div class="ingredients font-small font-gray font-sf-medium my-3">
                                <div
                                    v-for="item in article.products"
                                    :key="item.id"
                                >{{ item.quantity }} {{ item.name && item.name != null ? item.name : item.product.title }}
                                </div>
                            </div>
                            <button 
                                class="btn btn-sm rounded-pill btn-block btn-green"
                                @click="scrollToItems"
                            >
                                <i class="fas fa-shopping-cart"></i> Order the Ingredients
                            </button>
                        </div>
                    </div>
                </div>
                <CommentForm 
                    :article="article"
                    :isLoggedIn="isLoggedIn"
                    :isLiked="isLiked"
                    :likes="likes"
                    :user="user"
                    @like="like"
                />
            </div>
            <div 
                class="bbdoit-items"
                id="bbdoit-items"
                v-if="ingredients && ingredients.length > 0"
            >
                <div class="font-xxlarge font-sf-medium mb-3">{{ article.subtitle }}</div>
                <div class="row row-thin">
                    <div 
                        class="col-xl-3 col-sm-6 col-12 mb-3"
                        v-for="item in ingredients"
                        :key="item.slug"
                    >
                        <ProductCard
                            :product="item.product"
                            @toggle-list-modal="$emit('toggle-list-modal', product)"
                            @show-product-popup="showProductPopup(product)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <ProductPopup
			:product="popupProduct"
			@toggle-list-modal="$emit('toggle-list-modal')"
		/>
    </div>
</template>

<script>
import { Imager } from '@components/common';
import ProductCard from '@components/productCard/ProductCard';
import ProductPopup from '@components/productPopup';
import BbdoitCard from '@components/bbdoitCard/BbdoitCard';
import CommentForm from './components/commentForm';
import { isLoggedIn, getUser } from '../../helpers/session.helper';
import { connect } from '@api';
import { mapMutations } from 'vuex';
import * as types from '../../store/mutation-types';

export default {
    components: {
        Imager,
        ProductCard,
        ProductPopup,
        BbdoitCard,
        CommentForm
    },
    data() {
        return {
            carouselArticles: [],
            article: null,
            ingredients: [],
            popupProduct: null,
            likes: 0,
        }
    },
    computed: {
        isLoggedIn() {
            return isLoggedIn()
        },
        user() {
            return getUser();
        },
        isLiked() {

            let liked = false;

            if (isLoggedIn()) {
                let likes = this.user.customer.liked_articles;
    
                let isLiked = likes.find(article => {
                    return article.article_id == this.article.id;
                })

                if (isLiked) {
                    liked = true;
                }
            }

			return liked;
		}
    },
    created() {
        this.getArticle();
    },
    methods: {
        ...mapMutations({
            updateUser: types.UPDATE_USER
        }),
        like() {		

            if (!isLoggedIn()) {
                return;
            }

			let article_id = this.article.id;

			connect().post('/articles/like', {article_id} )
				.then(res => {

					if (res.data.data) {
						this.likes += 1;

						let {liked_articles} = this.user.customer;
						liked_articles.push(res.data.data);

						this.user.customer.liked_articles = liked_articles;

						this.updateUser(this.user);
						
					} else {
						this.likes -= 1;

						let {liked_articles} = this.user.customer;

						liked_articles = liked_articles.filter(article => {
							return article.article_id !== article_id;
						})

						this.user.customer.liked_articles = liked_articles;

						this.updateUser(this.user);
					}
				})
		},
        getArticle() {
            connect().get('/articles/find', { params: { slug: this.$route.params.slug } })
                    .then(res => {
                        if (res.status == 200) {
                            this.article = res.data.data;
                            this.ingredients = res.data.data.products;
                            this.likes = this.article.likes_count;

                            this.getCarouselArticles();
                        }
                    })
        },
        getCarouselArticles() {

            let params = {
                exclude: [this.article.id],
                type: 'article'
            };

            connect().get('/articles/carousel', { params: {...params} })
                    .then(res => {
                        if (res.status == 200) {
                            this.carouselArticles = res.data.data;
                        }
                    })
        },
        timeFormat(str) {

            let timeArr = str.split(':');
            let timeStr = '';

            timeArr.forEach((time, index) => {
                
                let indexes = ['hr', 'min', 'sec'];

                time = parseInt(time);

                if (time != 0) {
                    timeStr += time + indexes[index] + `${ time > 1 ? 's' : '' } `;
                }
            });

            return timeStr;
        },
        showProductPopup(product) {
            this.popupProduct = product;
            this.$bvModal.show('product-popup');
        },
        scrollToItems() {
            
            let currentScrollPosition = 0;
            let targetElement = document.getElementById('bbdoit-items');

            // Firefox, Chrome, Opera, Safari
            if (self.pageYOffset) currentScrollPosition = self.pageYOffset;
            // IE 6
            if (document.documentElement && document.documentElement.scrollTop) currentScrollPosition = document.documentElement.scrollTop;
            // IE 6,7,8
            if (document.body.scrollTop) currentScrollPosition = document.body.scrollTop;

            window.scrollTo(currentScrollPosition, targetElement.offsetTop);
        }
    },
    watch: {
        $route() {
            this.getArticle()
        }
    }
}
</script>