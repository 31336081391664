<template>
    <div :class="['', className]" >
        <div class="d-flex align-items-center">
            <div>
                <StarRating
                    :star-size="13"
                    :rounded-corners="true"
                    :border-width="3"
                    :increment="0.5"
                    :read-only="true"
                    :rating="productRating"
                    border-color="#29673E"
                    active-color="#29673E"
                    inactive-color="white"
                    text-class="font-xsmall font-sf-medium font-green"
                />
            </div>
            <div class="ml-2 font-xsmall font-gray">Customer Ratings</div>
        </div>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating';

export default {
    components: {
        StarRating
    },
    props: ["productRating", "className"]
}
</script>