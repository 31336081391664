<template>
	<div>
		<div class="d-flex products-page">
			<FilterSidebar 
				:vendors="vendors"
				:categories="categories"
				@handle-vendor-filter="filterVendors"
				@handle-apply-filter="applyFilter"
				@handle-clear-filters="clearFilters"
				:vendorsLoading="vendorsLoading"
				:categoriesLoading="categoriesLoading"
				:params="params"
				:defaultRange="defaultRange"
				:toggleMobileFilters="toggleMobileFilters"
				:showMobileFilter="showMobileFilter"
			/>
			<div class="pt-4 flex-grow-1 px-3">
				<div class="mb-5">
					<div v-if="isLoading" class="mt-3" >
						<div class="w-25 mb-4">
							<div class="skeleton-loader text"></div>
						</div>
						<div class="w-50">
							<div class="skeleton-loader text"></div>
						</div>
					</div>
					<div v-else>
						<div class="d-flex align-items-center justify-content-between flex-wrap mb-3">
							<h2 class="font-giantsize font-sf-bold">{{ activeCategory != '' ? activeCategory.title : 'All Products' }}</h2>
							<div class="d-block d-lg-none">
								<span 
									@click="toggleMobileFilters"
									class="font-xsmall clickable font-green font-sf-medium p-3 pr-0"
								>Show Filters</span>
							</div>
						</div>
						<div>
							<div 
								v-if="categoryList" 
								class="d-flex flex-wrap align-items-center"
							>
								<router-link
									class="btn btn-white btn-sm rounded-pill py-1 mr-2"
									:to="{
										query: { ...params, category: categoryList.slug }
									}"
								> All
								</router-link>
								<div v-if="categoryList.children && categoryList.children.length > 0" >
									<CategoryButtons
										v-for="(category, index) in categoryList.children"
										:key="index"
										:category="category"
										:params="params"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="my-5">
					<div 
						v-if="breadcrumbs" 
						class="d-flex align-items-center font-sf-medium font-xxlarge"
					>
						<div
							v-for="(category, index) in breadcrumbs"
							:key="index"
							class="d-flex align-items-center"
							:style="{ order: (breadcrumbs.length - index) }"
						>
							<router-link
								:to="{
									query: { category: category.slug, page: 1 }
								}"
							>{{ category.title }}
							</router-link>
							<i 
								class="fas fa-chevron-right mx-2 font-xxsmall"
								v-if="index > 0"
							></i> 
						</div>
					</div>
				</div>
				<div class="d-flex align-items-center mb-3 flex-column flex-md-row">
					<span class="mr-1 font-gray font-xsmall ml-auto w-xs-100">Sort by:</span>
					<button 
						:class="`btn btn-white btn-sm mr-1 p-2 px-3 rounded w-xs-100 mb-2 mb-md-0 ${ params.sort == 'hot:desc' ? 'active' : '' }`"
						@click="sort('hot:desc')"
					>
						<img class="img-small" src="../../assets/img/hot.png" alt="hot-icon" />
						Hot Deals
					</button>
					<button 
						:class="`btn btn-white btn-sm mr-1 p-2 px-3 rounded w-xs-100 mb-2 mb-md-0 ${ params.sort == 'latest:desc' ? 'active' : '' }`"
						@click="sort('latest:desc')"
					>
						<div class="d-inline-block bg-green font-white font-xxsmall">NEW</div>
						Latest
					</button>
					<button 
						:class="`btn btn-white btn-sm mr-1 p-2 px-3 rounded w-xs-100 mb-2 mb-md-0 ${ params.sort == 'sales:desc' ? 'active' : '' }`"
						@click="sort('sales:desc')"
					>
						<img class="img-small" src="../../assets/img/hot.png" alt="hot-icon" />
						Top Sales
					</button>
					<button 
						:class="`btn btn-white btn-sm mr-1 p-2 px-3 rounded w-xs-100 mb-2 mb-md-0 ${ params.sort == 'trending:desc' ? 'active' : '' }`"
						@click="sort('trending:desc')"
					>
						<img class="img-small" src="../../assets/img/trending.png" alt="trending-icon" />
						Trending
					</button>
					<button 
						:class="`btn btn-white btn-sm mr-1 p-2 px-3 rounded w-xs-100 mb-2 mb-md-0 ${ params.sort == 'alpha:asc' || params.sort == 'alpha:desc' ? 'active' : '' }`"
						@click="(params.sort != 'alpha:asc' && params.sort != 'alpha:desc') ? sort('alpha:asc') : (params.sort == 'alpha:asc' ? sort('alpha:desc') : sort('alpha:asc'))"
					>
						Alphabetical {{ (params.sort != 'alpha:asc' && params.sort != 'alpha:desc') ? 'A-Z' : (params.sort == 'alpha:asc') ? 'A-Z' : 'Z-A'}}
						<span :class="`fas fa-long-arrow-alt-down ml-1`" />
					</button>
					<button 
						:class="`btn btn-white btn-sm mr-1 p-2 px-3 rounded w-xs-100 mb-2 mb-md-0 ${ params.sort == 'price:asc' || params.sort == 'price:desc' ? 'active' : '' }`"
						@click="(params.sort != 'price:asc' && params.sort != 'price:desc') ? sort('price:asc') : (params.sort == 'price:asc' ? sort('price:desc') : sort('price:asc'))"
					>
						Price {{ (params.sort != 'price:asc' && params.sort != 'price:desc') ? 'L-H' : (params.sort == 'price:asc') ? 'L-H' : 'H-L'}}
						<span :class="`fas fa-long-arrow-alt-down ml-1`" />
					</button>
				</div>
				<div v-if="isLoading" >
					<div class="row row-thin mb-4">
						<div 
							class="col-xl-3 col-sm-6 col-12 col-lg-3"
							v-for="n in 4"
							:key="n"
						>
							<CardLoader />
						</div>
					</div>
				</div>
				<div v-else >
					<div v-if="products && products.length > 0" >
						<div  class="row row-thin">
							<div 
								v-for="product in products"
								:key="`productsCard${product.id}`"
								class="col-xl-3 col-sm-6 col-12 mb-3"
							>
								<ProductCard
									:product="product"
									@toggle-list-modal="$emit('toggle-list-modal')"
								/>
							</div>
						</div>
						<div 
							v-if="pagination && pagination.last_page > 1" 
							class="py-5"
						>
							<Pagination
								:currentPage="params.page"
								:lastPage="pagination.last_page"
								:params="params"
							/>
						</div>
					</div>
					<div v-else class="py-5 my-5 text-center font-giantsize font-sf-bold" >No products found</div>
				</div>
			</div>
		</div>
		<ProductPopup
			:product="popupProduct"
			@toggle-list-modal="$emit('toggle-list-modal')"
		/>
	</div>
</template>

<script>
import ProductCard from '../../components/productCard/ProductCard';
import FilterSidebar from './components/filterSidebar';
import CategoryButtons from './components/categoryButtons';
import ProductPopup from '@components/productPopup';
import { CardLoader } from '@components/common';
import Pagination from '@components/pagination';
import { connect } from '@api';

let initialParams = {
	page: 1,
	page_size: 24,
	sort: "relevance:desc",
	brands: [],
	tags: "",
	category: '',
	price_range: [],
	keyword: ''
}

export default {
	components: {
		ProductCard,
		FilterSidebar,
		ProductPopup,
		CardLoader,
		Pagination,
		CategoryButtons
	},
	props: ['categories'],
	created() {
		let { query } = this.$route;

		this.params = { ...this.params, ...query };
		this.getProducts();
		this.getVendors();
		this.getActiveCategory(this.params.category);
	},
	data() {
		return {
			products: [],
			categoryList: null,
			activeCategory: '',
			brandFilterInput: '',
			params: initialParams,
			defaultRange: [0, 1000],
			pagination: null,
			selectedProduct: {},
			showPopup: false,
			popupProduct: null,
			isLoading: true,
			vendorsLoading: false,
			categoriesLoading: false,
			showMobileFilter: false,
			vendors: [],
			vendorFilter: ''
		}
	},
	computed: {
		breadcrumbs: {
			get: function() {
				let activeCategory = this.activeCategory;
				let categories = this.categoryList;

				let breadcrumbs = [];

				breadcrumbs.push(activeCategory);
				
				if (activeCategory.parent && activeCategory.parent != 0) {
					breadcrumbs.push(activeCategory.parent_cat);

					if (activeCategory.parent_cat.parent != 0) {
						breadcrumbs.push(categories);
					}
				}

				return breadcrumbs;
			},
			set: function(newValue) {
			}
		}
	},
	methods: {
		sort(sort) {

            let params = {
                page: 1,
                sort: sort,
            };

            params = {...this.params, ...params};

            this.$router.push({
                query: {...params}
            })
		},
		toggleMobileFilters() {
			this.showMobileFilter = !this.showMobileFilter;
		},
		getProducts() {

			this.isLoading = true;

			connect().get("products/get", { params: this.params })
                    .then(res => {
						this.products = res.data.products.data;
						this.pagination = res.data.products;

						res.data.price_range.map((price, index) => {
							res.data.price_range[index] = parseInt(res.data.price_range[index]);
						});
						this.params.price_range = res.data.price_range;

						res.data.all_range.map((price, index) => {
							res.data.all_range[index] = parseInt(res.data.all_range[index]);
						});
						this.defaultRange = res.data.all_range;

						this.isLoading = false;
					})
		},
		getVendors(keyword = '') {
            connect().get("/brands", { params: { title_LK: keyword } })
                    .then(res => {
                        if (res.status == 200 && res.data) {
							this.vendors = res.data.data;
							this.vendorsLoading = false;
                        }
                    });
        },
		getActiveCategory(category) {
			if (category) {
				connect().get("/categories/find", { params: { slug: category } })
					.then(res => {
						if (res.status == 200) {
							this.activeCategory = res.data.data;

							this.getCategoryAncestors(this.activeCategory);
						}
					})
			}
		},
		getCategoryAncestors(category) {
			connect().get('/categories/ancestors', { params: { id: category.id } })
				.then(res => {
					if (res.status == 200) {
						this.categoryList = res.data.data;
					}
				})
				.catch(err => {
					console.error(err);
				})
		},
		showProductPopup(product) {
            this.popupProduct = product;
            this.$bvModal.show('product-popup');
		},
		filterVendors(keyword) {
			this.getVendors(keyword);
		},
		applyFilter(params) {
			
			let query = {...this.params, ...params};
			query.page = 1;
			this.$router.push({ query: query })
		},
		clearFilters() {
			this.getVendors('');
			this.showMobileFilter = false;
			this.params = initialParams;
			this.activeCategory = this.params.category;
			this.breadcrumbs = [];
			this.categoryList = [];
			this.$router.push({ name: 'products', query: {} }).catch(() => {});
			this.getProducts();
		}
	},
	watch: {
		$route(to) {
			let { query } = to;

			if (query.search) {
				this.activeCategory = '';
				this.breadcrumbs = [];
				this.categoryList = [];
				this.params = initialParams;
				this.params.keyword = query.keyword;
				this.params.brands = [];
			} else {
				this.params.brands = [];
				this.params = { ...this.params, ...query };
			}

			this.showMobileFilter = false;

			this.getProducts();

			this.getActiveCategory(this.params.category);
		}
	}
};
</script>

<style></style>
