<template>
    <div>
        <span v-if="user && user.asset && user.asset.path"  >
            <Imager 
                :className="`avatar-mini-image rounded-circle ${className}`"
                :src="user.asset"
                :extract="true"
                name="avatar-image"
            />
        </span>
        <span v-else-if="user && user.customer && user.customer.user_image" >
            <Imager 
                :className="`avatar-mini-image rounded-circle ${className}`"
                :src="user.customer.user_image"
                :extract="true"
                name="avatar-image"
            />
        </span>
        <span 
            v-else
            :class="['avatar-mini-image bg-green font-small font-white rounded-circle', className ]"
            :style="{ backgroundColor: color }"
        ><b>{{ userInitials ? userInitials : 'U' }}</b></span>
    </div>
</template>

<script>
    export default {
        name: 'Avatar',
        props: ["className", 'user', 'color', 'user_image'],
        computed: {
            userInitials() {
                let initials = '';

                let { user } = this;

                if (user) {

                    let fullname = user.name.split(' ');

                    initials += fullname[0].charAt(0);
                    initials += fullname[1].charAt(0);
                }
                return initials;
            }
        }
    }
</script>