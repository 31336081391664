<template>
	<div>
		<button class="btn btn-clear btn-sm font-gray" @click="handleBack">
			<i class="fas fa-chevron-left mr-2" /> Back
		</button>

		<h3 class="text-center font-sf-bold font-medium mt-3">Agree to Terms & Policy</h3>

		<div class="p-2 border-gray-1 rounded terms-container mt-3 font-xsmall font-gray">
			<p class="font-small">
				Terms and Conditions
			</p>
			<div v-if="page && page.content && page.content.content" v-html="page.content.content" ></div>
		</div>

		<label class="font-green mt-2 font-xsmall">
			<input 
				class="mr-2" 
				type="checkbox" 
				:value="true"
				v-model="checked"
			/> 
			I agree to the <router-link :to="{name: 'page', params: { slug: 'terms-and-conditions' }}" class="font-green" target="_blank" >Terms and Service</router-link> and <router-link :to="{name: 'page', params: { slug: 'privacy-policy' }}" class="font-green" target="_blank" >Privacy Policy</router-link>
		</label>

		<button
			class="btn btn-green btn-block btn-radius-3 mt-2 py-2"
			@click="handleRegistration"
			v-bind:disabled="!checked"
		>
			Create Account
		</button>
	</div>
</template>

<script>
export default {
	name: 'termsandconditions',
	props: ['page', 'handleRegistration', 'handleBack', 'nextDisabled'],
	data() {
		return {
			checked: false,
		}
	},
	methods: {
		onCreateAccountClick() {
			if (!this.checked) return;

			this.$emit('handleRegistration');
		}
	}
};
</script>

<style></style>
