<template>
	<div class="home-page">
		<div class="container">
			<Banners />
			<HomeProducts
				@toggle-list-modal="$emit('toggle-list-modal')"
			/>

			<div class="d-flex justify-content-center pt-5">
				<router-link
					:to="{ name: 'products' }"
					class="btn btn-green-lighter btn-xl btn-radius-5 py-3 px-4"
				>
					BROWSE ALL PRODUCTS <i class="far fa-long-arrow-right ml-3"></i>
				</router-link>
			</div>
		</div>
		<div class="bbdoit-section py-5 bg-gray-2 mt-5">
			<div class="container">
				<div
					class="d-flex flex-column flex-md-row justify-content-between align-items-center flex-wrap"
				>
					<h5 class="mb-4 font-xxlarge font-sf-medium">BBdoit</h5>

					<div class="d-flex align-items-center flex-column flex-md-row w-xs-100 mb-4">
						<span class="mr-1 font-xsmall text-nowrap font-gray">Sort by:</span>
						<button 
							:class="['btn btn-sm rounded btn-white d-flex align-items-center w-xs-100 mb-2 mb-md-0 mr-1 justify-content-center justify-content-md-start ', articleParams.sort == 'recommended:desc' ? 'active' : '']"
							@click="sortArticles('recommended:desc')"
						>
							<img class="img-small mr-1" src="@assets/img/like.png" alt="like-icon" />
							<span class="mr-3" >Recommended</span>
						</button>
						<button 
							:class="['btn btn-sm rounded btn-white mr-1 d-flex align-items-center w-xs-100 mb-2 mb-md-0 justify-content-center justify-content-md-start ', articleParams.sort == 'latest:desc' ? 'active' : '']"
							@click="sortArticles('latest:desc')"
						>
							<div class="d-inline-block bg-green font-white font-xxsmall mr-1">
								NEW
							</div>
							Latest
						</button>
						<button 
							:class="['btn btn-sm rounded btn-white d-flex align-items-center w-xs-100 mb-2 mb-md-0 mr-1 justify-content-center justify-content-md-start ', articleParams.sort == 'trending:desc' ? 'active' : '']"
							@click="sortArticles('trending:desc')"
						>
							<img class="img-small mr-1" src="@assets/img/trending.png" alt="trending-icon" />
							<span class="mr-3">Trending</span>
						</button>
						<button 
							:class="['btn btn-sm rounded btn-white mr-1 w-xs-100 mb-2 mb-md-0 justify-content-center justify-content-md-start ', articleParams.sort == 'alpha:desc' || articleParams.sort == 'alpha:asc' ? 'active' : '']"
							@click="(articleParams.sort != 'alpha:asc' && articleParams.sort != 'alpha:desc') ? sortArticles('alpha:asc') : (articleParams.sort == 'alpha:asc' ? sortArticles('alpha:desc') : sortArticles('alpha:asc'))"
							:style="{ whiteSpace: 'nowrap' }"
						>
							<span>Alphabetical {{ (articleParams.sort != 'alpha:asc' && articleParams.sort != 'alpha:desc') ? 'A-Z' : (articleParams.sort == 'alpha:asc') ? 'A-Z' : 'Z-A'}}</span>
							<span :class="`fas fa-long-arrow-alt-down ml-1`" />
						</button>
						<input
							type="text"
							class="form-control form-control-sm bg-white flex-grow-1 w-xs-100 mb-2 mb-md-0"
							placeholder="Search"
							v-model="articleParams.search"
							@keyup.enter="getFeaturedArticles"
						/>
					</div>
				</div>

				<div v-if="featuredArticles && featuredArticles.length > 1 || featuredArticle" class="row row-thin">
					<div v-if="featuredArticle" class="col-sm-12 mb-4">
						<BbdoitCard 
							:article="featuredArticle"
							:wide="true" 
							:showNumbers="false"
						/>
					</div>
					<div 
						v-for="article in featuredArticles"
						:key="article.slug"
						class="col-sm-6 col-md-6 col-lg-4 mb-3"
					>
						<BbdoitCard 
							:article="article"
							:showNumbers="false"
						/>
					</div>
				</div>
				<div v-else >
					<div class="text-center font-xlarge font-sf-bold my-5">No Articles Found</div>
				</div>

				<div class="mt-5 text-center">
					<router-link 
						class="btn btn-clear btn-xl font-green" 
						:to="{ name: 'bbdoit' }"
					>
						BROWSE MORE BBDOIT <i class="far fa-long-arrow-right ml-4"></i>
					</router-link>
				</div>
			</div>
		</div>
		<ShopBySection 
			:categories="categories"
			:vendors="vendors"
		/>
	</div>
</template>

<script>
import { HomeProducts, Banners } from './components';
import BbdoitCard from '../../components/bbdoitCard/BbdoitCard';
import ShopBySection from './shopBySection';
import * as api from '@api';
import { getPage } from '@helpers/caboodle.helper';
import * as types from '../../store/mutation-types'

import './home.scss'
export default {
	name: 'Home',
	components: {
		BbdoitCard,
		ShopBySection,
		HomeProducts,
		Banners
	},
	props: ['categories', 'vendors'],
	data() {
		return {
			featuredArticles: [],
			featuredArticle: null,
			articleParams: {
				sort: 'recommended:desc',
				num_articles: 10,
				search: '',
				type: 'article'
			},
			page: null,
			seo: {
				title: '',
				data: ''
			}
		}
	},
	mounted() {
		this.getFeaturedArticles();
	},
	methods: {
		getFeaturedArticles() {
			api
				.connect()
				.get('/articles/featured', { params: this.articleParams })
					.then(res => {
						if (res.status == 200) {
							this.featuredArticles = res.data.data;
							this.featuredArticle = this.featuredArticles[0];

							this.featuredArticles = this.featuredArticles.filter((article) => {
								return article.id !== this.featuredArticle.id
							})
						}
					})
		},
		sortArticles(sort) {
			this.articleParams.sort = sort;

			this.getFeaturedArticles();
		}
	}
};
</script>
