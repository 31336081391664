<template>
    <button
        :class="`btn btn-green ${ buttonClass } ${ isLoading || isDisabled ? `loading` : '' }`"
        @click="onClick"
    >
        <div :style="{ opacity: isLoading ? 0 : 1 }">
            <slot />
        </div>
        <div v-if="isLoading">
            <Ellipsis />
        </div>
    </button>
</template>

<script>
    import { Ellipsis } from '../loaders';

    export default {
        props: {
            buttonClass: String,
            isLoading: Boolean,
            isDisabled: Boolean,
            onClick: Function
        },
        components: {
            Ellipsis
        },
        setup() {
            
        },
    }
</script>