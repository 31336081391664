import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import notfound from '@views/notFound/NotFound.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	routes: [
		...routes,

		// keep not found last
		{
			path: '*',
			name: 'notfound',
			component: notfound,
		},
	],
});
