import store from '../store/store';
import moment from 'moment';

const CART_KEY = 'buyboy-cart';
const CART_DATE_KEY = 'buyboy-cart-date';

export const setCart = (cart) => {
    localStorage.setItem(CART_KEY, JSON.stringify(cart));
}

export const getCart = () => {
	
    const cart = JSON.parse(localStorage.getItem(CART_KEY));

	if (localStorage.getItem(CART_KEY)) {
        /* if(!localStorage.getItem(CART_DATE_KEY)){
            localStorage.setItem(CART_DATE_KEY, JSON.stringify({
                expiry: moment().toDate()
            }));
        }
        let start_date = moment(JSON.parse(localStorage.getItem(CART_DATE_KEY)).expiry);
        let dateExpired = start_date.clone();
        let now = moment();
        dateExpired = dateExpired.add(1,'days');
        
        if(moment(now).isBetween(start_date,dateExpired)){
            return cart;
        } else {
            return {items:[],source:'',last_device: ''};
        } */

        return cart;
    } 

	return { items: [], source: '', last_device: '' };
}

export const hasCart = () => {
    return localStorage.getItem(CART_KEY) ? true : false;
}