import config from '../constants/config';
import ImageNotFound from '../assets/img/notfound.png';
import * as api from '@api';

export function extractAssetObj(
	obj,
	custom_path = false,
	type = 'path',
	customPlaceholder = false
) {
	if (custom_path && custom_path.length > 0) {
		for (let i = 0; i < custom_path.length; i++) {
			let prop = custom_path[i];
			if (!obj || !Object.prototype.hasOwnProperty.call(obj, prop)) {
				return customPlaceholder ? customPlaceholder : ImageNotFound;
			} else {
				obj = obj[prop];
			}
		}
		if (!obj) {
			return customPlaceholder ? customPlaceholder : ImageNotFound;
		}
		return `${config.ASSET_URL}/${obj}`;
	}
	if (obj && obj.asset && obj.asset[type]) {
		return `${config.ASSET_URL}/${obj.asset[type]}`;
	} else if (obj && obj[type]) {
		return `${config.ASSET_URL}/${obj[type]}`;
	}

	return customPlaceholder ? customPlaceholder : ImageNotFound;
}

export function backgroundImageAsset(src, extractMap = null) {

	let computedSrc = extractAssetObj(src, extractMap);

	return computedSrc;
}

export function getPage(slug) {

	return api
			.connect()
			.get(`/page/${slug}`, {});
}
