import * as types from '../mutation-types';
import { connect } from '../../helpers/api.helper';

const state = {
	page: {},
};

// used to get data from state
const getters = {
	[types.GET_PAGE]: (state) => state.page,
};

// same as react actions
const actions = {
	[types.FETCH_PAGE]: async ({ commit }, slug) => {
		const response = await connect().get(`/page/${slug}`);
		// console.log(response);
		commit(types.UPDATE_PAGE, { slug, data: response.data });
	},
};

// same as react reducer
const mutations = {
	[types.UPDATE_PAGE]: (state, payload) => (state.page[payload.slug] = payload.data),
};

export default {
	state,
	getters,
	actions,
	mutations,
};
