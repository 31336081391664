<template>
    <div>
        <button 
            class="btn btn-clear btn-sm font-gray"
            @click="handleBack"
        >
            <i class="fas fa-chevron-left mr-2" /> Back
        </button>

        <div>
            <div
                class="mobile-icon font-green mx-auto font-giantsize bg-gray-2 d-flex align-items-center justify-content-center"
            >
                <!-- <img src="" alt="" /> -->
                <i class="far fa-mobile-android"></i>
            </div>

            <div class="px-2 px-sm-5 mx-2 mx-sm-4 mb-5 pb-4 mt-3 font-small text-center font-sf-bold">
                Enter the verification code <br> sent to you via SMS
            </div>
        </div>

        <div
            class="sms-code-container d-flex align-items-center justify-content-center"
            @click="onFocus"
        >
            <div class="sms-input mx-2">{{ smsCode.charAt(0) }}</div>
            <div class="sms-input mx-2">{{ smsCode.charAt(1) }}</div>
            <div class="sms-input mx-2">{{ smsCode.charAt(2) }}</div>
            <div class="sms-input mx-2">{{ smsCode.charAt(3) }}</div>
            <div class="sms-input mx-2">{{ smsCode.charAt(4) }}</div>
            <div class="sms-input mx-2">{{ smsCode.charAt(5) }}</div>
            <input
                class="position-absolute sms-code-input"
                type="text"
                v-on:input="(e) => updateSmsCode(e.target.value)"
                ref="smsCodeInput"
                maxlength="6"
            />
        </div>
        <div v-if="smsError" class="font-small font-red text-center my-3">{{smsError}}</div>
        <div class="text-center mt-2" v-if="!smsVerified">
            <button
                class="btn btn-clear btn-sm font-green px-0"
                v-bind:disabled="resendDisabled"
                @click="startTimer"
            >
                <span class="font-gray">
                    Resend code
                </span>
                {{ timer ? timer + 's' : '' }}
            </button>
        </div>
        <button
            class="btn btn-green btn-block btn-radius-3 my-5 py-2"
            @click="handleSubmit"
            :disabled="processing"
        >
            {{ processing ? 'Validating...' : 'Submit' }}
        </button>
        <!-- <div 
            class="font-xsmall font-green text-center clickable"
            @click="handleSkip"
        >
            Skip number verification for now
        </div> -->
    </div>
</template>

<script>
import {connect} from '@api';
import { mapMutations } from 'vuex';
import * as types from '../../store/mutation-types';
import { showAlert } from '@helpers/notification.helper';

let swalSettings = {
    title: 'Congratulations!',
    text: 'You have successfully changed your number',
};

export default {
    data() {
        return {
            timer: 0,
            resendDisabled: true,
            showResend: false,
            smsCode: '',
            smsVerified: false,
            phone: '',
            dummyCode: '000000',
            verificationCode: '',
            invalidCode: false,
            processing: false,
            smsError: '',
        }
    },
    mounted() {
        this.startTimer();
    },
    methods: {
        ...mapMutations({
            updateUser: types.UPDATE_USER
        }),
        startTimer() {
			this.showResend = true;
			this.timer = 120;
			this.resendDisabled = true;

			this.handleSendSmsCode();

			let interval = setInterval(() => {
				this.timer--;
				if (this.timer == 0) {
					clearInterval(interval);
					this.resendDisabled = false;
				}
			}, 1000);
        },
        updateSmsCode(code) {
            this.smsCode = code;
        },
        handleUpdateUser(params) {
            connect().post('customers/update-details', params)
                .then(res => {
                    this.updateUser(res.data.data);
                    this.processing = false;
                    showAlert(swalSettings)
                        .then(() => {
                            this.$router.push('/account/profile');
                        });
                })
        },
        handleBack() {
            this.$router.push({
                name: 'account.number',
                query: {
                    phone: this.phone,
                    step: 2
                }
            })
        },
        handleSkip() {
            this.processing = true;

            let params = {
                phone: this.phone,
                phone_verified: false
            };

            this.handleUpdateUser(params);
        },
        handleSubmit() {
            this.smsError = '';
            this.processing = true; 

            if (this.smsCode != this.verificationCode) {
                this.smsError = 'Invalid Code';
                this.processing = false;
                return;
            }

            let params = {
                phone: this.phone,
                phone_verified: true,
            }

            this.handleUpdateUser(params);
        },
        onFocus() {
			this.$nextTick(() => this.$refs.smsCodeInput.focus());
        },
        async handleSendSmsCode() {

            this.verificationCode = Math.floor(100000 + Math.random() * 900000);
            this.phone = this.$route.query.phone;

            let text = `Your phone verification code is: ${this.verificationCode}`;

            let params = {
                text,
                phone: this.phone
            };

            let response = await connect().post('mysms/send', params);

            if (response.status != 200) {
                this.resendDisabled = false;
                this.timer = 0;
            }

            return true;
		},
    }
}
</script>