import checkout from '@views/checkout';
import information from '@views/checkout/information';
import schedule from '@views/checkout/schedule';
import payment from '@views/checkout/payment';
import placed from '@views/checkout/placed';

export default {
    path: '/checkout',
    name: 'checkout',
    component: checkout,
    children: [
        {
            path: 'placed',
            name: 'checkout.placed',
            component: placed 
        }
    ]
}