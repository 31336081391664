<template>
    <div class="banners position-relative">
        <carousel
            :perPage="1"
            :paginationEnabled="false"
            @pageChange="onPageChange"
            ref="carousel"
        >
            <slide
                v-bind:key="banner.image.id"
                v-for="banner in banners"
            >
                <div class="banner position-relative">
                    <img :src="extractAssetObj(banner, ['image', 'path'])" alt="" class="w-100 h-100 position-absolute banner-bgi" />
                    <div  
                        v-if="banner.button_link && banner.button_text"
                        class="position-absolute w-100 h-100 banner-content d-flex align-items-end"
                        @click="redirectToLink(banner.button_link)"
                    >
                        <button class="btn btn-white">
                            {{ banner.button_text }}
                        </button>
                    </div>
                </div>
            </slide>
        </carousel>
        <div
            v-if="banners.length > 1"
            class="position-absolute d-flex justify-content-center banner-thumbs w-100"
        >
            <div
                v-bind:key="`banner-thumb-${ index }`"
                v-for="(banner, index) in banners"
                :class="`banner-thumb ${ index === bannerCurrentSlide ? 'active' : '' }`"
                @click="goToPage(index)"
            />
        </div>
        <div
            v-if="banners.length > 1"
            class="banner-control position-absolute p-15 d-flex align-items-center" control-action="next"
        >
            <button
                class="btn-icon"
                @click="goToPage(bannerCurrentSlide + 1)"
            >
                <i class="fas fa-chevron-circle-right text-light font-upsized"/>
            </button>
        </div>
        <div
            v-if="banners.length > 1"
            class="banner-control position-absolute p-15 d-flex align-items-center" control-action="prev"
        >
            <button
                class="btn-icon"
                @click="goToPage(bannerCurrentSlide - 1)"
            >
                <i class="fas fa-chevron-circle-left text-light font-upsized"/>
            </button>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

import * as api from '@api'
import { extractAssetObj } from '@helpers/caboodle.helper';

export default {
    components: { Carousel, Slide },
	data: function() {
		return {
			banners: [],
            bannerCurrentSlide: 0
		}
	},
	mounted: function() {
		this.getHome()
	},
	methods: {
        redirectToLink(link) {
            window.location.href = link;
        },
        getHome () {
            api
                .connect()
                .get("page/home")
                    .then(res => {
                        if (res.data && res.data.data && res.data.data.banners) {

                            let banners = res.data.data.banners.items;

                            if (banners.length > 0) {

                                banners = banners.filter(banner => banner.publish == 'published');
                            }

                            this.banners = banners;
                        }
                    })
        },
        onPageChange (page) {
            this.bannerCurrentSlide = page;
        },
        goToPage (page) {
            const bannerSize = this.banners.length - 1
            if (page > bannerSize) page = 0
            if (page < 0) page = bannerSize
            this.$refs.carousel.goToPage(page);
        },
        extractAssetObj
    }
}
</script>