var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer-section",attrs:{"id":"footer"}},[_c('div',{staticClass:"bg-green pt-5 pb-5 font-white"},[_c('div',{staticClass:"container bottom-footer mb-5 text-center text-sm-left"},[_vm._m(0),(_vm.isPublished('about'))?_c('div',[_c('h6',{staticClass:"font-sf-medium"},[_vm._v(" COMPANY ")]),_c('ul',{staticClass:"style-none"},[(_vm.isPublished('about'))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'about' }}},[_vm._v(" About Us ")])],1):_vm._e()])]):_vm._e(),(_vm.hasContactUs || _vm.isPublished('return-policy') || _vm.isPublished('delivery') || _vm.isPublished('faq'))?_c('div',[_c('h6',{staticClass:"font-sf-medium"},[_vm._v(" CUSTOMER SERVICE ")]),_c('ul',{staticClass:"style-none"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'contact' }}},[_vm._v(" Contact Us ")])],1),(_vm.isPublished('return-policy'))?_c('li',[_c('router-link',{attrs:{"to":{
								name: 'page',
								params: {
									slug: 'return-policy'
								}
							}}},[_vm._v(" Return Policy ")])],1):_vm._e(),(_vm.isPublished('delivery'))?_c('li',[_c('router-link',{attrs:{"to":{
								name: 'page',
								params: {
									slug: 'delivery'
								}
							}}},[_vm._v(" Delivery ")])],1):_vm._e(),(_vm.isPublished('faq'))?_c('li',[_c('router-link',{attrs:{"to":{ 
								name: 'page',
								params: {
									slug: 'faq'
								}
							}}},[_vm._v(" FAQ ")])],1):_vm._e()])]):_vm._e(),(_vm.isPublished('terms-and-conditions') || _vm.isPublished('privacy-policy') || _vm.isPublished('cookies'))?_c('div',[_c('h6',{staticClass:"font-sf-medium"},[_vm._v(" POLICIES ")]),_c('ul',{staticClass:"style-none"},[(_vm.isPublished('terms-and-conditions'))?_c('li',[_c('router-link',{attrs:{"to":{
								name: 'page',
								params: {
									slug: 'terms-and-conditions'
								}
							}}},[_vm._v(" Terms & Conditions ")])],1):_vm._e(),(_vm.isPublished('privacy-policy'))?_c('li',[_c('router-link',{attrs:{"to":{
								name: 'page',
								params: {
									slug: 'privacy-policy'
								}
							}}},[_vm._v(" Privacy Policy ")])],1):_vm._e(),(_vm.isPublished('cookies'))?_c('li',[_c('router-link',{attrs:{"to":{
								name: 'page',
								params: {
									slug: 'cookies'
								}
							}}},[_vm._v(" Cookies ")])],1):_vm._e()])]):_vm._e(),(_vm.urls.facebook_page || _vm.urls.instagram_page)?_c('div',[_c('h6',{staticClass:"font-sf-medium"},[_vm._v(" CONNECT WITH US ")]),_c('ul',{staticClass:"style-none"},[(_vm.urls.facebook_page)?_c('li',[_c('a',{attrs:{"href":_vm.urls.facebook_page,"target":"_blank"}},[_vm._v(" Facebook ")])]):_vm._e(),(_vm.urls.instagram_oage)?_c('li',[_c('a',{attrs:{"href":_vm.urls.instagram_page,"target":"_blank"}},[_vm._v(" Instagram ")])]):_vm._e()])]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"arr-container"},[_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"font-sf-medium"},[_vm._v(" © 2021 QuincyBuyBoy ")]),_c('div',{staticClass:"font-sf-medium"},[_vm._v(" All Rights Reserved. ")])]),_c('div',{staticClass:"mb-5 mb-md-0"},[_vm._v(" Design by Think Sumo ")])])}]

export { render, staticRenderFns }