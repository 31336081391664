<template>
    <div v-if="product && product.inventory > 0" class="product-addons bg-gray-2 card border-0 my-4">
        <div class="card-body">
            <div>Product Add-on</div>
            <div class="row">
                <div class="col-lg-2 col-12 my-3 d-none d-lg-block">
                    <div class="bg-white text-center" >
                        <Imager 
                            :src="product.image_paths"
                            :extract="true"
                        />
                    </div>
                    <div class="font-medium">
                        <Price 
                            :price="product.price"
                            :originalPrice="product.orig_price"      
                        />
                    </div>
                    <div class="font-xsmall font-green">{{ parent.brand && parent.brand.title }}</div>
                    <div class="font-small font-gray mb-5">{{ product.title }}</div>
                    <!-- <div class="font-xsmall font-gray mt-4">
                        1 x 500g <i class="fas fa-caret-down"></i>
                    </div> -->
                </div>
                <div class="col-1 text-center mt-5 d-none d-lg-block">
                    <i class="fal fa-plus fa-2x"></i>
                </div>
                <div 
                    class="col-lg-6 col-12 mx-auto mx-md-0 row my-3 addon-column"
                    
                >
                    <div 
                        class="col-lg-4 col-md-6 col-12 mb-4 mb-md-0 product-addon-item"
                        v-for="addon in productAddOns"
                        :key="addon.id"
                    >
                        <div class="bg-white text-center" >
                            <Imager 
                                :src="addon.asset"
                                :extract="true"
                            />
                        </div>
                        <div class="font-medium">
                            <Price 
                                :price="addon.price"
                            />
                        </div>
                        <div class="font-xsmall font-green">{{ parent.brand && parent.brand.title }}</div>
                        <div class="font-small font-gray mb-5 addon-column-name">{{addon.name}}</div>
                        <!-- <div class="font-xsmall font-gray mt-5">
                            1 x 500g <i class="fas fa-caret-down"></i>
                        </div> -->
                        <button 
                            :class="`btn font-xsmall py-2 mt-3 btn-block rounded-pill ${ selectedIDs.includes(addon.id) ? 'btn-green font-white' : 'bg-green-transparent font-green' }`"
                            @click="selectAddon(addon)"
                        >
                            <span v-if="selectedIDs.includes(addon.id)" ><i class="fas fa-check mr-1"></i> Item Added</span>
                            <span v-else >Add Item</span>
                        </button>
                    </div>
                </div>
                <div class="ml-sm-auto ml-0 my-sm-3 col-lg-2 col-12 border-left border-black d-flex justify-content-center flex-column text-left">
                    <div class="my-auto">
                        <div class="font-small font-sf-medium mb-1">Total</div>
                        <Price 
                            :price="total"
                        />
                        <!-- <div class="font-xsmall font-green">
                            Saved <Price :inline="true" :price="360.00" />
                        </div> -->
                    </div>
                    <button 
                        class="btn btn-green rounded-pill py-2 mt-4 mt-lg-0"
                        @click="$emit('add-to-cart')"
                    >Add to Cart</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Price } from '@components/common';

    export default {
        props: ['parent', 'product', 'productAddOns', 'total', 'selectedAddons'],
        components: {
            Price
        },
        computed: {
            selectedIDs() {
                let { selectedAddons } = this;
                let selected = [];

                if (selectedAddons.length > 0) {
                    selectedAddons.map(addon => {
                        selected.push(addon.id);
                    })
                }

                return selected;
            }
        },
        methods: {
            selectAddon(addon) {
                this.$emit('handle-add-on-select', addon);
            },
        }
}
</script>