import axios from 'axios';
import config from '../constants/config';

function connect(additionalHeaders = {}) {
	let headers = { 'Access-Control-Allow-Origin': '*', ...additionalHeaders };

    // let credentials = window.btoa(config.PAYMONGO_PUBLIC_KEY + ':' + config.PAYMONGO_SECRET_KEY);
    let credentials = window.btoa(config.PAYMONGO_SECRET_KEY);
    let auth = 'Basic ' + credentials;

    headers = { ...headers, Authorization: ' ' + auth };

    let instance = axios.create({
        baseURL: config.PAYMONGO_URL,
        headers,
    });
	
	instance.interceptors.response.use(
		function(response) {
			// Do something with response data
			//only return the data
			const { data, status } = response;
			return { data, status };
		},
		function(error) {

			return Promise.reject(error.response);
		}
	);
	return instance;
}

export function getPaymentMethod(id, params) {
	return connect().get(`payment_methods/${id}`);
}

export function createPaymentMethod(params) {
    return connect().post('payment_methods', { data: params });
}

export function getPaymentIntent(id) {
	return connect().get(`payment_intents/${id}`);
}

export function createPaymentIntent(params) {
    return connect().post('payment_intents', { data: params });
}

export function attachPaymentIntent(id, params) {
    return connect().post(`payment_intents/${id}/attach`, { data: params });
}
