<template>
    <span>
        <span v-if="category.children && category.children.length > 0" >
            <b-dropdown
                :toggle-class="`btn btn-sm btn-white py-1 rounded-pill mr-2 category-btn-parent ${ $router.query && $router.query.category == category.slug ? 'router-link-active' : '' }`"
                variant="light"
                :no-caret="true"
                menu-class="p-2"
            >
                <template #button-content>
                    <div>
                        <span
                            @click.stop="() => {
                                $router.replace({
                                    name: 'products',
                                    query: {
                                        ...params, category: category.slug
                                    }
                                })
                            }"
                        >{{category.title}}</span>
                        <i class="ml-2 fas fa-caret-down font-gray fa-lg" />
                    </div>
                </template>
                <b-dropdown-text 
                    text-class="font-xsmall mb-2"
                    v-for="(child, index) in category.children"
                    :key="index"
                >
                    <router-link
                        class="clickable"
                        :to="{
                            query: { ...params, category: child.slug }
                        }"
                    >{{child.title}}</router-link>
                </b-dropdown-text>
            </b-dropdown>
        </span>
        <span v-else >
            <router-link
                class="btn btn-sm btn-white py-1 rounded-pill mr-2"
                :to="{
                    query: { ...params, category: category.slug }
                }"
            >
            {{category.title}}
            </router-link>
        </span>
    </span>
</template>

<script>
    export default {
        name: 'CategoryButtons',
        props: {
            category: Object,
            params: Object
        },
        mounted() {
            let parents = document.getElementsByClassName('category-btn-parent');
            
            if (parents) {
                parents.forEach(parent => {
                    let children = parent.nextSibling.children;

                    children.forEach(child => {
                        let routerLinkClasses = child.lastChild.lastChild.classList;

                        if (routerLinkClasses.contains('router-link-active')) {
                            parent.classList.add('router-link-active');
                        }
                    })
                });
            }
        }
    }
</script>