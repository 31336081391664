<template>
  <div :class="`cart-table-row-grid addon px-2 py-4 ${ className ? className : '' }`">
      <div class="checkbox-column text-center">
        <div class="custom-control custom-checkbox">
            <input 
                type="checkbox" 
                class="custom-control-input" 
                :id="`cart-item-addon-${item.id}`"
                :checked="isChecked"
                @change="toggleSelect"
            >
            <label class="custom-control-label" :for="`cart-item-addon-${item.id}`"></label>
        </div>
    </div>
    <div class="image-column text-center px-sm-4 px-2">
        <Imager 
            :src="item.info"
            :extract="true"
            :alt="item.info.name"
        />
    </div>
    <div class="description-column">
        <div class="py-2">
            <!-- <div v-if="item.product.brand && item.product.brand.title" class="font-xsmall font-green">{{item.product.brand.title}}</div> -->
            <div class="font-small">{{item.info.name}}</div>
            <div class="d-flex align-items-center flex-wrap">
                <div 
                    v-if="item.product && item.product.variant_options"
                    class="d-flex align-items-center"
                >
                    <span 
                        class="bg-gray-2 font-xsmall font-sf-medium p-1 px-2 rounded font-green"
                        v-for="(variant, index) in item.product.variant_options"
                        :key="index"
                    >
                        {{variant.option}}: {{variant.name}}
                        <!-- <i class="fas fa-caret-down ml-1 font-gray"></i> -->
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="price-column text-center">
        <Price
            :price="item.info.price"
            priceClass="font-sf-medium"
        />
    </div>
    <div class="quantity-column text-center px-4">
        <div class="input-group">
            <div class="input-group-prepend border">
                <div 
                    class="input-group-text clickable"
                >
                    <i 
                        v-if="item.info && item.info.is_quantifiable > 0" 
                        class="fas fa-minus font-green"
                        @click="handleQuantityChange('minus')"
                    ></i>
                </div>
            </div>
            <input 
                type="text"
                class="form-control bg-white text-center border border-right-0 px-0"
                v-model="quantity"
                maxlength="4"
                pattern="/d*"
                :readonly="item.info.is_quantifiable ? false : true"
                @change="handleQuantityChange"
            >
            <div class="input-group-prepend border">
                <div 
                    class="input-group-text clickable"
                >
                    <i 
                        v-if="item.info && item.info.is_quantifiable > 0" 
                        class="fas fa-plus font-green"
                        @click="handleQuantityChange('plus')"
                    ></i>
                </div>
            </div>
        </div>
    </div>
    <div class="total-column text-center">
        <div class="font-sf-medium ml-n5" >
            <Price
                :price="total"
            />
        </div>
    </div>
  </div>
</template>

<script>
    export default {
        props: ['item', 'selectedAddons', 'className', 'changeAddonQty'],
        data() {
            return {
                quantity: this.item.qty ? this.item.qty : 1
            }
        },
        computed: {
            total() {
                let total = 0;

                if (this.item && this.item.info) {
                    total += ( parseFloat(this.item.info.price) * this.quantity );
                }

                return total;
            },
            isChecked() {
                let { selectedAddons, item } = this;
                let checked = false;

                if (selectedAddons && selectedAddons.length > 0) {
                    checked = selectedAddons.includes(item.id)
                }

                return checked;
            }
        },
        methods: {
            handleQuantityChange(method = null) {

                if (method != null) {
                    if (method == 'plus') {
                        this.quantity = parseInt(this.quantity) + 1;
                    } else {
                        if (this.quantity <= 1) {
                            this.$emit('remove-item-addon', this.item);
                        } else {
                            this.quantity = parseInt(this.quantity) - 1;
                        }
                    }
                }

                if (this.quantity > 0) {
                    this.changeAddonQty(this.item, this.quantity)
                }
            },
            toggleSelect(addon) {
                this.$emit('toggle-select', addon)
            }
        }
    }
</script>