<template>
    <div>
        <ul class="pagination custom-pagination justify-content-center flex-wrap pb-3">
            <li class="page-item mb-2">
                <button 
                    :class="['btn btn-pagination rounded-pill mr-2 ', currentPage == 1 ? 'disabled' : '']"
                    @click="changePage(parseInt(currentPage) - 1, 'prev')"
                >
                    <i class="fas fa-long-arrow-left mr-3"></i> PREVIOUS
                </button>
            </li>
            <li 
                v-if="currentPage > maxPages"
                class="page-item mb-2"
            >
                <button 
                    :class="['btn btn-pagination rounded-circle mr-2 ', currentPage == 1 ? 'active' : '']"
                    @click="changePage(1)"
                >{{ 1 }}</button>
            </li>
            <li 
                class="page-item mb-2"
                v-if="currentPage > maxPages"
            >
                <span class="btn btn-clear mr-2 font-green">...</span>
            </li>
            <li 
                class="page-item mb-2"
                v-for="n in pages"
                :key="n"
            >
                <button 
                    :class="['btn btn-pagination rounded-circle mr-2 ', currentPage == n ? 'active' : '']"
                    @click="changePage(n)"
                >{{ n }}</button>
            </li>
            <li 
                class="page-item mb-2"
                v-if="(pages[pages.length - 1] + 1) <= lastPage"
            >
                <span class="btn btn-clear mr-2 font-green">...</span>
            </li>
            <li 
                v-if="pages[pages.length - 1] != lastPage"
                class="page-item mb-2"
            >
                <button 
                    :class="['btn btn-pagination rounded-circle mr-2 ', currentPage == lastPage ? 'active' : '']"
                    @click="changePage(lastPage)"
                >{{ lastPage }}</button>
            </li>
            <li class="page-item mb-2">
                <button 
                    :class="['btn btn-pagination rounded-pill ', currentPage == lastPage ? 'disabled' : '']"
                    @click="changePage(parseInt(currentPage) + 1, 'next')"
                >
                    NEXT <i class="fas fa-long-arrow-right ml-3"></i>
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ['currentPage', 'lastPage', 'params'],
    data() {
        return {
            maxPages: 3, // max number of pages before ellipsis
        }
    },
    computed: {
        pages() {
            let { lastPage, currentPage, maxPages } = this;
            let pages = [];
            let pageCount = 0; 

            // Compute number of pages
            for (let i = 1; i <= lastPage; i++) {
                if (i >= currentPage) {
                    if (pageCount < maxPages) {
                        pages.push(i);
                        pageCount += 1;
                    }
                }
            }

            // Check if pages is less than max pages
            if (pages.length < maxPages) {
                while(pages.length < maxPages) {
                    pages.unshift((pages[0] - 1));
                }
            }

            pages = pages.filter(page => {
                return page >= 1;
            })

            return pages;
        }
    },
    methods: {
        changePage(pageNumber, specialLink = '') {    

            if (specialLink) {
                if (this.currentPage == 1 && specialLink == 'prev' ) {
                    return
                }
    
                if (this.currentPage == this.lastPage && specialLink == 'next' ) {
                    return
                }
            }

            let newPage = { page: pageNumber };

            this.$router.push({ query: { ...this.params, ...newPage } });
        }
    }
}
</script>