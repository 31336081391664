const paymongoURL = 'https://api.paymongo.com/v1/';

const env = {
	API_URL: process.env.VUE_APP_API_URL,
	ASSET_URL: process.env.VUE_APP_ASSET_URL,
	SITE_URL: process.env.VUE_APP_SITE_URL,
	FB_ID: process.env.VUE_APP_FB_ID,
	PAYMONGO_URL: paymongoURL,
	PAYMONGO_PUBLIC_KEY: process.env.VUE_APP_PAYMONGO_PUBLIC_KEY,
	PAYMONGO_SECRET_KEY: process.env.VUE_APP_PAYMONGO_SECRET_KEY,
	GOOGLE_APP_ID: process.env.VUE_APP_GOOGLE_APP_ID,
	GOOGLE_API_KEY: process.env.VUE_APP_GOOGLE_API_KEY,
	GOOGLE_CLIENT_ID: process.env.VUE_APP_GOOGLE_CLIENT_ID,
	GOOGLE_CLIENT_SECRET: process.env.VUE_APP_GOOGLE_CLIENT_SECRET,
	FB_APP_ID: process.env.VUE_APP_FB_APP_ID,
	FB_APP_SECRET: process.env.VUE_APP_FB_APP_SECRET
};

export default env;
