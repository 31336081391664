<template>
    <div>
        <div class="row">
            <div class="col-md-8 col-12">
                <div>
                    <router-link
                        :to="{ name: 'account.accounts' }"
                        class="font-xxlarge font-sf-medium d-flex align-items-center clickable" 
                    >
                        <i class="fas fa-chevron-left font-small mr-2"></i> <span>My Bank Accounts</span>
                    </router-link>
                </div>
                <div>
                    <label class="mb-0 mt-3 font-xsmall font-gray" >Bank Name</label>
                    <input
                        type="text"
                        class="form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black"
                        v-model="formValues.bank_name"
                    />
                    <div v-if="formErrors.bank_name" class="font-red font-xsmall" >{{ formErrors.bank_name }}</div>
                </div>
                <div>
                    <label class="mb-0 mt-3 font-xsmall font-gray" >Account Name</label>
                    <input
                        type="text"
                        class="form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black"
                        v-model="formValues.account_name"
                    />
                    <div v-if="formErrors.account_name" class="font-red font-xsmall" >{{ formErrors.account_name }}</div>
                </div>
                <div>
                    <label class="mb-0 mt-3 font-xsmall font-gray" >Account Number</label>
                    <input
                        type="text"
                        class="form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black"
                        v-model="formValues.account_number"
                    />
                    <div v-if="formErrors.account_number" class="font-red font-xsmall" >{{ formErrors.account_number }}</div>
                </div>
                <div class="mt-5">
                    <button 
                        class="btn btn-sm btn-block btn-green"
                        :disabled="processing"
                        @click="handleSubmit"    
                    >
                        {{ processing ? 'Saving...' : 'Save' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { showAlert } from '@helpers/notification.helper';
    import { connect } from '@api';
    import * as types from '../../store/mutation-types';
    import { mapMutations } from 'vuex';
    import validate from '@helpers/validation.helper';
    import { getUser } from '@helpers/session.helper';

    export default {
        props: {
            bank: Object
        },
        data() {
            return {
                formValues: {
                    bank_name: '',
                    account_name: '',
                    account_number: '',
                },
                formErrors: {
                    bank_name: '',
                    account_name: '',
                    account_number: '',
                },
                processing: false
            }
        },
        computed: {
            method() {
                return this.$route.params.method
            },
            banks() {
                let user = getUser();
                return user.customer.banks
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.id) {

                let bank_id = this.$route.query.id;
                let bank = this.banks.find(bank => {
                    return bank.id == bank_id;
                });

                this.formValues = {...this.formValues, ...bank};
            }
        },
        methods: {
            ...mapMutations({
                updateUser: types.UPDATE_USER
            }),
            async validateForm() {
                const schema = {
                    properties: {
                        bank_name: {
                            type: 'string',
                            required: true,
                            allowEmpty: false
                        },
                        account_name: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                        },
                        account_number: {
                            type: 'string',
                            required: true,
                            allowEmpty: false
                        }
                    }
                };

                const validateResults = validate(this.formValues, schema, undefined, true);

                this.formErrors = validateResults.errors;

                return validateResults.valid;
            },
            async handleSubmit() {
                this.processing = true;

                if (!(await this.validateForm())) {
                    this.processing = false;
                    return;
                }

                if (this.method == 'edit') {

                    let swalSettings = {
                        title: 'Congratulations!',
                        text: 'You successfully updated this account'
                    };

                    let params = {
                        id: this.$route.query.id,
                        ...this.formValues
                    };

                    connect().post('/customers/update-bank', params)
                        .then(res => {
                            this.processing = false;
                            this.updateUser(res.data.user);

                            showAlert(swalSettings)
                                .then(() => {
                                    this.$router.push({ name: 'account.accounts' });
                                })
                        })
                        .catch(err => {
                            this.processing = false;
                            return;
                        })

                } else {

                    let swalSettings = {
                        title: 'Congratulations!',
                        text: 'You successfully added this account'
                    };

                    connect().post('/customers/store-bank', this.formValues)
                            .then(res => {
                                this.processing = false;
                                this.updateUser(res.data.user);

                                showAlert(swalSettings)
                                    .then(() => {
                                        this.$router.push({ name: 'account.accounts' });
                                    })
                            })
                            .catch(err => {
                                this.processing = false;
                                return;
                            })
                }

            }
        }
    }
</script>