<template>
    <div class="account-page py-5" >
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-12">
                    <AccountSidebar 
                        :user="user"
                        @logout="handleLogout"
                    />
                </div>
                <div class="col-lg-9 col-12">
                    <router-view
                        :user="user"
                        @toggle-settings-popup="toggleSettingsPopup"
                    ></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AccountSidebar from './components/sidebar';
import { getUser } from '@helpers/session.helper';
import { mapActions } from 'vuex';
import * as types from '../../store/mutation-types';

export default {
    computed: {
        user: {
            get() {
                return getUser();
            }
        }
    },
    methods: {
        ...mapActions({
            logout: types.LOGOUT
        }),
        toggleSettingsPopup(event) {
            event.target.nextSibling.classList.toggle('show');
        },
        handleLogout() {
            this.logout();

            this.$router.push({ name: 'login' });
        }
    },
    components: {
        AccountSidebar
    },
}
</script>