<template>
    <div :class="`page ${$route.params.slug}-page`" >
        <div v-if="isLoading" >
            <div class="container my-5" >
                <div class="mb-5">
                    <div class="skeleton-loader header w-50 my-5"></div>
                </div>
                <div class="mb-5">
                    <div class="skeleton-loader mb-4 text w-75"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text w-75"></div>
                </div>
                <div class="mb-5">
                    <div class="skeleton-loader mb-4 text w-75"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text w-75"></div>
                </div>
                <div class="mb-5">
                    <div class="skeleton-loader mb-4 text w-75"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text w-75"></div>
                </div>
            </div>
        </div>
        <div v-else >
            <div v-if="page && page.content" >
                <div
                    class="banner bg-mint-green border-gray-1 d-flex align-items-center justify-content-center font-giantsize font-sf-bold"
                    v-if="page && page.content && page.content.title"
                    v-html="page.content.title"
                ></div>
                <div class="container my-5">
                    <div class="row">
                        <div class="col-md-8 col-12 mx-auto">
                            <div
                                v-if="page && page.content && page.content.content"
                                v-html="page.content.content"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="page && page.contents" >
                <div
                    class="banner bg-mint-green border-gray-1 d-flex align-items-center justify-content-center font-giantsize font-sf-bold"
                    v-if="page && page.title"
                    v-html="page.title"
                ></div>
                <div v-if="page.contents && page.contents.length > 0" class="container my-5">
                    <div class="row">
                        <div class="col-md-8 col-12 mx-auto">
                            <div
                                v-for="(content, key) in page.contents"
                                class="border-bottom mb-5 pb-5"
                                :key="key"
                            >
                                <p class="font-oversized font-sf-bold">{{content.title}}</p>
                                <div v-if="content.items && content.items.length > 0" >
                                    <ul>
                                        <li
                                            v-for="(item, itemIndex) in content.items"
                                            :key="itemIndex"
                                        >
                                            <p class="font-xlarge font-sf-bold">{{item.title}}</p>
                                            <div class="font-large" v-if="item.content" v-html="item.content" ></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NotFound v-else />
        </div>
    </div>
</template>

<script>
import { getPage } from '@helpers/caboodle.helper';
import NotFound from '@views/errors/404';

export default {
    data() {
        return {
            page: null,
            seo: {
                title: '',
                description: ''
            },
            isLoading: true,
        }
    },
    mounted() {

        this.page = null;

        this.getPageData(this.$route.params.slug);
    },
    methods: {
        getPageData(slug) {
            this.isLoading = true;

			getPage(slug)
				.then(res => {
					if (res.status == 200 && res.data) {
                        this.page = res.data.data;
					} else {
                        this.page = null;
                    }

                    this.isLoading = false;
				})
                .catch(err => {
                    this.page = null;
                    this.isLoading = false;
                })
		}
    },
    watch: {
        $route(to) {
            this.getPageData(to.params.slug);
        },
    },
    components: {
        NotFound
    }
}
</script>