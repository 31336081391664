<template>
    <div class="profile-form" >
        <div class="font-giantsize font-sf-bold my-3">My Profile</div>
        <div class="row">
            <div class="col-md-8 col-12">

                <!-- FULL NAME FIELD -->
                <div>
                    <label class="mb-0 mt-3 font-xsmall font-gray" >Full Name</label>
                    <input
                        type="text"
                        class="form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black"
                        v-model="formValues.fullname"
                        @keydown="handleInputChange"
                    />
                    <div v-if="formErrors.fullname" class="font-red font-small" >{{ formErrors.fullname }}</div>
                </div>

                <!-- EMAIL FIELD -->
                <label class="mb-0 mt-3 font-xsmall font-gray" >Email Address</label>
                <div class="input-group rounded-0 font-xsmall font-black">
                    <div
                        class="form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black border-right-0"
                    >
                    <span class="mr-2" >{{ user.email }}</span>
                    <Verified
                        v-if="user.verified"
                    /> 
                    </div>
                    <div class="input-group-append">
                        <router-link 
                            class="input-group-text clickable font-green rounded-0 bg-gray-2 border-gray-2 font-xsmall"
                            :to="{ 
                                name: 'verify.email',
                                query: {
                                    action: user.verified ? 'change' : 'verify'
                                }
                            }"
                        ><span v-html="user.verified ? 'CHANGE' : 'VERIFY'"/></router-link>
                    </div>
                </div>

                <!-- PHONE NUMBER FIELD -->
                <label class="mb-0 mt-3 font-xsmall font-gray" >Phone Number</label>
                <div class="input-group append rounded-0 font-xsmall font-black">
                    <div
                        class="form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black border-right-0"
                    >
                    <span class="mr-2" >{{ user.customer && user.customer.phone }}</span>
                    <Verified 
                        v-if="user.phone_verified"
                    /> 
                    </div>
                    <div class="input-group-append">
                        <router-link 
                            class="input-group-text clickable font-green rounded-0 bg-gray-2 border-gray-2 font-xsmall"
                            :to="{ 
                                name: 'account.number',
                                query: user.phone_verified ? {} : {
                                    phone: user.customer.phone,
                                    step: 2,
                                }
                            }"
                        ><span v-html="user.phone_verified ? 'CHANGE' : 'VERIFY'"/></router-link>
                    </div>
                </div>

                <!-- BIRTHDAY FIELD -->
                <div>
                    <label class="mb-0 mt-3 font-xsmall font-gray" >Birthday</label>
                    <input
                        :type="`${ isSafari ? 'text' : 'date' }`"
                        class="form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black"
                        v-model="formValues.birthday"
                        @change="handleInputChange"
                        :min="validYears.min"
                        :max="validYears.max"
                        placeholder="YYYY-MM-DD"
                    />
                    <div v-if="formErrors.birthday" class="font-small font-red" >{{ formErrors.birthday }}</div>
                </div>

                <!-- GENDER FIELD -->
                <div>
                    <label class="mb-0 mt-3 font-xsmall font-gray" >Gender</label>
                    <select 
                        class="form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black" 
                        v-model="formValues.gender"
                        @change="handleInputChange"
                    >
                        <option value="" disabled selected>Select a gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                    <div v-if="formErrors.gender" class="font-small font-red">{{ formErrors.gender }}</div>
                </div>

                <div class="my-5 text-center" >
                    <button 
                        v-if="showSaveButton" 
                        class="btn btn-green py-3 font-xsmall rounded-pill btn-block mx-auto"
                        @click="saveChanges"
                        :disabled="saving"
                    >{{ saving ? 'Saving...' : 'Save Changes' }}</button>
                </div>
            </div>
            <div class="col-md-4 col-12 text-center">
                <label for="avatar" class="mt-3" >
                    <img 
                        v-if="uploadedFile" 
                        :src="uploadedFile" 
                        class="avatar-img rounded-circle clickable"
                    >
                    <div v-else >
                        <Imager
                            v-if="defaultValues.imageData && defaultValues.imageData.path"
                            :src="defaultValues.imageData"
                            :extract="true"
                            className="avatar-img rounded-circle clickable"
                        />
                        <div v-else class="avatar-placeholder rounded-circle bg-gray-light d-flex align-items-center justify-content-center clickable">
                            <i class="fal fa-plus font-giantsize font-white"></i>
                        </div>
                    </div>
                    <div class="font-green font-xsmall font-green mt-3 clickable">
                        {{ defaultValues.imageData && defaultValues.imageData.path ? 'CHANGE IMAGE' : 'UPLOAD IMAGE' }}
                    </div>
                    <input 
                        type="file" 
                        name="imageData" 
                        id="avatar" 
                        class="d-none" 
                        accept="image/jpg, image/jpeg, image/png" 
                        @change="handleFileChange($event.target.name, $event.target.files)"
                    >
                </label>
            </div>
        </div>
    </div>
</template>
<script>
import { Verified } from '@components/common';
import moment from 'moment';
import { connect } from '@api';
import { mapMutations, mapActions } from 'vuex';
import * as types from '../../store/mutation-types';
import { Imager } from '@components/common';
import validate from '@helpers/validation.helper';
import { showAlert } from '@helpers/notification.helper';
import { getValidYears, validateBirthday } from '@helpers/date.helper';
import * as sessionHelper from '@helpers/session.helper';
import * as is from 'is_js'

let swalSettings = {
    title: 'Congratulations!',
    text: 'You have successfully updated your profile'
};

export default {
    props: ['user'],
    components: {
        Verified,
        Imager
    },
    data() {
        return {
            defaultValues: {},
            formValues: {
                fullname: '',
                birthday: '',
                gender: '',
                imageData: ''
            },
            formErrors: {
                fullname: '',
                birthday: '',
                gender: '',
                imageData: ''
            },
            showSaveButton: false,
            uploadedFile: null,
            saving: false,
            isSafari: false
        }
    },
    computed: {
        validYears() {
            return getValidYears()
        }
    },
    mounted() {

        this.isSafari = is.safari();

        if (this.user) {
            this.defaultValues = {
                fullname: this.user && this.user.customer && this.user.customer.fullname,
                birthday: this.user && this.user.customer && this.user.customer.birthday,
                gender: this.user && this.user.customer && this.user.customer.gender,
                imageData: this.user && this.user.asset && this.user.asset
            }
        }
        this.formValues = {...this.formValues, ...this.defaultValues};
    },
    methods: {
        ...mapActions({
            calculateUserAge: types.CALCULATE_USER_AGE
        }),
        ...mapMutations({
            setUser: types.SET_USER_AGE,
            updateUser: types.UPDATE_USER,
            setUserAge: types.SET_USER_AGE
        }),
        handleInputChange() {
            if (this.isChanged()) {
                this.showSaveButton = true;
            } else {
                this.showSaveButton = false;
            }
        },
        moment(date) {
            return moment(date)
        },
        async validateForm() {

            const schema = {
                properties: {
                    fullname: {
                        type: 'string',
                        required: true,
                        allowEmpty: false,
                        messages: {
                            allowEmpty: 'Please enter your full name',
                            required: 'Please enter your full name',
                        }
                    },
                    gender: {
                        type: 'string',
                        required: true,
                        allowEmpty: false,
                        messages: {
                            allowEmpty: 'Please select a gender',
                            required: 'Please select a gender',
                        }
                    },
                    birthday: {
                        type: 'string',
                        required: true,
                        allowEmpty: false,
                        format: 'date',
                        messages: {
                            allowEmpty: 'Please enter your birthday',
                            required: 'Please enter your birthday',
                            format: 'Please enter a valid date format YYYY-MM-DD of your birthday'
                        }
                    }
                }
            };

            const validateResults = validate(this.formValues, schema, undefined, true);

            this.formErrors = validateResults.errors;

            // BIRTHDAY VALIDATION
            if (!validateBirthday(this.formValues.birthday)) {
                this.formErrors.birthday = 'Please enter a valid date of your birthday. Allowed age must be 15 to 100 years old.';
                validateResults.valid = false;
            }
            
            return validateResults.valid;
        },
        isChanged() {
            let defaultValues = this.defaultValues;
            let formValues = this.formValues;

            let keys = Object.keys(this.defaultValues);

            for (let key of keys) {
                if (defaultValues[key] != formValues[key]) {
                    return true;
                }
            }

            return false;
        },
        handleFileChange(fieldName, fileList) {
            if (!fileList.length) return;

            // LOAD IMAGE ON SELECT
            this.uploadedFile = URL.createObjectURL(fileList[0]);

            this.formValues.imageData = fileList[0];
            this.showSaveButton = true;
        },
        async saveChanges() {
            this.saving = true;

            if (!(await this.validateForm())) {
                
                this.saving = false;
                return false;

            } else {
                const formData = new FormData();
                const fieldNames = Object.keys(this.formValues);
                let formValues = this.formValues;

                console.log('Form Values', formValues);

                for (let key of fieldNames) {
                    if (formValues[key]) {
                        if (key == 'imageData' && formValues[key] instanceof Blob ) {
                            formData.append(key, formValues[key], formValues[key].name);
                        } else {
                            formData.append(key, formValues[key]);
                        }
                    }
                }

                let res = await connect().post('customers/update-details', formData);

                if (res.status == 200) {
                    let user = res.data.data;
                    // console.log(res.data.data);
                    this.updateUser(user);
                    this.saving = false;
                    this.calculateUserAge(user);
                    this.showSaveButton = false;
                    showAlert(swalSettings);
                }
            }

        }
    }
}
</script>