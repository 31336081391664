import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import user from './models/user.model';
import page from './models/page.model';
import settings from './models/settings.model';
import wishlist from './models/wishlist.model';
import cart from './models/cart.model';

Vue.use(Vuex);

const persistedState = createPersistedState({
	key: 'vue-buyboy',
	paths: ['user', 'settings', 'wishlist', 'cart'],
});

export default new Vuex.Store({
	modules: {
		user,
		page,
		settings,
		wishlist,
		cart
	},
	plugins: [persistedState],
});
