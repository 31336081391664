<template>
    <div>
        <MainFilters :setFilter="setFilter"/>
        <SecondaryFilters
            :activeFilter="activeFilter"
            :activeSort="params.sort"
            :setSort="setSort"
        />
        <div v-if="isLoading" >
            <div class="row row-thin">
                <div 
                    class="col-xl-3 col-sm-6 col-12 mb-4"
                    v-for="n in 8"
                    :key="n"
                >
                    <CardLoader />
                </div>
            </div>
        </div>
        <div v-else >
            <div v-if="products.data && products.data.length > 0" class="row row-thin">
                <div
                    v-for="product in products.data"
                    :key="`productcard${ product.id }`"
                    class="col-xl-3 col-sm-6 col-12 mb-3"
                >
                    <ProductCard
                        :product="product"
                        @show-product-popup="showProductPopup"
                        @toggle-list-modal="$emit('toggle-list-modal')"
                    />
                </div>
            </div>
            <div v-else class="py-5 my-5 text-center font-giantsize font-sf-bold" >No products found</div>
            <ProductPopup 
                :slug="popupSlug"
                @toggle-list-modal="$emit('toggle-list-modal')"
            />
        </div>
    </div>
</template>

<script>
import ProductCard from '@components/productCard/ProductCard'
import { MainFilters, SecondaryFilters } from './components'
import ProductPopup from '@components/productPopup'
import { CardLoader } from '@components/common';
import * as api from '@api'

export default {
    components: {
        ProductCard,
        MainFilters,
        SecondaryFilters,
        ProductPopup,
        CardLoader
    },
    data () {
        return {
            products: {},
            params: {
                page_size: 8,
                sort: "relevance:desc",
                tags: ""
            },
            activeFilter: "",
            popupSlug: '',
            isLoading: true
        }
    },
    created() {
        this.getProducts()
    },
    watch: {
        params: {
            handler () {
                this.getProducts()
            },
            deep: true
        }
    },
    methods: {
        getProducts () {
            this.isLoading = true;
            api
                .connect()
                .get("products/get", { params: this.params })
                    .then(res => {
                        if (res.status == 200) {
                            this.products = res.data.products;
                            this.isLoading = false;
                        }
                    })
        },
        setFilter (filter) {
            this.params.tags = filter.value
            this.activeFilter = filter.label
        },
        setSort (sort) {
            this.params.sort = sort
        },
        showProductPopup(slug) {
            this.popupSlug = slug;
            this.$bvModal.show('product-popup');
        }
    },
}
</script>