<template>
    <div 
        class="customer-reviews py-4"
    >
        <div class="row">
            <div class="col-md-7 col-12">
                <div class="font-xlarge font-sf-medium mb-2">Customer Reviews</div>
                <div v-if="hasPendingReview" >
                    <div class="customer-reviews-form border-bottom pb-3">
                        <div class="d-flex">
                            <Avatar
                                :user="user"
                                className="mr-3"
                            />
                            <div class="flex-grow-1" >
                                <div class="mb-1" >
                                    <StarRating
                                        :star-size="13"
                                        :rounded-corners="true"
                                        :border-width="3"
                                        :increment="0.5"
                                        v-model="reviewParams.rating"
                                        border-color="#29673E"
                                        active-color="#29673E"
                                        inactive-color="white"
                                        text-class="font-xsmall font-sf-medium font-green"
                                    />
                                </div>
                                <textarea 
                                    cols="30" 
                                    rows="5" 
                                    class="form-control form-control-sm"
                                    placeholder="Say something"
                                    v-model="reviewParams.content"
                                ></textarea>
                                <div class="text-right mt-3">
                                    <button
                                        class="btn btn-sm btn-green rounded-pill"
                                        @click="submitReview"
                                    >Submit Review</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="reviews.data && reviews.data.length > 0" >
                    <div 
                        class="review-card border-bottom py-3"
                        v-for="(review, key) in reviews.data"
                        :key="key"
                    >
                        <div class="d-flex">
                            <div class="mr-3 py-1">
                                <Avatar
                                    className="mini"
                                    :user="review.customer.user"
                                />
                            </div>
                            <div class="flex-grow-1 font-small">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>{{review.customer.fullname}}</div>
                                    <span 
                                        v-if="user && user.id"
                                        class="font-gray font-xsmall clickable hover-green"
                                        @click="flagReview(review.id)"
                                    >Flag Review?</span>
                                </div>
                                <div>
                                    <span class="p-1 bg-gray-2 font-xsmall" >
                                        <i 
                                            v-for="n in review.rating"
                                            :key="n"
                                            class="fas fa-star font-green mr-1"
                                        ></i>
                                        <span v-if="5 - review.rating > 0" >
                                            <i 
                                                v-for="i in (5 - review.rating)"
                                                :key="i"
                                                class="far fa-star font-green mr-1"
                                            ></i>
                                        </span>
                                        {{ review.rating }}
                                    </span>
                                </div>
                                <div class="font-gray my-1" v-html="review.content"></div>
                                <div class="font-xsmall font-gray-light">Submitted {{ formatDate(review.created_at) }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="reviews.last_page > 1" class="mt-4" >
                        <Pagination
                            :currentPage="reviews.current_page"
                            :lastPage="reviews.last_page"
                            @change-page="$emit('change-page')"
                        />
                    </div>
                </div>
                <div v-else >
                    <div class="py-3 font-large font-sf-medium">No reviews found</div>
                </div>
            </div>
            <div class="col-md-4 col-12 ml-auto">
                <div class="card bg-gray-2 border-0 rounded-0">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between font-xlarge font-sf-medium mb-2">
                            <div class="font-green" >
                                <i class="fas fa-star"></i>
                                {{ Number(average).toFixed(1) }}
                            </div>
                            <div>
                                {{ totalRatings }} rating{{ totalRatings > 1 ? 's' : '' }}
                            </div>
                        </div>
                        <div 
                            class="font-xsmall row font-green mb-1"
                            v-for="n in [5,4,3,2,1]"
                            :key="n"
                        >
                            <span class="col-1 pr-0" >{{n}}</span>
                            <span class="col pl-2">
                                <i 
                                    class="fas fa-star mr-1"
                                    v-for="i in n"
                                    :key="i"
                                ></i>
                            </span>
                            <div class="col ml-auto font-black text-right">{{ratings[n]}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Avatar } from '@components/common';
    import moment from 'moment';
    import Pagination from './reviewsPagination';
    import StarRating from 'vue-star-rating';

    export default {
        components: {
            Avatar,
            Pagination,
            StarRating
        },
        props: ['average', 'reviews', 'ratings', 'hasPendingReview', 'user', 'reviewParams', 'submitReview', 'flagReview'],
        computed: {
            totalRatings() {

                let ratingsCount = 0;
                let keys = Object.keys(this.ratings);

                if (keys.length > 0) {
                    keys.map(index => {
                        ratingsCount += parseInt(this.ratings[index]);
                    })
                }

                return ratingsCount;
            }
        },
        methods: {
            formatDate(date) {

                return moment(date).format('MMM DD')
            }
        }
    }
</script>

<style scoped>
    .hover-green:hover {
        color: #29673e;
    }
</style>