<template>
    <div class="card h-100">
        <div class="card-body">
            <div class="font-xxlarge font-sf-medium flex-fill">{{ deliveryOption == 'delivery' ? 'Delivery' : 'Pick Up' }} Schedule</div>
            <div v-if="subtext" class="my-3 font-xxsmall font-gray" v-html="subtext" ></div>
            <!-- <div class="my-3 font-xxsmall font-gray">
                Choose your preferred time and date for delivery. <br> Cut off for same-day {{ deliveryOption == 'delivery' ? 'delivery' : 'pick up' }} is 4:00pm. {{ deliveryOption == 'delivery' ? 'We deliver' : 'Pick up is available' }} from 10:00am - 5:00pm
            </div> -->
            <!-- <div class="font-small font-red" v-if="scheduleError" >{{scheduleError}}</div> -->
            <div 
                class="row mt-4 mx-n1"
                v-if="dates && dates.length > 0"
            >
                <div 
                    class="flex-fill w-xs-100 p-0 mb-2" 
                    v-for="(date, index) in dates" 
                    :key="index"
                >
                    <div 
                        :class="`card flex-fill mx-1 clickable ${ schedule.date && schedule.date.getTime() == date.getTime() ? 'border-green' : '' }`"
                        @click="setDate(date)"
                    >
                        <div :class="['card-body']">
                            <div :class="`font-small font-sf-bold text-center ${ schedule.date && schedule.date.getTime() == date.getTime() ? 'font-green' : '' }`">
                                {{ months[date.getMonth()] + ' ' + date.getDate() }}
                            </div>
                            <div class="font-xxsmall font-gray text-center">{{ date.getTime() == today.getTime() ? 'Today' : days[date.getDay()] }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-3">
                <div v-if="availableTimes && availableTimes.length > 0">
                    <div 
                        :class="`btn btn-sm font-small font-gray btn-white bg-white border rounded d-block text-left mb-2 ${ schedule.time && schedule.time.id == time.id ? 'active border-green' : '' }`"
                        v-for="(time, index) in availableTimes"
                        :key="index"
                        @click="setTime(time)"
                    >
                        <i :class="`${ schedule.time && schedule.time.id == time.id ? 'fas fa-check-circle ' : 'far fa-circle' } mr-2 text-uppercase`"></i>
                        <span>{{ time.english_start_time }} - {{ time.english_end_time }}</span>
                    </div>
                </div>
                <div v-else class="font-xsmall" >
                    There are no time slots available for this date
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    export default {
        props: [
            'deliveryOption',
            'dates',
            'times',
            'schedule',
            'setDate',
            'setTime',
            'scheduleError',
            'subtext',
            'blockOffDates'
        ],
        computed: {
            months() {
                return monthNames;
            },
            days() {
                return dayNames;
            },
            today() {
                return moment().startOf('day').toDate()
            },
            availableTimes() {
                let { times, schedule, blockOffDates } = this;
                let now = moment();

                if (times.length > 0) {
                    if (now.isSame(moment(schedule.date).toDate(), 'day')) {
                        times = times.filter(time => {
                            let startTime = moment(now.format('YYYY-MM-DD') + ' ' + time.start_time);

                            if (now.isBefore(startTime)) {
                                return time;
                            }
                        })
                    } else {
                        times = times.filter(time => {

                            let blockedDates = blockOffDates.filter(date => {
                                return date.date == moment(schedule.date).format('YYYY-MM-DD')
                            });

                            let blocked = false;

                            if (blockedDates) {

                                // console.clear();

                                blockedDates.map(blockedDate => {
                                    let blockOffStart = moment(blockedDate.date + 'T' + blockedDate.start_time);
                                    let blockOffEnd = moment(blockedDate.date + 'T' + blockedDate.end_time);
    
                                    let startTime = moment(blockedDate.date + ' ' + time.start_time);
                                    let endTime = moment(blockedDate.date + 'T' + time.end_time)

                                    let startTimeIsBetween = startTime.isBetween(blockOffStart, blockOffEnd);
                                    let endTimeIsBetween = endTime.isBetween(blockOffStart, blockOffEnd);
                                    // console.log('Start time: ' + startTime.format('LT') + ' ' + (startTimeIsBetween ? 'is between' : 'is not between') + ' ' + blockOffStart.format('LT') + ' - ' + blockOffEnd.format('LT'));
                                    // console.log('End time: ' + endTime.format('LT') + ' ' + (endTimeIsBetween ? 'is between' : 'is not between') + ' ' + blockOffStart.format('LT') + ' - ' + blockOffEnd.format('LT'));

                                    if (startTimeIsBetween || endTimeIsBetween) {
                                        blocked = true;
                                    }
                                })
                            } 

                            if (!blocked) {
                                return time;
                            }
                        })
                    }
                }

                return times;
            }
        },

    }
</script>