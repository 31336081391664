import Vue from 'vue';
import App from './App.vue';
import store from './store/store';
import router from './router/';
import { BootstrapVue, ModalPlugin, CollapsePlugin } from 'bootstrap-vue';
import { Imager, Price, ProductPrice } from '@components/common'
import VueSweetalert2 from 'vue-sweetalert2';
import VueCarousel from 'vue-carousel';
import VueTheMask from 'vue-the-mask'
import './assets/css/fontawesome-all.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/scss/app.scss';
import { isLoggedIn } from '@helpers/session.helper';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import ListPopup from '@components/listPopup';
import ListPopupForm from '@components/listPopupForm';
import config from './constants/config';
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMeta from 'vue-meta';
import GoogleLogin from 'vue-google-login';
import VFacebookLogin from 'vue-facebook-login-component';

Vue.config.productionTip = false;

// Global Imports
Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(CollapsePlugin);
Vue.use(ModalPlugin);
Vue.use(VueTheMask);
Vue.use(VueCarousel);
Vue.use(VueGoogleMaps, {
	load: {
		key: config.GOOGLE_API_KEY,
		libraries: 'places,geocoder'
	}
});

Vue.component('FacebookLogin', VFacebookLogin);
Vue.component('GoogleLogin', GoogleLogin);
Vue.component('Imager', Imager);
Vue.component('Price', Price);
Vue.component('vue-slider', VueSlider)
Vue.component('list-popup', ListPopup);
Vue.component('list-popup-form', ListPopupForm);
Vue.component('ProductPrice', ProductPrice);

const sweetalertOptions = {
	width: '370px',
	background: '#000000D9',
	customClass: {
		actions: 'px-4'
	}
}
Vue.use(VueSweetalert2, sweetalertOptions);

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!isLoggedIn()) {
			next({
				name: 'login',
				query: { redirect: to.fullPath }
			})
		} else {
			next();
		}
	} else if (to.matched.some(record => record.meta.guest)) {
		if (isLoggedIn()) {
			next({
				name: 'home'
			})
		} else {
			next();
		}
	} else {
		next();
	}
})

new Vue({
	store,
	router,
	render: (h) => h(App)
}).$mount('#app');
