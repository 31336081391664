import store from '../store/store';

export const getUser = () => {

	const user = { ...store.state.user };

	if (user.user) return user.user;

	return false;
};

export const isLoggedIn = () => {
	const user = getUser();

	if (user && user.id) return true;

	return false;
};
