<template>
    <div :class="`bg-gray-2 pt-4 filters-container font-xsmall pb-5 ${ showMobileFilter ? 'show' : '' }`">
        <div class="px-3">
            <div class="text-right d-block d-lg-none">
                <i 
                    class="fas fa-times fa-2x font-green"
                    @click="toggleMobileFilters"
                ></i>
            </div>
            <h4 class="font-xxlarge font-green font-sf-medium">Categories</h4>
            <div v-if="categories" >
                <ul 
                    class="style-none mb-0 sidebar-categories-container" 
                >
                    <li
                        v-for="(group, index) in categories"
                        :key="index"
                        class="border-bottom py-2"
                    >
                        <CategoryItem 
                            v-for="category in group"
                            :key="category.id"
                            :category="category" 
                            :params="params"
                        />
                    </li>
                </ul>
            </div>
            <div v-else >
                <span class="font-sf-medium">No categories found</span>
            </div>
        </div>
        <hr />
        <div class="px-3 font-sf-medium">
            <h4 class="font-xxlarge font-green mb-n1">Brands</h4>
        </div>
        <div>
            <div
                class="px-3 font-gray font-sf-medium">
                <div class="input-group input-group-rounded prepend my-3">
                    <div class="input-group-prepend">
                        <div class="input-group-text bg-white border-transparent p-0 px-2"><i class="fas fa-search"></i></div>
                    </div>
                    <input
                        type="text"
                        class="form-control form-control-sm font-sf-regular bg-white pl-0 font-xsmall border-left-0"
                        placeholder="Filter Brands"
                        v-model="vendorFilter"
                        @keyup="handleVendorFilterSubmit"
                    />
                </div>
                <div v-if="vendors" >
                    <div 
                        v-for="vendor in vendors"
                        :key="vendor.id"
                        class="custom-control custom-checkbox mb-2"
                    >
                        <input 
                            type="checkbox" 
                            class="custom-control-input" 
                            :id="`vendor-${ vendor.slug }`" 
                            :value="vendor.slug" 
                            v-model="selectedVendors"
                        />
                        <label class="custom-control-label clickable" :for="`vendor-${ vendor.slug }`">{{ vendor.title }}</label>
                    </div>
                </div>
            </div>
            <div v-if="params.price_range && params.price_range.length > 0" class="my-3 px-3 border-top border-bottom py-2">
                <h4 class="font-xxlarge font-green mb">Price Range</h4>
                <div class="font-xsmall font-green font-sf-medium" >
                    <span
                        v-for="(price, index) in params.price_range"
                        :key="index"
                    >
                        {{ $store.getters.SITE_OPTIONS.currency }} {{ price }} {{ index == 0 ? ' - ' : '' }}
                    </span>
                </div>
                <vue-slider
                    v-model="params.price_range"
                    :default="params.price_range[1]"
                    :min="defaultRange[0]"
                    :max="defaultRange[1]"
                    :tooltip-formatter="val => $store.getters.SITE_OPTIONS.currency + ' ' + val"
                    :processStyle="{
                        backgroundColor: '#29673e'
                    }"
                    :tooltipStyle="{
                        backgroundColor: '#29673e',
                        borderColor: '#29673e',
                        fontSize: '12px'
                    }"
                />
            </div>
            <div class="px-3 my-3">
                <button
                    class="btn btn-white btn-block border-transparent-2 font-green font-medium rounded-pill py-1 text-uppercase"
                    @click="handleApplyFilter"
                >
                    APPLY FILTER
                </button>
                <button
                    class="btn btn-green btn-block border-transparent-2 font-white font-medium rounded-pill py-1 text-uppercase"
                    @click="handleClearFilter"
                >
                    CLEAR FILTER
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import CategoryItem from './categoryItem';

export default {

    props: ['categories', 'vendors', 'categoriesLoading', 'vendorsLoading', 'params', 'defaultRange', 'showMobileFilter', 'toggleMobileFilters'],
    data() {
        return {
            selectedVendors: [...this.params.brands],
            vendorFilter: ''
        }
    },
    components: {
        CategoryItem
    },
    methods: {
        handleVendorFilterSubmit() {
            this.$emit('handle-vendor-filter', this.vendorFilter);
        },
        handleApplyFilter() {
            this.$emit('handle-apply-filter', { brands: this.selectedVendors })
        },
        handleClearFilter() {
            this.vendorFilter = '';
            this.selectedVendors = [];
            this.$emit('handle-clear-filters');
        }
    },
    watch: {
        $route(to) {
            let { query } = to;
                
            if (query.search) {
                this.selectedVendors = [];
            }
        },
        params(newParams) {
            if (newParams.brands) {
                this.selectedVendors = newParams.brands;
            } else {
                this.selectedVendors = [];
            }
        }
    }
}
</script>

<style scoped>
    .sidebar-categories-container {
        transition: height 0.2s;
        overflow-y: hidden;
    }
</style>