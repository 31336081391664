<template>
    <div class="blog-inner" >
        <div class="container" v-if="article" >
            <div class="col-md-10 col-12 mx-auto px-0">
                <div class="blog-inner-title font-giantsize font-sf-bold mt-5 mb-1">{{ article.name }}</div>
                <div class="font-small font-sf-medium">Posted {{ formattedDate }}</div>
                <Imager
                    :src="article.asset"
                    :extract="true"
                    className="py-3"
                />
                <div class="blog-inner-content col-md-10 py-4 mb-5 col-12 mx-auto" v-html="article.content" ></div>
            </div>
        </div>
        <div 
            v-if="carouselArticles && carouselArticles.length > 0" 
            class="bbdoit-carousel py-4 bg-gray-2 px-5 px-md-0"
        >
            <div class="container">
                <div class="font-xlarge font-sf-bold mb-3">Other blogs</div>

                <carousel
                    :perPageCustom="[[300, 1], [768, 3], [1200, 5]]"
                    :paginationEnabled="false"
                    :navigationEnabled="true"
                    navigationNextLabel="
                        <div class='fa-stack shadow rounded-circle'>
                            <i class='fas fa-circle fa-stack-2x font-white'></i>
                            <i class='fas fa-chevron-right fa-stack-1x font-gray font-xsmall'></i>
                        </div>
                    "
                    navigationPrevLabel="
                        <div class='fa-stack shadow rounded-circle'>
                            <i class='fas fa-circle fa-stack-2x font-white'></i>
                            <i class='fas fa-chevron-left fa-stack-1x font-gray font-xsmall'></i>
                        </div>
                    "
                >
                    <slide
                        v-for="article in carouselArticles"
                        :key="article.slug"
                        class="mx-0 mx-md-1"
                    >
                        <BlogCard
                            :article="article"
                            className="h-100 bg-gray-2"
                        />
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { connect } from '@api';
import moment from 'moment'
import BlogCard from '@components/blogCard';

export default {
    components: {
        BlogCard
    },
    data() {
        return {
            article: null,
            carouselArticles: []
        }
    },
    computed: {
        formattedDate() {
            return moment(this.article.date).format('MMMM DD, YYYY');
        }
    },
    mounted() {
        this.getArticle();
    },
    methods: {
        getArticle() {
            connect().get('/articles/find', { params: { slug: this.$route.params.slug } })
                    .then(res => {
                        if (res.status == 200) {
                            this.article = res.data.data;

                            this.getCarouselArticles();
                        }
                    })
        },
        getCarouselArticles() {

            let params = {
                exclude: [this.article.id],
                type: 'blog'
            };

            connect().get('/articles/carousel', { params: {...params} })
                    .then(res => {
                        if (res.status == 200) {
                            this.carouselArticles = res.data.data;
                        }
                    })
        },
    },
    watch: {
        $route() {
            this.getArticle();
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }
}
</script>