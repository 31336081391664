<template>
    <div class="cart-table-row-grid order">
        <div class="image-column text-center px-sm-4 px-2">
            <Imager 
                :src="item.product && item.product.asset ? 
                        item.product 
                        : 
                        item.product.parent_product && item.product.parent_product.asset ? 
                            item.product.parent_product
                            :
                            ''
                        "
                :extract="true"
                :alt="item.product.title"
            />
        </div>
        <div class="description-column">
            <div class="py-2">
                <div 
                    v-if="productBrand && productBrand.title" 
                    class="font-xsmall font-green clickable"
                    @click="redirectToBrand(productBrand)"
                >{{productBrand.title}}</div>

                <div 
                    class="font-small clickable"
                    @click="redirectToProduct(item.product)"
                >{{ parseInt(item.quantity) }} x {{item.product.title}}</div>

                <div class="d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center">
                    <div 
                        v-if="item.product && item.product.variant_options"
                        class="d-flex align-items-center"
                    >
                        <span 
                            class="bg-gray-2 font-xsmall font-sf-medium p-1 px-2 rounded font-green"
                            v-for="(variant, index) in item.product.variant_options"
                            :key="index"
                        >
                            {{variant.option}}: {{variant.name}}
                            <!-- <i class="fas fa-caret-down ml-1 font-gray"></i> -->
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="total-column text-center">
            <div class="font-sf-medium" >
                <Price
                    :price="(parseFloat(item.subtotal) + parseFloat(item.addon_subtotal))"
                />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['item'],
        data() {
            return {
                quantity: this.item.quantity ? this.item.quantity : 0
            }
        },
        computed: {
            isChecked() {
                let {selectedItems, item} = this;

                let checked = false;

                if (selectedItems.length > 0) { 
                    checked = selectedItems.includes(item.id)
                }


                return checked;
            },
            total: {
                get() {
                    let total = 0,
                    { item } = this;

                    total += parseFloat(item.product.price);
                    
                    total *= this.quantity;

                    return Number(total).toFixed(2);
                },
                set(total) {
                    this.total = total;
                }
            },
            category() {

                let {product} = this.item;
                let category = null;

                if (product.parent_product && product.parent_product.categories && product.parent_product.categories.length > 0 ) {
                    category = product.parent_product.categories[0].category;
                } else {
                    if (product.categories && product.categories.length > 0) {
                        category = product.categories[0].category;
                    }
                }

                return category;
            },
            productBrand() {
                let { product } = this.item;
                let { brand } = product;

                if (product.parent_product && product.parent_product.brand) {
                    brand = product.parent_product.brand;
                } 
                return brand;
            }
        },
        methods: {
            redirectToProduct(product) {

                let urlParams = {
                    name: 'products.view',
                    params: {
                        slug: product.slug
                    }
                };

                if (product.parent_product) {
                    urlParams.params.slug = product.parent_product.slug;
                    urlParams.query = {
                        variant: product.option1
                    }
                } 

                this.$router.push(urlParams);
            },
            redirectToBrand(brand) {

                let urlParams = {
                    name: 'products',
                    query: {
                        brands: [brand.slug]
                    }
                }
                this.$router.push(urlParams)
            },
        }
    }
</script>