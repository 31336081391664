<template>
	<div>

		<!-- WIDE CARD -->
		<div 
			class="bbdoit-card bbdoit-wide clickable" 
			v-if="wide"
			@click="redirect"
		>
			<div class="image-container" v-if="article.asset && article.asset.path" >
				<Imager
					:src="article.asset"
					:extract="true"
				/>
			</div>
			<div class="ml-lg-3">
				<h3 class="mb-1 font-oversized font-sf-semibold">{{ article.name }}</h3>
				<div class="font-gray">{{ article.blurb }}</div>
				<div v-if="showNumbers" class="font-gray font-xsmall">
					<span v-if="likes > 0" ><i class="fas fa-thumbs-up" /> {{ likes }} like{{ likes > 1 ? 's' : '' }}</span>
					<span v-if="likes > 0" class="dot-separator mx-2">.</span> 
					<i class="fas fa-comment" /> {{ article.comments_count }} comment{{ article.comments_count > 1 ? 's' : '' }}
				</div>
			</div>
		</div>

		<!-- MINI CARD -->
		<div 
			class="bbdoit-card mb-3" 
			v-else
		>
			<div 
				:class="['image-container ', mini ? 'mini' : '']"
			>
				<Imager
					:src="article.asset && article.asset.path ? article.asset : ''"
					:extract="true"
					@click-image="redirect"
					className="clickable"
				/>
				<button 
					v-if="isLoggedIn"
					class="like-btn btn btn-sm btn-green rounded py-1 px-3"
					@click="like"
				><i class="fas fa-thumbs-up mr-1"></i> {{ isLiked ? 'Liked' : 'Like' }}</button>
			</div>
			<h6 
				@click="redirect" 
				class="card-title mt-3 font-medium text-left text-left text-md-left font-sf-medium mb-0 clickable"
			>{{ article.name }}</h6>
			<div v-if="showNumbers" class="font-gray font-xsmall d-flex align-items-center">
				<span v-if="likes > 0" ><i class="fas fa-thumbs-up mr-1" /> {{ likes }} like{{ likes > 1 ? 's' : '' }} </span>
				<span v-if="likes > 0 && article.comments_count > 0" class="fas fa-circle dot mx-2"></span>
				<span v-if="article.comments_count > 0" >
					<i class="fas fa-comment mr-1" /> {{ article.comments_count }} comment{{ article.comments_count > 1 ? 's' : '' }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { Imager } from '@components/common';
import { isLoggedIn, getUser } from '../../helpers/session.helper';
import { connect } from '@api';
import { mapMutations } from 'vuex';
import * as types from '../../store/mutation-types';

export default {
	components: {
		Imager
	},
	props: {
		wide: {
			type: Boolean,
			default: false,
		},
		mini: {
			type: Boolean,
			default: false
		},
		article: Object,
		showNumbers: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		...mapMutations({
			updateUser: types.UPDATE_USER
		}),
		isLoggedIn() {
			return isLoggedIn();
		},
		user() {
			return getUser();
		},
		isLiked() {
			let likes = this.user.customer.liked_articles;
			let isLiked = false;

			if (likes && likes.length > 0) {
				isLiked = likes.find(article => {
					return article.article_id == this.article.id;
				})
			}


			return isLiked ? true : false;
		}
	},
	data() {
		return {
			likes: this.article.likes_count
		}
	},
	mounted() {
		// console.log('is liked', this.isLiked);
	},
	methods: {
		like() {		

			let article_id = this.article.id;

			connect().post('/articles/like', {article_id} )
				.then(res => {

					if (res.data.data) {
						this.likes += 1;

						let {liked_articles} = this.user.customer;
						liked_articles.push(res.data.data);

						this.user.customer.liked_articles = liked_articles;

						this.updateUser(this.user);
						
					} else {
						this.likes -= 1;

						let {liked_articles} = this.user.customer;

						liked_articles = liked_articles.filter(article => {
							return article.article_id !== article_id;
						})

						this.user.customer.liked_articles = liked_articles;

						this.updateUser(this.user);
					}
				})
		},
		redirect() {
			this.$router.push({
				name: 'bbdoit.view',
				params: {
					slug: this.article.slug
				}
			})
		}
	}
};
</script>

<style></style>
