<template>
    <div>
        <div class="card mb-4">
            <div class="card-body">
                <div class="font-xxlarge font-sf-medium flex-fill mb-3">For {{deliveryOption}}</div>
                <table class="table table-sm table-borderless font-xsmall">
                    <tbody>
                        <tr>
                            <td class="w-25 font-gray">{{deliveryOption}} date</td>
                            <td>{{moment(schedule.date).format('MMMM DD, YYYY')}}</td>
                        </tr>
                        <tr>
                            <td class="w-25 font-gray">{{deliveryOption}} time</td>
                            <td>{{ formatTime(schedule.time.start_time) + ' - ' + formatTime(schedule.time.end_time) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card mb-4">
            <div class="card-body">
                <div class="font-xxlarge font-sf-medium flex-fill mb-3">Payment Option</div>
                <div v-if="paymentMethod && paymentMethod.bank" >
                    <div class="card">
                        <div class="card-body">
                            <div class="border-bottom mb-3" >
                                <div class="font-small font-green mb-2">{{ paymentMethod.name }}</div>
                            </div>
                            <div class="font-xsmall">
                                <p>{{ paymentText }}. You may upload your deposit slip through <span v-if="orderContactEmail" >our email <span class="font-green">{{orderContactEmail}}</span> or upload it</span> <span @click.stop="toggleDepositModal" class="font-green clickable">here.</span></p>
                                <div v-if="paymentMethod.bank" >
                                    <table class="table table-borderless table-sm">
                                        <tbody>
                                            <tr>
                                                <td class="w-25">Bank:</td>
                                                <td class="font-green">{{ paymentMethod.bank.bank_name }}</td>
                                            </tr>
                                            <tr>
                                                <td class="w-25">Account Name:</td>
                                                <td class="font-green">{{ paymentMethod.bank.account_name }}</td>
                                            </tr>
                                            <tr>
                                                <td class="w-25">Account Number:</td>
                                                <td class="font-green">{{ paymentMethod.bank.account_number }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <button 
                                    v-if="!uploadedFile"
                                    class="btn btn-white btn-sm rounded-pill border-green font-green bg-gray-2"
                                    @click="toggleDepositModal"
                                >Upload Deposit Slip</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else >
                    {{ paymentMethod.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        props: [
            'orderData',
            'schedule',
            'depositForm',
            'depositFormErrors',
            'toggleDepositModal',
            'handleDepositModalFileChange',
            'handleDepositModalSubmit',
            'uploadedFile',
            'handleRemoveUploadedFile',
            'formValues',
            'bankAccounts',
            'orderContactEmail',
            'paymentMethods',
            'paymentText'
        ],
        data() {
            return {
                moment: moment
            }
        },
        computed: {
            deliveryOption() {
                return this.formValues.delivery_option == 'delivery' ? 'Delivery' : 'Pick up';
            },
            paymentMethod() {

                let { bankAccounts, formValues, paymentMethods } = this;

                let selectedPaymentMethod = paymentMethods.find(method => method.id == formValues.payment_type_id);

                if (selectedPaymentMethod) {
                    let bank = bankAccounts.find(bank => bank.bank_name == selectedPaymentMethod.name);

                    selectedPaymentMethod.bank = bank;
                }
                
                return selectedPaymentMethod;
            }
        },
        methods: {
            formatTime(timeString) {
                let now = moment().format('YYYY-MM-DD');
                let time = moment(now + 'T' + timeString).format('h:mm A')

                return time;
            }
        }
    }
</script>