<template>
    <div :class="['category-popup bg-white pt-3 pb-5', show ? 'show' : '']">
        <div class="container">
            <div class="font-sf-medium font-xxlarge font-green text-capitalize">Window Shopping by {{ type }} </div>
            <div v-if="type == 'brands'" >
                <div 
                    class="row my-3 letter-filters"
                >
                    <div class="col font-black font-xsmall font-sf-medium letter-filters-item" v-for="(letter, index) in letters" :key="index" >
                        <span 
                            :class="[activeLetter == letter ? 'active' : '', 'clickable text-uppercase']"
                            @click="filterVendors(letter)"
                        >{{ letter }}</span>
                    </div>
                </div>
                <div 
                    class="row flex-row mt-4 brands-container"
                    v-if="filteredVendors.length > 0"
                >
                    <div 
                        v-for="(vendors, index) in filteredVendors" 
                        :key="`vendors${index}`"
                        class="col"
                    >
                        <router-link 
                            v-for="vendor in vendors" 
                            :key="vendor.slug" 
                            :to="{ name: 'brands', params: { slug: vendor.slug } }" 
                            class="d-block mb-2 font-xsmall font-sf-regular" 
                        >
                            {{ vendor.title }}
                        </router-link>
                    </div>
                </div>
                <div
                    v-else
                    class="d-flex align-items-center justify-content-center mt-5"
                >
                <span class="font-large">No brands found</span>
                </div>
            </div>
            <div v-else >
                <div v-if="categories" >
                    <div class="d-inline-flex flex-wrap flex-column categories-container mt-3" >
                        <div 
                            v-for="(group, index) in categories" 
                            :key="index" 
                            class="category-list px-3" 
                            :style="{zIndex: zIndexes[index]}"
                        >
                            <div 
                                v-for="category in group"
                                :key="category.id"
                                class="d-flex align-items-center justify-content-between font-xsmall btn btn-white category-list-item border-0 p-1 rounded-0 position-relative" 
                                :style="{opacity: 1, zIndex: zIndexes[index]}"
                            >
                                <span
                                    class="flex-grow-1 text-left"
                                    @click="() => { $router.push({ name: 'products', query: { category: category.slug } }) }"
                                >
                                    {{ category.title }}
                                </span>
                                <i v-if="category.children.length > 0" class="fas fa-caret-down font-gray font-medium pl-4"></i>
                                <CategoryDropdown
                                    v-if="category.children.length > 0"
                                    :categories="category.children"
                                />
                            </div>
                            <hr>
                        </div>
                    </div>
                </div>
                <div 
                    v-else
                    class="d-flex align-items-center justify-content-center mt-5"
                >
                    <span class="font-large">No categories found</span>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import CategoryDropdown from './components/categoryDropdown';
import * as api from '@api';

export default {
    props: ['show', 'type'],
    components: {
        CategoryDropdown
    },
    data() {
        return {
            letters: [],
            activeLetter: "a",
            vendors: [],
            filteredVendors: [],
            zIndexes: [],
            categories: []
        }
    },
    created() {

        // A-Z LETTERS
        let first = 'a';
        let last = 'z';

        for (let i = first.charCodeAt(0); i <= last.charCodeAt(0); i++) {
            this.letters.push(String.fromCharCode(i));
        }
    },
    mounted() {
        this.getCategories();
        this.getVendors();
    },
    methods: {
        filterVendors(letter) {
            this.activeLetter = letter;
            let vendors = this.vendors;

            if (vendors) {
                this.filteredVendors = vendors.filter(vendor => {
                    return vendor.slug.charAt(0) == this.activeLetter
                })

                this.filteredVendors = this.chunkVendors(this.filteredVendors);
            }
        },
        getCategories() {
            api.connect().get("/categories/tree", {})
                .then(res => {
                    if (res.status == 200) {
                        this.categories = res.data.data;

                        if (Object.keys(this.categories).length === 1) {
                            this.categories = this.chunkCategories(this.categories);
                        }

                        let keys = Object.keys(this.categories);

                        if (keys.length > 0) {
                            let temp = [];
                
                            let zIndex = 2000;
                            keys.map(value => {
                                temp[value] = zIndex;
                                zIndex = zIndex - 100;
                            });
                
                            this.zIndexes = temp;
                
                            // console.log('z-indexes', this.zIndexes)
                        }
                    }
                })
        },
        getVendors() {
            api.connect().get('brands', {})
                .then(res => {
                    if (res.status == 200) {
                        this.vendors = res.data.data;

                        this.filterVendors('a');
                    }
                });
        },
        chunkVendors(vendors) {
            let chunkSize = 4,
                tempArray = [];
            
            for (let i = 0; i < vendors.length; i+=chunkSize) {
                let row = vendors.slice(i, i+chunkSize);
                tempArray.push(row);
            }

            return tempArray;
        },
        chunkCategories(categories) {
            let chunkSize = 19, tempArray = [];

            for (let i = 0; i < categories[""].length; i+= chunkSize) {
                let row = categories[""].slice(i, i+chunkSize);
                tempArray.push(row);
            }

            return tempArray;
        }
    }
}
</script>