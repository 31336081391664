<template>
    <div>
        <!-- DELIVERY OPTION -->
        <div class="card mb-4">
            <div class="card-body px-4">
                <div class="font-xlarge font-sf-medium mb-4">How would you like to receive your order?</div>
                <div 
                    :class="`btn btn-white py-2 px-3 pr-5 bg-white btn-block rounded ${ deliveryOption == 'delivery' ? 'active' : '' }`"
                    @click="changeDeliveryOption('delivery')"
                >
                    <div class="d-flex align-items-start">
                        <i :class="`${ deliveryOption == 'delivery' ? 'fas fa-check-circle' : 'far fa-circle' } mr-3 mt-1`"></i>
                        <div class="text-left flex-grow-1" >
                            <div :class="`font-small font-sf-medium ${ deliveryOption == 'delivery' ? 'font-green' : 'font-gray' }`">For Delivery</div>
                            <div class="font-xsmall font-gray">Delivery will be handled by QuincyBuyboy.</div>


                            <!-- ADDRESS FIELD -->
                            <div 
                                v-if="deliveryOption == 'delivery'" 
                                class="text-left border-top mt-4 pt-3"
                            >
                                <div v-if="showAddressForm" >
                                    <div class="form-group mb-0 text-right " v-if="user && user.id">
                                        <div 
                                            class="custom-control custom-checkbox"
                                        >
                                            <input 
                                                type="checkbox" 
                                                class="custom-control-input" 
                                                :id="`for-gifting`" 
                                                v-model="addressForm.isGift"
                                            />
                                            <label class="custom-control-label clickable" :for="`for-gifting`">As a gift?</label>
                                        </div>
                                    </div>
                                    <!-- <div class="font-small font-red mb-2" v-if="addressFormErrors.address" >{{addressFormErrors.address}}</div> -->
                                    <div class="form-group mt-3">
                                        <label for="address">Address</label>
                                        <gmap-autocomplete
                                            :value="addressForm.address"
                                            placeholder="Please enter your address"
                                            :class="`form-control font-xsmall font-sf-medium ${ addressFormErrors.address ? 'border-danger' : '' }`"
                                            id="address"
                                            @place_changed="setPlace"
                                            :select-first-on-enter="true"
                                            :componentRestrictions="{ country:'PH' }"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <div class="map-container">
                                            <GmapMap
                                                :center="{ ...mapCoords }"
                                                :zoom="17"
                                                :options="{
                                                    zoomControl: true,
                                                    mapTypeControl: false,
                                                    scaleControl: false,
                                                    streetViewControl: false,
                                                    rotateControl: false,
                                                    fullscreenControl: false,
                                                    disableDefaultUI: false
                                                }"
                                            >
                                                <GmapMarker
                                                    :position="{ ...mapCoords }"
                                                    :draggable="true"
                                                    @dragend="setPlace"
                                                />
                                            </GmapMap>
                                        </div>
                                    </div>
                                    <div class="form-group mt-4" v-if="user && user.id && !addressForm.isGift" >
                                        <div 
                                            @click="updateAddress"
                                            class="font-xsmall font-green clickable"
                                        >Save Address</div>
                                    </div>
                                </div>
                                <div v-else >
                                    <table class="table table-borderless table-sm">
                                        <tbody>
                                            <tr class="font-xsmall" >
                                                <td class="font-gray w-25">
                                                    Address
                                                </td>
                                                <td>
                                                    {{ addressForm.address  }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div class="form-group mt-4">
                                        <span 
                                            class="font-xsmall font-green clickable ml-1"
                                            @click="toggleAddressForm"
                                        >Edit Address</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div 
                    :class="`btn btn-white p-2 px-3 pr-5 bg-white btn-block rounded ${ deliveryOption == 'pickup' ? 'active' : '' }`"
                    @click="changeDeliveryOption('pickup')"
                >
                    <div class="d-flex align-items-start">
                        <i :class="`${ deliveryOption == 'pickup' ? 'fas fa-check-circle' : 'far fa-circle' } mr-3 mt-1`"></i>
                        <div class="text-left flex-grow-1" >
                            <div :class="`font-small font-sf-medium ${ deliveryOption == 'pickup' ? 'font-green' : 'font-gray' }`">Pickup</div>
                            <div class="font-xsmall font-gray">Customer will pick-up from store or book their own courier.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- PERSONAL INFORMATION -->
        <div class="card mb-4" >
            <div class="card-body px-4">
                <div class="d-flex align-items-center flex-wrap mb-4">
                    <div class="font-xxlarge font-sf-medium flex-fill">Personal Information</div>
                    <div 
                        v-if="user && !user.id"
                        class="text-right font-xxsmall clickable font-green flex-fill"
                    >
                        <span @click="goToLogin" >I already have an account</span>
                    </div>
                    <div 
                        v-if="!showUserForm" 
                        class="text-right font-xsmall clickable font-green flex-fill"
                    >
                        <span @click="toggleUserForm" >Update</span>
                    </div>
                </div>

                <div v-if="showUserForm" >
                    <!-- FULL NAME FIELD -->
                    <div class="form-group">
                        <label class="mb-0 font-xsmall font-gray" >Full Name</label>
                        <input
                            type="text"
                            :class="`form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black ${ userFormErrors.fullname ? 'border-danger' : '' }`"
                            v-model="userForm.fullname"
                        />
                        <!-- <div v-if="userFormErrors.fullname" class="font-small font-red">{{userFormErrors.fullname}}</div> -->
                    </div>

                    <!-- EMAIL FIELD -->
                    <div class="form-group">
                        <label class="mb-0 font-xsmall font-gray" >Email Address</label>
                        <input
                            type="text"
                            :class="`form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black ${ userFormErrors.email ? 'border-danger' : '' }`"
                            v-model="userForm.email"
                        />
                        <!-- <div v-if="userFormErrors.email" class="font-small font-red">{{userFormErrors.email}}</div> -->
                    </div>

                    <!-- PHONE NUMBER FIELD -->
                    <div class="form-group">
                        <label class="mb-0 font-xsmall font-gray" >Phone Number</label>
                        <!-- <input
                            type="text"
                            :class="`form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black ${ userFormErrors.phone ? 'border-danger' : '' }`"
                            v-model="userForm.phone"
                        /> -->
                        <the-mask 
                            :mask="['###########']"
                            placeholder="ex. 09xxxxxxxxx"
                            v-model="userForm.phone"
                            :class="`form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black ${ userFormErrors.phone ? 'border-danger' : '' }`"
                            type="text"
                        />
                        <!-- <div v-if="userFormErrors.phone" class="font-small font-red">{{userFormErrors.phone}}</div> -->
                    </div>

                    <!-- BIRTHDAY FIELD -->
                    <div class="form-group">
                        <label class="mb-0 font-xsmall font-gray" >Birthday</label>
                        <!-- Input date for chrome -->
                        <input
                            :type="`${ isSafari ? 'text' : 'date' }`"
                            :class="`form-control form-control-lg rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black ${ userFormErrors.birthday ? 'border-danger' : '' }`"
                            v-model="userForm.birthday"
                            :min="validYears.min"
                            :max="validYears.max"
                            placeholder="YYYY-MM-DD"
                        />
                        <!-- <div v-if="userFormErrors.birthday" class="font-small font-red">{{userFormErrors.birthday}}</div> -->
                    </div>

                    <div class="form-group" v-if="user && user.id" >
                        <span 
                            class="font-xsmall font-green clickable"
                            @click="updateUserDetails"
                        >Save</span>
                    </div>
                </div>
                <div v-else >
                    <!-- <div v-for="(label, key) of userFields" :key="key" >
                        <div v-if="userFormErrors[key]" class="font-small font-red" >{{ userFormErrors[key] }}</div>
                    </div> -->
                    <table class="table table-sm table-borderless">
                        <tr
                            v-for="(label, key) of userFields"
                            :key="key"
                            class="font-xsmall"
                        >
                            <td class="font-gray w-25" >{{ label }}</td>
                            <td>{{ userForm[key] }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import * as is from 'is_js'

    let userFields = {
        fullname: 'Full Name',
        email: 'Email Address',
        phone: 'Phone Number',
        birthday: 'Birthday'
    };

    export default {
        props: [
            'deliveryOption',
            'changeDeliveryOption',
            'addressForm',
            'addressFormErrors',
            'provinces',
            'cities',
            'getCities',
            'user',
            'userForm',
            'userFormErrors',
            'checkoutType',
            'showUserForm',
            'showAddressForm',
            'toggleUserForm',
            'toggleAddressForm',
            'updateAddress',
            'updateUserDetails',
            'goToLogin',
            'isGift',
            'mapCoords',
            'setPlace',
            'validYears'
        ],
        data() {
            return {
                isSafari: false
            }
        },
        mounted() {
            this.isSafari = is.safari();
        },
        computed: {
            userFields() {
                return userFields
            },
            addressString() {

                let str = '';
                let { customer } = this.user;

                if (customer.primary_address && customer.primary_address.id) {

                    str += customer.primary_address.address_line_1 + ', ';
                    str += customer.primary_address.custom_area + ', '
                    str += customer.primary_address.city_name + ', '
                    str += customer.primary_address.province_name
                }

                return str;
            }
        }
    }
</script>