<template>
	<div>
		<h2 class="text-center font-sf-bold font-giantsize py-3">Create Account</h2>
		<FacebookLogin
			class="btn btn-gray d-flex align-items-center justify-content-center btn-sm btn-block btn-radius-3 py-2"
			id="fb-login"
			:app-id="facebookAppID"
			@login="onFacebookAuthLogin"
			@sdk-init="onFacebookSDKInit"
		/>
		<GoogleLogin
			class="btn btn-gray d-flex align-items-center justify-content-center btn-sm btn-block btn-radius-3 py-2"
			id="google-login"
			:params="googleLoginParams"
			:onSuccess="onGoogleAuthSuccess"
			:onFailure="onGoogleAuthFail"
		>
			<i class="font-small mr-2 fab fa-google"></i> {{ googleAuthenticating ? 'Please wait...' : 'Continue with Google' }}
		</GoogleLogin>
		<div class="or-separator my-3 font-gray font-xsmall">
			<span class="position-relative px-3">
				or
			</span>
		</div>
		<div class="form-group font-xsmall">
			<label class="font-gray">First Name</label>
			<input
				type="text"
				:class="`form-control font-xsmall ${ formErrors.firstname ? 'border-danger' : '' }`"
				placeholder="First Name"
				v-model="formValues.firstname"
			/>
			<small v-if="formErrors.firstname" class="text-danger font-small">{{
				formErrors.firstname
			}}</small>
		</div>
		<div class="form-group font-xsmall">
			<label class="font-gray">Last Name</label>
			<input
				type="text"
				:class="`form-control font-xsmall ${ formErrors.lastname ? 'border-danger' : '' }`"
				placeholder="Last Name"
				v-model="formValues.lastname"
			/>
			<small v-if="formErrors.lastname" class="text-danger font-small">{{
				formErrors.lastname
			}}</small>
		</div>
		<div class="form-group font-xsmall">
			<label class="font-gray">Phone Number</label>
			<!-- <input
				type="text"
				class="form-control font-xsmall"
				placeholder="Phone Number"
				v-model="formValues.phone"
			/> -->
			<the-mask 
				:mask="['###########']"
				placeholder="ex. 09xxxxxxxxx"
				v-model="formValues.phone"
				:class="`form-control font-xsmall ${ formErrors.phone ? 'border-danger' : '' }`"
				type="text"
			/>
			<small v-if="formErrors.phone" class="text-danger font-small">{{
				formErrors.phone
			}}</small>
		</div>
		<div class="form-group font-xsmall">
			<label class="font-gray">Birthday</label>
			<!-- Input date for chrome -->
			<input
				:type="`${ isSafari ? 'text' : 'date' }`"
				:class="`form-control font-xsmall ${ formErrors.birthday ? 'border-danger' : '' }`"
				placeholder="YYYY-MM-DD"
				v-model="formValues.birthday"
				:min="validYears.min"
				:max="validYears.max"
			/>
			<small v-if="formErrors.birthday" class="text-danger font-small">{{
				formErrors.birthday
			}}</small>
		</div>
		<div class="form-group font-xsmall">
			<label class="font-gray">Gender</label>
			<select 
				type="text" 
				:class="`form-control font-xsmall ${ formErrors.gender ? 'border-danger' : '' }`"
				v-model="formValues.gender"
			>
				<option value="" disabled selected>Select a gender</option>
				<option value="male">Male</option>
				<option value="female">Female</option>
			</select>
			<small v-if="formErrors.gender" class="text-danger font-small">{{
				formErrors.gender
			}}</small>
		</div>
		<div class="form-group font-xsmall">
			<label class="font-gray">Password</label>
			<div class="input-group ">
				<input
					:type="`${ !passwordToggled ? 'password' : 'text' }`"
					:class="`form-control font-xsmall border-right-0 ${ formErrors.password ? 'border-danger' : '' }`"
					placeholder="Password"
					v-model="formValues.password"
					ref="password"
				/>
				<div class="input-group-append">
					<span 
						:class="`input-group-text clickable ${ formErrors.password ? 'border-danger' : '' }`"
						@click="togglePassword('password')"
					>
						<i :class="`${ !passwordToggled ? 'fas fa-eye' : 'fas fa-eye-slash' }`"></i>
					</span>
				</div>
			</div>
			<small v-if="formErrors.password" class="text-danger font-small">{{
				formErrors.password
			}}</small>
		</div>
		<div class="form-group font-xsmall">
			<label class="font-gray">Confirm Password</label>
			<div class="input-group">
				<input
					:type="`${ !confirmPasswordToggled ? 'password' : 'text' }`"
					:class="`form-control font-xsmall border-right-0 ${ formErrors.confirm_password ? 'border-danger' : '' }`"
					placeholder="Confirm Password"
					v-model="formValues.confirm_password"
				/>
				<div class="input-group-append">
					<span 
						:class="`input-group-text clickable ${ formErrors.confirm_password ? 'border-danger' : '' }`"
						@click="togglePassword('confirm_password')"
					>
						<i :class="`${ !confirmPasswordToggled ? 'fas fa-eye' : 'fas fa-eye-slash' }`"></i>
					</span>
				</div>
			</div>
			<small v-if="formErrors.confirm_password" class="text-danger font-small">{{
				formErrors.confirm_password
			}}</small>
		</div>
		<button
			class="btn btn-green btn-block btn-radius-3 mt-2 mb-4 py-2"
			@click="handleNext"
			v-bind:disabled="nextDisabled"
		>
			Next
		</button>
		<div class="font-xsmall text-center">
			<router-link to="/login" class="font-green">I already have an Account</router-link>
		</div>
	</div>
</template>

<script>

import * as is from 'is_js';

export default {
	name: 'information',
	props: [
		'formValues',
		'formErrors', 
		'handleNext', 
		'nextDisabled',
		'onGoogleAuthSuccess',
		'onGoogleAuthFail',
		'googleAuthenticating',
		'googleLoginParams',
		'facebookAppID',
		'onFacebookAuthLogin',
		'onFacebookSDKInit',
		'validYears'
	],
	data() {
		return {
			passwordToggled: false,
			confirmPasswordToggled: false,
			isSafari: false
		}
	},
	mounted() {
		this.isSafari = is.safari();
	},
	methods: {
		togglePassword(field) {

			if (field == 'password') {
				this.passwordToggled = !this.passwordToggled;
			} else {
				this.confirmPasswordToggled = !this.confirmPasswordToggled;
			}
		}
	}
};
</script>

<style></style>
