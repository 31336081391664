<template>
	<div>
		<nav class="custom-nav font-xsmall bg-white fixed-top" id="custom-nav" >
			<div class="nav-top bg-green">
				<div class="container d-flex align-items-center justify-content-end font-white py-1">
					<router-link 
						class="btn btn-clear-white font-white btn-sm py-0 d-none d-lg-inline-block"
						:to="{ name: 'orders' }"
						v-if="isLoggedIn"
					>My Orders</router-link>
					
					<span v-if="isLoggedIn" class="px-2 d-none d-lg-inline-block">|</span>
					<div 
						class="position-relative d-none d-lg-inline-block" 
						v-if="isLoggedIn"
					>
						<button 
							@click="toggleProfilePopup()"
							class="btn btn-clear-white font-white btn-sm py-0"
						>
							Welcome, {{ user.customer && user.customer.fullname }} <i class="fas fa-caret-down ml-2" />
						</button>
						<ProfilePopup 
							@toggle-popup="$emit('toggle-popup')" 
							@logout="handleLogout"
							:show="showProfilePopup" 
						/>
					</div>

					<router-link
						:to="{ name: 'login' }"
						class="btn btn-clear-white font-white btn-sm py-0 d-none d-lg-inline-block"
						v-if="!isLoggedIn"
					> Login or Sign up</router-link>

					<span class="pl-2 pr-4 d-none d-lg-inline-block">|</span>
					<div class="d-flex align-items-center" >
						<!-- Language: <button id="google-translate-element" class="btn btn-clear-white font-white btn-sm py-0 text-uppercase">{{language}}</button> -->
						<span class="mr-2">Language: </span>
						<div id="google-translate-element" ></div>
					</div>
				</div>
			</div>

			<!-- MOBILE NAV -->
			<div class="nav-bottom py-3 d-block d-lg-none">
				<div class="container">
					<div class="d-flex align-items-center">
						<router-link to="/" class="mr-4" >
							<img class="nav-logo mobile" src="../../assets/img/logo.png" alt="Buyboy Logo" />
						</router-link>
						<button 
							class="btn btn-sm btn-clear ml-auto border rounded"
							@click="toggleMobileSidebar"
						><i class="fas fa-bars"></i></button>
					</div>
				</div>
			</div>
			<!-- DESKTOP NAV -->
			<div class="nav-bottom pt-3 pb-1 d-none d-lg-block">
				<div class="container">
					<div class="d-flex align-items-center">
						<router-link to="/" class="mr-4" >
							<img class="nav-logo" src="../../assets/img/logo.png" alt="Buyboy Logo" />
						</router-link>
						<div class="flex-grow-1 mx-3">
							<div class="input-group nav-search">
								<div class="input-group-prepend border-gray-2">
									<span class="input-group-text bg-gray-2 border-gray-2"><i class="fas fa-search"></i></span>
								</div>
								<input 
									class="form-control font-xsmall font-sf-regular bg-gray-2 border-gray-2 border-left-0 pl-0" 
									type="search" 
									placeholder="Search a product" 
									v-model="params.keyword"
									@keyup.enter="handleSearch"
								/>
							</div>
						</div>
						<div class="cart-btn-container" >
							<div>
								<router-link
									:to="{name: 'cart'}"
									class="btn btn-green btn-radius-5 font-small font-sf-regular p-1 px-3"
								>
									<div class="d-flex align-items-center">
										<div class="shopping-cart-icon mr-3">
											<img src="../../assets/img/shopping-cart-icon.png" alt="cart-icon">
											<span class="bg-white font-green border border-green rounded-pill font-xxxsmall font-sf-medium shopping-cart-icon-indicator">{{cartItemCount}}</span>
										</div>
										<div><Price :price="parseFloat(cartSubTotal)" /></div>
									</div>
								</router-link>
							</div>
							<AddToCartNotif />
						</div>
					</div>
					<div class="d-flex align-items-center">
						<button 
							@click="toggleCategoryPopup('categories')"
							class="btn btn-clear btn-sm px-2 py-1 font-sf-regular"
						>Categories<i class="fas fa-caret-down ml-2 font-green" />
						</button>
						<button 
							@click="toggleCategoryPopup('brands')"
							class="btn btn-clear btn-sm px-2 py-1 font-sf-regular"
						>Brands<i class="fas fa-caret-down ml-2 font-green" />
						</button>
						<span class="font-gray pb-2 mx-2">
							|
						</span>
						<router-link
							class="btn btn-clear btn-sm px-2 py-1 font-black font-sf-regular nav-link"
							:to="{
								name: 'products',
								query: {
									tags: 'hot-deals:hot:hot deals',
									sort: 'relevance:desc',
									category: ''
								}
							}"
							>Hot Deals and Promo</router-link
						>
						<router-link
							class="btn btn-clear btn-sm px-2 py-1 font-black font-sf-regular nav-link"
							:to="{
								name: 'products',
								query: {
									tags: 'recommended',
									sort: 'relevance:desc',
									category: ''
								}
							}"
							>Recommended Goods</router-link
						>
						<router-link
							class="btn btn-clear btn-sm px-2 py-1 font-black font-sf-regular nav-link"
							:to="{
								name: 'products',
								query: {
									tags: 'trending',
									sort: 'relevance:desc',
									category: ''
								}
							}"
							>Trending Products</router-link
						>
						<router-link
							class="btn btn-clear btn-sm px-2 py-1 font-black font-sf-regular nav-link"
							:to="{name: 'bbdoit'}"
							>BBdoit</router-link
						>
						<router-link
							class="btn btn-clear btn-sm px-2 py-1 font-black font-sf-regular nav-link"
							:to="{name: 'blog'}"
							>Blog</router-link
						>
						<div 
							class="ml-auto" 
							v-if="isLoggedIn"
						>
							<b-dropdown
								toggle-class="btn btn-clear py-1 btn-sm px-2 py-1 font-sf-regular ml-auto border-0 ml-auto bg-white"
								variant="light"
								:right="true"
								menu-class="px-3"
							>
								<template #button-content>
									<span class="mx-2">My FreQuincy List</span>
								</template>
								<div v-if="frequincyList && frequincyList.length > 0" >
									<b-dropdown-text
										text-class="my-2 font-xsmall"
										v-for="(list, index) in frequincyList"
										:key="index"
									>
										<div
											class="clickable font-gray"
											@click="() => {
												$router.replace({
													name: 'frequincy',
													query: {
														list_id: list.id
													}
												})
											}"
										>
											{{ list.name }}
										</div>
									</b-dropdown-text>
									<b-dd-divider v-if="frequincyList.length < 3" />
								</div>
								<b-dropdown-text
									text-class="my-2"
									v-if="frequincyList.length < 3"
								>
									<div 
										class="clickable font-xsmall font-gray"
										@click="handleCreateList"
									>
										Create New List
									</div>
								</b-dropdown-text>
							</b-dropdown>
						</div>
					</div>
				</div>
			</div>
			<CategoryPopup 
				:show="showCategoryPopup" 
				:categories="categories" 
				:vendors="vendors"
				:type="popupType" 
			/>
		</nav>

		<!-- MOBILE SIDEBAR -->
		<aside :class="`custom-nav custom-mobile-sidebar bg-white ${ showMobileSideBar ? 'show' : '' }`" id="custom-mobile-sidebar" >
			<div class="p-4">
				<div class="mb-3">
					<img @click="() => { $router.push({ name: 'home' }) }" class="nav-logo mobile" src="@assets/img/logo.png" :style="{ height: '40px' }" alt="Buyboy Logo" />
				</div>
				<div class="mt-4">
					<div class="input-group nav-search">
						<div class="input-group-prepend border-gray-2">
							<span class="input-group-text bg-gray-2 border-gray-2"><i class="fas fa-search"></i></span>
						</div>
						<input 
							class="form-control font-xsmall font-sf-regular bg-gray-2 border-gray-2 border-left-0 pl-0" 
							type="search" 
							placeholder="Search a product" 
							v-model="params.keyword"
							@keyup.enter="handleSearch"
						/>
					</div>
				</div>
				<div class="cart-btn-container mt-4" >
					<div v-if="isLoggedIn || hasCart" >
						<router-link
							:to="{name: 'cart'}"
							class="btn btn-block btn-green btn-radius-5 font-small font-sf-regular"
						>
							<div class="d-flex justify-content-center align-items-center">
								<div class="shopping-cart-icon mr-3">
									<img src="../../assets/img/shopping-cart-icon.png" alt="cart-icon">
									<span class="bg-white font-green border border-green rounded-pill font-xxxsmall font-sf-medium shopping-cart-icon-indicator">{{cartItemCount}}</span>
								</div>
								<div><Price :price="parseFloat(cartSubTotal)" /></div>
							</div>
						</router-link>
					</div>
					<div v-else >
						<router-link
							:to="{name: 'cart'}"
							class="btn btn-green btn-block btn-radius-5 font-small font-sf-regular"
						>
							<div class="d-flex justify-content-center align-items-center">
								<div class="shopping-cart-icon mr-3">
									<img src="../../assets/img/shopping-cart-icon.png" alt="cart-icon">
									<span class="bg-white font-green border border-green rounded-pill font-xxxsmall font-sf-medium shopping-cart-icon-indicator">{{cartItemCount}}</span>
								</div>
								<div><Price :price="parseFloat(cartSubTotal)" /></div>
							</div>
						</router-link>
					</div>
				</div>
				<div class="mt-4">
					<div v-if="isLoggedIn" >
						<div class="font-xlarge px-2" >Welcome, {{ user.customer.fullname }}</div>
						<div class="" >
							<router-link 
								class="btn btn-clear font-xlarge px-2 py-1 font-sf-regular nav-link text-left"
								:to="{ name: 'orders' }"
							>My Orders
							</router-link>
						</div>
						<div>
							<router-link 
								class="btn btn-clear font-xlarge px-2 py-1 font-sf-regular nav-link text-left"
								:to="{ name: 'account.profile' }"
							>My Profile
							</router-link>
						</div>
						<div>
							<router-link 
								class="btn btn-clear font-xlarge px-2 py-1 font-sf-regular nav-link text-left"
								:to="{ name: 'account.address' }"
							>My Address
							</router-link>
						</div>
						<div>
							<router-link 
								class="btn btn-clear font-xlarge px-2 py-1 font-sf-regular nav-link text-left"
								:to="{ name: 'account.password' }"
							>Change Password
							</router-link>
						</div>
						<div>
							<router-link 
								class="btn btn-clear font-xlarge px-2 py-1 font-sf-regular nav-link text-left"
								:to="{ name: 'frequincy' }"
							>My FreQuincy List
							</router-link>
						</div>
						<div>
							<button 
								class="btn btn-clear font-xlarge px-2 py-1 font-sf-regular" 
								@click="handleLogout"
							>
								Sign Out
							</button>
						</div>
					</div>
					<div v-else >
						<div>
							<router-link 
								class="btn btn-clear font-xlarge px-2 py-1 font-sf-regular"
								:to="{ name: 'login' }"
							>Login or Sign Up
							</router-link>
						</div>
					</div>
				</div>
				<div class="links-container font-small mt-4">
					<div>
						<router-link 
							class="btn btn-clear font-xlarge px-2 py-1 font-sf-regular nav-link text-left"
							:to="{ name: 'home' }"
						>Home
						</router-link>
					</div>
					<div>
						<button 
							class="btn btn-clear btn-block d-flex align-items-center justify-content-between font-xlarge px-2 py-1 font-sf-regular"
							v-b-toggle.categories-collapse
						>Categories<i class="fas fa-caret-down ml-2 font-green" />
						</button>
						<b-collapse id="categories-collapse" >
							<div  class="ml-4 p-2" >
								<div 
									class="border-bottom mb-3"
									v-for="(group, index) in categories"
									:key="index"
								>
									<div
										v-for="(category, i) in group"
										:key="i"
										class="mb-3"
									>
										<div class="d-flex justify-content-between align-items-center" >
											<router-link
												:to="{
													name: 'products',
													query: {
														category: category.slug
													}
												}"
												class="nav-link"
											>{{category.title}}</router-link>
											<span 
												v-if="category.children && category.children.length > 0" 
												class="fas fa-caret-down font-green"
												v-b-toggle="`${category.slug}`"
											/>
										</div>
										<div v-if="category.children && category.children.length > 0" >
											<b-collapse
												:id="category.slug"
												class="ml-4"
											>
												<div 
													v-for="(child, c) in category.children" 
													:key="c"
													class="mt-3"
												>
													<div class="d-flex align-items-center justify-content-between" >
														<router-link
															:to="{
																name: 'products',
																query: {
																	category: child.slug
																}
															}"
															class="nav-link"
														>{{ child.title }}</router-link>
														<span 
															class="fas fa-caret-down font-green"
															v-if="child.children.length > 0"
															v-b-toggle="`${ child.slug }`"
														/>
													</div>
													<div v-if="child.children && child.children.length > 0">
														<b-collapse
															:id="child.slug"
															class="ml-4"
														>
															<div
																v-for="(gchild, g) in child.children"
																:key="g"
																class="mt-3"
															>
																<div class="d-flex align-items-center justify-content-between">
																	<router-link
																		:to="{
																			name: 'products',
																			query: {
																				category: gchild.slug
																			}
																		}"
																		class="nav-link"
																	>{{ gchild.title }}</router-link>
																</div>
															</div>
														</b-collapse>
													</div>
												</div>
											</b-collapse>
										</div>
									</div>
								</div>
							</div>
						</b-collapse>
					</div>
					<div>
						<button 
							class="btn btn-clear btn-block d-flex align-items-center justify-content-between font-xlarge px-2 py-1 font-sf-regular"
							@click="showVendorsCollapse = !showVendorsCollapse"
						>Brands<i :class="`fas ml-auto font-green ${ showVendorsCollapse ? 'fa-caret-up' : 'fa-caret-down' }`" />
						</button>
						<b-collapse id="brands-collapse" v-model="showVendorsCollapse" >
							<div v-if="vendors && vendors.length > 0" class="my-3 pl-4">
								<router-link 
									v-for="vendor in vendors" 
									:key="vendor.slug" 
									:to="{ name: 'brands', params: { slug: vendor.slug } }" 
									class="d-block mb-3 font-sf-regular" 
								>
									{{ vendor.title }}
								</router-link>
							</div>
						</b-collapse>
					</div>
					<div>
						<router-link
							class="btn btn-clear btn-block text-left font-xlarge px-2 py-1 font-black font-sf-regular nav-link text-left"
							:to="{
								name: 'products',
								query: {
									tags: 'hot-deals:hot:hot deals',
									sort: 'relevance:desc',
									category: ''
								}
							}"
							>Hot Deals and Promo</router-link
						>
					</div>
					<div>
						<router-link
							class="btn btn-clear d-block text-left font-xlarge px-2 py-1 font-black font-sf-regular nav-link text-left"
							:to="{
								name: 'products',
								query: {
									tags: 'recommended',
									sort: 'relevance:desc',
									category: ''
								}
							}"
							>Recommended Goods</router-link
						>
					</div>
					<div>
						<router-link
							class="btn btn-clear d-block text-left font-xlarge px-2 py-1 font-black font-sf-regular nav-link text-left"
							:to="{
								name: 'products',
								query: {
									tags: 'trending',
									sort: 'relevance:desc',
									category: ''
								}
							}"
							>Trending Products</router-link
						>
					</div>
					<div>
						<router-link
							class="btn btn-clear d-block text-left font-xlarge px-2 py-1 font-black font-sf-regular nav-link text-left"
							:to="{name: 'bbdoit'}"
							>BBdoit</router-link
						>
					</div>
					<div>
						<router-link
							class="btn btn-clear d-block text-left font-xlarge px-2 py-1 font-black font-sf-regular nav-link text-left"
							:to="{name: 'blog'}"
							:style="{zIndex: 1500}"
							>Blog</router-link
						>
					</div>
				</div>
			</div>
		</aside>
	</div>
</template>

<script>
	import CategoryPopup from './CategoryPopup';
	import ProfilePopup from './ProfilePopup';
	import { getUser, isLoggedIn } from '@helpers/session.helper';
	import { getCart, hasCart } from '@helpers/cart.helper';
	import { mapActions, mapGetters, mapMutations } from 'vuex';
	import * as types from '../../store/mutation-types';
	import AddToCartNotif from './components/addtoCartNotif';
	import { connect } from '@api';

	export default {
		components: {
			CategoryPopup,
			ProfilePopup,
			AddToCartNotif
		},
		props: ['showProfilePopup', 'showCategoryPopup', 'popupType', 'navbarHeight', 'toggleMobileSidebar', 'showMobileSideBar', 'categories', 'vendors'],
		data() {
			return {
				list: [],
				params: {
					page: 1,
					keyword: '',
					search: true
				},
				showVendorsCollapse: false,
				collapses: [],
				language: 'en',
				windowWidth: 1366
			}
		},
		computed: {
			user() {
				return getUser();
			},
			isLoggedIn() {
				return isLoggedIn();
			},
			frequincyList() {
				return this.getList()
			},
			cart() {
				return getCart();
			},
			hasCart() {
				return hasCart();
			},
			cartSubTotal() {
				let subTotal = 0;

				let { cart } = this.$store.state.cart;

				if (cart) {
					
					let { items } = cart;

					if (items && items.length > 0) {
						items.map(item => {
	
							subTotal += (parseFloat(item.product.converted_selling_price) * item.quantity );
	
							if (item.add_ons.length > 0) {
								item.add_ons.map(addon => {
									subTotal += (parseFloat(addon.info.price) * addon.qty )
								})
							}
						})
					}
				}

				return subTotal;
			},
			cartItemCount() {

				let count = 0;

				let { cart } = this.$store.state.cart;
				let { items } = cart && cart;

				if (items && items.length > 0) {
					items.map(item => {
						count += 1;
					})
				}

				return count;
			},
		},
		mounted() {

			let navbar = document.getElementById('custom-nav');

			if (navbar) {
				this.$emit('nav-mounted', navbar.clientHeight);
			}

			this.windowWidth = window.outerWidth;

			document.addEventListener('resize', () => {
				
				this.windowWidth = window.outerWidth;
				navbar = document.getElementById('custom-nav');

				if (navbar) {
					this.$emit('nav-mounted', navbar.clientHeight);
				}
			})

			// setInterval(() => {
			// 	this.readCookie('googtrans');
			// }, 1000);
		},
		methods: {
			...mapActions({
				logout: types.LOGOUT,
			}),
			...mapGetters({
				getList: types.GET_FREQUINCY_LIST,
				getFB: types.GET_FB
			}),
			...mapMutations({
				toggleListPopupForm: types.TOGGLE_LIST_POPUP_FORM,
                setListPopupFormType: types.SET_LIST_POPUP_FORM_TYPE,
			}),
			readCookie(name) {
				var c = document.cookie.split('; '),
				cookies = {}, i, C;

				for (i = c.length - 1; i >= 0; i--) {
					C = c[i].split('=');
					cookies[C[0]] = C[1];
				}

				if (cookies[name]) {
					this.language = cookies[name];
				}
			},
			toggleProfilePopup() {
				this.$emit('toggle-popup');
			},
			toggleCategoryPopup(type) {
				this.$emit('toggle-category-popup', type);
			},
			handleCreateList() {
				this.setListPopupFormType('create');
				this.toggleListPopupForm();
			},
			handleLogout() {
				this.logout();
				let FB = this.getFB();

				if (FB && FB.hasOwnProperty('getLoginStatus')) {
					FB.getLoginStatus(function(response) {
						if (response.status == 'connected') {
							if (FB && FB.hasOwnProperty('logout')) {
								FB.logout(response => {
									console.log('logged out', response);
								})
							}
						}
					})
				}
				this.$router.push({ name: 'login' })
			},
			handleSearch() {
				this.$router.push({
					name: 'products',
					query: this.params
				})
			},
		},
	};
</script>

<style></style>
