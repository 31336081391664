<template>
    <div 
        class="cart-notification font-xsmall rounded font-white p-2 pb-0"
        v-if="showCart"
    >
        <div 
            v-for="(item, index) in items" class="mb-2 d-flex"
            :key="index"
        >
            <div class="img-container d-flex align-items-center p-1 bg-white m-1">
                <Imager
                    :src="item.product"
                    :extract="true"
                    :alt="item.product.title"
                />
            </div>
            <div class="details flex-grow-1 p-2">
                <b>( {{item.quantity}} ) {{item.product.title}}</b> has been added to your cart
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import * as types from '../../../store/mutation-types';

    export default {
        computed: {
            showCart: {
                get() {
                    return this.getShowCartNotif();
                },
                set(newValue) {
                    this.toggleCartNotif(newValue);
                }
            },
            items() {
                return this.getCartNotifItems();
            }
        },
        methods: {
            ...mapGetters({
                getShowCartNotif: types.GET_SHOW_ADD_TO_CART_NOTIF,
				getCartNotifItems: types.GET_ADD_TO_CART_NOTIF_ITEMS
            }),
            ...mapMutations({
                toggleCartNotif: types.TOGGLE_ADD_TO_CART_NOTIF,
                setCartNotifItems: types.SET_ADD_TO_CART_NOTIF_ITEMS
            })
        },
        watch: {
            showCart(show) {
                if (show) {
                    setTimeout(() => {
                        this.showCart = false;
                    }, 2000);
                }
            }
        }
    }
</script>
