<template>
    <div class="cart-page">

        <div class="container">
            <div class="cart-nav d-flex flex-wrap align-items-center py-4">
                <router-link
                    :to="{ name: 'cart' }"
                    class="cart-nav-item font-xxlarge font-sf-medium mr-5"
                >   
                    <span>My Cart</span>
                </router-link>
                <router-link
                    :to="{ name: 'frequincy', query: {} }"
                    class="cart-nav-item font-xxlarge font-sf-medium"
                >   
                    <span>My FreQuincy List</span>
                </router-link>
            </div>

            <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
                <div>
                    <span v-if="frequincyList && frequincyList.length > 0" class="d-flex align-items-center flex-wrap" >
                        <button 
                            v-for="(list, index) in frequincyList"
                            :key="index"
                            :class="`btn btn-white btn-sm rounded-pill px-3 mb-3 mb-sm-0 mr-2 font-gray ${ selectedList == list.id ? 'active' : '' }`"
                            @click="setSelectedList(list.id)"
                        >{{ list.name }}</button>
                        <button 
                            v-if="frequincyList && frequincyList.length < 3"
                            class="btn-clear btn-sm border border-gray-1 rounded-pill font-gray px-3 mb-3 mb-sm-0"
                            @click="handleCreateList"
                        >
                            <i class="fas fa-plus font-green mr-1"></i> Add New List
                        </button>
                    </span>
                </div>
                <div v-if="frequincyList.length > 0" class="dropdown mb-3 mb-sm-0">
                    <b-dropdown
                        toggle-class="btn btn-white btn-sm rounded-pill px-3 font-gray"
                        no-caret
                        menu-class="px-3 font-xsmall font-sf-medium font-gray"
                        right
                        variant="light"
                    >
                        <template #button-content>
                            <span class="mr-2" >Manage</span> <i class="fas fa-ellipsis-v"></i>
                        </template>
                        <div v-if="selectedList" >
                            <b-dropdown-text text-class="my-2" >
                                <div 
                                    class="clickable"
                                    @click="handleEditList(selectedList)"
                                >Rename this List</div>
                            </b-dropdown-text>
                            <b-dropdown-text text-class="my-2" >
                                <div 
                                    class="clickable"
                                    @click="handleClearList(selectedList)"
                                >Clear this List</div>
                            </b-dropdown-text>
                            <b-dropdown-text text-class="my-2" >
                                <div 
                                    class="clickable"
                                    @click="handleDeleteList(selectedList)"
                                >Delete this List</div>
                            </b-dropdown-text>
                            <b-dd-divider v-if="frequincyList && frequincyList.length < 3" />
                        </div>
                        <div v-if="frequincyList && frequincyList.length < 3" >
                            <b-dropdown-text text-class="my-2" >
                                <div 
                                    class="clickable"
                                    @click="handleCreateList"
                                >Create New List</div>
                            </b-dropdown-text>
                        </div>
                    </b-dropdown>
                </div>
            </div>

            <!-- CART TABLE -->
            
            <div class="mb-5">
                <div v-if="frequincyList.length > 0" class="cart-table table-responsive">
                    <div class="cart-table-row font-xsmall font-gray font-sf-medium">
                        <div class="cart-table-row-grid header">
                            <div class="custom-control custom-checkbox">
                                <input 
                                    type="checkbox" 
                                    class="custom-control-input" 
                                    id="toggle-select-all"
                                    :checked="selectedItems.length > 0 && selectedItems.length === items.length"
                                    @change="toggleSelectAll"
                                >
                                <label class="custom-control-label" for="toggle-select-all"><div class="pt-1" >Select All ({{items.length}} item/s)</div></label>
                            </div>
                            <span 
                                class="clickable" 
                                v-if="selectedItems.length > 0 && subTotal > 0"
                                @click="handleAddToCartItems"
                            >
                                <i class="far fa-plus-circle font-green"></i>
                                Add Selected to My Cart
                            </span>
                            <span 
                                class="clickable" 
                                @click="removeItems"
                                v-if="selectedItems.length > 0"
                            >
                                <i class="fas fa-trash font-green"></i>
                                Remove Selected Item/s
                            </span>
                        </div>
                    </div>
                    <div class="cart-table-row bg-gray-2 font-xsmall font-gray font-sf-medium d-none d-lg-block">
                        <div class="cart-table-row-grid frequincy">
                            <div class="checkbox-column"></div>
                            <div class="image-column text-center">Image</div>
                            <div class="description-column">Item Description</div>
                            <div class="price-column text-left pl-4"><span class="ml-3">Unit Price</span></div>
                            <div class="quantity-column text-center"></div>
                            <div class="total-column text-center"></div>
                            <div class="remove-column"></div>
                        </div>
                    </div>
                    <div 
                        :class="`cart-table-row ${ isOutOfStock(item.product) ? 'bg-gray-2' : '' }`"
                        v-for="(item, index) in items" 
                        :key="index" 
                    >
                        <FrequincyItem
                            :item="item"
                            :selectedItems="selectedItems"
                            @toggle-select="toggleSelect(item.id)"
                            @remove-item="removeItem(item)"
                            @add-to-cart="handleAddToCartItem(item)"
                            @change-quantity="handleChangeQuantity"
                        />
                    </div>
                    <div class="cart-table-row footer bg-green-transparent">
                        <div class="py-5 text-center">
                            <router-link 
                                class="btn btn-sm py-2 btn-clear rounded-pill border-green font-green font-sf-medium"
                                :to="{ name: 'products' }"
                            >Shop More</router-link>
                        </div>
                    </div>
                </div>

                <div v-else >
                    <div class="font-xlarge text-center font-sf-bold py-5 bg-gray-2">
                        <div class="my-5">
                            No list found
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <CheckoutSection 
            :selectedItems="selectedItems"
            :items="items"
            :subTotal="subTotal"
            @proceed-to-checkout="proceedToCheckout"
        />
    </div>    
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex';
    import * as types from '../../store/mutation-types';
    import { showAlert } from '@helpers/notification.helper';
    import { isLoggedIn, getUser } from '@helpers/session.helper';
    import { hasCart, getCart } from '@helpers/cart.helper';
    import FrequincyItem from './components/FrequincyItem';
    import CheckoutSection from './components/CheckoutSection';

    const swalSettings = {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true,
        customClass: {
            actions: 'd-flex px-3',
            confirmButton: 'flex-fill bg-white font-black',
            cancelButton: 'flex-fill bg-green font-white'
        }
    }

    let successSwalSettings = {
        confirmButtonText: 'Ok',
        customClass: {
            confirmButton: 'flex-fill mx-5'
        }
    }

    let swalOutOfStockSettings = {
        html: 'There are items that are out of stock.',
        confirmButtonText: 'Ok',
        customClass: {
            confirmButton: 'flex-fill mx-5'
        }
    };

    let swalEmptySettings = {
        html: 'You have not selected any items for checkout',
        confirmButtonText: 'Ok',
        customClass: {
            confirmButton: 'flex-fill mx-5'
        }
    };

    export default {
        components: {
            FrequincyItem,
            CheckoutSection
        },
        props: {
            showListModal: Boolean
        },
        data() {
            return {
                selectedList: null,
                showManagePopup: false,
                listModalType: '',
                items: [],
                selectedItems: [],
            }
        },
        computed: {
            frequincyList: {
                get() {
                    let { list } = this;

                    if (isLoggedIn()) {
                        list = this.getList();
                    }

                    return list;
                },
                set(newValue) {
                    this.frequincyList = newValue;
                }
            },
            subTotal: {
                get(){
                    let { items, selectedItems } = this;
                    let subTotal = 0.00;

                    if (selectedItems && selectedItems.length > 0) {
                        selectedItems.map(itemID => {

                            let item = items.find(item => {
                                return item.id == itemID;
                            });

                            if (item && item.product && item.product.inventory > 1) {
                                subTotal += parseFloat(item.product.price);
                            }
                        });
                    }
    
                    return subTotal;
                }
            }
        },
        mounted() {

            let selectedList = null;

            if (this.$route.query && this.$route.query.list_id) {
                selectedList = this.$route.query.list_id;
            } else {
                if (this.frequincyList) {
                    selectedList = this.frequincyList[0].id;
                }
            }

            this.selectedList = selectedList;
            this.getItems(this.selectedList);
        },
        methods: {
            ...mapGetters({
                getList: types.GET_FREQUINCY_LIST
            }),
            ...mapActions({
                fetchItems: types.FETCH_FREQUINCY_LIST_ITEMS,
                addList: types.CREATE_FREQUINCY_LIST,
                updateList: types.UPDATE_FREQUINCY_LIST,
                deleteList: types.DELETE_FREQUINCY_LIST,
                clearList: types.CLEAR_FREQUINCY_LIST_ITEMS,
                deleteItem: types.DELETE_FREQUINCY_LIST_ITEM,
                deleteItems: types.DELETE_FREQUINCY_LIST_ITEMS,
                syncCart: types.SYNC_CART,
                checkout: types.PROCEED_TO_CHECKOUT,
                updateItem: types.UPDATE_FREQUINCY_LIST_ITEM
            }),
            ...mapMutations({
                toggleListPopupForm: types.TOGGLE_LIST_POPUP_FORM,
                setListPopupFormType: types.SET_LIST_POPUP_FORM_TYPE,
                setListPopupFormID: types.SET_LIST_POPUP_FORM_ID,
                toggleCartNotif: types.TOGGLE_ADD_TO_CART_NOTIF,
				setCartNotifItems: types.SET_ADD_TO_CART_NOTIF_ITEMS
            }),
            setSelectedList(id) {
                this.$router.replace({
                    name: 'frequincy',
                    query: {
                        list_id: id
                    }
                })
                .catch(err => {})
            },
            getItems(id) {

                let params = {
                    id: id
                };

                this.fetchItems({ params: params })
                    .then(res => {
                        if (res.status == 200) {
                            this.items = res.data.data;
                        }
                    })
                    .catch(err => {
                        console.error(err)
                    })
            },
            handleChangeQuantity(item, quantity) {

                let { items } = this;
                let selectedItem = null;
                let selectedItemIndex = items.findIndex(frequincyItem => frequincyItem.id == item.id);

                if (selectedItemIndex > -1) {
                    selectedItem = items[selectedItemIndex];
                    selectedItem.quantity = quantity;
                }

                this.updateItem(selectedItem)
            },
            handleAddToCartItem(item) {

                let cart = getCart();
				let user = getUser();
                let selectedProduct = item.product;
                let quantity = parseInt(item.quantity);
                
                let items = (cart && cart.items && cart.items.length > 0) ? cart.items : [];
				let existingProductIndex = items.findIndex(item => item.product_id == selectedProduct.id);

				if (existingProductIndex > -1) {

					let newQuantity = items[existingProductIndex].quantity + quantity;

					if (items[existingProductIndex].product.inventory <= newQuantity) {
						items[existingProductIndex].quantity = items[existingProductIndex].product.inventory;
					} else {
						items[existingProductIndex].quantity = newQuantity;
					}

				} else {
					items.push({
						product_id: selectedProduct.id,
						quantity: quantity,
						product: selectedProduct
					})
				}

				let cartParams = {
					customer_id: user && user.customer_id ? user.customer_id : 0,
					source: 'website',
					last_device: 'laptop'
				}

				cartParams.items = items;

				this.syncCart(cartParams)
					.then(res => {
						console.log(res);
					})

				let notifItems = [];
				notifItems.push({
					product: selectedProduct,
					quantity: quantity	
				})

				this.setCartNotifItems(notifItems);
				this.toggleCartNotif(true);
            },
            handleAddToCartItems() {

                let cart = getCart();
				let user = getUser();
                let { selectedItems, items } = this;

                let newCartItems = [];

                let cartItems = (cart && cart.items && cart.items.length > 0) ? cart.items : [];
                if (selectedItems.length > 0) {

                    selectedItems.map(selected => {

                        let selectedItem = items.find(item => item.id == selected);
                        let quantity = 0;
                        let selectedProduct = null;

                        if (selectedItem) {
                            selectedProduct = selectedItem.product;
                            newCartItems.push(selectedProduct);
                            quantity = selectedItem.quantity;
                        }

                        let existingProductIndex = cartItems.findIndex(item => item.product_id == selectedProduct.id);

                        if (existingProductIndex > -1) {

                            let newQuantity = cartItems[existingProductIndex].quantity + quantity;

                            if (cartItems[existingProductIndex].product.inventory <= newQuantity) {
                                cartItems[existingProductIndex].quantity = cartItems[existingProductIndex].product.inventory;
                            } else {
                                cartItems[existingProductIndex].quantity = newQuantity;
                            }

                        } else {
                            cartItems.push({
                                product_id: selectedProduct.id,
                                quantity: quantity,
                                product: selectedProduct
                            })
                        }

                    })
                }

                let cartParams = {
					customer_id: user && user.customer_id ? user.customer_id : 0,
					source: 'website',
					last_device: 'laptop'
                };
                
                cartParams = {...cartParams, ...cart};
                cartParams.items = cartItems;

                this.syncCart(cartParams)
					.then(res => {
						console.log(res);
					})

                let notifItems = [];
                if (newCartItems.length > 0) {
                    newCartItems.map(item => {
                        notifItems.push({
                            product: item,
                            quantity: item.quantity	
                        })
                    })
                }

				this.setCartNotifItems(notifItems);
				this.toggleCartNotif(true);

            },
            proceedToCheckout() {

                let { selectedItems, items } = this;

                if (selectedItems.length > 0) {

                    let hasOutOfStock = false;

                    selectedItems.map(selected => {

                        let item = items.find(item => item.id == selected);

                        if (item.product.enable_inventory > 0) {
                            if (item.product.inventory <= 0) {
                                hasOutOfStock = true;
                            }
                        }
                    });

                    if (hasOutOfStock) {
                        showAlert(swalOutOfStockSettings, 'error');
                        return;
                    }

                } else {
                    showAlert(swalEmptySettings, 'error');
                    return;
                }

                let params = {
                    items: this.processCheckoutItems()
                }

                this.checkout(params)
                    .then(res => {
                        if (res.status == 200) {

                            this.$router.push({
                                name: 'checkout',
                                query: {
                                    token: res.data.data.token
                                }
                            })
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    })
            },
            isOutOfStock(product) {

                let isOutOfStock = true;

                if (product.enable_inventory > 0) {
                    isOutOfStock = product.inventory > 0 ? false : true;
                } else {
                    isOutOfStock = false;
                }

                return isOutOfStock;
            },
            processCheckoutItems() {

                let { selectedItems, items } = this;
                let checkoutItems = [];

                if (selectedItems.length > 0) {

                    selectedItems.map(selected => {
                        
                        let checkoutItem;

                        let item = items.find(item => selected == item.id);

                        if (item) {

                            checkoutItem = {
                                product_id: item.product_id,
                                quantity: 1,
                                notes: item.notes,
                                eta: item.eta
                            };

                            checkoutItems.push(checkoutItem);
                        }
                    })
                }

                return checkoutItems;
            },
            handleCreateList() {
                this.setListPopupFormType('create');
                this.toggleListPopupForm();
            },
            handleEditList(id) {

                this.setListPopupFormID(id);
                this.setListPopupFormType('edit');
                this.toggleListPopupForm();
            },
            handleClearList(id) {

                swalSettings.html = 'This will clear all item(s) on your list. <br/> Do you want to continue?';
                showAlert(swalSettings, 'confirm')
                    .then(swal => {
                        if (swal.isConfirmed) {
                            let params = {
                                id: id
                            };
                            this.clearList({ params: params })
                                .then(() => {
                                    successSwalSettings.html = "Items successfully removed from this list."
                                    showAlert(successSwalSettings)
                                    this.items = [];
                                })
                        }
                    })
            },
            handleDeleteList(id) {
                
                let { frequincyList, selectedList } = this;

                swalSettings.html = 'Are you sure you want to delete this list?';
                showAlert(swalSettings, 'confirm')
                    .then(swal => {
                        if (swal.isConfirmed) {

                            let params = {
                                id: id
                            };
                            this.deleteList({ params: params })
                                .then(() => {
                                    successSwalSettings.html = "List successfully deleted";
                                    showAlert(successSwalSettings);

                                    if (selectedList == id) {
                                        if (this.frequincyList.length > 0) {
                                            this.selectedList = this.frequincyList[0].id;
                                        } else {
                                            this.selectedList = 0;
                                        }
                                    }
                                })
                        }
                    })
            },
            toggleSelect(id) {
                
                let { selectedItems } = this;

                let found = selectedItems.find(item => {
                    return id == item;
                });

                if (found) {
                    
                    selectedItems = selectedItems.filter(item => {
                        return item !== id;
                    })

                } else {
                    selectedItems.push(id);
                }

                this.selectedItems = selectedItems;
            },
            toggleSelectAll() {
                
                let { items, selectedItems } = this,
                    newItems = [];
                
                if (items.length <= 0) {
                    return;
                }

                if (items.length == selectedItems.length) {
                    this.selectedItems = [];
                    return;
                } 

                items.map(item => {
                    newItems.push(item.id);
                });

                this.selectedItems = newItems;
            },
            removeItem(item) {
                
                swalSettings.html = 'Do you want to remove the selected product(s)?';
                showAlert(swalSettings, 'confirm')
                    .then(swal => {
                        if (swal.isConfirmed) {

                            let user = getUser();

                            let params = {
                                id: item.id,
                                frequincy_list_id: this.selectedList,
                                customer_id: user.customer_id
                            };

                            this.deleteItem(params)
                                .then(() => {

                                    let alert = {
                                        html: 'Product removed from your list',
                                        customClass: {
                                            confirmButton: 'mx-5 btn-block'
                                        }
                                    }

                                    showAlert(alert);

                                    this.getItems(this.selectedList);
                                })
                        }
                    })
            },
            removeItems() {
                
                let { selectedItems, selectedList } = this;

                if (selectedItems.length <= 0) {
                    return;
                }

                swalSettings.html = 'Do you want to remove the selected product(s)?';
                showAlert(swalSettings, 'confirm')
                    .then(swal => {
                        if (swal.isConfirmed) {

                            let user = getUser();

                            let params = {
                                ids: selectedItems,
                                frequincy_list_id: selectedList,
                                customer_id: user.customer_id
                            };

                            this.deleteItems({ params: params })
                                .then(res => {
                                    if (res.status == 200) {

                                        let alert = {
                                            html: 'Product/s removed from your list',
                                            customClass: {
                                                confirmButton: 'mx-5 btn-block'
                                            }
                                        }

                                        showAlert(alert);

                                        this.getItems(selectedList);
                                    }
                                })
                                .catch(err => {
                                    console.error(err.message);
                                })
                        }
                    })
            }
        },
        watch: {
            $route(to) {

                let { query } = to;
                
                if (query && query.list_id) {
                    this.selectedList = query.list_id;
                    this.selectedItems = [];
                    this.setListPopupFormID(this.selectedList);
                    this.getItems(this.selectedList);
                }
            },
            frequincyList(newValue) {
                
                this.getItems(this.selectedList);
            }
        }
    }
</script>
