import email from '@views/verify/email';
import phone from '@views/verify/phone';
import verify from '@views/verify';

export default {
    path: '/verify',
    name: 'verify',
    component: verify,
    meta: {
        requiresAuth: true
    },
    props: true,
    children: [
        {
            path: 'email',
            name: 'verify.email',
            component: email,
            props: true
        },
        {
            path: 'phone',
            name: 'verify.phone',
            component: phone,
            props: true,
        }
    ]
}