<template>
    <router-link 
        :to="{
            name: 'blog.view',
            params: {
                slug: this.article.slug
            }
        }"
    >
        <div :class="`card blog-card border-0 ${ className }`">
            <div class="position-relative">
                <Imager
                    v-if="article.asset && article.asset.path"
                    className="card-img-top"
                    :src="article.asset"
                    :extract="true"
                />
                <div class="d-flex align-items-center justify-content-center h-100 btn-container">
                    <button class="btn btn-green blog-card-btn px-5 py-2 font-small rounded-pill">READ</button>
                </div>
            </div>
            <div class="card-body px-0 py-2">
                <div 
                    class="blog-card-title font-medium font-sf-medium"
                >{{ article.name }}
                </div>
                <div class="font-xsmall font-gray" >{{formattedDate}}</div>
            </div>
        </div>
    </router-link>
</template>

<script>
import moment from 'moment';

export default {
    props: ['article', 'className'],
    computed: {
        formattedDate() {
            return moment(this.article.date).format('MMMM DD, YYYY');
        }
    },
    methods: {
    }
}
</script>
