var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.category.children && _vm.category.children.length > 0)?_c('span',[_c('b-dropdown',{attrs:{"toggle-class":("btn btn-sm btn-white py-1 rounded-pill mr-2 category-btn-parent " + (_vm.$router.query && _vm.$router.query.category == _vm.category.slug ? 'router-link-active' : '')),"variant":"light","no-caret":true,"menu-class":"p-2"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',[_c('span',{on:{"click":function($event){$event.stopPropagation();return (function () {
                            _vm.$router.replace({
                                name: 'products',
                                query: Object.assign({}, _vm.params, {category: _vm.category.slug})
                            })
                        })($event)}}},[_vm._v(_vm._s(_vm.category.title))]),_c('i',{staticClass:"ml-2 fas fa-caret-down font-gray fa-lg"})])]},proxy:true}],null,false,2815160939)},_vm._l((_vm.category.children),function(child,index){return _c('b-dropdown-text',{key:index,attrs:{"text-class":"font-xsmall mb-2"}},[_c('router-link',{staticClass:"clickable",attrs:{"to":{
                        query: Object.assign({}, _vm.params, {category: child.slug})
                    }}},[_vm._v(_vm._s(child.title))])],1)}),1)],1):_c('span',[_c('router-link',{staticClass:"btn btn-sm btn-white py-1 rounded-pill mr-2",attrs:{"to":{
                query: Object.assign({}, _vm.params, {category: _vm.category.slug})
            }}},[_vm._v(" "+_vm._s(_vm.category.title)+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }