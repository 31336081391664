<template>
    <div class="orders-page">
        <div class="container">
            <div class="col-lg-10 col-12 mx-auto py-5">
                <div class="order-view-page">
                    <router-link
                        :to="{ name: 'orders'}"
                        class="font-xxlarge font-sf-medium mt-n5"
                    >
                        <i class="fas fa-chevron-left mr-2"></i> Back
                    </router-link>
                    <div v-if="orderData" class="mt-4">
                        <div v-if="orderData.order_status != 'completed'" class="card bg-green-2 border-green-2 mb-4">
                            <div class="card-body">
                                <div class="d-flex align-items-center flex-wrap font-white">
                                    <div>
                                        <div class="rounded-circle p-3 text-center bg-green">
                                            <img 
                                                :src="orderImageSrc" 
                                                alt="order status image"
                                            >
                                        </div>
                                    </div>
                                    <div v-if="orderData.order_status == 'pending'" class="ml-3" >
                                        <div class="font-small font-sf-medium">Order has been placed</div>
                                        <div class="font-xxsmall">Order will be prepared by {{ moment(orderData.date_delivery).format('DD-MM-YYYY') }}</div>
                                    </div>
                                    <div v-if="orderData.order_status == 'pending' && (orderData.payment_type.slug != 'cod' && orderData.payment_type.slug != 'cop' && orderData.payment_type.slug != 'paymongo')" class="ml-auto" >
                                        <button 
                                            class="btn btn-sm btn-clear bg-white rounded-pill font-green"
                                            @click="toggleDepositModal"
                                        >{{ hasDepositSlip ? 'Update' : 'Upload' }} Deposit Slip</button>
                                    </div>
                                    <div v-if="orderData.order_status == 'processing'" class="ml-3">
                                        <div class="font-xsmall font-sf-medium">Preparing your Order</div>
                                        <div class="font-xxsmall">
                                            <div v-if="orderData.delivery_option == 'delivery'" >Order will be shipped out by {{ moment(orderData.date_delivery).format('DD-MM-YYYY') }}</div>
                                            <div v-else >Order will be ready for pick up by {{ moment(orderData.date_delivery).format('DD-MM-YYYY') }}</div>
                                        </div>
                                    </div>
                                    <div v-if="orderData.order_status == 'delivery' && orderData" class="ml-3">
                                        <div v-if="orderData.delivery_option == 'delivery'" >
                                            <div class="font-small font-sf-medium">Driver is on the way</div>
                                            <div class="font-xxsmall">Order will be delivered today</div>
                                        </div>
                                        <div v-else >
                                            <div class="font-small font-sf-medium">Available for Pickup</div>
                                            <div class="font-xxsmall">Order is now available for pick up</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="hasDepositSlip" class="card mb-4">
                            <div class="card-body">
                                <div class="font-small font-sf-medium mb-3">Deposit Slip</div>
                                <div class="row">
                                    <div class="col-md-6 col-12 font-xsmall" v-if="depositData" >
                                        <div class="w-75">
                                            <div class="d-flex mb-2">
                                                <div class="font-gray">Account Name</div>
                                                <div class="ml-auto">{{ depositData.account_name ? depositData.account_name : '---'  }}</div>
                                            </div>
                                            <div class="d-flex mb-2" v-if="depositData.payment_date" >
                                                <div class="font-gray">Date of Payment</div>
                                                <div class="ml-auto">{{ depositData.payment_date ? moment(depositData.payment_date).format('MM/DD/YYYY') : '---' }}</div>
                                            </div>
                                            <div class="d-flex mb-2">
                                                <div class="font-gray">Amount Paid</div>
                                                <div class="ml-auto">
                                                    <span v-if="depositData.amount_paid" >
                                                        <Price
                                                            :price="parseFloat(depositData.amount_paid)"
                                                        />
                                                    </span>
                                                    <span v-else >---</span>
                                                </div>
                                            </div>
                                            <div class="d-flex mb-2">
                                                <div class="font-gray">Reference No.</div>
                                                <div class="ml-auto">{{ depositData.reference_number ? depositData.reference_number : '---' }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="d-flex align-items-center justify-content-center border bg-gray-2 rounded p-3">
                                            <Imager
                                                :src="uploadedFile"
                                                :extract="extractAsset"
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div v-if="orderData.delivery_option == 'delivery'" class="card mb-4">
                            <div class="card-body">
                                <div class="font-small font-sf-medium mb-3">Delivery Address</div>
                                <div class="row mx-0 font-xsmall">
                                    <div class="col-lg-2 pl-md-0">{{ orderData.customer && orderData.customer.fullname }}</div>
                                    <div class="col-lg-2 pl-md-0">{{ orderData.customer && orderData.customer.phone }}</div>
                                    <div class="col">{{ addressString }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="card mb-4" >
                            <div class="card-body">
                                <div class="font-small font-sf-medium mb-3">Pickup Address</div>
                                <div class="mx-0 font-xsmall">{{ orderData.pickup_location ? orderData.pickup_location : '---' }}</div>
                            </div>
                        </div>
                        <div v-if="orderData.items && orderData.items.length > 0" class="cart-table table-responsive mb-4">
                            <div class="cart-table-row bg-gray-2 font-xsmall font-gray font-sf-medium d-none d-md-block pr-md-5">
                                <div class="cart-table-row-grid order">
                                    <div class="image-column text-center">Image</div>
                                    <div class="description-column">Item Description</div>
                                    <div class="text-right">Total</div>
                                </div>
                            </div>
                            <div 
                                class="cart-table-row pr-md-5"
                                v-for="(item, index) in orderData.items"
                                :key="index"
                            >
                                <OrderItem
                                    :item="item"
                                />
                                <div class="ml-5 pl-4 pr-0 mt-3" v-if="item.add_ons && item.add_ons.length > 0" >
                                    <OrderItemAddon
                                        v-for="(add_on, index) in item.add_ons"
                                        :key="index"
                                        :item="add_on"
                                    />
                                </div>
                            </div>
                            <div class="cart-table-row py-3 pr-5">
                                <div class="ml-auto col-lg-4 pr-0 col-12">
                                    <div class="d-flex my-1 font-gray">
                                        <span class="">Subtotal</span>
                                        <span class="text-right ml-auto">
                                            <Price
                                                :price="(parseFloat(orderData.converted_raw_subtotal) + parseFloat(orderData.discount_amount))"
                                            />
                                        </span>
                                    </div>
                                    <div class="d-flex my-1 font-gray" v-if="orderData.delivery_option == 'delivery'" >
                                        <span>Shipping Fee</span>
                                        <span class="text-right ml-auto">
                                            <Price
                                                :price="orderData.shipping_amount"
                                            />
                                        </span>
                                    </div>
                                    <div v-if="discounts && discounts.length > 0" >
                                        <div
                                            v-for="(discount, i) in discounts" 
                                            :key="i"
                                            class="d-flex my-1 font-gray">
                                                <span>{{ discount.name }}</span>
                                                <span class="text-right ml-auto" >
                                                    -
                                                    <Price
                                                        :price="discount.value"
                                                        :inline="true"
                                                    />
                                                </span>
                                            </div>
                                    </div>
                                    <div class="font-xlarge my-1 d-flex">
                                        <span>Total</span>
                                        <span class="text-right ml-auto" >{{ orderData.converted_overall_total }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="orderData.payment_type" >
                            <div class="card-body">
                                <div class="font-small font-sf-medium">Payment Method</div>
                                <div class="row mx-0 font-xsmall align-items-center">
                                    <div class="col pl-0">
                                        {{ orderData.payment_type.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DepositModal
            :show="showDepositModal"
            :toggleDepositModal="toggleDepositModal"
            :depositForm="depositForm"
            :depositFormErrors="depositFormErrors"
            :handleDepositModalFileChange="handleDepositModalFileChange"
            :handleDepositModalSubmit="handleDepositModalSubmit"
            :uploadedFile="uploadedFile"
            :handleRemoveUploadedFile="handleRemoveUploadedFile"
            :extractAsset="extractAsset"
            :hasDepositSlip="hasDepositSlip"
            :uploading="uploading"
        />
    </div>
</template>

<script>
    import { connect } from '@api';
    import moment from 'moment';
    import placedIcon from '@assets/img/order-placed-icon.png';
    import preparingIcon from '@assets/img/order-preparing-icon.png';
    import deliveryIcon from '@assets/img/order-delivery-icon.png';
    import completedIcon from '@assets/img/order-complete-icon.png';
    import OrderItem from './components/orderItem';
    import OrderItemAddon from './components/orderItemAddon';
    import DepositModal from './components/depositModal';
    import validate from '@helpers/validation.helper';

    const initialDepositForm = {
        imageData: null,
        account_name: '',
        payment_date: '',
        amount_paid: '',
        reference_number: ''
    }

    const initialDepositFormErrors = {
        imageData: '',
        account_name: '',
        payment_date: '',
        amount_paid: '',
        reference_number: ''
    }

    export default {
        components: {
            OrderItem,
            OrderItemAddon,
            DepositModal
        },
        data() {
            return {
                orderData: null,
                moment: moment,
                depositForm: initialDepositForm,
                depositFormErrors: initialDepositFormErrors,
                showDepositModal: false,
                uploadedFile: null,
                uploading: false,
                extractAsset: false,
                formValues: {
                    deposit: null
                }
            }
        },
        computed: {
            status() {
                return this.$route.params.status;
            },
            discounts() {
                let discounts = [];
                let { orderData } = this;

                if (orderData) {
                    if (orderData.discount_id > 0) {
                        if (orderData.discount && orderData.discount.coupon_code) {
                            discounts.push({
                                name: orderData.discount.coupon_code.code,
                                value: orderData.discount_amount
                            })
                        }
                    }
                    if (orderData.bank_discount_amount > 0) {
                        discounts.push({
                            name: 'Bank deposit discount',
                            value: orderData.bank_discount_amount
                        })
                    }
                }

                return discounts;
            },
            hasDepositSlip() {
                let { orderData } = this;
                let hasDepositSlip = false;

                if (orderData) {
                    let depositData = JSON.parse(orderData.deposit_data);

                    if (depositData && depositData.account_name) {
                        hasDepositSlip = true;
                    }

                    if (orderData.deposit_slip && orderData.deposit_slip.id) {
                        hasDepositSlip = true;
                    }
                }

                return hasDepositSlip;
            },
            depositData() {
                return JSON.parse(this.orderData.deposit_data);
            },
            addressString() {

                let str = '';
                let { shipping_address } = this.orderData;

                if (shipping_address) {

                    str += shipping_address.address_line_1 + ', ';
                    str += shipping_address.custom_area + ', '
                    str += shipping_address.city_name + ', '
                    str += shipping_address.province_name
                }

                return str;
            },
            orderImageSrc() {
                let src = '';

                let { order_status } = this.orderData;

                if (order_status == 'pending') src = placedIcon;
                if (order_status == 'processing') src = preparingIcon;
                if (order_status == 'delivery') src = deliveryIcon;
                if (order_status == 'completed') src = completedIcon

                return src;
            }
        },
        mounted() {

            let { params } = this.$route;

            if (params.reference_number) {
                this.findOrder(params.reference_number);
            }
        },
        methods: {
            findOrder(reference_number) {
                connect().get(`customers/get-customer-order-data?reference_number=${ reference_number }`, {})
                    .then(res => {
                        if (res.status == 200) {
                            this.orderData = res.data.data;

                            let depositSlip = this.orderData.deposit_slip;
                            let depositData = this.orderData.deposit_data;

                            if (depositData) {
                                depositData = JSON.parse(depositData);

                                this.depositForm = {...this.depositForm, ...depositData};
                            }
                            if (depositSlip) {
                                this.extractAsset = true;
                                this.uploadedFile = depositSlip;
                            }
                        }
                    })
                    .catch(err => {
                        if (err.data && err.data.message) {
                            console.error(err.data.message);
                        }
                    })
            },
            toggleDepositModal() {
                this.showDepositModal = !this.showDepositModal;
            },
            handleDepositModalFileChange(fileList) {
                
                if (!fileList.length) return;
                
                this.extractAsset = false;
                this.uploadedFile = URL.createObjectURL(fileList[0]);

                this.depositForm.imageData = fileList[0];
            },
            handleRemoveUploadedFile() {
                this.uploadedFile = null;
                this.depositForm.imageData = null;
            },
            async validateDepositForm() {
                const schema = {
                    properties: {
                        account_name: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                        },
                        payment_date: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                        },
                        amount_paid: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                        },
                        reference_number: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                        },
                    }
                };

                const validateResults = validate(this.depositForm, schema, undefined, true);

                this.depositFormErrors = validateResults.errors;

                return validateResults.valid;
            },
            async handleDepositModalSubmit() {

                let { depositForm, orderData } = this;
                this.uploading = true;

                if (!depositForm.imageData) {
                    if (!(await this.validateDepositForm())) {
                        return;
                    }
                } else {
                    let params = {
                        order_id: orderData.id,
                        deposit_slip: depositForm.imageData
                    }
    
                    let depositFormData = new FormData();
                    depositFormData.append('order_id', orderData.id);
                    depositFormData.append('deposit_slip', depositForm.imageData);
    
                    if (depositForm.imageData) {

                        let response = await connect().post('orders/upload-attachments', depositFormData).then(res => res).catch(err => err);
                        if (response.status == 200) {
                            this.orderData.deposit_data = response.data.data.deposit_data;
                        } else {
                            this.uploading = false;
                            this.depositFormErrors.imageData = 'Uploading failed';
                            return;
                        }
                    }
                }


                this.formValues.deposit = JSON.stringify(depositForm);

                let params = {
                    order_id: orderData.id,
                    deposit: this.formValues.deposit
                };

                let response = await connect().post('orders/update-deposit-data', params).then(res => res).catch(err => err);

                if (response.status == 200) {
                    this.uploading = false;
                    this.depositFormErrors = initialDepositFormErrors;
                    this.toggleDepositModal();
                    return;
                } else {
                    this.uploading = false;
                    this.depositFormErrors.reference_number = response.data.message;
                    return;
                }
            },
        }
    }
</script>