<template>
    <div class="vh-75 d-flex align-items-center">
        <div class="container">
            <div class="py-5 my-5 text-center">
                <p class="font-giantsize m-0 font-sf-semi-bold">Page Unavailable</p>
                <p class="font-lg font-gray font-sf-regular">We can't seem to find the page you're looking for.</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>