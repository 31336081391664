<template>
    <b-dropdown  
        size="sm"
        variant="light"
        :no-caret="true"
        toggle-class="btn-settings clickable btn-sm bg-white px-3"
        menu-class="p-3"
    >
        <template #button-content>
            <span class="fas fa-ellipsis-v"></span>
        </template>
        <b-dropdown-text
            text-class="font-xsmall font-sf-medium font-gray mb-2"
            tag="div"
        >
            <span @click="() => { $router.push(editPath) }" class="clickable" >Edit this {{ type }}</span>
        </b-dropdown-text>
        <b-dropdown-text
            text-class="font-xsmall font-sf-medium font-gray"
            tag="div"
        >
            <span @click="$emit('delete-item', id)" class="clickable">Delete this {{ type }}</span>
        </b-dropdown-text>
        <!-- <router-link 
            :to="editPath" 
            @click="() => {console.log('clicked')}"
            class="dropdown-item font-xsmall font-sf-medium font-gray" 
        >Edit this {{ type }}</router-link>
        <span 
            class="dropdown-item font-xsmall font-sf-medium font-gray clickable"
            @click="$emit('delete-item', id)"
        >Delete this {{ type }} </span> -->
    </b-dropdown>
</template>

<script>
export default {
    props: {
        show: Boolean,
        type: String,
        id: Number,
    },
    computed: {
        editPath() {
            let path = {
                params: {
                    method: 'edit'
                },
                query: {
                    id: this.id
                }
            }

            if (this.type == 'account') {
                path.name = 'account.bank'
            }
            if (this.type == 'card') {
                path.name = 'account.card'
            }
            if (this.type == 'address') {
                path.name = 'account.address.form'
            }
            return path;
        }
    },
    methods: {
        hidePopup(e) {
            e.target.classList.remove('show');
        }
    }
}
</script>