import axios from 'axios';
import config from '../constants/config';
import { getUser, isLoggedIn } from './session.helper';

export function connect(additionalHeaders = {}) {
	let headers = { 'Access-Control-Allow-Origin': '*', ...additionalHeaders };

	if (isLoggedIn()) {
		const user = getUser();
		headers = { ...headers, Authorization: 'Bearer ' + (user.token ? user.token : '') };

		instance = axios.create({
			baseURL: config.API_URL,
			headers,
		});
	} else {
		instance = axios.create({
			headers,
			baseURL: config.API_URL,
		});
	}

	let instance = axios.create({
		headers,
		baseURL: config.API_URL,
	});
	
	instance.interceptors.response.use(
		function(response) {
			// Do something with response data
			//only return the data
			const { data, status } = response;
			return { data, status };
		},
		function(error) {

			return Promise.reject(error.response);
		}
	);
	return instance;
}
