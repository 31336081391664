<template>
    <div class="cart-table-row-grid">
        <div class="checkbox-column text-center">
            <div class="custom-control custom-checkbox">
                <input 
                    type="checkbox" 
                    class="custom-control-input" 
                    :id="`cart-item-${item.id}`"
                    :checked="isChecked"
                    @change="toggleSelect(item.id)"
                >
                <label class="custom-control-label" :for="`cart-item-${item.id}`"></label>
            </div>
        </div>
        <div class="image-column text-center px-sm-4 px-2">
            <Imager 
                :src="item.product && item.product.asset ? 
                        item.product 
                        : 
                        item.product.parent_product && item.product.parent_product.asset ? 
                            item.product.parent_product
                            :
                            ''
                        "
                :extract="true"
                :alt="item.product.title"
            />
        </div>
        <div class="description-column">
            <div class="py-2">
                <div 
                    v-if="productBrand && productBrand.title" 
                    class="font-xsmall font-green clickable"
                    @click="redirectToBrand(productBrand)"
                >{{productBrand.title}}</div>

                <div 
                    class="font-small clickable"
                    @click="redirectToProduct(item.product)"
                >{{item.product.title}}</div>

                <div class="d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center">
                    <div 
                        v-if="item.product && item.product.variant_options"
                        class="d-flex align-items-center"
                    >
                        <span 
                            class="bg-gray-2 font-xsmall font-sf-medium p-1 px-2 rounded font-green mr-1"
                            v-for="(variant, index) in item.product.variant_options"
                            :key="index"
                        >
                            {{variant.option}}: {{variant.name}}
                            <!-- <i class="fas fa-caret-down ml-1 font-gray"></i> -->
                        </span>
                    </div>
                    <span
                        v-if="isOutOfStock"
                        class="bg-red-dark-transparent font-xsmall font-sf-medium p-1 px-2 rounded font-red"
                    >Out of stock</span>
                </div>
            </div>
        </div>
        <div class="price-column text-center">
            <ProductPrice
                :product="item.product"
                priceClass="font-sf-medium"
                originalPriceClass="font-gray-light"
            />
        </div>
        <div class="quantity-column text-center px-4">
            <div class="input-group quantity-input">
                <div class="input-group-prepend border">
                    <div 
                        class="input-group-text clickable"
                        @click="handleQuantityChange('subtract')"
                    ><i class="fas fa-minus font-green"></i></div>
                </div>
                <input 
                    type="text"
                    min="1"
                    class="form-control bg-white border border-right-0 text-center no-spinner px-0"
                    v-model="quantity"
                    @change="handleQuantityChange()"
                    pattern="\d*"
                    maxlength="4"
                >
                <div class="input-group-prepend border">
                    <div 
                        class="input-group-text clickable"
                        @click="handleQuantityChange('add')"
                    ><i class="fas fa-plus font-green"></i></div>
                </div>
            </div>
            <div 
                v-if="isOutOfStock" 
                class="font-xsmall font-sf-medium font-green text-center clickable mt-1 curso"
                @click="redirectToCategory(category)" 
            >Find Similar Product</div>
        </div>
        <div class="total-column text-center">
            <div class="font-sf-medium" >
                <Price
                    :price="total"
                />
            </div>
        </div>
        <div class="remove-column text-sm-right text-center">
            <button 
                class="btn btn-clear btn-sm font-xsmall font-gray"
                @click="removeItem(item)"
            >Remove</button>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapActions, mapGetters } from 'vuex';
    import * as types from '../../../store/mutation-types';

    export default {
        props: ['item', 'selectedItems', 'cartParams', 'items'],
        data() {
            return {
                quantity: this.item.quantity ? this.item.quantity : 0
            }
        },
        computed: {
            isChecked() {
                let {selectedItems, item} = this;

                let checked = false;

                if (selectedItems.length > 0) { 
                    checked = selectedItems.includes(item.id)
                }


                return checked;
            },
            total: {
                get() {
                    let total = 0,
                    { item } = this;

                    total += item.product.converted_selling_price;
                    
                    total *= this.quantity;

                    return Number(total).toFixed(2);
                },
                set(total) {
                    this.total = total;
                }
            },
            isOutOfStock() {
                let outOfStock = true;
                let { item } = this;

                if (item.product) {
                    
                    if (item.product.enable_inventory > 0) {
                        outOfStock = item.product.inventory > 0 ? false : true;
                    } else {
                        outOfStock = false;
                    }
                }

                return outOfStock;
            },
            category() {

                let {product} = this.item;
                let category = null;

                if (product.parent_product && product.parent_product.categories && product.parent_product.categories.length > 0 ) {
                    category = product.parent_product.categories[0].category;
                } else {
                    if (product.categories && product.categories.length > 0) {
                        category = product.categories[0].category;
                    }
                }

                return category;
            },
            productBrand() {
                let { product } = this.item;
                let { brand } = product;

                if (product.parent_product && product.parent_product.brand) {
                    brand = product.parent_product.brand;
                } 
                return brand;
            }
        },
        methods: {
            ...mapActions({
                syncCart: types.SYNC_CART
            }),
            handleQuantityChange(method = null) {

                let { item, quantity } = this;
                let { product } = item;

                quantity = parseInt(quantity);

                if (product.enable_inventory > 0 && product.inventory <= 0) return;

                if (method == 'add') {

                    if (product.enable_inventory > 0) {
                        if (quantity < product.inventory) {
                            quantity += 1;
                        }
                    } else {
                        quantity += 1;
                    }
                } else if (method == 'subtract') {
                    if (quantity > 1) {
                        quantity -= 1;
                    } else {
                        this.removeItem();
                    }
                } else {
                    if (quantity >= 1) {
                        if (product.enable_inventory > 0 && quantity > product.inventory) quantity = parseInt(product.inventory);
                    } else {
                        quantity = 1;
                        this.removeItem();
                    }
                }

                this.quantity = quantity;

                if (this.quantity > 0) {
                    this.$emit('change-quantity', item, quantity);
                }
            },
            toggleSelect(id) {
                this.$emit('toggle-select', id);
            },
            removeItem(item) {
                this.$emit('remove-item', item);
            },
            redirectToProduct(product) {

                let urlParams = {
                    name: 'products.view',
                    params: {
                        slug: product.slug
                    }
                };

                if (product.parent_product) {
                    urlParams.params.slug = product.parent_product.slug;
                    urlParams.query = {
                        variant: product.options_combination
                    }
                } 

                this.$router.push(urlParams);
            },
            redirectToBrand(brand) {

                let urlParams = {
                    name: 'products',
                    query: {
                        brands: [brand.slug]
                    }
                }
                this.$router.push(urlParams)
            },
            redirectToCategory(category) {

                if (!category) return;

                this.$router.push({
                    name: 'products',
                    query: {
                        category: category.slug
                    }
                })
            },
        }
    }
</script>