<template>
    <div class="card h-100">
        <div class="card-body pb-5">
            <div class="font-xxlarge font-sf-medium flex-fill mb-3">Payment Option</div>
            <!-- <div class="font-small font-red mb-3" v-if="paymentError" >{{paymentError}}</div> -->
            <div v-if="filteredMethods.length > 0" >
                <div 
                    v-for="(method, index) in filteredMethods"
                    :key="index"
                >
                    <div
                        :class="`p-2 btn btn-white bg-white rounded mb-2 d-block ${ selectedMethod == method.id ? 'active' : ''} ${method.slug == 'paymongo' && subtotal < 100 ? 'btn-disabled' : ''}`"
                        @click="setPaymentMethod(method.id)"
                    >
                        <div class="d-flex align-items-center">
                            <i :class="`${ selectedMethod == method.id ? 'fas fa-check-circle' : 'far fa-circle' } mr-2`"></i> 
                            <div class="font-gray text-left" >
                                <div v-if="method.slug == 'bdo' || method.slug == 'unionbank'" >
                                    {{ method.name + ' ' + (method.slug == 'bdo' ? 'Online Banking with 0.5% discount' : 'with 0.5% discount') }}
                                </div>
                                <div v-else-if="method.slug == 'paymongo'" >
                                    {{ method.name }} <span class="font-gray-light font-xxsmall" >(Checkout total must be <Price :price="100" :inline="true" /> and above)</span>
                                </div>
                                <div v-else >{{ method.name }}</div>
                            </div>
                        </div>
                        <div v-if="method.slug == 'paymongo' && selectedMethod == method.id" class="p-3 font-black text-left">
                            <div class="my-3" v-if="paymongoValues.errors" >
                                <div v-if="paymongoValues.errors.length > 0" >
                                    <div 
                                        v-for="(error, index) in paymongoValues.errors"
                                        :key="index"
                                        class="font-red font-xsmall"
                                    >{{error.detail}}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8 col-12">
                                    <div class="border-bottom mb-4"><div class="font-small font-green">Card Details</div></div>
                                    <div>
                                        <label class="mb-0 font-xsmall font-gray" >Card Number</label>
                                        <input
                                            required
                                            type="text"
                                            :class="`form-control rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black ${ cardFormErrors.card_number ? 'border-danger' : '' }`"
                                            v-model="cardForm.card_number"
                                        />
                                        <!-- <div v-if="cardFormErrors.card_number" class="font-red font-small" >{{ cardFormErrors.card_number }}</div> -->
                                    </div>
                                    <div>
                                        <label class="mb-0 mt-3 font-xsmall font-gray" >Expiration</label>
                                        <div class="row">
                                            <div class="col-md-6 col-12">
                                                <select
                                                    v-model="cardForm.expiration_month"
                                                    :class="`form-control rounded-0 font-xsmall ${cardFormErrors.expiration_month ? 'border-danger' : ''}`"
                                                >
                                                    <option value="" hidden >Month</option>
                                                    <option
                                                        v-for="n in 12"
                                                        :key="n"
                                                        :value="n"
                                                    >{{n}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <select 
                                                    v-model="cardForm.expiration_year"
                                                    :class="`form-control rounded-0 font-xsmall ${ cardFormErrors.expiration_year ? 'border-danger' : '' }`"
                                                >
                                                    <option value="" hidden>Year</option>
                                                    <option 
                                                        v-for="n in yearValues"
                                                        :key="n"
                                                        :value="n"
                                                    >{{n}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="">
                                        <label class="mb-0 mt-3 font-xsmall font-gray" >CVC</label>
                                        <input
                                            type="password"
                                            required
                                            :class="`form-control rounded-0 bg-gray-2 border-gray-2 font-xsmall font-black ${ cardFormErrors.cvc ? 'border-danger' : '' }`"
                                            v-model="cardForm.cvc"
                                        />
                                        <!-- <div v-if="cardFormErrors.cvc" class="font-red font-small" >{{ cardFormErrors.cvc }}</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="(method.slug == 'bdo' || method.slug == 'unionbank') && selectedMethod == method.id" class="p-3 font-black text-left" >
                            <div class="font-xsmall">
                                <p>Dear Customer<br/> {{ paymentText }} You may upload your deposit slip through <span v-if="orderContactEmail" > our email <span class="font-green">{{orderContactEmail}}</span> or upload it </span><span @click.stop="toggleDepositModal" class="font-green">here.</span></p>
                                <div v-if="method.bank" >
                                    <table class="table table-borderless table-sm">
                                        <tbody>
                                            <tr>
                                                <td class="w-25">Bank:</td>
                                                <td class="font-green">{{ method.bank.bank_name }}</td>
                                            </tr>
                                            <tr>
                                                <td class="w-25">Account Name:</td>
                                                <td class="font-green">{{ method.bank.account_name }}</td>
                                            </tr>
                                            <tr>
                                                <td class="w-25">Account Number:</td>
                                                <td class="font-green">{{ method.bank.account_number }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        props: [
            'paymentMethods',    
            'deliveryOption',
            'selectedMethod',
            'setPaymentMethod',
            'bankAccounts',
            'orderContactEmail',
            'toggleDepositModal',
            'cardForm',
            'cardFormErrors',
            'paymongoValues',
            'paymentText',
            'subtotal',
            'paymentError'
        ],
        data() {
            return {
                moment: moment
            }
        },
        computed: {
            filteredMethods() {

                let methods = this.paymentMethods;
                let option = this.deliveryOption;

                if (methods.length > 0) {
                    methods = methods.filter(method => {
                        if (option == 'delivery') {
                            return (method.slug != 'cop' && method.slug != 'credit-card-on-pickup');
                        } else {
                            return method.slug != 'cod';
                        }
                    })
                }

                if (methods.length > 0) {

                    methods.map(method => {

                        let bank = this.getBankDetails(method.name);

                        if (bank) {
                            method.bank = bank;
                        }
                    })
                }

                return methods;
            },
            yearValues() {
                let { moment } = this;
                let currentYear = moment().year();
                let endYear = moment().add(20, 'y').year();

                let years = [];

                for (let i = currentYear; i <= endYear; i++) {
                    years.push(i);
                }

                return years;
            }
        },
        methods: {
            getBankDetails(bankName) {

                let { bankAccounts } = this;

                return bankAccounts.find(bank => bank.bank_name == bankName);
            }
        } 
    }
</script>

<style scoped>
    .btn.btn-disabled {
        background-color: #e1e2e1 !important;
    }
    .btn.btn-disabled:hover {
        border-color: #e1e2e1 !important;
    }
</style>