<template>
    <div class="bbdoit-comments bg-gray-2 p-4">
        <div class="bbdoit-comment-form">
            <div class="d-flex align-items-center justify-content-between mb-3">
                <div class="flex-grow-1 d-flex font-small font-sf-medium">
                    <div class="mr-3" v-if="likes > 0" ><i class="fas fa-thumbs-up font-gray mr-1"></i> {{ likes }} like{{ likes > 1 ? 's' : '' }}</div>
                    <div><i class="fas fa-comment font-gray mr-1"></i> {{ article.comments_count }} comments</div>
                </div>
                <button 
                    class="btn-sm btn-green rounded-lg font-small px-3"
                    v-if="isLoggedIn"
                    @click="like"
                >
                    <i class="fas fa-thumbs-up mr-1"></i> {{ isLiked ? 'Liked' : 'Like' }}
                </button>
            </div>
            <div v-if="isLoggedIn" class="bg-white pl-3 pl-md-4">
                <div class="border-bottom border-gray-2 d-flex pt-3">
                    <div class="mr-3 d-none d-md-block" >
                        <Avatar 
                            className="" 
                            :user="user"
                            :color="formValues.avatar_color"
                        />
                        <input type="hidden" name="avatar_color" v-model="formValues.avatar_color" >
                    </div>
                    <div class="flex-grow-1 mb-3">
                        <div v-if="parentObject != null" class="font-xsmall font-gray d-flex align-items-center" >
                            <i
                                class="fas fa-times clickable mr-2"
                                @click="removeReply"
                            ></i>
                            <span>Replying to {{ parentObject.user.customer.fullname }}... </span>
                        </div>
                        <textarea
                            class="border-0 font-sf-medium font-sm font-gray w-100"
                            placeholder="Write a comment...."
                            rows="3"
                            v-model="formValues.comment"
                            maxlength="240"
                        ></textarea>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div class="text-right font-small font-gray flex-grow-1 mr-2">{{formValues.comment.length}}/240 characters</div>
                    <button 
                        class="btn btn-sm btn-green rounded-0 px-4"
                        @click="submitComment"
                    >SEND</button>
                </div>
            </div>
        </div>
        <div v-if="article.comments && article.comments.length > 0" class="bbdoit-comment-list py-3">
            <div
                v-for="comment in article.comments"
                :key="`comment-${comment.id}`"
            >
                <CommentItem 
                    v-if="comment.parent == 0"
                    :comment="comment"
                    @reply="replyTo(comment)"
                />
            </div>
        </div>
        <div class="mt-5 text-center font-small font-gray font-sf-medium">There are no {{ article.comments && article.comments.length > 0 ? 'more' : '' }} comments to show</div>
    </div>
</template>

<script>
import CommentItem from './commentItem';
import { Avatar } from '@components/common/';
import { connect } from '@api';
import { showAlert } from '@helpers/notification.helper';

let swalSettings = {
    title: 'Congratulations!',
    text: 'The comment is being reviewed, please come back later.'
};

export default {
    components: {
        CommentItem,
        Avatar
    },
    props: ['article', 'isLoggedIn', 'isLiked', 'likes', 'user'],
    computed: {
        randomColor() {
            let colors = [
                '#29673E',
                '#C3BE02',
                '#579569',
                '#143C17',
                '#1C5C21'
            ];
            
            let color = Math.floor(Math.random() * colors.length);
            return colors[color];
        }
    },
    mounted() {
        this.formValues.avatar_color = this.randomColor;
    },
    data() {
        return {
            defaultValues: {
                comment: '',
                parent: 0,
                avatar_color: '',
                article_id: this.article.id
            },
            formValues: {
                comment: '',
                parent: 0,
                avatar_color: '',
                article_id: this.article.id
            },
            formErrors: {
                comment: '',
                parent: '',
                avatar_color: ''
            },
            parentObject: null
        }
    },
    methods: {
        like() {
            this.$emit('like');
        },
        submitComment() {

            if (this.formValues.comment == '') {
                this.formErrors.comment = 'Please enter your comment';
                return;
            }

            connect().post('/articles/comment', this.formValues)
                .then(res => {
                    showAlert(swalSettings);

                    this.formValues = {...this.defaultValues};
                    this.parentObject = null;
                })
                .catch(err => {
                    console.log(err);
                    return
                })

        },
        replyTo(parent) {
            this.parentObject = parent;
            this.formValues.parent = parent.id;
            console.log('replying');
        },
        removeReply() {
            this.parentObject = null;
            this.formValues.parent = 0;
        }
    }
}
</script>