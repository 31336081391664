<script>
import ButtonComponent from './button';
import TextInput from './textInput';
import TextAreaComponent from './textArea';

export const Button = ButtonComponent;
export const Input = TextInput;
export const TextArea = TextAreaComponent;

export default {
    setup() {
        
    },
}
</script>