<template>
    <div
        class="card order-card clickable mb-4"
        v-if="order"
        @click="$router.push({ name: 'orders.view', params: { reference_number: order.reference_number, status: status } })"
    >
        <div class="card-body">
            <div class="order-card-header d-flex flex-wrap justify-content-between flex-wrap font-sf-medium font-small">
                <span>#{{ order.reference_number }}</span>
                <span class="font-green">{{ orderStatus }}</span>
            </div>
            <div
                v-if="items && items.length > 0" 
                class="products-list border-top border-bottom border-gray-2 row align-items-center my-3 py-4 mx-0"
            >
                <div 
                    class="order-product col-3 col-md-1" 
                    v-for="(orderItem, i) in items"
                    :key="i"
                >
                    <Imager
                        :src="orderItem.product.image_paths"
                        :extract="true"
                        :alt="orderItem.product.title"
                    />
                </div>
                <span
                    class="font-gray font-small font-sf-medium col"
                    v-if="order.items && order.items.length > 4"
                >+ {{ order.items.length - 4 }} More</span>
            </div>
            <div class="order-card-header d-flex flex-wrap justify-content-between flex-wrap font-sf-medium font-small">
                <div class="d-flex align-items-center flex-wrap justify-content-around flex-grow-1 flex-grow-md-0">
                    <div class="mr-auto text-left">
                        <span class="font-gray mr-4">Total Payment</span>
                        <span>
                            {{ order.converted_overall_total }}
                        </span>
                    </div>
                    <div class="mr-auto mx-md-auto" >
                        <span class="font-gray mr-4">Payment Method</span>
                        <span>{{ order.payment_type && order.payment_type.name }}</span>
                    </div>
                </div>
                <div v-if="order.payment_status.name != 'paid' & (order.payment_type.slug != 'cod' && order.payment_type.slug != 'cop' && order.payment_type.slug != 'paymongo')" class="flex-fill text-left text-md-right">
                    <button 
                        class="btn btn-sm btn-clear border-green rounded-pill font-green mt-3 mt-md-0"
                        @click.stop="toggleDepositModal(order)"
                    >{{ order.deposit_data ? 'Update' : 'Upload' }} Deposit Slip</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'order',
            'status',
            'toggleDepositModal',
            'handleDepositModalFileChange',
            'handleDepositModalSubmit',
            'handleRemoveUploadedFile'
        ],
        computed: {
            orderStatus() {
                let orderStatus = '';
                let { status, order } = this;

                if (status == 'pending') orderStatus = 'Order Placed';
                if (status == 'processing') orderStatus = 'Preparing your Order';
                if (status == 'delivery') orderStatus = 'For Delivery';
                if (status == 'completed') orderStatus = order.delivery_option == 'delivery' ? 'Delivered' : 'Received';

                return orderStatus;
            },
            items() {
                let { items } = this.order;
                let orderItems = [];

                items.map((item, index) => {
                    if (index < 4) {
                        orderItems.push(item);
                    }
                })

                return orderItems;
            }
        }
    }
</script>