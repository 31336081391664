<template>
	<div class="about-page" :style="{ overflowX: 'hidden' }" >
		<div v-if="isLoading" >
            <div class="container my-5" >
                <div class="mb-5">
                    <div class="skeleton-loader header w-50 my-5"></div>
                </div>
                <div class="mb-5">
                    <div class="skeleton-loader mb-4 text w-75"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text w-75"></div>
                </div>
                <div class="mb-5">
                    <div class="skeleton-loader mb-4 text w-75"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text w-75"></div>
                </div>
                <div class="mb-5">
                    <div class="skeleton-loader mb-4 text w-75"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text"></div>
                    <div class="skeleton-loader mb-4 text w-75"></div>
                </div>
            </div>
        </div>
		<div v-else >
			<div v-if="page && page.content" >
				<div
					class="banner bg-mint-green border-gray-1 d-flex align-items-center justify-content-center font-giantsize font-sf-bold"
					v-if="page.content && page.content.title"
					v-html="page.content.title"
				></div>
				<div class="container my-5">
					<div class="row row-wide pt-5">
						<div 
							class="col-md-12 col-lg-6" 
							v-if="page.content && page.content.content"
							v-html="page.content.content" 
						></div>
						<div class="col-md-12 col-lg-6">
							<div 
								class="image-container d-flex align-items-center justify-content-center"
								v-if="page.content && page.content.image"
							>
								<Imager 
									:src="page.content.image" 
									:extract="true"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="banner bg-transparent-green d-flex align-items-center justify-content-center px-3 px-md-0">
					<div class="text-center">
						<h4 class="font-giantsize font-sf-bold">
							Your newest online grocery shopping mart
						</h4>
						<router-link 
							:to="{ name: 'products' }"
							class="btn btn-green px-5 btn-radius-3 mt-3 py-2"
							>Start Shopping</router-link
						>
					</div>
				</div>
			</div>
			<NotFound v-else />
		</div>
	</div>
</template>

<script>
import { Imager } from '@components/common';
import { getPage } from '@helpers/caboodle.helper';
import { mapGetters } from 'vuex';
import * as types from '../../store/mutation-types';
import NotFound from '@views/errors/404';

export default {
	components: {
		Imager,
		NotFound
	},
	data() {
		return {
			page: null,
			isLoading: true,
			seo: {
				title: '',
				description: ''
			}
		}
	},
	mounted() {
		this.page = null;
		this.getPageData('about');
	},
	methods: {
		...mapGetters({
			getSEO: types.GET_SEO
		}),
		getPageData(slug) {

			this.isLoading = true;

			getPage(slug)
				.then(res => {
					if (res.status == 200 && res.data) {
						this.page = res.data.data
					} else {
						this.page = null
					}

					this.isLoading = false;
				})
				.catch(err => {
					this.page = null;
					this.isLoading = false;
				})
		}
	}
};
</script>

<style></style>
