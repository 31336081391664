import account from '@views/account';
import accountProfile from '@views/account/profileForm';
import accountAddress from '@views/account/address';
import accountBankAccounts from '@views/account/banks';
import accountPassword from '@views/account/passwordForm';
import accountNumber from '@views/account/phoneNumberForm';
import accountForgotPassword from '@views/account/forgotPassword';
import accountBankForm from '@views/account/bankForm';
import accountCardForm from '@views/account/cardForm';
import addressForm from '@views/account/addressForm';

export default {
    path: '/account',
    name: 'account',
    component: account,
    meta: {
        requiresAuth: true
    },
    children: [
        {
            path: 'profile',
            name: 'account.profile',
            component: accountProfile,
            props: true,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: 'address',
            name: 'account.address',
            component: accountAddress,
            props: true,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: 'address/:method',
            name: 'account.address.form',
            component: addressForm,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: 'banks',
            name: 'account.accounts',
            component: accountBankAccounts,
            props: true,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: 'change-password',
            name: 'account.password',
            component: accountPassword,
            props: true,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: 'change-number',
            name: 'account.number',
            component: accountNumber,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: 'forgot-password',
            name: 'account.forgot.password',
            component: accountForgotPassword,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: 'bank/:method',
            name: 'account.bank',
            component: accountBankForm,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: 'card/:method',
            name: 'account.card',
            component: accountCardForm,
            meta: {
                requiresAuth: true
            }
        }
    ]
}