<template>
    <div :class="`${ inline ? 'd-inline' : '' }`" >
        <span :class="[priceClass]" > {{ currency }} {{ formatPrice(price) }}</span>
        <del v-if="parseFloat(originalPrice) > 0 && originalPrice != price" :class="['ml-2 font-gray',originalPriceClass]">{{ currency }} {{ formatPrice(originalPrice) }}</del>
    </div>
</template>

<script>
export default {
    props: [ "price", "originalPrice", "priceClass", "originalPriceClass", "inline" ],
    computed: {
        currency() {
            let currency = '₱';

            if (this.$store && this.$store.getters && this.$store.getters.SITE_OPTIONS && this.$store.getters.SITE_OPTIONS.currency) {
                currency = this.$store.getters.SITE_OPTIONS.currency
            }

            return currency;
        }
    },
    methods: {
        formatPrice(price) {
            return Number(price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
    }
}
</script>