import * as types from '../mutation-types';
import { connect } from '../../helpers/api.helper';

const defaultState = {
	registered: false
};

const state = defaultState;

// used to get data from state
const getters = {
	[types.SITE_OPTIONS] (state) { return state.settings },
	[types.GET_USER_AGE] (state) { return state.age },
	[types.GET_CHECKOUT_TEXT] (state) { return state.settings.checkout_texts },
	[types.GET_STORE_LOCATION] (state) { return state.settings.store_location },
	[types.GET_SEO] (state) { return state.settings.seo },
	[types.GET_FB] (state) { return state.FB },
	[types.GET_REGISTERED] (state) { return state.registered }
};

// same as react actions
const actions = {
	async [types.GET_SITE_OPTIONS] ({ commit }, slug)  {
		return await connect().post(`get_site_option`, { slug });
	},
    async [types.GET_CURRENCY] ({ commit }) {
		const response = await connect().get(`get-currency`);
		// console.log(response);
		commit(types.UPDATE_SITE_OPTIONS, { data: { currency: response.data.data } });
	},
	async [types.GET_SITE_SEO] ({commit}, url) {
		const response = await connect().get(`get-site-seo?url=${url}`);

		commit(types.UPDATE_SITE_OPTIONS, { data: { seo: response.data.data } });
	},
	async [types.FETCH_STORE_LOCATION] ({ commit }) {

		const response = await connect().get('get-store-location');

		commit(types.UPDATE_SITE_OPTIONS, { data: { store_location: response.data.data } });
	},
	async [types.GET_CHECKOUT_TEXTS] ({commit}) {
		const response = await connect().get('get-checkout-texts');

		commit(types.UPDATE_SITE_OPTIONS, { data: { checkout_texts: response.data.data } })
	},
	[types.CALCULATE_USER_AGE] ({commit}, params) {

		let today = new Date();
		let birthday = new Date(params.customer.birthday);

		let age = today.getFullYear() - birthday.getFullYear();
		let m = today.getMonth() - birthday.getMonth();
		let d = today.getDate() - birthday.getDate();

		if (m == 0 && d < 0) {
			age--;
		} else if (m < 0) {
			age--;
		}

		commit(types.SET_USER_AGE, { data: age });
	},

	[types.FB_INIT] ({commit}, params) {
		commit(types.SET_FB, { data: params.FB });
	},
};

// same as react reducer
const mutations = {
	[types.UPDATE_SITE_OPTIONS] (state, payload) { state.settings = { ...state.settings, ...payload.data }},

	[types.SET_USER_AGE] (state, payload) { state.age = payload.data },

	[types.SET_FB] ( state, payload ) { state.FB = payload.data },

	[types.SET_REGISTERED] ( state, payload ) { state.registered = payload.data }
};

export default {
	state,
	getters,
	actions,
	mutations,
};
