<template>
    <div class="card account-sidebar h-100">
        <div class="card-body d-flex flex-column">
            <div class="font-xxlarge font-sf-medium">Hello, {{user.name}}!</div>
            <div class="pt-2 pb-3 border-bottom">
                <Verified
                    v-if="user && user.verified && user.verified_phone"
                />
            </div>
            <div class="py-2 font-sf-medium font-small d-flex flex-column font-gray account-sidebar-nav flex-grow-1">
                <router-link :to="{ name: 'account.profile' }" class="my-2 account-sidebar-nav-item" >My Profile</router-link>
                <router-link :to="{ name: 'account.address' }" class="my-2 account-sidebar-nav-item" >My Address</router-link>
                <!-- <router-link :to="{ name: 'account.accounts' }" class="my-2 account-sidebar-nav-item" >My Bank & Cards</router-link> -->
                <router-link :to="{ name: 'account.password' }" class="my-2 account-sidebar-nav-item" >Change Password</router-link>
                <div 
                    class="clickable mt-auto account-sidebar-nav-item" 
                    @click="$emit('logout')"
                >Sign Out</div>
            </div>
        </div>
    </div>
</template>

<script>
import { Verified } from '@components/common';

export default {
    components: {
        Verified
    },
    props: {
        current: Object,
        user: Object
    }
}
</script>