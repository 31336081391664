<template>
    <div class="login-page bg-gray-2 d-flex justify-content-center py-5">
        <div class="bg-white p-4 login-card rounded my-5">
			<h2 class="text-center font-sf-bold font-xlarge py-3">Forgot Password</h2>
            <div class="form-group mt-5">
                <input 
                    type="email" 
                    class="email form-control font-xsmall"
                    v-model="email"
                    placeholder="Enter email address"
                    @keyup.enter="handleSubmit"
                >
                <div class="font-small font-red" v-if="emailError" >{{ emailError }}</div>
            </div>
            <button 
				@click="handleSubmit"
				:class="`btn btn-green btn-block btn-radius-3 mt-2 mb-5 mt-3 py-2`"
			>{{ processing ? 'Processing...' : 'Submit' }}</button>
            <div class="font-xsmall text-center">
                Remembered your password?
				<router-link :to="{ name: 'login' }" class="font-green">Login</router-link>
			</div>
        </div>
    </div>
</template>

<script>
    import { connect } from '@api';
    import { showAlert } from '@helpers/notification.helper';
    import { isLoggedIn } from '@helpers/session.helper';

    const swalSettings = {
        title: 'Congratulations!',
        text: 'Password reset link sent to your email',
        customClass: {
            actions: 'px-3',
        }
    }

    export default {
        data() {
            return {
                email: '',
                emailError: '',
                processing: false
            }
        },
        methods: {
            handleSubmit() {
                this.processing = true;
                this.emailError = '';

                if (this.email == '') {
                    this.emailError = 'Please enter your email address';
                    this.processing = false;
                    return;
                }

                connect().get(`auth/request-reset-password?email=${ this.email }`, {})
                    .then(res => {
                        if (res.status == 200) {
                            showAlert(swalSettings)
                                .then(swal => {
                                    this.$router.push({ name: isLoggedIn() ? 'home' : 'login' });
                                })
                        }
                    })
                    .catch(err => {
                        this.processing = false;
                        if (err.data && err.data.message) {
                            this.emailError = err.data.message;
                            console.error(err.data.message)
                        } 
                    })
            }
        }
    }
</script>