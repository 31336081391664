<template>
    <div class="row row-thin mb-5 mt-3">
        <div
            v-for="(filter, index) in filters"
            :key="`mainfilter${ index }`"
            class="col-lg-4 col-12 mb-2"
        >
            <button
                :class="`btn btn-lg btn-block d-flex align-items-center justify-content-center btn-radius-3 px-3 py-2 btn-${ filter.color }`"
                @click="setFilter(filter)"
            >
                <img class="mr-2" :src="require(`@assets/img/${ filter.image }`)" alt="" />
                {{ filter.label }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: [ "setFilter" ],
    data() {
        return {
            filters: [
                {
                    label: "Hot Deals and Promo",
                    image: "hot.png",
                    value: "hot-deals:hot:hot deals",
                    color: "red"
                },
                {
                    label: "Recommended Goods",
                    image: "like.png",
                    value: "recommended",
                    color: "orange"
                },
                {
                    label: "Trending Products",
                    image: "trending.png",
                    value: "trending",
                    color: "blue"
                }
            ]
        }
    },
    setup() {
        
    },
}
</script>