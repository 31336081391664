<template>
    <div class="login-page bg-gray-2 d-flex justify-content-center py-5">
        <div class="bg-white p-4 login-card rounded my-5">
			<h2 class="text-center font-sf-bold font-xlarge py-3">Reset Password</h2>
            <div class="form-group mt-5">
                <div class="input-group">
                    <input 
                        :type="`${ !passwordToggled ? 'password' : 'text' }`" 
                        class="form-control font-xsmall border-right-0"
                        v-model="password"
                        placeholder="Enter new password"
                    >
                    <div 
                        class="input-group-append clickable"
                        @click="() => { passwordToggled = !passwordToggled }"
                    >
                        <span class="input-group-text">
                            <i :class="`fas ${ !passwordToggled ? 'fa-eye' : 'fa-eye-slash' } `"></i>
                        </span>
                    </div>
                </div>
                <div class="font-small font-red" v-if="passwordError" >{{ passwordError }}</div>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <input 
                        :type="`${ !confirmPasswordToggled ? 'password' : 'text' }`" 
                        class="form-control font-xsmall border-right-0"
                        v-model="confirm_password"
                        placeholder="Confirm new password"
                    >
                    <div 
                        class="input-group-append clickable"
                        @click="() => { confirmPasswordToggled = !confirmPasswordToggled }"
                    >
                        <span class="input-group-text">
                            <i :class="`fas ${ !confirmPasswordToggled ? 'fa-eye' : 'fa-eye-slash' } `"></i>
                        </span>
                    </div>
                </div>
                <div class="font-small font-red" v-if="confirmPasswordError" >{{ confirmPasswordError }}</div>
            </div>
            <button 
				@click="handleSubmit"
				:class="`btn btn-green btn-block btn-radius-3 mt-2 my-5 py-2`"
			>{{ processing ? 'Processing...' : 'Submit' }}</button>
        </div>
    </div>
</template>

<script>
    import { connect } from '@api';
    import { showAlert } from '@helpers/notification.helper';
    import { isLoggedIn } from '@helpers/session.helper';
    import { mapActions } from 'vuex';
    import * as types from '../../store/mutation-types';

    const swalSettings = {
        title: 'Congratulations!',
        text: 'Your password has been successfully updated',
        customClass: {
            actions: 'px-3',
            confirmButton: 'btn-block'
        }
    }

    export default {
        data() {
            return {
                password: '',
                passwordError: '',
                passwordToggled: false,

                confirm_password: '',
                confirmPasswordError: '',
                confirmPasswordToggled: false,

                token: '',
                processing: false,
            }
        },
        mounted() {
            if (this.$route.params && this.$route.params.token) {
                this.token = this.$route.params.token;
            }
        },
        methods: {
            ...mapActions({
                logout: types.LOGOUT
            }),
            handleSubmit() {
                this.passwordError = '';
                this.confirmPasswordError = '';
                this.processing = true;
                let hasError = false;

                if (this.password == '') {
                    this.passwordError = 'Please enter your new password';
                    hasError = true;
                }
                if (this.password.length < 8) {
                    this.passwordError = 'New password must contain at least 8 characters';
                    hasError = true;
                }
                if (this.confirm_password == '') {
                    this.confirmPasswordError = 'Please confirm your new password';
                    hasError = true;
                }
                if (this.password != this.confirm_password) {
                    this.confirmPasswordError = 'Passwords do not match';
                    hasError = true;
                }
                if (hasError) {
                    this.processing = false;
                    return;
                }

                let params = {
                    password: this.password,
                    token: this.token
                }

                connect().post('auth/reset-password', params)
                    .then(res => {
                        if (res.status == 200) {
                            this.processing = false;
                            showAlert(swalSettings)
                                .then(swal => {

                                    if (isLoggedIn()) {
                                        this.logout();
                                    }
                                    this.$router.push({
                                        name: 'login'
                                    })
                                })
                        }
                    })
                    .catch(err => {
                        this.processing = false;
                        if (err.data && err.data.message) {
                            console.error(err.data.message);
                            this.confirmPasswordError = err.data.message;
                        }
                    })
            }
        }
    }
</script>