import { connect } from '../../helpers/api.helper';
import * as cartHelper from '../../helpers/cart.helper';
import * as types from '../mutation-types';

const initialState = {
    cart: {},
    showCartNotif: false,
    cartNotifItems: []
}

const state = initialState;

// used to get state
const getters = {

    [types.GET_SHOW_ADD_TO_CART_NOTIF]: (state) => state.showCartNotif,

    [types.GET_ADD_TO_CART_NOTIF_ITEMS]: (state) => state.cartNotifItems,

    [types.GET_CART]: (state) => state.cart

}

// same as react actions
const actions = {
    
    [types.SYNC_CART]: ({commit, dispatch}, params) => {
        return (
            connect().post('orders/sync-cart', params)
                .then(res => {
                    commit(types.UPDATE_CART, {...res.data});
                })
                .catch(err => {
                    console.error(err);
                })
        )
    },

    [types.PROCEED_TO_CHECKOUT]: ({commit, dispatch}, params) => {
        return connect().post('orders/checkout', params);
    },
}

// same as react reducer
const mutations = {

    [types.UPDATE_CART]: (state, payload) => {
        cartHelper.setCart(payload.data);
        state.cart = { ...state.cart, ...payload.data };
    },

    [types.TOGGLE_ADD_TO_CART_NOTIF]: (state, payload) => {
        if (payload) {
            state.showCartNotif = payload;
        } else {
            state.showCartNotif = !state.showCartNotif;
        }
    },

    [types.SET_ADD_TO_CART_NOTIF_ITEMS]: (state, payload) => {
        state.cartNotifItems = payload;
    },

    [types.CLEAR_CART]: (state, payload) => {
        state.cart = {};
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}