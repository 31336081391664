<template>
    <span :class="['font-xsmall font-blue-dark font-sf-regular', className]"><i class="fas fa-check-circle"></i> Verified</span>
</template>

<script>
export default {
    name: 'VerifiedTag',
    props: {
        className: String
    }
}
</script>