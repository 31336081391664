<template>
    <div class="bank-accounts-page">
        <div class="mb-4">
            <div class="d-flex align-items-center justify-content-between">
                <div class="font-giantsize font-sf-bold my-3">My Bank Accounts</div>
                <router-link 
                    :to="{ name: 'account.bank', params: { method: 'create' } }" 
                    class="btn btn-sm py-1 btn-clear border-gray-1 rounded-pill"
                >
                    <i class="fas fa-plus font-green"></i> 
                    Add new bank account
                </router-link>
            </div>
            <div class="table-container table-responsive flex-grow-1">
                <table class="table font-xsmall address-table border-right border-left">
                    <thead class="bg-gray-2 font-sf-medium font-gray">
                        <tr>
                            <th>Full Name</th>
                            <th>Bank Account</th>
                            <th>Bank Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="banks && banks.length > 0" class="font-black" >
                        <tr class="border-bottom" v-for="bank in banks" :key="bank.id" >
                            <td>{{ bank.account_name }}</td>
                            <td>{{ maskNumber(bank.account_number) }}</td>
                            <td>{{ bank.bank_name }}</td>
                            <td>
                                <div class="d-flex align-items-center justify-content-flex-end dropdown">
                                    <button 
                                        :class="['btn-default btn-clear ml-auto mr-3', bank.id == default_bank ? 'active' : '']" 
                                        :disabled="default_bank == bank.id"
                                        @click="setDefault('bank', bank.id)"
                                    >
                                        <span>{{ default_bank == bank.id ? 'Default' : 'Set as Default' }}</span>
                                    </button>
                                    <SettingsPopup 
                                        :key="bank.id" 
                                        type="account" 
                                        :id="bank.id"
                                        @delete-item="deleteBankAccount(bank.id)"
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody class="font-black" v-else >
                        <tr class="border-bottom" >
                            <td colspan="4" >
                                <div class="text-center py-5 font-sf-medium">
                                    No bank accounts found
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mb-4">
            <div class="d-flex align-items-center justify-content-between">
                <div class="font-giantsize font-sf-bold my-3">My Cards</div>
                <router-link 
                    :to="{ name: 'account.card', params: { method: 'create' } }" 
                    class="btn btn-sm py-1 btn-clear border-gray-1 rounded-pill"
                >
                    <i class="fas fa-plus font-green"></i> 
                    Add new card
                </router-link>
            </div>
            <div class="table-container table-responsive flex-grow-1">
                <table class="table font-xsmall address-table border-right border-left">
                    <thead class="bg-gray-2 font-sf-medium font-gray">
                        <tr>
                            <th>Full Name</th>
                            <th>Card Number</th>
                            <th>MM/YY</th>
                            <th class="text-center" >CVC</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="cards && cards.length > 0" class="font-black" >
                        <tr class="border-bottom" v-for="card in cards" :key="card.id" >
                            <td>{{card.account_name}}</td>
                            <td>{{maskNumber(card.card_number)}}</td>
                            <td>{{card.expiration}}</td>
                            <td class="text-center" >
                                <i :class="[ card.cvc ? 'fas fa-check-circle font-blue-dark' : 'far fa-circle font-gray']"></i>
                            </td>
                            <td>
                                <div class="d-flex align-items-center justify-content-flex-end dropdown">
                                    <button 
                                        :class="['btn-default btn-clear ml-auto mr-3', card.id == default_card ? 'active' : '']" 
                                        :disabled="default_card == card.id"
                                        @click="setDefault('card', card.id)"
                                    >
                                        <span>{{ default_card == card.id ? 'Default' : 'Set as Default' }}</span>
                                    </button>
                                    <SettingsPopup 
                                        :key="card.id" 
                                        :id="card.id"
                                        @delete-item="deleteCard(card.id)" 
                                        type="card" 
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else class="font-black">
                        <tr class="border-bottom" >
                            <td colspan="5" >
                                <div class="text-center py-5 font-sf-medium">
                                    No cards found
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import SettingsPopup from './settingsPopup';
import { showAlert } from '@helpers/notification.helper';
import { connect } from '@api';
import * as types from '../../store/mutation-types';
import { mapMutations } from 'vuex';

export default {
    props: {
        user: Object
    },
    components: {
        SettingsPopup
    },
    computed: {
        banks() {
            return this.user.customer.banks;
        },
        cards() {
            return this.user.customer.cards;
        },
        default_card() {
            return this.user.customer.customer_card_id;
        },
        default_bank() {
            return this.user.customer.customer_bank_id;
        }
    },
    mounted() {
        console.log(this.default_card);
    },
    methods: {
        ...mapMutations({
            updateUser: types.UPDATE_USER
        }),
        maskNumber(str) {
            return str.replace(/\d(?=(?:\D*\d){4})/g, '*');
        },
        togglePopup(e) {
            this.$emit('toggle-settings-popup', e);
        },
        setDefault(type, id) {

            let endpoint = type == 'card' ? 'card' : 'bank';

            connect().post(`/customers/set-default-${endpoint}`, { id: id })
                .then(res => {
                    this.updateUser(res.data.user);
                })
                .catch(err => {
                    console.log(err);
                })
        },
        deleteBankAccount(id) {
            
            let infoSwalSettings = {
                title: 'Delete account',
                text: 'Are you sure you want to delete this account',
                confirmButtonText: 'Continue',
                customClass: {
                    actions: 'd-flex flex-wrap px-4',
                    confirmButton: 'flex-fill',
                    cancelButton: 'flex-fill'
                }
            };

            showAlert(infoSwalSettings, 'confirm')
                .then(alert => {
                    if (alert.isConfirmed) {

                        let deleteSwalSettings = {
                            title: 'Congratulations!',
                            text: 'You successfully deleted this account'
                        };

                        connect().post('/customers/delete-bank', {id: id})
                            .then(res => {
                                this.updateUser(res.data.user);

                                showAlert(deleteSwalSettings);
                            })
                            .catch(err => {
                                console.log(err);
                            })
                    }
                })

        },
        deleteCard(id) {
            let infoSwalSettings = {
                title: 'Delete card',
                text: 'Are you sure you want to delete this card',
                confirmButtonText: 'Continue',
                customClass: {
                    actions: 'd-flex flex-wrap px-4',
                    confirmButton: 'flex-fill',
                    cancelButton: 'flex-fill'
                }
            };

            showAlert(infoSwalSettings, 'confirm')
                .then(alert => {
                    if (alert.isConfirmed) {

                        let deleteSwalSettings = {
                            title: 'Congratulations!',
                            text: 'You successfully deleted this card'
                        };

                        connect().post('/customers/delete-card', {id: id})
                            .then(res => {
                                this.updateUser(res.data.user);

                                showAlert(deleteSwalSettings);
                            })
                            .catch(err => {
                                console.log(err);
                            })
                    }
                })
        }
    }
}
</script>