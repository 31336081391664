import Vue from 'vue';

export function showAlert(settings = {}, type='success') {

    let swalSettings = {};

    if (type == 'success') {
        swalSettings.iconHtml = '<div class="fa-stack fa-xs"><i class="fas fa-certificate font-yellow fa-stack-2x" ></i><i class="fas fa-check font-black fa-stack-1x" ></i></div>';
        swalSettings.confirmButtonText = 'Done';
        swalSettings.customClass = {
            confirmButton: 'btn-block'
        }
    }
    if (type == 'confirm') {
        swalSettings.iconHtml = '<div class="fa-stack fa-xs"><i class="fas fa-circle font-yellow fa-stack-2x" ></i><i class="fas fa-question font-black fa-stack-1x" ></i></div>';
        swalSettings.showCancelButton = true;
    }
    if (type == 'error') {
        swalSettings.iconHtml = '<div class="fa-stack fa-xs"><i class="fas fa-circle font-yellow fa-stack-2x" ></i><i class="fas fa-times font-black fa-stack-1x" ></i></div>';
    }

    swalSettings = {...swalSettings, ...settings}

    return Vue.swal(swalSettings);
}