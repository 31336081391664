<template>
    <div class="flex-grow-1 d-flex align-items-center justify-content-center p-4 p-md-0">
        <div id="contactUsFormWrapper">
            <h1 class="font-giantsize font-sf-bold mb-30">
                Contact Us
            </h1>
            <Input
                label="Full name"
                placeholder="Full name"
                container-class="form-group font-xsmall"
                input-class="form-control font-xsmall"
                v-model="form.full_name"
                :error="errors.full_name"
                :disabled="isLoading"
            />
            <Input
                label="Email address"
                placeholder="Email address"
                container-class="form-group font-xsmall"
                input-class="form-control font-xsmall"
                v-model="form.email"
                :error="errors.email"
                type="text"
                :disabled="isLoading"
            />
            <TextArea
                label="Message"
                placeholder="Write your message here..."
                container-class="form-group font-xsmall"
                input-class="form-control font-xsmall"
                v-model="form.message"
                :error="errors.message"
                :disabled="isLoading"
            />
            <div v-if="requestFailed" class="case text-danger case-error mb-10">
                <i  class="fas fa-exclamation-circle" />
                <div class="ml-20">
                    It seems we cannot process your request at the moment. Please try again later.
                </div>
            </div>
            <div class="d-flex">
                <Button
                    button-class="flex-grow-1"
                    :is-loading="isLoading"
                    :onClick="onSubmit"
                >
                    <slot>
                        Send Message
                    </slot>
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import { Button, Input, TextArea } from '@components/form';
import * as api from '@api';
import validate from '@helpers/validation.helper';
import { showAlert } from '@helpers/notification.helper';

const formRules = {
    properties: {
        full_name: {
            type: 'string',
            required: true,
            allowEmpty: false,
            messages: {
                allowEmpty: 'Please enter your full name',
                required: 'Please enter your full name',
            }
        },
        email: {
            type: 'string',
            format: "email",
            required: true,
            allowEmpty: false,
            messages: {
                allowEmpty: 'Please enter your email address',
                required: 'Please enter your email address',
            }
        },
        message: {
            type: 'string',
            required: true,
            allowEmpty: false,
            messages: {
                allowEmpty: 'Please enter your message',
                required: 'Please enter your message',
            }
        },
    }    
}

let swalSettings = {
    html: "Thank you for your feed back.<br/>We will get back to you as soon as we can.",
    confirmButtonText: 'Ok'
};

export default {
    components: {
        Button,
        Input,
        TextArea
    },
    data() {
        return {
            form: {
                full_name: "",
                email: "",
                message: ""
            },
            errors: {},
            requestSuccess: false,
            requestFailed: false,
            isLoading: false
        }
    },
    watch: {
        form: {
            handler() {
                if (this.requestFailed) this.requestFailed = false
            },
            deep: true
        }
    },
    methods: {
        onSubmit: function () {
            const validation = validate(this.form, formRules)
            
            this.errors = { ...validation.errors }
            if ( validation.valid ) {
                this.isLoading = !this.isLoading

                api
                    .connect()
                    .post("inquiry/send", this.form)
                        .then(() => {
                            this.requestSuccess = true
                            this.form = {}
                            showAlert(swalSettings)
                        })
                        .catch(() => this.requestFailed = true)
                        .finally(() => this.isLoading = false)
            }
        },
        dismissModal: function () {
            this.requestSuccess = false
        }
    }
}
</script>