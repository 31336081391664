<template>
    <div class="py-4 mb-5">
        <div class="container" >
            <div class="product-page position-relative">
                <div v-if="isLoading" >
                    <div class="w-25 mb-4">
                        <div class="skeleton-loader text"></div>
                    </div>
                    <div class="col-md-11 col-12 mx-auto">
                        <div class="row mb-4 mx-0">
                            <div class="col pl-0">
                                <div class="skeleton-loader image large"></div>
                            </div>
                            <div class="col pr-0">
                                <div class="skeleton-loader text w-25 mb-3"></div>
                                <div class="skeleton-loader header w-50 mb-4"></div>
                                <div class="my-5 py-5">
                                    <div class="skeleton-loader text mb-4"></div>
                                    <div class="skeleton-loader text mb-4"></div>
                                    <div class="skeleton-loader text w-50"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-thin mb-4">
                        <div class="col-xl-3 col-sm-6 col-12 mb-3" v-for="n in 4" :key="n" >
                            <CardLoader />
                        </div>
                    </div>
                </div>
                <div v-else >
                    <div v-if="product" >
                        <div 
                            v-if="product.getDiscountPercentage(product) > 0"
                            class="discount font-xsmall font-white font-sf-medium"
                        >
                            <div class="position-relative py-1 px-2">
                                Save {{ product.getDiscountPercentage(product) }}%
                            </div>
                        </div>

                        <ol 
                            class="breadcrumb bg-white font-xsmall font-gray p-0"
                            v-if="productBreadcrumbs && productBreadcrumbs.length > 0"
                        >
                            <li 
                                v-for="(breadcrumb, index) in productBreadcrumbs"
                                :key="index"
                                :class="`breadcrumb-item ${ productBreadcrumbs.length - 1 == index ? 'active' : '' }`"
                            >{{breadcrumb && breadcrumb.title ? breadcrumb.title : ''}}</li>
                        </ol>
                        <div class="col-md-10 col-12 mx-auto">
                            <div class="row">
                                <div class="col-lg-6 col-12 d-flex flex-column">
                                    <div class="product-image-container text-center p-3 flex-grow-1 d-flex align-items-center justify-content-center">
                                        <Imager 
                                            :src="activeImage" 
                                            :extract="true" 
                                        />
                                    </div>
                                    <carousel 
                                        v-if="product.images && product.images.length > 0"
                                        class="images-container mx-n4 my-3"
                                        :perPage="6"
                                        :paginationEnabled="false"
                                    >
                                        <slide 
                                            v-for="(image, index) in product.images" 
                                            :key="index" 
                                            class="p-2 clickable"
                                        >
                                            <Imager 
                                                :src="image.asset"
                                                :extract="true"
                                                @click-image="setActiveImage(image.asset)"
                                            />
                                        </slide>
                                    </carousel>
                                </div>
                                <div class="col-lg-6 col-12 px-0 px-lg-5 my-4">
                                    <div class="d-flex flex-column h-100">
                                        <div class="d-flex flex-wrap mb-2">
                                            <span
                                                class="btn rounded-0 font-xsmall py-0 px-3 font-white font-sf-medium bg-green w-auto mr-2 d-flex align-items-center"
                                                v-if="product.getTag('new')"
                                            >
                                                NEW
                                            </span>
                                            <div
                                                class="btn rounded-0 trending font-xsmall py-1 px-2 font-white font-sf-medium d-inline-flex align-items-center w-auto mr-2"
                                                v-if="product.getTag('trending')"
                                            >
                                                <img class="img-small mr-1" src="@assets/img/trending.png" alt="" />
                                                Trending
                                            </div>
                                            <div
                                                class="btn rounded-0 w-auto font-xsmall py-0 px-2 bg-orange-light font-orange font-sf-medium mr-2 d-flex align-items-center"
                                                v-if="product.admin_rating > 0 ||product.getTag('recommended')"
                                            >
                                                Recommended
                                            </div>
                                             <span
                                                class="btn rounded-0 font-xsmall py-0 px-3 font-yellow font-sf-medium bg-green w-auto mr-2 d-flex align-items-center"
                                                v-if="product.getTag('bundle')"
                                            >
                                                BUNDLE
                                            </span>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <div class="flex-grow-1" >
                                                <div 
                                                    v-if="product.brand"
                                                    class="font-small font-green font-sf-medium"
                                                >
                                                    {{ product.brand.title }}
                                                </div>
                                                <div class="font-sf-medium font-upsized d-flex align-items-center flex-wrap">
                                                    {{ product.title }}
                                                </div>
                                            </div>
                                            <div class="text-center clickable" @click="$bvModal.show('social-share-popup')" >
                                                <div class="fa-stack">
                                                    <i class="fal fa-circle fa-stack-2x  font-gray-1"></i>
                                                    <i class="fas fa-share-alt fa-stack-1x font-gray font-xsmall"></i>
                                                </div>
                                                <div class="font-xxsmall font-gray">Share</div>
                                            </div>
                                        </div>
                                        
                                        <div class="my-3" >
                                            <div>
                                                <CustomerRating 
                                                    :productRating="Number(product.average_review)" 
                                                />
                                            </div>
                                            <div v-if="product.admin_rating > 0" >
                                                <BuyboyRating 
                                                    :productRating="product.admin_rating" 
                                                />
                                            </div>
                                        </div>

                                        <div 
                                            v-if="product.variant_groups && product.variant_groups.length > 0" 
                                            class="my-3"
                                        >
                                            <div 
                                                v-for="(variant, groupIndex) in product.variant_groups" 
                                                :key="groupIndex"
                                                class="mb-2"
                                            >
                                                <div class="font-small">{{variant.name}}</div>
                                                <div class="d-flex align-items-center flex-wrap">
                                                    <span 
                                                        v-if="variant.options && variant.options.length > 0" 
                                                    >
                                                        <button 
                                                            v-for="(option, index) in variant.options"
                                                            :key="index"
                                                            :class="`btn btn-sm rounded-pill btn-white font-xxsmall mr-2 ${ selectedVariant.includes(option.id) ? 'active' : '' }`"
                                                            @click="handleVariantSelect(groupIndex,option)" 
                                                        >{{option.name}}</button>
                                                    </span>
                                                    <div v-if="(groupIndex + 1) == product.variant_groups.length" >
                                                        <span 
                                                            class="font-xxsmall font-gray-light"
                                                            v-if="selectedProduct"
                                                        > 
                                                            <div v-if="!isOutOfStock" >
                                                                <span v-if="selectedProduct.enable_inventory > 0" >
                                                                    {{ selectedProduct.unit && selectedProduct.unit.name ? selectedProduct.inventory : parseInt(selectedProduct.inventory) }} {{ selectedProduct.unit ? (selectedProduct.unit.short_name ? selectedProduct.unit.short_name : selectedProduct.unit.name ) : `piece${selectedProduct.inventory > 1 ? 's' : ''}` }} available
                                                                </span>
                                                                <span v-else >available</span>
                                                            </div>
                                                            <div v-else class="font-red" >Out of stock</div>
                                                        </span>
                                                        <span 
                                                            v-else 
                                                            class="font-xxsmall font-gray-light"
                                                        >Please select a variant</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="my-4 font-xxsmall font-gray-light" v-else >
                                            <div v-if="!isOutOfStock" >
                                                <span v-if="selectedProduct.enable_inventory > 0" >
                                                    {{ selectedProduct.unit && selectedProduct.unit.name ? selectedProduct.inventory : parseInt(selectedProduct.inventory) }} {{ selectedProduct.unit ? (selectedProduct.unit.short_name ? selectedProduct.unit.short_name : selectedProduct.unit.name ): (selectedProduct.inventory > 1 ? 'pcs' : 'pc') }} available
                                                </span>
                                                <span v-else >
                                                    Available
                                                </span>
                                            </div>
                                            <div v-else class="font-red" >Out of stock</div>
                                        </div>

                                        <div class="font-upsized font-sf-bold">
                                            <ProductPrice 
                                                :product="selectedProduct"
                                                priceClass="font-red-dark mr-2"
                                                originalPriceClass="font-gray-light"
                                            />
                                        </div>
                                        <div v-if="selectedProduct">
                                            <div v-if="!isOutOfStock" >
                                                <div 
                                                    class="btn bg-gray-2 d-flex align-items-center rounded-pill p-2"
                                                >
                                                    <i 
                                                        class="fas fa-minus flex-fill"
                                                        @click="changeQty('minus')"
                                                    ></i>
                                                    <input 
                                                        type="text"
                                                        class="flex-grow-1 inline-quantity-input" 
                                                        v-model="quantity"   
                                                        pattern="\d*"
                                                        maxlength="4"
                                                        @change="handleQtyOnChange"
                                                    >
                                                    <i 
                                                        class="fas fa-plus flex-fill"
                                                        @click="changeQty('add')"
                                                    ></i>
                                                </div>
                                                <button 
                                                    class="btn my-2 p-2 btn-block rounded-pill bg-green font-white font-small font-sf-medium"
                                                    @click="handleAddToCart"
                                                    :disabled="quantity <= 0"
                                                >Add to Cart</button>
                                            </div>
                                            <div v-else >
                                                <div class="form-group mt-3">
                                                    <label class="font-xsmall" for="">Enter your email and we'll let you know when the product is in stock</label>
                                                    <div class="input-group input-group-rounded append">
                                                        <input 
                                                            type="text" 
                                                            v-model="productNotifEmail"
                                                            class="form-control font-xsmall pl-3"
                                                            placeholder="Enter email address"
                                                            @keyup.enter="handleProductNotifSubmit"
                                                        >
                                                        <div 
                                                            class="input-group-append"
                                                            @click="handleProductNotifSubmit"
                                                        >
                                                            <span class="input-group-text px-3 font-xsmall clickable">Submit</span>
                                                        </div>
                                                    </div>
                                                    <div class="font-xsmall font-red" v-if="productNotifEmailError" >{{ productNotifEmailError }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div 
                                            class="font-xsmall font-gray font-sf-medium clickable mt-3"
                                            v-if="selectedProduct"
                                            @click="handleAddToWishlist"
                                        >
                                            <i :class="`${ wishlisted ? 'fas' : 'far' } fa-heart font-red-dark mr-1`"></i>
                                            <span v-html="wishlisted ? 'Added' : 'Add'" /> to your FreQuincy List
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-0 px-lg-3">
                                <ProductAddons 
                                    v-if="selectedProduct && selectedProduct.add_ons && selectedProduct.add_ons.length > 0"

                                    :parent="product"
                                    :product="selectedProduct" 
                                    :productAddOns="selectedProduct.add_ons && selectedProduct.add_ons"
                                    :selectedAddons="selectedAddons"
                                    :total="addonsTotal"
                                    @add-to-cart="handleAddToCart"
                                    @handle-add-on-select="handleAddOnSelect"
                                />
                                <div class="border-top border-bottom" v-if="product.description" >
                                    <div class="font-xlarge font-sf-medium my-3">Product Description</div>
                                    <div class="font-small font-gray" v-html="product.description" ></div>
                                </div>
                                <div>
                                    <ProductReviews 
                                        :ratings="ratings"
                                        :reviews="reviews"
                                        :average="product.average_review"
                                        :hasPendingReview="hasPendingReview"
                                        :user="user ? user : false"
                                        :reviewParams="reviewParams"
                                        :reviewParamsError="reviewParamsError"
                                        :submitReview="submitReview"
                                        :flagReview="handleFlagReview"
                                        @change-page="changePaginationPage"
                                    />
                                </div>
                            </div>
                        </div>
                        <div 
                            v-if="relatedProducts && relatedProducts.length > 0" 
                            class="border-top mt-5 pt-2" 
                        >
                            <div class="font-xxlarge font-sf-medium my-2">Related Products</div>
                            <div class="row row-thin">
                                <div 
                                    class="col-xl-3 col-sm-6 col-12 mb-3"
                                    v-for="relatedProduct in relatedProducts"
                                    :key="relatedProduct.slug"
                                >
                                    <ProductCard
                                        :product="relatedProduct"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SharePopup :product="this.product" />
    </div>
</template>

<script>
    import Product from '@factories/product.factory'
    import { connect } from '@api';
    import ProductCard from '@components/productCard/ProductCard';
    import SharePopup from './components/sharePopup';
    import { Price, CustomerRating, BuyboyRating, CardLoader } from '@components/common';
    import ProductAddons from './components/productAddons';
    import ProductReviews from './components/productReviews';
    import { isLoggedIn, getUser } from '@helpers/session.helper';
	import { hasCart, getCart } from '@helpers/cart.helper';
    import { mapActions, mapMutations, mapGetters } from 'vuex';
    import * as types from '../../store/mutation-types';
    import { showAlert } from '@helpers/notification.helper';

    const initialReviewParams = {
        customer_id: 0,
        product_id: 0,
        author: '',
        content: '',
        rating: 5,
        status: 'pending',
        verified: 0
    }

    const initialFlagParams = {
        user_id: 0,
        reason: '',
        comment: '',
        flagged_id: 0,
        flagged_type: 'App/ProductReview',
        type: ''
    }

    export default {
        components: {
            CustomerRating,
            ProductCard,
            CardLoader,
            BuyboyRating,
            ProductAddons,
            ProductReviews,
            SharePopup
        },
        data() {
            return {
                product: null,
                relatedProducts: [],
                activeImage: null,
                selectedProduct: null,
                selectedVariant: [],
                variants: [],
                selectedAddons: [],
                reviews: null,
                ratings: null,
                quantity: 1,
                params: {
                    page_size: 4,
                    category: '',
                    exclude: [],
                    sort: 'relevance:desc'
                },
                productNotifEmail: '',
                productNotifEmailError: '',
                isNotified: false,
                reviewsPage: 1,
                isLoading: true,
                prevRoute: null,
                hasPendingReview: false,
                reviewParams: initialReviewParams,
                reviewParamsError: '',
                flagParams: initialFlagParams,
                flagParamsError: '',
                showFlagModal: false,
                wishlisted: false
            }
        },
        computed: {
            addonsTotal: {
                get() {
                    let total = 0
                    let { selectedProduct, quantity, selectedAddons } = this;

                    if (selectedAddons.length > 0) {
                        selectedAddons.map(addon => {
                            total += parseFloat(addon.price);
                        })
                    }

                    total += ( parseFloat(selectedProduct.price) * quantity );

                    return total;
                },
                set(total) {
                    this.addonsTotal = total
                }
            },
            user() {
                return isLoggedIn() ? getUser() : null
            },
            isOutOfStock() {

                let isOutOfStock = true;
                let { selectedProduct } = this;

                if (selectedProduct) {
                    if (selectedProduct.enable_inventory > 0) {
                        isOutOfStock = selectedProduct.inventory > 0 ? false : true;
                    } else {
                        isOutOfStock = false;
                    }
                }
                
                return isOutOfStock;
            },
            productBreadcrumbs() {
                let category = null,
                    categories = [],
                    breadcrumbs = [];

                if (this.product && this.product.categories && this.product.categories.length > 0) {

                    this.product.categories.map(cat => {   
                        if (cat.category) {
                            categories.push(cat.category);
    
                            if (cat.category && cat.category.parent_cat && cat.category.parent_cat.id) {
                                categories.push(cat.category.parent_cat);
    
                                if (cat.category.parent_cat.parent_cat && cat.category.parent_cat.parent_cat.id) {
                                    categories.push(cat.category.parent_cat.parent_cat);
                                }
                            }
                        }         
                    });

                    let { query } = this.$route;
                    if (query && query.category) {
                        category = categories.find(cat => {
                            if (cat.category) {
                                return cat.category.slug == query.category;
                            }
                        });
                    } else {
                        category = categories[0];
                    }

                    breadcrumbs.push(category);

                    if (category && category.parent_cat && category.parent_cat.id) {
                        breadcrumbs.push(category.parent_cat);

                        if (category.parent_cat.parent_cat && category.parent_cat.parent_cat.id) {
                            breadcrumbs.push(category.parent_cat.parent_cat);
                        }
                    }
                    breadcrumbs = breadcrumbs.reverse();
                }
                

                return breadcrumbs;
            },
            userWishlist() {
				let wishlist = [];

				if (isLoggedIn()) {

					let user = getUser();
					wishlist = user.wishlist;
				}

				return wishlist;
			}
        },
        created() {
            this.findProduct(this.$route.params.slug);
        },
        mounted() {
            if (isLoggedIn()) {
                let user = getUser();

                this.productNotifEmail = user.email;
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from;

                return to;
            })
        },
        methods: {
            ...mapGetters({
                getUserAge: types.GET_USER_AGE,
                getSEO: types.GET_SEO
            }),
            ...mapActions({
                syncCart: types.SYNC_CART,
                deleteFrequincyItem: types.DELETE_FREQUINCY_LIST_ITEM
            }),
            ...mapMutations({
				togglePopup: types.TOGGLE_LIST_POPUP,
                setPopupProductID: types.SET_LIST_POPUP_PRODUCT_ID,
                toggleCartNotif: types.TOGGLE_ADD_TO_CART_NOTIF,
                setCartNotifItems: types.SET_ADD_TO_CART_NOTIF_ITEMS,
                setUserAge: types.SET_USER_AGE,
                updateUser: types.UPDATE_USER
            }),
            handleFlagReview(id) {
                
                let swalSettings = {
                    title: 'Flag this review?',
                    html: 'Click yes if you find this review inappropriate',
                    confirmButtonText: 'Yes',
                    customClass: {
                        actions: 'd-flex px-3',
                        confirmButton: 'flex-fill',
                        cancelButton: 'flex-fill'
                    }
                }

                showAlert(swalSettings, 'confirm')
                    .then(swal => {
                        if (swal.isConfirmed) {

                            let reason = 'inappropriate';

                            let newParams = {
                                user_id: this.user.id,
                                reason: reason,
                                comment: '',
                                flagged_id: id,
                                type: ''
                            };

                            this.flagParams = {...this.flagParams, ...newParams};

                            connect().post('product_reviews/flag', this.flagParams)
                                .then(res => {
                                    if (res.status == 200) {

                                        let successSettings = {
                                            title: res.data.message,
                                            customClass: {
                                                confirmButton: 'btn-block mx-3'
                                            }
                                        }

                                        showAlert(successSettings)
                                    }
                                })
                                .catch(err => {

                                    if (err.data && err.data.message) {

                                        let errorSettings = {
                                            title: err.data.message,
                                            customClass: {
                                                actions: 'px-3',
                                                confirmButton: 'btn-block'
                                            }
                                        }

                                        showAlert(errorSettings, 'error');
                                    }
                                })

                        }
                    })

            },
            submitReview() {

                let { user, selectedProduct, product, reviewParams, hasPendingReview } = this;

                let newValues = {
                    author: user.customer.fullname,
                    customer_id: user.customer_id,
                    product_id: product.id,
                    verified: user.verified ? true : false,
                    user_id: user.id,
                    pending_review_id: hasPendingReview.id
                }

                reviewParams = {...reviewParams, ...newValues};

                connect().post('product_reviews/store', reviewParams)
                    .then(res => {
                        if (res.status == 200) {

                            let swalSettings = {
                                title: 'Congratulations!',
                                text: 'Your review has been submitted for review',
                                backdrop: false,
                                allowOutsideClick: false,
                                customClass: {
                                    confirmButton: 'btn-block px-3'
                                }
                            };

                            showAlert(swalSettings)
                                .then(swal => {
                                    this.hasPendingReview = false;
                                })
                        }
                    })
                    .catch(err => {
                        if (err.data && err.data.message) console.error(err.data.message)
                    })

            },
            getPendingReviews(customer_id) {

                connect().get(`pending_reviews/get?customer_id=${customer_id}`)
                    .then(res => {
                        if (res.status == 200) {
                            let pendingReviews = res.data.data;

                            let hasReview = pendingReviews.find(review => review.product_id == this.product.id);

                            if (hasReview) {
                                this.hasPendingReview = hasReview;
                            }
                        }
                    })
                    .catch(err => {
                        if (err.data && err.data.message) console.error(err.data.message);
                    })
            },
            handleAddOnSelect(addon) {

                let { selectedAddons } = this;
                let found = false;
                
                if (selectedAddons.length > 0) {
                    found = selectedAddons.find(i => i.id == addon.id);

                    if (found) {
                        selectedAddons = selectedAddons.filter(i => i.id != addon.id);
                    } else {
                        selectedAddons.push(addon);
                    }
                } else {
                    selectedAddons.push(addon);
                }
                
                this.selectedAddons = selectedAddons;
            },
            handleAddToCart() {

                let cart = getCart();
                let user = getUser();

                let { selectedProduct, selectedAddons, quantity } = this;
                quantity = parseInt(quantity);

                if (quantity > 0) {

                    let items = (cart && cart.items && cart.items.length > 0) ? cart.items : [];
                    let existingProductIndex = items.findIndex(item => {
                        return item.product_id == selectedProduct.id;
                    });

                    if (existingProductIndex > -1) {

                        let newQuantity = items[existingProductIndex].quantity + quantity;
                        
                        if (selectedProduct.enable_inventory > 0) {
                            if (items[existingProductIndex].product.inventory <= newQuantity) {
                                items[existingProductIndex].quantity = items[existingProductIndex].product.inventory;
                            } else {
                                items[existingProductIndex].quantity = newQuantity;
                            }
                        } else {
                            items[existingProductIndex].quantity = newQuantity;
                        }

                    } else {
                        items.push({
                            product_id: selectedProduct.id,
                            quantity: quantity,
                            product: selectedProduct
                        })
                    }

                    if (selectedAddons.length > 0 && selectedProduct.add_ons.length > 0) {
                        
                        let itemAddons = [];

                        selectedAddons.map(addon => {
                            let addonItem = {
                                value: addon.name,
                                qty: 1,
                                addon_group: addon.id,
                                addon_option: 0
                            }

                            itemAddons.push(addonItem);
                        })

                        let cartItemIndex = items.findIndex(item => item.product_id == selectedProduct.id);

                        if (cartItemIndex > -1)  {
                            items[cartItemIndex].add_ons = itemAddons;
                        }
                    }
                    
                    let cartParams = {
                        customer_id: user && user.customer_id ? user.customer_id : 0,
                        source: 'website',
                        last_device: 'laptop'
                    }
                    
                    cartParams.items = items;

                    this.syncCart(cartParams)
                        .then(res => {
                            console.log(res);
                        })

                    let notifItems = [];
                    notifItems.push({
                        product: selectedProduct,
                        quantity: this.quantity	
                    })

                    this.setCartNotifItems(notifItems);
                    this.toggleCartNotif(true);
                    
                    this.quantity = 1;
                }
            },
            handleProductNotifSubmit() {
                
                let { productNotifEmail, productNotifEmailError, selectedProduct } = this;

                this.productNotifEmailError = '';
                if (productNotifEmail == '') {
                    this.productNotifEmailError = 'Please fill up this field';
                    return;
                }

                let successSwal = {
                    html: 'You will be notified when this product is re-stocked'
                }

                let params = {
                    email: this.productNotifEmail,
                    product_id: selectedProduct.id
                }

                connect().post('product-stock-alert', params)
                    .then(res => {
                        if (res.status == 200) {
                            showAlert(successSwal);
                            this.isNotified = true;
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    })

            },
            findProduct(slug) {
                this.isLoading = true;
                connect().get('products/find', { params: { slug: slug } })
                    .then(res => {
                        if (res.status == 200) {

                            if (res.data && res.data.data && res.data.data.parent) {
                                this.product = new Product(res.data.data.parent);

                                if (isLoggedIn()) {
                                    let user = getUser();

                                    this.getPendingReviews(user.customer_id);
                                }

                                if (this.product.age_restricted) {

                                    let userAge = this.getUserAge();

                                    if (isLoggedIn() && userAge < 21) {

                                        let swalSettings = {
                                            html: 'You must be at least 21 years old to view this content',
                                            confirmButtonText: 'Go Back',
                                            allowOutsideClick: false,
                                            customClass: {
                                                actions: 'px-3',
                                                confirmButton: 'btn-block'
                                            }
                                        };

                                        showAlert(swalSettings, 'error')
                                            .then(swal => {
                                                if (swal.isConfirmed) {
                                                    if (this.prevRoute) {
                                                        this.$router.push(this.prevRoute.fullPath);
                                                    }
                                                }
                                            })
                                    }

                                    if (!userAge) {

                                        let swalSettings = {
                                            html: 'You must be at least 21 years old to view this content.',
                                            showCancelButton: true,
                                            confirmButtonText: 'I am over 21',
                                            cancelButtonText: 'I am not 21 yet',
                                            allowOutsideClick: false,
                                            customClass: {
                                                actions: 'd-flex px-3',
                                                confirmButton: 'flex-fill',
                                                cancelButton: 'flex-fill'
                                            }
                                        }

                                        showAlert(swalSettings, 'confirm')
                                            .then(swal => {
                                                if (swal.isConfirmed) {

                                                    this.setUserAge({ data: 21 });
                                                } else {
                                                    if (this.prevRoute) {
                                                        this.$router.push(this.prevRoute.fullPath);
                                                    }
                                                }
                                            })
                                    }
                                }
                                
                                if (this.product.variants && this.product.variants.length > 0) {
                                    this.variants = this.product.variants;

                                    if (this.$route.query.variant) {
                                        this.selectVariantFromRoute(this.$route.query.variant);
                                    }
                                } else {
                                    this.selectedProduct = this.product;
                                }

                                if (this.product.asset && this.product.asset.path) {
                                    this.activeImage = this.product.asset;
                                }



                                let newParams = {
                                    exclude: [this.product.id]
                                }

                                if (this.product.categories && this.product.categories.length > 0) {

                                    let { query } = this.$route;
                                    let category = null;

                                    if (query && query.category) {
                                        category = this.product.categories.find(cat => {
                                            return cat.slug == query.category;
                                        });
                                    } else {
                                        category = this.product.categories[0];
                                    }

                                    if (category && category.slug) {
                                        newParams.category = category.slug;
                                    }
                                }

                                this.params = {...this.params, ...newParams};

                                this.getProductReviews(this.product.id);
                                this.getRelatedProducts();
                            }
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    })
            },
            getProductReviews(id, page = 1) {

                let params = {
                    pid: id,
                    page: page
                };

                connect().get('product/get-product-review', { params: params })
                    .then(res => {
                        if (res.status == 200) {
                            this.reviews = res.data.data.reviews;
                            this.ratings = res.data.data.ratings;
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    })
            },
            getRelatedProducts() {
                connect().get('products/get', { params: this.params })
                    .then(res => {
                        if (res.status == 200) {
                            this.isLoading = false;
                            this.relatedProducts = res.data.products.data;
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    })
            },
            selectVariantFromRoute(optionCombination) {

                let tempVariant = optionCombination.split(',');

                tempVariant.map((item, index) => {
                    tempVariant[index] = parseInt(tempVariant[index]);
                })

                this.selectedVariant = tempVariant;

                let foundVariant = this.variants.find(variant => {
                    return variant.options_combination == optionCombination;
                });

                if (foundVariant) this.selectedProduct = foundVariant;
            },
            handleVariantSelect(index, option) {

                let { product } = this;
                let variantGroups = product.variant_groups;
                let tempVariants = new Array(variantGroups.length);

                if (this.selectedVariant.length > 0) {
                    this.selectedVariant.map((variant, index) => {
                        tempVariants[index] = variant;
                    })
                }

                tempVariants[index] = option.id;
                this.selectedVariant = tempVariants;

                let optionCombination = this.selectedVariant.join(',');

                let foundVariant = this.variants.find(variant => {
                    return variant.options_combination == optionCombination;
                });

                if (foundVariant) this.selectedProduct = foundVariant;
            },
            handleAddToWishlist() {

                if (!isLoggedIn()) {
                    this.$router.push({ name: 'login' });
                    return;
                }


                if (!this.wishlisted) {
                    this.togglePopup();
                    this.setPopupProductID([this.selectedProduct.id]);
                } else {

                    let swalSettings = {
                        title: 'Remove item from wishlist',
                        text: 'Do you want to remove this item from your wishlist',
                        customClass: {
                            actions: 'd-flex align-items-center px-3',
                            confirmButton: 'flex-fill bg-white font-black',
                            cancelButton: 'flex-fill bg-green font-white'
                        }
                    }

                    showAlert(swalSettings, 'confirm')
                        .then(swal => {
                            if (swal.isConfirmed) {
                                if (isLoggedIn()) {
                                let user = getUser();
                                
                                if (user.wishlist && user.wishlist.length > 0) {
                                    let item = user.wishlist.find(item => item.product_id == this.selectedProduct.id);

                                    if (item) {

                                        let params = {
                                            id: item.id,
                                            frequincy_list_id: item.frequincy_list_id,
                                            customer_id: user.customer_id
                                        };

                                        this.deleteFrequincyItem(params)
                                            .then((res) => {
                                                let newWishlist = user.wishlist.filter(wishlist => wishlist.id != item.id);
                                                user.wishlist = newWishlist;

                                                this.updateUser(user);

                                                const removeSwalSettings = {
                                                    title: 'Item removed from your wishlist'
                                                }

                                                showAlert(removeSwalSettings);
                                            })
                                    }
                                }
                            }
                            }
                        })
                }

            },
            handleQtyOnChange() {
                let { selectedProduct, quantity } = this;

                if (selectedProduct.enable_inventory) {
                    if (parseInt(selectedProduct.inventory) < parseInt(quantity)) {
                        this.quantity = parseInt(selectedProduct.inventory);
                    }
                }
            },
            changeQty(operation) {
                if (operation == 'add') {
                    this.quantity = parseInt(this.quantity) + 1;
                } else {
                    if (this.quantity > 1) {
                        this.quantity = parseInt(this.quantity) - 1;
                    }
                }
            },
            setActiveImage(image) {
                this.activeImage = image;
            },
            changePaginationPage(pageNumber) {
                this.reviewsPage = pageNumber;
            },
        },
        watch: {
            $route(to) {
                this.findProduct(to.params.slug);
            },
            selectedProduct(newProduct) {

                if (isLoggedIn()) {
                    let user = getUser();
                    
                    if (this.userWishlist.length > 0) {
                        let exists = this.userWishlist.find(item => item.product_id == newProduct.id);
                        this.wishlisted = exists ? true : false
                    }
                }
                this.getRelatedProducts(newProduct.id);
            },
            reviewsPage(newPage) {
                this.getProductReviews(this.product.id, newPage);
            },
            userWishlist(newWishlist) {

				let exists = newWishlist.find(item => item.product_id == this.selectedProduct.id);
				this.wishlisted = exists ? true : false;

			}
        }
    }
</script>