<template>
	<div class="account-address-page-form d-flex flex-column h-100">
		<div class="col-lg-8 col-12 pl-0">
			<div class="mb-4">
				<router-link
					:to="{ name: 'account.address' }"
					class="font-xxlarge font-sf-medium d-flex align-items-center clickable"
				>
					<i class="fas fa-chevron-left font-small mr-2"></i>
					<span>{{ method == 'create' ? 'Create' : 'Edit' }} Address</span>
				</router-link>
				<div class="font-small font-gray">Please provide us your shipping details</div>
			</div>
			<div class="card font-xsmall font-gray">
				<div class="card-body">
					<div class="border-bottom border-black">
						<div class="form-group">
							<label for="fullname">Full Name</label>
							<input
								type="text"
								id="fullname"
								class="form-control font-xsmall font-sf-medium"
								placeholder="Enter Full Name"
								v-model="formValues.name"
							/>
							<div v-if="formErrors.name" class="font-small font-red">{{formErrors.name}}</div>
						</div>
						<!-- <div class="form-group">
							<label for="phone">Phone Number</label>
							<input
								type="text"
								id="phone"
								class="form-control font-xsmall font-sf-medium"
								placeholder="Enter Phone Number"
								v-model="formValues.phone"
							/>
							<div v-if="formErrors.phone" class="font-xsmall font-red">{{formErrors.phone}}</div>
						</div> -->
					</div>
					<div class="form-group mt-3">
						<label for="address">Address</label>
						<gmap-autocomplete
							:value="formValues.address"
							placeholder="Please enter your address"
							class="form-control font-xsmall font-sf-medium"
							id="address"
							@place_changed="setPlace"
							:select-first-on-enter="true"
							:componentRestrictions="{ country:'PH' }"
						/>
						<div class="font-small font-red" v-if="formErrors.address" >{{formErrors.address}}</div>
					</div>
					<div class="form-group">
						<div class="map-container">
							<GmapMap
								:center="{ ...mapCoords }"
								:zoom="17"
								:options="{
									zoomControl: true,
									mapTypeControl: false,
									scaleControl: false,
									streetViewControl: false,
									rotateControl: false,
									fullscreenControl: false,
									disableDefaultUI: false
								}"
							>
								<GmapMarker
									:position="{ ...mapCoords }"
									:draggable="true"
									@dragend="setPlace"
								/>
							</GmapMap>
						</div>
					</div>
					<div class="border-top pt-3 text-right">
						<button class="btn btn-sm btn-green rounded-pill" @click="handleSubmit">Save Address</button>
					</div>
				</div>
			</div>
			<!-- <div class="card">
				<div class="card-body font-xsmall font-gray">
					<div class="form-group mt-3">
						<label for="province_id">Province</label>
						<select
							id="province_id"
							class="form-control font-xsmall font-sf-medium"
							v-model="formValues.province_id"
							@change="getCities(formValues.province_id)"
							:disabled="provinces.length <= 0"
						>
							<option value hidden selected disabled>Select Province</option>
							<option
								v-for="(province, index) in provinces"
								:key="index"
								:value="province.id"
							>{{province.name}}</option>
						</select>
						<div v-if="formErrors.province_id" class="font-xsmall font-red">{{formErrors.province_id}}</div>
					</div>
					<div class="form-group form-row">
						<div class="col">
							<label for="city">City</label>
							<select
								id
								class="form-control font-xsmall font-sf-medium"
								v-model="formValues.city_id"
								:disabled="cities.length <= 0"
							>
								<option value hidden selected disabled>Select City</option>
								<option v-for="(city, index) in cities" :key="index" :value="city.id">{{city.name}}</option>
							</select>
							<div v-if="formErrors.city_id" class="font-xsmall font-red">{{formErrors.city_id}}</div>
						</div>
						<div class="col">
							<label for="address_line_1">Barangay</label>
							<input
								type="text"
								id="address_line_1"
								class="form-control font-xsmall font-sf-medium"
								placeholder="Enter Barangay"
								v-model="formValues.custom_area"
							/>
							<div v-if="formErrors.custom_area" class="font-xsmall font-red">{{formErrors.custom_area}}</div>
						</div>
					</div>
					<div class="form-group">
						<label for="address_line_1">House No., Building, Street Name</label>
						<input
							type="text"
							id="address_line_1"
							class="form-control font-xsmall font-sf-medium"
							placeholder="Address Line"
							v-model="formValues.address_line_1"
						/>
						<div
							v-if="formErrors.address_line_1"
							class="font-xsmall font-red"
						>{{formErrors.address_line_1}}</div>
					</div>
					<div class="border-top pt-3 text-right">
						<button class="btn btn-sm btn-green rounded-pill" @click="handleSubmit">Save Address</button>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>
<script>
import locations from "../../constants/locations.json";
import * as sessionHelper from "@helpers/session.helper";
import { showAlert } from "@helpers/notification.helper";
import validate from "@helpers/validation.helper";
import { connect } from "@api";
import { mapMutations, mapActions, mapGetters } from "vuex";
import * as types from "../../store/mutation-types";
import store from '../../store/store';
import { gmapApi } from 'vue2-google-maps';

let createSwalSettings = {
	title: "Congratulations!",
	text: "Address successfully created",
	customClass: {
		actions: "d-flex",
		confirmButton: "flex-fill mx-5"
	}
};

let updateSwalSettings = {
	title: "Congratulations!",
	text: "Address successfully updated",
	customClass: {
		actions: "d-flex",
		confirmButton: "flex-fill mx-5"
	}
};

let initialFormValues = {
	address: "",
	customer_id: 0,
	email: "",
	name: "",
	phone: "",
	country_id: 1,
	province_id: "",
	city_id: "",
	zip: "",
	custom_area: "",
	address_line_1: "",
	lat: 0,
	lng: 0,
	city: "",
	custom_province: ""
};

let initialFormErrors = {
	address: "",
	customer_id: "",
	email: "",
	name: "",
	phone: "",
	country_id: "",
	province_id: "",
	city_id: "",
	zip: "",
	custom_area: "",
	address_line_1: "",
	lat: 0,
	lng: 0,
	city: "",
	custom_province: ""
};

export default {
	data() {
		return {
			provinces: [],
			cities: [],
			areas: [],
			formValues: initialFormValues,
			formErrors: initialFormErrors,
			mapCoords: {
				lat: 14.6091,
				lng: 121.0223,
			},
		};
	},
	computed: {
		method() {
			return this.$route.params.method;
		},
		user() {
			return sessionHelper.getUser();
		},
		google() {
			return gmapApi
		}
	},
	mounted() {
		this.getProvinces();

		this.formValues = {
			customer_id: 0,
			email: "",
			name: "",
			phone: "",
			country_id: 1,
			province_id: "",
			city_id: "",
			zip: "",
			custom_area: "",
			address_line_1: "",
			city: "",
			custom_province: "",
			lat: 0,
			lng: 0
		};

		if (this.user && this.method == "create") {
			this.formValues.customer_id = this.user.customer_id;
			this.formValues.email = this.user.email;
			this.formValues.name = this.user.name;
			this.formValues.phone = this.user.customer.phone;

			let storeLocation = this.getStoreLocation();

			let location = {
				lat: parseFloat(storeLocation.lat),
				lng: parseFloat(storeLocation.lng)
			}

			this.mapCoords = {...this.mapCoords, ...location}
		}

		if (this.$route.query && this.$route.query.id) {
			let { id } = this.$route.query;
			let { user } = this;

			let addresses =
				user &&
				user.customer.all_address &&
				user.customer.all_address.length > 0 ? user.customer.all_address : [];

			if (addresses.length > 0) {
				let address = addresses.find(address => address.id == id);

				this.formValues = { ...this.formValues, ...address };
				this.formValues.name = address.firstname + " " + address.lastname;

				let mapCoords = { 
					lat: address.lat,
					lng: address.lng
				}

				this.mapCoords = { ...mapCoords }
				this.formValues.address = this.formatAddress(address)
			}
		}
	},
	methods: {
		...mapMutations({
			updateUser: types.UPDATE_USER
		}),
		...mapGetters({
			getStoreLocation: types.GET_STORE_LOCATION
		}),
		setPlace(place) {
			if (!place) return;

			let self = this;

			// REVERSE GEOCODE
			if (place.formatted_address) {

				this.mapCoords = {
					...this.mapCoords,
					lat: place.geometry.location.lat(),
					lng: place.geometry.location.lng()
				};

				this.setAddress(place);
			} else {

				this.mapCoords = {
					...this.mapCoords,
					lat: place.latLng.lat(),
					lng: place.latLng.lng()
				};

				let latLng = {lat: parseFloat(place.latLng.lat()), lng: parseFloat(place.latLng.lng())};

				let google = new this.google();
				let geocoder = new google.maps.Geocoder();

				geocoder.geocode({'location': latLng}, function(results, status) {
					if (status == 'OK') {
						place = results[0];
						
						self.setAddress(place);
					} else {
						console.error('No location found');
					}
				})
			}

		},
		setAddress(place) {

			let address = {
				address_line_1: "",
				city: "",
				custom_area: "",
				custom_province: "",
				zip: "",
				lat: 0,
				lng: 0
			}

			// address.address_line_1 = place.name;
			address.lat = place.geometry.location.lat();
			address.lng = place.geometry.location.lng();

			// GET VALUES FROM ADDRESS_COMPONENTS
			let address_line = [];
			let city = [];
			let province = [];
			let zip = '';
			let custom_area = '';
			place.address_components.map(address => {

				// GET STREET NUMBER
				if (address.types.includes('street_number')) {
					address_line.push(address.long_name);
				}

				// GET STREET
				if (address.types.includes('route')) {
					address_line.push(address.long_name);
				}

				// GET BRGY
				if (address.types.includes('neighborhood')) {
					custom_area.push(address.long_name);
				}
				// GET CITY
				if (address.types.includes('locality') || address.types.includes('sublocality')) {
					city.push(address.long_name);
				}
				// GET PROVINCE
				if (address.types.includes('administrative_area_level_1') || address.types.includes('administrative_area_level_2')) {
					province.push(address.long_name);
				}
				// GET ZIP
				if (address.types.includes('postal_code')) {
					zip = address.long_name;
				}
			})

			address.address_line_1 = address_line.join(' ');
			address.city = city.join(', ');
			address.custom_province = province.join(', ');
			address.zip = zip;
			address.custom_area = custom_area;

			let formatted_address = [address.address_line_1, address.city, address.custom_area, address.custom_province]
									.filter(e => {
										return (e != null && e != '')
									})
									.join(', ');

			this.formValues.address = formatted_address;
			this.formValues = {...this.formValues, ...address};
		},
		async validateForm() {
			const schema = {
				properties: {
					name: {
						type: "string",
						required: true,
						allowEmpty: false,
						messages: {
							allowEmpty: 'Please etner your full name',
							required: 'Please enter your full name'
						}
					},
					// phone: {
					// 	type: "string",
					// 	required: true,
					// 	allowEmpty: false
					// },
					address: {
						type: "string",
						required: true,
						allowEmpty: false,
						messages: {
							allowEmpty: 'Please etner your address',
							required: 'Please enter your address'
						}
					}
				}
			};

			const validateResults = validate(
				this.formValues,
				schema,
				undefined,
				true
			);

			this.formErrors = validateResults.errors;

			return validateResults.valid;
		},
		getProvinces() {
			connect()
				.get("/options/address", {})
				.then(res => {
					if (res.status == 200) {
						this.provinces = res.data.data.selected.province;
					}
				});
		},
		getCities(province_id) {
			this.formValues.city_id = "";

			connect()
				.get(`/options/cities/${province_id}`, {})
				.then(res => {
					if (res.status == 200) {
						this.cities = res.data.data;
					}
				});
		},
		formatAddress(address) {
            let addressString = '';
            
            if (address.address_line_1) {
                addressString += address.address_line_1 + ', ';
            }
            if (address.custom_area) {
                addressString += address.custom_area + ', ';
            }
            if (address.city_name) {
                addressString += address.city_name + ', ';
            }
            if (address.province_name) {
                addressString += address.province_name + ', ';
            }
            
            return addressString;
        },
		async handleSubmit() {
			if (!(await this.validateForm())) {
				return;
			}

			let params = { ...this.formValues };
			let name = params.name.split(" ");
			params.firstname = name[0];
			params.lastname = name[1];

			if (this.method == "create") {
				connect()
					.post("customers/create-address", { ...params })
					.then(res => {
						let { user } = this;

						if (user.customer) {
							user.customer.all_address.push(res.data.data);
						}
						this.updateUser(user);

						showAlert(createSwalSettings).then(swal => {
							this.$router.push({
								name: "account.address"
							});
						});
					});
			} else {
				let { id } = this.$route.query;
				params.id = id;

				connect()
					.post("customers/update-address", { ...params })
					.then(res => {
						let { user } = this;

						if (user.customer) {
							user.customer.all_address.push(res.data.data);
						}
						this.updateUser(user);

						showAlert(updateSwalSettings).then(swal => {
							this.$router.push({
								name: "account.address"
							});
						});
					});
			}
		}
	},
	watch: {
		$route(to) {
			this.formValues = initialFormValues;
			this.formErrors = initialFormErrors;
		},
	}
};
</script>