<template>
    <div class="category-dropdown card" >
        <div class="card-body px-0">
            <div 
                class="clickable "
                v-for="(category, index) in categories"
                :key="index"
            >
                <div class="category-dropdown-item position-relative" >
                    <div class="d-flex btn btn-white border-0 p-1 btn-sm rounded-0 align-items-center justify-content-between mx-3" >
                        <span
                            class="flex-grow-1 text-left"
                            @click="() => { $router.push({ name: 'products', query: { category: category.slug } }) }"
                        >
                            {{ category.title }}
                        </span>
                        <span v-if="category.children.length > 0" class="fas fa-caret-down font-gray font-medium pl-4"></span>
                    </div>
                    <CategoryDropdown  
                        v-if="category.children.length > 0"
                        :categories="category.children"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CategoryDropdown',
    props: {
        categories: Array
    }
}
</script>