<template>
    <div @mouseleave="toggleProfilePopup()" :class="[!show ? 'd-none' : '', 'profile-popup bg-white font-xsmall font-gray p-3 border rounded']" >
        <div class="profile-popup-item mb-1">
            <div class="d-flex align-items-center w-100 clickable" @click="toggleCollapse()" >
                <span>My Account</span> <i :class="['fas font-green ml-auto', collapsed ? 'fa-caret-up' : 'fa-caret-down']"></i>
            </div>
            <div :class="['collapse pl-2 my-2', collapsed ? 'show' : '']" id="account-collapse">
                <router-link class="profile-popup-item mb-1" :to="{ name: 'account.profile' }" >My Profile</router-link>
                <router-link class="profile-popup-item mb-1" :to="{ name: 'account.address' }" >My Address</router-link>
                <!-- <router-link class="profile-popup-item mb-1" :to="{ name: 'account.accounts' }" >My Banks & Cards</router-link> -->
                <router-link class="profile-popup-item mb-1" :to="{ name: 'account.password' }" >Change Password</router-link>
            </div>
        </div>
        <router-link class="profile-popup-item mb-1" :to="{ name: 'cart' }" >My Cart</router-link>
        <router-link class="profile-popup-item mb-1" :to="{ name: 'frequincy' }" >My FreQuincy List</router-link>
        <!-- <router-link class="profile-popup-item mb-1" to="/my-transactions" >My Transactions</router-link> -->
        <div class="border-bottom border-black my-2"></div>
        <div 
            class="mb-1 clickable"
            @click="clicked"
        >Sign Out</div>
    </div>
</template>

<script>
export default {
    name: 'ProfilePopup',
    props: {
        show: Boolean
    },
    data() {
        return {
            collapsed: false
        }
    },
    methods: {
        toggleCollapse() {
            this.collapsed = !this.collapsed;
        },
        hideCollapse() {
            this.collapsed = false;
        },
        toggleProfilePopup() {
            this.$emit('toggle-popup');
        },
        clicked() {
            this.$emit('logout');
        }
    }
}
</script>