<template>
    <div class="card">
        <div class="card-body">
            <div class="d-inline-block skeleton-loader image mb-3"></div>
            <div>
                <div class="w-50 mb-1">
                    <div class="d-inline-block skeleton-loader text"></div>
                </div>
                <div class="w-25">
                    <div class="d-inline-block skeleton-loader text"></div>
                </div>
                <div class="w-50 mt-5">
                    <div class="d-inline-block skeleton-loader text"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>