<template>
    <div class="container checkout-breadcrumbs">
        <ul class="breadcrumb bg-transparent py-3 mb-0 font-small font-sf-medium font-gray">
            <li 
                v-for="(step, index) in steps"
                :key="index"    
                :class="`breadcrumb-item ${index > 0 ? `clickable ${currentStep == index ? 'font-green' : ''}` : ''} `"
            >
                <span class="mr-2" v-if="index == 0" >{{step}}</span>
                <span 
                    v-else 
                    class="mx-2" 
                    @click="handleNext(index)"
                >{{step}}</span>
            </li>
            <!-- <li class="breadcrumb-item">
                <span class="mr-2">Cart</span>
            </li>
            <li :class="['breadcrumb-item clickable ', currentStep == 1 ? 'font-green' : '']">
                <span 
                    class="mx-2"
                    @click="handleNext(1)"
                >Information</span>
            </li>
            <li :class="['breadcrumb-item clickable ', currentStep == 2 ? 'font-green' : '']">
                <span 
                    class="mx-2"
                    @click="handleNext(2)"
                >Schedule</span>
            </li>
            <li :class="['breadcrumb-item clickable ', currentStep == 3 ? 'font-green' : '']">
                <span 
                    class="mx-2"
                    @click="handleNext(3)"
                >Payment</span>
            </li> -->
        </ul>
    </div>
</template>

<script>
    export default {
        props: ['currentStep', 'handleNext', 'user'],
        data() {
            return {
                steps: [
                    'Cart',
                    'Information',
                    'Schedule',
                    'Payment'
                ]
            }
        },
        mounted() {
            if (!this.user && !this.user.id)  {
                this.steps = [
                    'Cart',
                    'Information',
                    'Verification',
                    'Schedule',
                    'Payment'
                ];
            }
        }
    }
</script>