<script>
import homeProducts from './homProducts'
import homeBanners from './banners'
import bigBundleSavings from './bigBundleSavings'

export const HomeProducts = homeProducts
export const Banners = homeBanners
export const BigBundleSavings = bigBundleSavings

export default {
    setup() {
        
    }
}
</script>