class Product {
    
    constructor(product) {
        this.original = JSON.parse(JSON.stringify( product ));
        Object.keys(this.original).forEach((key) => {
            this[key] = this.original[key];
        });
    }

    getTag = (tagName) => {
        let searchTag = null;

        if (this.tags && this.tags.length) {
            searchTag = this.tags.find( tag => tag.name.toLowerCase() === tagName.toLowerCase() )
        }

        return searchTag
    }

    getDiscountPercentage = (product) => {

        let orig_price = parseFloat(product.orig_price);
        let price = parseFloat(product.price);
        let selling_price = product.converted_selling_price;

        let percentage = 0;

        if (orig_price != 0 && orig_price > selling_price) {
            percentage = Math.ceil(((orig_price - price) / orig_price ) * 100);
        } else {
            if (selling_price < price) {
                percentage = Math.ceil(((price - selling_price) / price ) * 100);
            }
        }
        
        return percentage;
    }
}

export default Product;