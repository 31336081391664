<template>
	<div class="bbdoit-page">
		<div
			v-if="featuredArticle"
			class="banner d-flex align-items-center"
			v-bind:style="{  backgroundImage: featuredArticle.image && featuredArticle.image.path ? `url(${backgroundImageAsset(featuredArticle.image)})` : '' }"
		>
			<div class="container">
				<div class="banner-card position-relative p-4 bg-white">
					<div class="bg-green font-sf-medium font-small font-white featured px-3 py-2">
						<i class="fas fa-star font-orange"></i> FEATURED BBDOIT
					</div>
					<div class="p-3">
						<h3 class="font-oversized font-sf-semibold">{{featuredArticle.name && featuredArticle.name}}</h3>
						<div class="font-sf-medium">
							<i class="fas fa-tag font-green mr-1" :style="{ transform: 'rotate(90deg)' }" /> {{ featuredArticle.category.name }}
						</div>
						<div class="font-gray mt-3 mb-5">{{featuredArticle.blurb}}</div>
						<button 
							class="btn btn-green btn-block btn-radius-3 py-2"
							@click="() => $router.push({ name: 'bbdoit.view', params: { slug: featuredArticle.slug } })"
						>Know More</button>
					</div>
				</div>
			</div>
		</div>

		<div class="bg-mint-green text-center py-5">
			<h3 class="font-giantsize font-sf-bold pb-3 pt-4">
				Looking for a specific BBdoit?
			</h3>
			<div class="col-md-6 col-12 mx-auto mb-3">
				<div class="bbdoit-search d-flex align-items-center rounded-pill bg-white p-2 font-gray">
					<div class="ml-3" ><i class="fas fa-search"></i></div>
					<input 
						type="text" 
						class="form-control bg-white border-0 h-100" 
						placeholder="Type recipe name..." 
						v-model="params.search"
						@keyup.enter="searchArticle"
					>
					<button 
						class="btn btn-sm btn-green rounded-pill px-5"
						@click="searchArticle"
					>SEARCH</button>
				</div>
			</div>
		</div>

		<div class="py-5" v-if="categories && categories.length > 0" >
			<div class="container">
				<div class="border-bottom" >
					<h2 class="font-giantsize text-center font-sf-bold py-3">
						Browse by
					</h2>
					<div class="row row-thin mb-5 mt-1">
						<div 
							v-for="category in categories"
							:key="category.id" 
							class="col-sm-12 col-md-6 col-lg-4 mb-2"
						>
							<button
								:class="['btn btn-white bg-green-lighter border-transparent btn-lg btn-block btn-radius-2 d-flex align-items-center ', params.category == category.slug ? 'active bg-gray-1' : '']"
								@click="filterByCategory(category)"
							>
								<Imager
									className="ml-md-5 ml-0 mr-2"
									:src="category.asset"
									:extract="true"
								/>
								{{ category.name }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container">
			<div v-if="articles.length > 0" class="d-flex flex-column flex-lg-row justify-content-end">
				<div class="d-flex align-items-center flex-column flex-md-row mb-4" id="articles-result">
					<span class="mr-2 font-xsmall font-sf-medium text-nowrap font-gray">Sort by</span>
					<button 
						:class="['btn btn-sm rounded justify-content-center justify-content-md-start w-xs-100 mb-2 mb-md-0 btn-white d-flex align-items-center mr-1 ', params.sort == 'recommended:desc' ? 'active' : '']"
						@click="sortBy('recommended:desc')"
					>
						<img class="img-small mr-1" src="@assets/img/like.png" alt="like-icon" />
						<span class="" >Recommended</span>
					</button>
					<button 
						:class="['btn btn-sm btn-white d-flex align-items-center rounded justify-content-center justify-content-md-start w-xs-100 mb-2 mb-md-0 mr-1 ', params.sort == 'trending:desc' ? 'active' : '']"
						@click="sortBy('trending:desc')"
					>
						<img class="img-small mr-1" src="@assets/img/trending.png" alt="trending-icon" />
						Trending
					</button>
					<button 
						:class="['btn btn-sm btn-white mr-1 d-flex align-items-center rounded justify-content-center justify-content-md-start w-xs-100 mb-2 mb-md-0 ', params.sort == 'latest:desc' ? 'active' : '']"
						@click="sortBy('latest:desc')"
					>
						<div class="d-inline-block bg-green font-white font-xxsmall mr-1">
							NEW
						</div>
						Latest
					</button>
					<button 
						:class="['btn btn-sm rounded justify-content-center justify-content-md-start w-xs-100 mb-2 mb-md-0 btn-white mr-1 ', params.sort == 'alpha:desc' || params.sort == 'alpha:asc' ? 'active' : '']"
						@click="(params.sort != 'alpha:asc' && params.sort != 'alpha:desc') ? sortBy('alpha:asc') : (params.sort == 'alpha:asc' ? sortBy('alpha:desc') : sortBy('alpha:asc'))"
						:style="{ whiteSpace: 'nowrap' }"
					>
						<span>Alphabetical {{ (params.sort != 'alpha:asc' && params.sort != 'alpha:desc') ? 'A-Z' : (params.sort == 'alpha:asc') ? 'A-Z' : 'Z-A'}}</span>
						<span :class="`fas fa-long-arrow-alt-down ml-1`" />
					</button>
				</div>
			</div>
			<div>
				<div v-if="articlesLoading" >
					<div class="row row-thin">
						<div 
							class="col-sm-6 col-md-6 col-lg-4 mb-3 mb-2"
							v-for="n in 6"
							:key="n"
						>
							<CardLoader />
						</div>
					</div>
				</div>
				<div v-else >
					<div v-if="articles.length > 0" class="mb-5" >
						<div class="row row-thin">
							<div 
								class="col-sm-6 col-md-6 col-lg-4 mb-3"
								v-for="article in articles"
								:key="article.slug"
							>
								<BbdoitCard 
									:article="article"
								/>
							</div>
						</div>
						<div v-if="pagination && pagination.last_page > 1" >
							<Pagination
								:currentPage="params.page"
								:lastPage="pagination.last_page"
								:params="params"
							/>
						</div>
					</div>
					<div v-else class="py-5 mb-5 font-giantsize text-center font-sf-bold">No articles found</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BbdoitCard from '@components/bbdoitCard/BbdoitCard';
import bannerBg from '@assets/img/strawberry.png';
import { Imager } from '@components/common';
import { backgroundImageAsset } from '@helpers/caboodle.helper';
import { CardLoader } from '@components/common';
import Pagination from '@components/pagination';
import { connect } from '@api';

const initialParams = {
	page_size: 18,
	page: 1,
	search: '',
	category: '',
	sort: 'recommended:desc',
	exclude: [],
	type: 'article'
}

export default {
	props: ['user'],
	components: {
		BbdoitCard,
		Imager,
		CardLoader,
		Pagination
	},
	data() {
		return {
			bannerBg,
			articles: [],
			categories: [],
			featuredArticle: null,
			pagination: null,
			params: initialParams,
			articlesLoading: true,
		};
	},
	created() {
		this.getFeaturedArticle()
				.then(res => {
					if (res.status == 200) {
						this.featuredArticle = res.data.data[0];
						// this.params.exclude.push(res.data.data[0].id);
						
						this.getArticles();
					}
				})
				.catch(err => {
					console.log(err)
				})
		this.getArticleCategories();
	},
	methods: {
		backgroundImageAsset,
		reInitializeParams() {
			this.params = initialParams;
		},
		searchArticle() {
			this.getArticles();

			let currentScrollPosition = 0;
            let targetElement = document.getElementById('articles-result');

            // Firefox, Chrome, Opera, Safari
            if (self.pageYOffset) currentScrollPosition = self.pageYOffset;
            // IE 6
            if (document.documentElement && document.documentElement.scrollTop) currentScrollPosition = document.documentElement.scrollTop;
            // IE 6,7,8
            if (document.body.scrollTop) currentScrollPosition = document.body.scrollTop;

            window.scrollTo(currentScrollPosition, targetElement.offsetTop);
		},
		sortBy(sort) {
			this.params.sort = sort;
			this.getArticles();
		},
		filterByCategory(category) {

			let currentCategory = this.params.category;
			let categoryQuery = '';

			this.reInitializeParams();

			if (currentCategory == category.slug) {
				categoryQuery = '';
			} else {
				categoryQuery = category.slug;
			}

			this.$router.replace({ query: { category: categoryQuery } })
		},
		getArticles() {
			this.articlesLoading = true;

			connect().get('/articles/get', { params: this.params })
					.then(res => {
						if (res.status == 200) {
							this.articles = res.data.data.data;
							this.pagination = res.data.data;
						}
						this.articlesLoading = false;
					})
					.catch(err => {
						this.articlesLoading = false;
					})
		},
		getFeaturedArticle() {
			return connect().get('/articles/featured', { params: { num_articles: 1, type: 'article' } })
		},
		getArticleCategories() {
			connect().get('/article-categories/get', {})
					.then(res => {
						if (res.status == 200) {
							this.categories = res.data.data;
						}
					})
		}
	},
	watch: {
		$route(to) {

			let { query } = to;
			this.params = {...this.params, ...query}

			this.getArticles();
		}
	}
};
</script>

<style></style>
