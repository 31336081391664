<template>
	<div>
		<button class="btn btn-clear btn-sm font-gray" @click="handleBack">
			<i class="fas fa-chevron-left mr-2" /> Back
		</button>

		<div>
			<div
				class="mobile-icon font-green mx-auto font-giantsize bg-gray d-flex align-items-center justify-content-center"
			>
				<!-- <img src="" alt="" /> -->
				<i class="far fa-mobile-android"></i>
			</div>

			<div class="px-2 px-sm-5 mx-2 mx-sm-4 mb-5 pb-4 mt-3 text-center font-sf-bold">
				Enter the verification code sent to you via SMS
			</div>
		</div>

		<div
			class="sms-code-container d-flex align-items-center justify-content-center"
			@click="onFocus"
		>
			<div class="sms-input mx-2">{{ smsCode.charAt(0) }}</div>
			<div class="sms-input mx-2">{{ smsCode.charAt(1) }}</div>
			<div class="sms-input mx-2">{{ smsCode.charAt(2) }}</div>
			<div class="sms-input mx-2">{{ smsCode.charAt(3) }}</div>
			<div class="sms-input mx-2">{{ smsCode.charAt(4) }}</div>
			<div class="sms-input mx-2">{{ smsCode.charAt(5) }}</div>
			<input
				class="position-absolute sms-code-input"
				type="text"
				v-on:input="(e) => this.$emit('updateSmsCode', e.target.value)"
				ref="smsCodeInput"
				maxlength="6"
				@keydown.enter="handleNext"
			/>
		</div>
		<div v-if="smsError" class="font-small font-red text-center my-3">{{smsError}}</div>
		<div class="text-center mt-2" v-if="!smsVerified">
			<button
				class="btn btn-clear btn-sm font-green pl-0"
				v-bind:disabled="resendDisabled"
				@click="startTimer"
			>
				<span class="font-gray">
					Resend code
				</span>
				{{ timer ? timer + 's' : '' }}
			</button>
		</div>
		<button
			class="btn btn-green btn-block btn-radius-3 mb-4 mt-5 py-2"
			@click="handleNext"
			v-bind:disabled="nextDisabled"
		>
			Next
		</button>
	</div>
</template>

<script>
import { connect } from '../../helpers/api.helper';
export default {
	name: 'smsverification',
	props: [
		'handleNext',
		'handleBack',
		'nextDisabled',
		'formValues',
		'smsCode',
		'smsVerified',
		'updateSmsCode',
		'smsError',
		'verificationCode',
		'handleSend'
	],
	data() {
		return {
			timer: 0,
			resendDisabled: true,
			showResend: false,
		};
	},
	mounted: function() {
		this.startTimer();
	},
	methods: {
		async startTimer() {
			this.showResend = true;
			this.timer = 120;
			this.resendDisabled = true;

			if (! (await this.handleSend())) {
				this.resendDisabled = false;
				this.timer = 0;
				return;
			}

			let interval = setInterval(() => {
				this.timer--;
				if (this.timer == 0) {
					clearInterval(interval);
					this.resendDisabled = false;
				}
			}, 1000);
		},
		onFocus() {
			this.$nextTick(() => this.$refs.smsCodeInput.focus());
		},
	},
};
</script>

<style></style>
