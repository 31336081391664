<template>
    <b-modal 
        id="product-popup" 
        modal-class="product-popup"
        dialog-class="modal-dialog-centered"
        content-class="border-0 rounded-0"
        body-class="p-3 px-4"
        size="lg"
        hide-footer
        hide-header
    >
        <div 
            @click="closePopup()"
            class="product-popup-close-btn clickable">
            <div class="fa-stack">
                <i class="fal fa-circle fa-stack-2x"></i>
                <i class="fas fa-times fa-stack-1x"></i>
            </div>
        </div>
        <div v-if="isLoading" >
            <div class="w-50 mb-4">
                <div class="skeleton-loader text"></div>
            </div>
            <div class="row mb-4 mx-0">
                <div class="col pl-0">
                    <div class="skeleton-loader image large"></div>
                </div>
                <div class="col pr-0">
                    <div class="skeleton-loader text w-25 mb-3"></div>
                    <div class="skeleton-loader header w-50 mb-4"></div>
                    <div class="my-5 py-5">
                        <div class="skeleton-loader text mb-4"></div>
                        <div class="skeleton-loader text mb-4"></div>
                        <div class="skeleton-loader text w-50"></div>
                    </div>
                </div>
            </div>
            <div class="row row-thin mb-4">
                <div class="col" v-for="n in 3" :key="n" >
                    <CardLoader />
                </div>
            </div>
        </div>
        <div v-else >
            <div v-if="product" >


                <div 
                    v-if="product.getDiscountPercentage(product) > 0"
                    class="discount font-xsmall font-white font-sf-medium"
                >
                    <div class="position-relative py-1 px-2">
                        Save {{ product.getDiscountPercentage(product) }}%
                    </div>
                </div>

                <ol 
                    class="breadcrumb bg-white font-xsmall font-gray p-0"
                    v-if="product.product_category"
                >
                    <li 
                        class="breadcrumb-item"
                        v-if="product.product_category.parent"
                    >{{product.product_category.parent.title}}</li>
                    <li class="breadcrumb-item active">{{ product.product_category.title }}</li>
                </ol>

                <div class="row">
                    <div class="col-lg-6 col-12 d-flex flex-column">
                        <div class="product-image-container text-center p-3 flex-grow-1 d-flex align-items-center justify-content-center">
                            <Imager 
                                :src="activeImage" 
                                :extract="true" 
                            />
                        </div>
                        <carousel 
                            v-if="product.images && product.images.length > 0"
                            class="images-container"
                            :perPage="6"
                            :paginationEnabled="false"
                        >
                            <slide 
                                v-for="(image, index) in product.images" 
                                :key="index" 
                                class="p-2 clickable"
                            >
                                <Imager 
                                    :src="image.asset"
                                    :extract="true"
                                    @click-image="setActiveImage(image.asset)"
                                />
                            </slide>
                        </carousel>
                    </div>
                    <div class="col-lg-6 col-12">
                        <div class="d-flex flex-column h-100">
                            <div class="d-flex flex-wrap mb-2">
                                <div
                                    class="btn rounded-0 trending font-xsmall py-1 px-2 font-white font-sf-medium d-inline-flex align-items-center w-auto mr-2"
                                    v-if="product.getTag('trending')"
                                >
                                    <img class="img-small mr-1" src="@assets/img/trending.png" alt="" />
                                    Trending
                                </div>
                                <div
                                    class="btn rounded-0 w-auto font-xsmall py-1 px-2 bg-orange-light font-orange font-sf-medium"
                                    v-if="product.admin_rating > 0 ||product.getTag('recommended')"
                                >
                                    Recommended
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1" >
                                    <div 
                                        v-if="product.brand"
                                        class="font-small font-green font-sf-medium"
                                    >
                                        {{ product.brand.title }}
                                    </div>
                                    <div class="font-sf-medium font-upsized d-flex align-items-center flex-wrap">
                                        <span
                                            class="rounded-0 font-xsmall px-2 font-white font-sf-medium bg-green w-auto mr-1"
                                            v-if="product.getTag('new')"
                                        >
                                            NEW!
                                        </span>
                                        {{ product.title }}
                                    </div>
                                </div>
                                <div class="text-center clickable">
                                    <div class="fa-stack">
                                        <i class="fal fa-circle fa-stack-2x  font-gray-1"></i>
                                        <i class="fas fa-share-alt fa-stack-1x font-gray font-xsmall"></i>
                                    </div>
                                    <div class="font-xxsmall font-gray">Share</div>
                                </div>
                            </div>
                            
                            <div class="my-4" >
                                <div>
                                    <CustomerRating :productRating="product.avg_rating" />
                                </div>
                                <div>
                                    <BuyboyRating :rating="4" />
                                </div>
                            </div>

                            <div 
                                v-if="product.variant_groups && product.variant_groups.length > 0" 
                                class="my-auto"
                            >
                                <div 
                                    v-for="(variant, index) in product.variant_groups" 
                                    :key="index"
                                >
                                    <div class="font-small">{{variant.name}}</div>
                                    <div class="d-flex align-items-center flex-wrap">
                                        <span 
                                            v-if="variant.options && variant.options.length > 0" 
                                        >
                                            <button 
                                                v-for="(option, index) in variant.options"
                                                :key="index"
                                                :class="`btn btn-sm rounded-pill btn-white font-xxsmall mr-2 ${ selectedVariant && selectedVariant.id == option.id ? 'active' : '' }`" 
                                                @click="() => { selectedVariant = option }"
                                            >{{option.name}}</button>
                                        </span>
                                        <span class="font-xxsmall font-gray-light"> {{ product.inventory }} {{ product.unit ? product.unit.name : 'pieces' }} available</span>
                                    </div>
                                </div>
                            </div>
                            <div class="my-4" v-else >
                                <span class="font-xxsmall font-gray-light"> {{ product.inventory }} {{ product.unit ? product.unit.name : 'pieces' }} available</span>
                            </div>

                            <div class="font-upsized font-sf-bold">
                                <Price 
                                    :price="product.price"
                                    priceClass="font-red-dark mr-2"
                                    :originalPrice="product.orig_price > 0 ? product.orig_price : 0"
                                    originalPriceClass="font-gray-light"
                                />
                            </div>
                            <button class="btn my-2 p-2 btn-block rounded-pill bg-green font-white font-small font-sf-medium" >Add to Cart</button>
                            <div 
                                class="font-xsmall font-gray font-sf-medium clickable"
                                @click="toggleListModal"
                            >
                                <i class="far fa-heart font-red-dark"></i>
                                Add to your FreQuincy List
                            </div>
                        </div>
                    </div>
                </div>
                <div 
                    v-if="relatedProducts && relatedProducts.length > 0" 
                    class="border-top mt-5 pt-2" 
                >
                    <div class="font-xxlarge font-sf-medium my-2">Related Products</div>
                    <div class="row row-thin">
                        <div 
                            class="col-md-4 col-12"
                            v-for="relatedProduct in relatedProducts"
                            :key="relatedProduct.slug"
                        >
                            <ProductCard
                                :product="relatedProduct"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import Product from '@factories/product.factory'
import { Price, CustomerRating, BuyboyRating } from '@components/common';
import ProductCard from '@components/productCard/ProductCard';
import { CardLoader } from '@components/common';
import { connect } from '@api';

export default {
    name: 'ProductPopup',
    components: {
        Price,
        CustomerRating,
        ProductCard,
        CardLoader,
        BuyboyRating
    },
    props: {
        show: Boolean,
        slug: String
    },
    data() {
        return {
            product: null,
            relatedProducts: [],
            activeImage: null,
            selectedVariant: null,
            params: {
                page_size: 3,
                category: '',
                exclude: [],
                sort: 'relevance:desc'
            },
            isLoading: true,
        }
    },
    mounted() {
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if (modalId == 'product-popup') {
                if (this.product && this.product.asset) {
                    this.setActiveImage(this.product.asset);
                    this.selectedVariant = null;
                }
            }
        })
    },
    methods: {
        findProduct(slug) {
            this.isLoading = true;
            connect().get('products/find', { params: { slug: slug } })
                .then(res => {
                    if (res.status == 200) {

                        if (res.data && res.data.data && res.data.data.parent) {
                            this.product = new Product(res.data.data.parent);

                            console.log(this.product.getTag('trending'));

                            if (this.product.asset && this.product.asset.path) {
                                this.activeImage = this.product.asset;
                            }

                            let newParams = {
                                category: this.product.product_category ? this.product.product_category.slug : '',
                                exclude: [this.product.id]
                            }
                            this.params = {...this.params, ...newParams};

                            this.getRelatedProducts();
                        }
                    }
                })
                .catch(err => {
                    console.error(err);
                })
        },
        getRelatedProducts() {
            connect().get('products/get', { params: this.params })
                .then(res => {
                    if (res.status == 200) {
                        this.isLoading = false;
                        this.relatedProducts = res.data.products.data;
                    }
                })
                .catch(err => {
                    console.error(err);
                })
        },
        setActiveImage(image) {
            this.activeImage = image;
        },
        closePopup() {
            this.$bvModal.hide('product-popup');
        },
        toggleListModal() {
            this.$emit('toggle-list-modal');
        }
    },
    watch: {
        slug: {
            handler (newSlug) {
                this.findProduct(newSlug);
            }
        }
    }
}
</script>