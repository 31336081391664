export default {
    path: '/activate',
    name: 'activate',
    redirect: to => {

        return {
            name: 'home',
            query: {...to.query}
        }
    }
}