<template>
    <div class="position-relative">
		<button class="btn btn-clear btn-sm font-gray" @click="handleBack">
			<i class="fas fa-chevron-left mr-2" /> Back
		</button>

		<div class="px-2 mx-sm-4 mb-4 pb-4 mt-5 text-center">
			<div class="font-sf-bold px-3">
				Enter your email to receive latest offers and promos from QuincyBuyBoy
			</div>
			<div class="font-xsmall font-gray px-5">
				We also send all your order confirmations and transactions via email
			</div>
		</div>

		<div>
			<div class="form-group font-xsmall mb-2">
				<label class="font-gray">Email</label>
				<div class="input-group append">
					<input 
                        type="text" 
                        class="form-control placeholder-gray-light font-xsmall" 
                        placeholder="Email" 
                        v-model="formValues.email"
                        :disabled="showResend"
                    />
					<div class="input-group-append">
                        <button 
                            class="input-group-text font-xsmall font-sf-medium font-gray-light clickable"
                            @click="sendEmailCode"
                            :disabled="showResend"
                            :style="{
                                cursor: showResend ? 'not-allowed' : 'pointer'
                            }"
                        >
                            {{ sendingCode ? 'SENDING...' : 'SEND CODE' }}
                        </button>
					</div>
				</div>
                <div v-if="formErrors.email" class="font-small font-red">{{ formErrors.email }}</div>
			</div>
			<div class="form-group font-xsmall mb-1">
				<input
					type="text"
					class="form-control font-xsmall"
					placeholder="Enter code sent via email"
					v-model="formValues.code"
				/>
                <div class="font-small font-red" v-if="formErrors.code" >{{ formErrors.code }}</div>
			</div>
		</div>
		<div class="mt-0" v-if="showResend">
			<button
				class="btn btn-clear btn-sm font-green pl-0"
				v-bind:disabled="resendDisabled"
				@click="sendEmailCode"
			>
				<span class="font-gray">
					Resend code
				</span>
				{{ timer ? timer + 's' : '' }}
			</button>
		</div>
		<div class="pt-5" >
            <button
                class="btn btn-green btn-block btn-radius-3 mb-4 mt-5 py-2"
                @click="handleSubmit(true)"
                :disabled="processing"
            >
                {{ processing ? 'Validating...' : 'Submit' }}
            </button>
        </div>
		<!-- <div class="text-center">
			<button 
                class="btn btn-clear btn-sm mt-3 font-green"
                @click="handleSubmit(false)"
            >
				Skip email verification for now
			</button>
		</div> -->
	</div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import { connect } from '@api';
import * as types from '../../store/mutation-types';
import { showAlert } from '@helpers/notification.helper';
import * as sessionHelper from '@helpers/session.helper';

const swalSettings = {
    title: 'Congratulations!',
    html: 'You have successfully changed your email. <br/> Please login using your new credentials.'
}

export default {
    data() {
        return {
            formValues: {
                email: '',
                code: ''
            },
            formErrors: {
                email: '',
                code: ''
            },
            timer: 0,
            resendDisabled: true,
            showResend: false,
            processing: false,
            sendingCode: false,
        }
    },
    mounted() {

        let { query } = this.$route;
        let user = sessionHelper.getUser();

        if (query.action && query.action == 'verify') {
            this.formValues.email = user.email;
        }
    },
    methods: {
        ...mapMutations({
            updateUser: types.UPDATE_USER
        }),
        ...mapActions({
            logout: types.LOGOUT
        }),
        startTimer() {
			this.showResend = true;
			this.timer = 120;
			this.resendDisabled = true;
			let interval = setInterval(() => {
				this.timer--;
				if (this.timer == 0) {
					clearInterval(interval);
					this.resendDisabled = false;
				}
			}, 1000);
        },
        reset(type = null) {

            let keys = Object.keys(this.formValues);

            for (let key of keys) {
                if (type == null) {
                    this.formValues[key] = '';
                    this.formErrors[key] = '';
                } else {
                    this[type][key] = '';
                }
            }

        },
        sendEmailCode() {

            this.sendingCode = true;

            if (this.formValues.email == '') {
                this.formErrors.email = 'Email is required';
                this.sendingCode = false;
                return;
            }

            connect().post('/customers/validate-email-and-send-code', { email: this.formValues.email })
                        .then(res => {
                            this.reset('formErrors');
                            this.sendingCode = false;
                            this.startTimer();  
                        })
                        .catch(err => {
                            this.sendingCode = false;
                            this.formErrors.email = err.data.message;
                            return;
                        })
        },
        handleSubmit(verified = false) {

            this.processing = true;

            if (this.formValues.email == '') {
                this.formErrors.email = 'Email is required';
                this.processing = false;
                return;
            }

            if (this.formValues.code == '' && verified == true) {
                this.formErrors.code = 'Invalid code';
                this.processing = false;
                return;
            }

            connect().post('/customers/validate-email-code', { code: verified ? this.formValues.code : 'skip', email: this.formValues.email })
                        .then(res => {
                            this.processing = false;
                            this.reset('formErrors');

                            this.updateUserDetails({
                                verified: verified,
                                email: this.formValues.email
                            });
                        })
                        .catch(err => {
                            this.formErrors.code = err.data.message;
                            this.processing = false
                            return;
                        })
        },
        updateUserDetails(params) {
            connect().post('/customers/update-details', params)
                .then(res => {
                    this.updateUser(res.data.data);
                    this.processing = false;
                    showAlert(swalSettings)
                            .then(() => {
                                // this.$router.push('/account/profile');
                                this.logout();
                                this.$router.push({ name: 'login' })
                            });
                })
        },
        handleBack() {
            this.$router.push({ name: 'account.profile' })
        }
    }
}
</script>