<template>
    <div :class="`${ inline ? 'd-inline' : '' }`" >
        <span :class="[priceClass]" > {{ currency }} {{ formatPrice(sellingPrice) }}</span>
        <del v-if="preDiscountedPrice != 0" :class="['ml-2 font-gray',originalPriceClass]">{{ currency }} {{ formatPrice(preDiscountedPrice) }}</del>
    </div>
</template>

<script>
export default {
    props: ["product", "priceClass", "originalPriceClass", "inline"],
    computed: {
        currency() {
            let currency = '₱';

            if (this.$store && this.$store.getters && this.$store.getters.SITE_OPTIONS && this.$store.getters.SITE_OPTIONS.currency) {
                currency = this.$store.getters.SITE_OPTIONS.currency
            }

            return currency;
        },
        sellingPrice() {
            let { product } = this;
            return product && product.converted_selling_price ? product.converted_selling_price : 0
        },
        preDiscountedPrice() {
            let { product, sellingPrice } = this;

            let origPrice = parseFloat(product.orig_price);
            let price = parseFloat(product.price);

            let preDiscountedPrice = 0.0;

            if (origPrice != 0 && origPrice > sellingPrice) {
                preDiscountedPrice = origPrice
            } else {
                if (sellingPrice < price) {
                    preDiscountedPrice = price;
                }
            }

            return preDiscountedPrice;
        }
    },
    mounted() {

    },
    methods: {
        formatPrice(price) {
            return Number(price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
    }
}
</script>