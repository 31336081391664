<template>
    <div class="d-flex align-items-center" >
        <span
            v-for="n in productRating"
            :key="n"
            class="mr-2"
        >
            <img 
                src="../../assets/img/buyboy-rating.png" 
                alt="rating"
                class="rating"
            >
        </span>
        <span
            v-if="5 - productRating > 0"
        >
            <span 
                class="mr-2"
                v-for="n in (5-productRating)"
                :key="n"
            >
                <img 
                    src="../../assets/img/buyboy-rating.png" 
                    alt="rating"
                    class="rating inverted"
                >
            </span>
        </span>
        <span class="mr-2 font-xsmall font-green font-sf-medium">{{ parseFloat(productRating).toFixed(1) }}</span>
        <span class="font-xsmall font-gray">Buyboy Rating</span>
    </div>
</template>

<script>
    export default {
        props: {
            productRating: Number
        }
    }
</script>