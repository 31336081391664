<template>
    <div id="contactUs" class="d-flex flex-column flex-lg-row">
        <ContactForm />
        <ContactMap 
            :address="address"
        />
    </div>
</template>

<script>
import ContactForm from './contactForm';
import ContactMap from './contactMap';
import './contact_us.scss';
import { mapGetters } from 'vuex';
import * as types from '../../store/mutation-types';

export default {
    components: {
        ContactForm,
        ContactMap
    },
    data() {
        return {
            address: {
                lat: 14.6091,
                lng: 121.0223,
                address: '',
                contact_number: '',
                contact_mobile: ''
            },
            seo: {
                title: '',
                description: ''
            }
        }
    },
    mounted() {
        let storeLocation = this.getStoreLocation();

        this.address = {...this.address, ...storeLocation};

        let seo = this.getSEO();
        this.seo = {...this.seo, ...seo};
    },
    methods: {
        ...mapGetters({
            getStoreLocation: types.GET_STORE_LOCATION,
            getSEO: types.GET_SEO
        })
    }
}
</script>