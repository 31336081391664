<template>
    <div :class="containerClass" :style="{ pointerEvents: disabled ? `none` : `auto` }">
        <label v-if="label" class="font-gray">{{ label }}</label>
        <div class="position-relative bb-form-group">
            <input
                :type="showPassword ? `text` : type"
                :class="`${ inputClass } ${ type === `password` ? `pr-50` : ``} bb-text-input form-control font-xsmall font-sf-regular`"
                :placeholder="placeholder"
                :value="value" 
                @input="$emit('input', $event.target.value)"
            />
            <button
                v-if="type === 'password'"
                class="show-toggle px-15"
                @click="toggleShowPassword"
            >
                <i class="fal fa-eye" />
            </button>
        </div>
        <div v-if="error" class="text-danger font-sm">
            {{ error }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: String,
        containerClass: String,
        inputClass: String,
        type: String,
        placeholder: String,
        label: String,
        disabled: Boolean,
        error: String
    },
    data () {
        return {
            showPassword: false
        }
    },
    methods: {
        toggleShowPassword: function() {
            this.showPassword = !this.showPassword;
        }
    },
}
</script>