import moment from 'moment';

export const validateBirthday = (date) => {

    let now = moment();
    let dateToValidate = moment(date);

    let difference = Math.floor(now.diff(dateToValidate, 'years', true));

    if (dateToValidate.isSameOrAfter(now)) {
        return false;
    }
    if (difference < 15 || difference > 100) {
        return false;
    }

    return true;
}

export const getValidYears = () => {

    let now = moment();
    let validYears = {
        max: now.format('YYYY-MM-DD'),
        min: now.subtract(100, 'years').format('YYYY-MM-DD')
    }

    return validYears;
}