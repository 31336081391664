<template>
	<div class=" py-4 bg-red-light mt-5">
		<h5 class="text-center mb-3 font-xxlarge font-sf-medium">Big Bundle Savings</h5>
		<div class="d-flex">
			<div class="bundle-control p-15 d-flex align-items-center" control-action="prev" >
				<button
					class="btn-icon"
					v-if="bundles.length > 1"
					@click="goToPage(bundleCurrentSlide - 1)"
				>
					<i class="fas fa-chevron-circle-left text-success font-upsized"/>
				</button>
			</div>
			<div class="w-100">
				<carousel
					:perPage="bundlePerPage"
					:paginationEnabled="false"
					ref="carousel"
					@pageChange="onPageChange"
				>
					<slide
						v-for="bundle in bundles"
						v-bind:key="bundle.id"
					>
						<div class="p-15">
							<BundleCard :bundle="bundle" />
						</div>
					</slide>
				</carousel>
			</div>
			<div class="bundle-control p-15 d-flex align-items-center" control-action="next" >
				<button
					class="btn-icon"
					v-if="bundles.length > 1"
					@click="goToPage(bundleCurrentSlide + 1)"
				>
					<i class="fas fa-chevron-circle-right text-success font-upsized"/>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import BundleCard from '@components/bundleCard/BundleCard';
import * as api from '@api'

export default {
	components: {
		BundleCard,
		Carousel,
		Slide
	},
	data () {
        return {
            bundles: [],
			bundleCurrentSlide: 0,
			bundlePerPage: 3
        }
    },
	mounted() {
		this.getBundles()
	},
	methods: {
        getBundles () {
            api
                .connect()
                .get("bundles")
                    .then(res => {
						if (res.status === 200) this.bundles = res.data.bundles
					})
        },
		onPageChange (page) {
            this.bundleCurrentSlide = page;
        },
        goToPage (page) {
            const bundleSize = Math.ceil(this.bundles.length / this.bundlePerPage) - 1
			console.log(page)
            if (page > bundleSize) page = 0
            if (page < 0) page = bundleSize
            this.$refs.carousel.goToPage(page);
        },
    }
};
</script>