<template>
    <div class="card border-gray-1">
        <div class="card-body">
            <div class="font-xxlarge font-sf-medium flex-fill mb-3">Payment Option</div>
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center flex-wrap font-sf-medium font-small">
                        <span class="font-green">BDO</span>
                        <img 
                            src="@assets/img/bdo-logo.png" 
                            class="ml-auto"
                            :style="{width: '40px'}"
                        >
                    </div>
                    <div class="border-top mt-2 font-small font-sf-medium font-gray pt-2">
                        <div>
                            Kindly settle your payment within 24 hours. You may upload your deposit slip through our email (<span class="font-green" >quincybuyboy@example.com</span>) or upload it <span class="font-green">here</span>.
                        </div>
                        <table class="table-borderless table-sm my-2">
                            <tbody>
                                <tr class="font-sf-medium" >
                                    <td class="font-gray" >Account Number</td>
                                    <td>1234-5678909</td>
                                </tr>
                                <tr class="font-sf-medium" >
                                    <td class="font-gray" >Account Name</td>
                                    <td>Joanne C. Santos</td>
                                </tr>
                            </tbody>
                        </table>
                        <button class="btn btn-sm btn-clear border-green rounded-pill font-green">Upload Deposit Slip</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>