<template>
    <div class="account-address-page d-flex flex-column h-100">
        <div class="d-flex align-items-center justify-content-between">
            <div class="font-giantsize font-sf-bold my-3">My Address</div>
            <router-link
                :to="{ name: 'account.address.form', params: { method: 'create' } }"
                class="btn btn-sm py-1 btn-white rounded-pill"
            >
                <i class="fas fa-plus font-green"></i> 
                Add new address
            </router-link>
        </div>
        <div class="table-container table-responsive flex-grow-1 border border-gray-1">
            <table class="table table-borderless font-xsmall address-table">
                <thead class="bg-gray-2 font-sf-medium font-gray">
                    <tr>
                        <th>Full Name</th>
                        <th>Complete Address</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="font-black" v-if="addresses.length > 0" >
                    <tr 
                        class="border-bottom" 
                        v-for="(address, index) in addresses" 
                        :key="index" 
                    >
                        <td>{{ address.firstname + ' ' + address.lastname }}</td>
                        <td>{{ formatAddress(address) }}</td>
                        <td>
                            <div class="d-flex align-items-center justify-content-flex-end dropdown">
                                <span 
                                    :class="['btn-default ml-auto mr-3', defaultAddress == address.id ? 'active' : '']" 
                                    @click="() => {
                                        if (defaultAddress !== address.id) setDefaultAddress(address.id);
                                    }"
                                >
                                    <span v-if="defaultAddress == address.id" >Default</span>
                                    <span 
                                        v-else 
                                    >Set as Default</span>
                                </span>
                                <SettingsPopup 
                                    :key="index" 
                                    type="address" 
                                    :id="address.id"
                                    @delete-item="deleteAddress(address.id)"
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody class="font-black" v-else >
                    <tr>
                        <td colspan="4" >
                            <div class="text-center py-5 font-sf-medium">No addresses found</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import SettingsPopup from './settingsPopup';
import { connect } from '@api';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import * as types from '../../store/mutation-types';
import * as sessionHelper from '@helpers/session.helper';
import { showAlert } from '@helpers/notification.helper';

let deleteSwalSettings = {
    title: 'Delete Address',
    text: 'Are you sure you want to delete this address?',
    confirmButtonText: 'Continue',
    cancelButtonText: 'Cancel',
    customClass: {
        actions: 'px-3',
        cancelButton: 'flex-fill',
        confirmButton: 'flex-fill'
    }
}

export default {
    data() {
        return {
            addresses: [],
            defaultAddress: 0
        }
    },
    computed: {
        user() {
            return sessionHelper.getUser()
        },
    },
    mounted() {
        if (this.user) {
            this.getAddresses();

            this.defaultAddress = this.user.customer && this.user.customer.shipping_address_id ? this.user.customer.shipping_address_id : 0;
        }
    },
    methods: {
        ...mapMutations({
            updateUser: types.UPDATE_USER
        }),
        togglePopup(e) {
            this.$emit('toggle-settings-popup', e);
        },
        formatAddress(address) {
            let addressString = '';
            
            if (address.address_line_1) {
                addressString += address.address_line_1 + ', ';
            }
            if (address.custom_area) {
                addressString += address.custom_area + ', ';
            }
            if (address.city_name) {
                addressString += address.city_name + ', ';
            }
            if (address.province_name) {
                addressString += address.province_name + ', ';
            }
            
            return addressString;
        },
        getAddresses() {
            connect().get('/customers/get-addresses', { params: {customer_id: this.user.customer_id} })
                .then(res => {
                    if (res.status == 200) {
                        this.addresses = res.data.data;
                    }
                })
                .catch(err => {
                    console.error(err.data.message);
                })
        },
        setDefaultAddress(id) {

            let params = {
                id: id,
                billing: true,
                shipping: true,
            };

            connect().post('/customers/set-default-address', params)
                .then(res => {
                    if (res.status == 200) {
                        this.defaultAddress = res.data.data.id;

                        let { user } = this;
                        user.customer.billing_address = res.data.data;
                        user.customer.billing_address_id = this.defaultAddress;

                        user.customer.shipping_address = res.data.data;
                        user.customer.shipping_address_id = this.defaultAddress;

                        user.customer.primary_address = res.data.data;

                        this.updateUser(user);
                    }
                })
                .catch(err => {
                    console.error(err.data.message)
                })
        },
        deleteAddress(id) {
            
            showAlert(deleteSwalSettings, 'confirm')
                .then(swal => {
                    if (swal.isConfirmed) {
                        connect().post('/customers/delete-address', { id: id })
                            .then(res => {
                                if (res.status == 200) {
                                    this.addresses = this.addresses.filter(address => address.id != id);
                                    this.defaultAddress = res.data.new_primary_address && res.data.new_primary_address.id ? res.data.new_primary_address.id : 0;
                                    
                                    let { user } = this;
                                    if (res.data.new_primary_address && res.data.new_primary_address.id) {

                                        user.customer.billing_address = res.data.new_primary_address;
                                        user.customer.billing_address_id = this.defaultAddress;
    
                                        user.customer.shipping_address = res.data.new_primary_address;
                                        user.customer.shipping_address_id = this.defaultAddress;

                                        user.customer.primary_address = res.data.new_primary_address;
                                    }

                                    user.customer.all_address = this.addresses;
                                    if (this.addresses.length == 0) {
                                        user.customer.primary_address = null;
                                    }

                                    this.updateUser(user);


                                    let notif = {
                                        title: 'Congratulations!',
                                        text: 'You successfully deleted this address',
                                        customClass: {
                                            confirmButton: 'flex-fill mx-5'
                                        }
                                    };

                                    showAlert(notif)
                                }

                            })
                            .catch(err => {
                                console.error(err.data.message)
                            })
                    }
                })
        }
    },
    components: {
        SettingsPopup
    },
}
</script>