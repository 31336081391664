<template>
    <div>
        <button
            class="btn btn-clear font-gray font-xsmall btn-block px-0 py-1 d-flex justify-content-between align-items-center text-left"
        >
            <router-link 
                class="text-wrap category-item flex-grow-1" 
                :to="{ query: { ...params, category: category.slug } }"
            >{{ category.title }} </router-link>
            <div v-if="category.children.length > 0" >
                <i 
                    class="fas fa-angle-down font-gray-light font-large pl-4"
                    v-b-toggle="`collapse-${ category.slug }`"
                ></i>
            </div>
        </button>
        <b-collapse 
            :id="`collapse-${ category.slug }`" 
            v-if="category.children.length > 0"
            class="pl-3"
        >
            <CategoryItem
                v-for="child in category.children"
                :key="child.id"
                :category="child"
            />
        </b-collapse>
    </div>
</template>

<script>
export default {
    name: 'CategoryItem',
    props: {
        category: Object,
        params: Object
    }
}
</script>