<template>
    <div class="brand-page" >
        <div v-if="loading" >
            <div class="container my-5">
                <div class="row row-thin my-5">
                    <div v-for="n in 8" :key="n" class="col-xl-3 col-sm-6 col-12 mb-3" >
                        <CardLoader/>
                    </div>
                </div>
            </div>
        </div>
        <div v-else >
            <div 
                v-if="brand"
                class="brand-page-header position-relative"
            >
                <div class="container">
                    <div class="d-flex align-items-center justify-content-center py-5">
                        <div class="text-center my-5">
                            <Imager 
                                :src="brand"
                                :extract="true"
                                class="rounded-circle mb-2 border border-gray-1 brand-image"
                            />
                            <div class="font-giantsize font-sf-bold">{{brand.title}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div 
                    v-if="hotDealsProducts && hotDealsProducts.length > 0"
                    class="bg-red-light rounded p-4"
                >
                    <div class="text-center font-xxlarge font-sf-medium mb-4">Brands Hot Deals</div>
                    <carousel 
                        class="images-container"
                        :perPageCustom="[[320, 1], [768, 2], [1024, 3], [1366, 4]]"
                        :paginationEnabled="false"
                        :navigationEnabled="true"
                        navigationNextLabel="
                            <div class='fa-stack shadow rounded-circle'>
                                <i class='fas fa-circle fa-stack-2x font-white'></i>
                                <i class='fas fa-chevron-right fa-stack-1x font-gray font-xsmall'></i>
                            </div>
                        "
                        navigationPrevLabel="
                            <div class='fa-stack shadow rounded-circle'>
                                <i class='fas fa-circle fa-stack-2x font-white'></i>
                                <i class='fas fa-chevron-left fa-stack-1x font-gray font-xsmall'></i>
                            </div>
                        "
                        v-if="products"
                    >
                        <slide 
                            v-for="product in hotDealsProducts" 
                            :key="product.slug"
                            class="p-0" 
                        >
                            <ProductCard
                                :product="product"
                                class="bg-white mx-2"
                            />
                        </slide>
                    </carousel>
                </div>
                <div class="py-5">
                    <div v-if="productsLoading" >
                        <div class="row row-thin">
                            <div
                                class="col-xl-3 col-sm-6 col-12 mb-3"
                                v-for="n in 8"
                                :key="n"
                            >
                                <CardLoader />
                            </div>
                        </div>
                    </div>
                    <div v-else >
                        <div v-if="products && products.length > 0"  >
                            <div class="d-flex align-items-center flex-column flex-md-row mb-4 flex-wrap">
                                <span class="mr-1 font-gray font-xsmall ml-md-auto">Sort by:</span>
                                <button 
                                    :class="`btn btn-white btn-sm mr-1 p-2 px-3 rounded w-xs-100 mb-2 mb-md-0 ${ params.sort == 'hot:desc' ? 'active' : '' }`"
                                    @click="sort('hot:desc')"
                                >
                                    <img class="img-small" src="../../assets/img/hot.png" alt="hot-icon" />
                                    Hot Deals
                                </button>
                                <button 
                                    :class="`btn btn-white btn-sm mr-1 p-2 px-3 rounded w-xs-100 mb-2 mb-md-0 ${ params.sort == 'latest:desc' ? 'active' : '' }`"
                                    @click="sort('latest:desc')"
                                >
                                    <div class="d-inline-block bg-green font-white font-xxsmall">NEW</div>
                                    Latest
                                </button>
                                <button 
                                    :class="`btn btn-white btn-sm mr-1 p-2 px-3 rounded w-xs-100 mb-2 mb-md-0 ${ params.sort == 'sales:desc' ? 'active' : '' }`"
                                    @click="sort('sales:desc')"
                                >
                                    <img class="img-small" src="../../assets/img/hot.png" alt="hot-icon" />
                                    Top Sales
                                </button>
                                <button 
                                    :class="`btn btn-white btn-sm mr-1 p-2 px-3 rounded w-xs-100 mb-2 mb-md-0 ${ params.sort == 'trending:desc' ? 'active' : '' }`"
                                    @click="sort('trending:desc')"
                                >
                                    <img class="img-small" src="../../assets/img/trending.png" alt="trending-icon" />
                                    Trending
                                </button>
                                <button 
                                    :class="`btn btn-white btn-sm mr-1 p-2 px-3 rounded w-xs-100 mb-2 mb-md-0 ${ params.sort == 'alpha:asc' || params.sort == 'alpha:desc' ? 'active' : '' }`"
                                    @click="(params.sort != 'alpha:asc' && params.sort != 'alpha:desc') ? sort('alpha:asc') : (params.sort == 'alpha:asc' ? sort('alpha:desc') : sort('alpha:asc'))"
                                >
                                    Alphabetical {{ (params.sort != 'alpha:asc' && params.sort != 'alpha:desc') ? 'A-Z' : (params.sort == 'alpha:asc') ? 'A-Z' : 'Z-A'}}
                                    <span :class="`fas fa-long-arrow-alt-down ml-1`" />
                                </button>
                                <button 
                                    :class="`btn btn-white btn-sm mr-1 p-2 px-3 rounded w-xs-100 mb-2 mb-md-0 ${ params.sort == 'price:asc' || params.sort == 'price:desc' ? 'active' : '' }`"
                                    @click="(params.sort != 'price:asc' && params.sort != 'price:desc') ? sort('price:asc') : (params.sort == 'price:asc' ? sort('price:desc') : sort('price:asc'))"
                                >
                                    Price {{ (params.sort != 'price:asc' && params.sort != 'price:desc') ? 'L-H' : (params.sort == 'price:asc') ? 'L-H' : 'H-L'}}
                                    <span :class="`fas fa-long-arrow-alt-down ml-1`" />
                                </button>
                            </div>
                            <div class="row row-thin">
                                <div 
                                    v-for="product in products"
                                    :key="`productsCard${product.id}`"
                                    class="col-xl-3 col-sm-6 col-12 mb-3"
                                >
                                    <ProductCard
                                        :product="product"
                                    />
                                </div>
                            </div>
                            <div 
                                v-if="pagination && pagination.last_page > 1" 
                                class="pt-5"
                            >
                                <Pagination
                                    :currentPage="params.page"
                                    :lastPage="pagination.last_page"
                                    :params="params"
                                />
                            </div>
                        </div>
                        <div v-else class="py-5 my-5 text-center font-giantsize font-sf-bold" >No products found</div>
                    </div>
                </div>
            </div>
        </div>
        <ProductPopup
            :product="popupProduct"
            @toggle-list-modal="$emit('toggle-list-modal')"
        />
    </div>
</template>

<script>
import { connect } from '@api';
import ProductCard from '@components/productCard/ProductCard';
import ProductPopup from '@components/productPopup';
import { Imager } from '@components/common';
import Pagination from '@components/pagination';
import { CardLoader } from '@components/common';

export default {
    name: 'Brands',
    components: {
        ProductCard,
        Imager,
        ProductPopup,
        CardLoader,
        Pagination
    },
    data() {
        return {
            products: [],
            hotDealsProducts: [],
            pagination: null,
            brand: {},
            params: {
                page: 1,
                page_size: 24,
                sort: "relevance:desc",
                brands: [this.$route.params.slug ? this.$route.params.slug : '']
            },
            popupProduct: null,
            productsLoading: true,
            loading: false
        }
    },
    created() {
        this.getBrand();
    },
    methods: {
        sort(sort) {

            let params = {
                page: 1,
                sort: sort,
            };

            params = {...this.params, ...params};
            this.params = params;

            this.products = [];
            this.getProducts();
        },
        getHotDeals() {
            let params = {
                page: 1,
                page_size: 100,
                sort: 'relevance:desc',
                tags: 'hot deals:hot-deals',
                brands: this.params.brands
            }

            connect().get('products/get', {params: params})
                .then(res => {
                    if (res.status == 200) {
                        this.hotDealsProducts = res.data.products.data
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },
        getProducts() {
            this.productsLoading = true;

			connect().get("products/get", { params: this.params })
                    .then(res => {
                        if (res.status == 200) {
                            this.products = res.data.products.data;
                            this.pagination = res.data.products;
                            this.productsLoading = false;
                        }
                    })
                    .catch(err => {
                        this.productsLoading = false;
                        console.log(err.data.message);
                    })
        },
        getBrand() {
            this.loading = true;

            connect().get("products/get_vendors")
                    .then(res => {
                        if (res.status === 200) {
                            let vendors = res.data.data;

                            this.brand = vendors.find(vendor => {
                                return vendor.slug == this.$route.params.slug;
                            });

                            this.getHotDeals();
                            this.getProducts();

                            this.loading = false
                        }
                    })
        },
        showProductPopup(product) {
            this.popupProduct = product;
            this.$bvModal.show('product-popup');
        }
    },
    watch: {
        $route(to) {

            let { query } = to;
			this.params = {...this.params, ...query}

            this.params.brands = [to.params.slug];
            this.getBrand();
        }
    }
}
</script>