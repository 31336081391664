<template>
    <div class="cart-page" >
        <div class="container">
            <div class="d-flex flex-wrap align-items-center py-4">
                <div class="flex-grow-1 cart-nav d-flex align-items-center">
                    <router-link
                        :to="{ name: 'cart' }"
                        class="cart-nav-item font-xxlarge font-sf-medium mr-5"
                    >   
                        <span>My Cart</span>
                    </router-link>
                    <router-link
                        v-if="user && user.id"
                        :to="{ name: 'frequincy' }"
                        class="cart-nav-item font-xxlarge font-sf-medium"
                    >   
                        <span>My FreQuincy List</span>
                    </router-link>
                </div>
                <button 
                    class="btn btn-sm btn-clear rounded-pill font-green border-green py-2 px-4 mt-4 mt-md-0"
                    @click="clearCart()"
                >Clear My Cart</button>
            </div>

            <!-- CART TABLE -->
            <div class="mb-5">
                <div class="cart-table table-responsive">
                    <div class="cart-table-row font-xsmall font-gray font-sf-medium">
                        <div class="cart-table-row-grid header">
                            <div class="custom-control custom-checkbox">
                                <input 
                                    type="checkbox" 
                                    class="custom-control-input" 
                                    id="toggle-select-all"
                                    :checked="selectedItems.length > 0 && selectedItems.length === items.length"
                                    @change="toggleSelectAll"
                                >
                                <label class="custom-control-label" for="toggle-select-all"><div class="pt-1" >Select All ({{items.length}} item/s)</div></label>
                            </div>
                            <span 
                                class="clickable"
                                v-if="selectedItems.length > 0 && user.id"
                                @click="handleAddToFrequincyList"
                            >
                                <i class="far fa-plus-circle font-green"></i>
                                Add Selected to My FreQuincy List
                            </span>
                            <span 
                                class="clickable" 
                                @click="removeItems"
                                v-if="selectedItems.length > 0"
                            >
                                <i class="fas fa-trash font-green"></i>
                                Remove Selected Items
                            </span>
                        </div>
                    </div>
                    <div class="cart-table-row bg-gray-2 font-xsmall font-gray font-sf-medium d-none d-lg-block">
                        <div class="cart-table-row-grid">
                            <div class="checkbox-column"></div>
                            <div class="image-column text-center">Image</div>
                            <div class="description-column">Item Description</div>
                            <div class="price-column text-center">Unit Price</div>
                            <div class="quantity-column text-center">Quantity</div>
                            <div class="total-column text-center">Total</div>
                            <div class="remove-column"></div>
                        </div>
                    </div>
                    <div 
                        :class="`cart-table-row ${ isOutOfStock(item.product) ? 'bg-gray-2' : '' }`"
                        v-for="(item, index) in items" 
                        :key="index" 
                    >
                        <CartItem
                            :item="item"
                            :selectedItems="selectedItems"
                            :cartParams="cartParams"
                            :items="items"
                            @toggle-select="toggleSelect(item.id)"
                            @remove-item="removeItem(item)"
                            @change-quantity="handleQuantityChange"
                        />
                        <div class="addon-container border border-green rounded p-3 mt-3" v-if="item.add_ons && item.add_ons.length > 0" >
                            <CartItemAddon
                                v-for="(add_on, index) in item.add_ons"
                                :key="index"
                                :item="add_on"
                                :selectedAddons="selectedAddons"
                                :className="`${ index > 0 ? 'border-top' : '' }`"
                                @toggle-select="toggleSelectAddon(add_on)"
                                :changeAddonQty="handleAddonQuantityChange"
                                @remove-item-addon="removeItemAddon(add_on)"
                            />
                        </div>
                    </div>
                    <div class="cart-table-row footer bg-green-transparent">
                        <div class="py-5 text-center">
                            <router-link 
                                class="btn btn-sm py-2 btn-clear rounded-pill border-green font-green font-sf-medium"
                                :to="{ name: 'products' }"
                            >Shop More</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CheckoutSection
            :items="items"
            :selectedItems="selectedItems"
            :subTotal="subTotal"
            @proceed-to-checkout="proceedToCheckout"
        />
    </div>
</template>

<script>
    import * as cartHelper from '@helpers/cart.helper';
    import * as sessionHelper from '@helpers/session.helper';
    import { showAlert } from '@helpers/notification.helper';
    import { mapGetters, mapActions, mapMutations } from 'vuex';
    import * as types from '../../store/mutation-types';
    import CheckoutSection from './components/CheckoutSection';
    import CartItem from './components/CartItem';
    import CartItemAddon from './components/CartItemAddon';

    let swalSettings = {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true,
        customClass: {
            actions: 'd-flex px-3',
            confirmButton: 'bg-white font-black flex-fill',
            cancelButton: 'bg-green font-white flex-fill'
        }
    };

    let clearCartSettings = {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true,
        customClass: {
            actions: 'd-flex px-3',
            confirmButton: 'bg-white font-black flex-fill',
            cancelButton: 'bg-green font-white flex-fill'
        }
    }

    let removeSettings = {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true,
        customClass: {
            actions: 'd-flex px-3',
            confirmButton: 'bg-white font-black flex-fill',
            cancelButton: 'bg-green font-white flex-fill'
        }
    }

    let swalOutOfStockSettings = {
        html: 'There are items that are out of stock.',
        confirmButtonText: 'Ok',
        customClass: {
            confirmButton: 'flex-fill mx-5'
        }
    };

    let swalEmptySettings = {
        html: 'You have not selected any items for checkout',
        confirmButtonText: 'Ok',
        customClass: {
            confirmButton: 'flex-fill mx-5'
        }
    };

    export default {
        components: {
            CheckoutSection,
            CartItem,
            CartItemAddon
        },
        data() {
            return {
                selectedItems: [],
                selectedAddons: [],
                cart: {},
                items: [],
                cartParams: {
                    customer_id: 0,
                    source: 'website',
                    last_device: 'laptop',
                    items: [],
                }
            }
        },
        mounted() {
            this.cart = cartHelper.getCart();

            if (this.cart) {
                this.cartParams = { ...this.cartParams, ...this.cart };
                this.items = this.cart.items;
            }
        },
        computed: {
            user() {
                return sessionHelper.getUser();
            },
            subTotal: {
                get() {

                    let { items, selectedItems, selectedAddons } = this;
                    let subTotal = 0;

                    if (selectedItems.length > 0) {

                        selectedItems.map(selected => {

                            let item = items.find(item => item.id == selected);

                            if (item) {

                                subTotal += ( parseFloat(item.product.converted_selling_price) * item.quantity );

                                if (item.add_ons.length > 0) {
                                    item.add_ons.map(addon => {
                                        
                                        if (selectedAddons.includes(addon.id)) {
                                            subTotal += ( parseFloat(addon.info.price) * addon.qty )
                                        }
                                    })
                                }
                            }
                        })

                    }


                    return subTotal;
                },
                set(newValue) {
                    this.subTotal = newValue
                }
            }
        },
        methods: {
            ...mapActions({
                syncCart: types.SYNC_CART,
                checkout: types.PROCEED_TO_CHECKOUT
            }),
            ...mapMutations({
                toggleListPopup: types.TOGGLE_LIST_POPUP,
                setPopupProductID: types.SET_LIST_POPUP_PRODUCT_ID,
            }),
            clearCart() {

                let swal = clearCartSettings;
                swal.html = 'This will clear all item(s) on your cart. <br/> Do you want to continue?';

                showAlert(swal, 'confirm')
                    .then(swal => {
                        if (swal.isConfirmed) {

                            let { cartParams } = this;
                            cartParams.items = [];

                            this.syncCart(cartParams);

                            this.items = [];
                        }
                    })
            },
            isOutOfStock(product) {

                let isOutOfStock = true;

                if (product.enable_inventory > 0) {
                    isOutOfStock = product.inventory > 0 ? false : true;
                } else {
                    isOutOfStock = false;
                }

                return isOutOfStock;
            },
            proceedToCheckout() {

                let { selectedItems, items, selectedAddons } = this;

                if (selectedItems.length > 0) {

                    let hasOutOfStock = false;

                    selectedItems.map(selected => {

                        let item = items.find(item => item.id == selected);

                        if (item.product.enable_inventory > 0) {
                            if (item.product.inventory <= 0) {
                                hasOutOfStock = true;
                            }
                        }
                    });

                    if (hasOutOfStock) {
                        showAlert(swalOutOfStockSettings, 'error');
                        return;
                    }

                } else {
                    showAlert(swalEmptySettings, 'error');
                    return;
                }

                if (!sessionHelper.isLoggedIn()) {

                    let swal = swalSettings;

                    swal.text = "You must create an account in order to checkout your items.";
                    swal.confirmButtonText = 'Login';
                    swal.cancelButtonText = 'Create Account';
                    swal.customClass.footer = "border-top-0 p-0";
                    swal.footer = "<div class='clickable font-small font-white font-sf-medium' id='proceed-to-checkout'><u>Proceed with Fast Checkout</u></div<"
    
                    showAlert(swal, 'confirm')
                        .then(swal => {

                            let { cartParams } = this;

                            // let newItems = [];
                            // cartParams.items.map(item => {
                            //     if (!selectedItems.includes(item.id)) {
                            //         newItems.push(item);
                            //     }
                            // })
                            // cartParams.items = newItems;
                            // this.syncCart(cartParams);

                            if (swal.isConfirmed) {
                                
                                let routeParams = {
                                    name: 'login',
                                    query: {
                                        redirect: {
                                            name: 'checkout',
                                        },
                                        items: selectedItems,
                                        add_ons: selectedAddons
                                    }
                                }
                                this.$router.push(routeParams);
                            }
                            else {
                                if (swal.dismiss == 'cancel') {

                                    let routeParams = {
                                        name: 'registration',
                                        query: {
                                            redirect: {
                                                name: 'checkout',
                                            },
                                            items: selectedItems,
                                            add_ons: selectedAddons
                                        }
                                    }

                                    this.$router.push(routeParams);
                                }
                            }
                        })
    
                    document.getElementById('proceed-to-checkout').addEventListener('click', () => { this.proceedToFastCheckout() })
                } else {

                    let params = {
                        items: this.processCheckoutItems()
                    };

                    let { cartParams } = this;

                    // let newItems = [];
                    // cartParams.items.map(item => {
                    //     if (!selectedItems.includes(item.id)) {
                    //         newItems.push(item);
                    //     }
                    // })
                    // cartParams.items = newItems;
                    // this.syncCart(cartParams);

                    this.checkout(params)
                        .then(res => {

                            if (res.status == 200) {
                                this.$router.push({ 
                                    name: 'checkout',
                                    query: {
                                        token: res.data.data.token
                                    }
                                });
                            }

                        })
                        .catch(err => {
                            console.error(err.data.message);
                        })
                }
            },
            processCheckoutItems() {

                let { selectedItems, selectedAddons, items } = this;
                let checkoutItems = [];

                if (selectedItems.length > 0) {

                    selectedItems.map(selected => {
                        
                        let checkoutItem;

                        let item = items.find(item => selected == item.id);

                        if (item) {

                            checkoutItem = {
                                product_id: item.product_id,
                                quantity: item.quantity,
                                notes: item.notes,
                                eta: item.eta
                            };

                            if (item.add_ons.length > 0) {

                                let checkoutItemAddons = [];

                                selectedAddons.map(selectedAddon => {

                                    let checkoutItemAddon;

                                    let addOn = item.add_ons.find(addOn => addOn.id == selectedAddon);

                                    if (addOn) {

                                        checkoutItemAddon = {
                                            addon_group: addOn.addon_group,
                                            qty: addOn.qty
                                        };

                                        checkoutItemAddons.push(checkoutItemAddon);
                                    }
                                });

                                if (checkoutItemAddons.length > 0) {
                                    checkoutItem.add_ons = checkoutItemAddons;
                                }
                            }

                            checkoutItems.push(checkoutItem);
                        }
                    })
                }

                return checkoutItems;
            },
            proceedToFastCheckout() {
                this.$swal.close();

                let params = {
                    items: this.processCheckoutItems()
                }

                this.checkout(params)
                    .then(res => {

                        if (res.status == 200) {
                            this.$router.push({ 
                                name: 'checkout',
                                query: {
                                    token: res.data.data.token
                                }
                            });
                        }

                    })
                    .catch(err => {
                        console.error(err.data.message);
                    })
            },
            handleAddToFrequincyList() {

                let { items, selectedItems } = this;
                let frequincyListProductIDs = [];

                if (selectedItems.length > 0) {
                    selectedItems.map(selected => {

                        let item = items.find(item => item.id == selected);

                        if (item) {
                            frequincyListProductIDs.push(item.product_id);
                        }
                    })

                    this.setPopupProductID(frequincyListProductIDs);
                    this.toggleListPopup();
                }
            },
            handleQuantityChange(item, quantity) {

                let { cartParams, items } = this;

                let itemIndex = items.findIndex(i => i.id == item.id);
                
                if (itemIndex > -1) {
                    items[itemIndex].quantity = quantity;
                }

                cartParams.items = items;
                this.syncCart(cartParams);
            },
            handleAddonQuantityChange(item, quantity) {
                let { cartParams, items } = this;

                let itemIndex = items.findIndex(i => i.id == item.cart_item);

                if (itemIndex > -1 && items[itemIndex].add_ons && items[itemIndex].add_ons.length > 0 ) {
                    
                    let addonIndex = items[itemIndex].add_ons.findIndex(addon => addon.id == item.id);

                    if (addonIndex > -1) {
                        items[itemIndex].add_ons[addonIndex].qty = quantity;
                    }
                }

                cartParams.items = items;
                this.syncCart(cartParams);
            },
            toggleSelect(id) {
                
                let { selectedItems, items, selectedAddons } = this;

                let item = items.find(item => item.id == id);
                let action = 'add';

                if (selectedItems.includes(item.id)) {
                    
                    selectedItems = selectedItems.filter(item => {
                        return item !== id;
                    })
                    action = 'remove';

                } else {
                    selectedItems.push(id);
                    action = 'insert';
                }

                if (item.add_ons && item.add_ons.length > 0) {
                    item.add_ons.map(addon => {

                        if (action == 'insert') {
                            selectedAddons.push(addon.id);
                        } else {
                            selectedAddons = selectedAddons.filter(addonID => addonID != addon.id);
                        }
                    })

                    this.selectedAddons = selectedAddons;
                }

                this.selectedItems = selectedItems;
            },
            toggleSelectAddon(addon) {

                let { selectedAddons, selectedItems } = this;

                if (selectedAddons.length > 0) {
                    
                    let existing = selectedAddons.includes(addon.id);

                    if (existing) {
                        selectedAddons = selectedAddons.filter(addOn => addOn != addon.id);
                    } else {
                        selectedAddons.push(addon.id);

                        if (!selectedItems.includes(addon.cart_item)) selectedItems.push(addon.cart_item);
                    }
                } else {
                    selectedAddons.push(addon.id);

                    if (!selectedItems.includes(addon.cart_item)) selectedItems.push(addon.cart_item);
                }

                this.selectedAddons = selectedAddons;
                this.selectedItems = selectedItems;
            },
            toggleSelectAll() {
                
                let { items, selectedItems, selectedAddons } = this;
                let newItems = [],
                    newAddons = [];
                
                if (items.length <= 0) {
                    return;
                }

                if (items.length == selectedItems.length) {
                    this.selectedItems = [];
                    this.selectedAddons = [];
                    return;
                } 

                items.map(item => {
                    newItems.push(item.id);

                    if (item.add_ons && item.add_ons.length > 0) {
                        item.add_ons.map(addon => {
                            newAddons.push(addon.id);
                        })
                    }
                });

                this.selectedItems = newItems;
                this.selectedAddons = newAddons;
            },
            removeItem(selectedItem) {

                let swal = removeSettings;
                swal.html = 'Do you want to remove the selected product(s)?';

                showAlert(swal, 'confirm')
                    .then(swal => {
                        if (swal.isConfirmed) {

                            let { items, cartParams } = this;

                            let newItems = items.filter(item => item.id !== selectedItem.id);
                            
                            cartParams.items = newItems;
                            this.items = newItems;

                            this.syncCart(cartParams)
                        }
                    })
            },
            removeItemAddon(item) {
                let swal = removeSettings;
                swal.html = 'Do you want to remove the selected add-on(s)?';

                showAlert(swal, 'confirm')
                    .then(swal => {
                        if (swal.isConfirmed) {

                            let { items, cartParams } = this;

                            let itemIndex = items.findIndex(i => i.id == item.cart_item);

                            if (itemIndex > -1 && items[itemIndex].add_ons && items[itemIndex].add_ons.length > 0 ) {
                                
                                let newAddons = items[itemIndex].add_ons.filter(addon => addon.id != item.id);
                                items[itemIndex].add_ons = newAddons;
                            }

                            cartParams.items = items;
                            this.syncCart(cartParams);
                        }
                    })
            },
            removeItems() {
                let swal = removeSettings;
                swal.html = 'Do you want to remove the selected product(s)?';

                showAlert(swal, 'confirm')
                    .then(swal => {
                        if (swal.isConfirmed) {

                            let { items, selectedItems, cartParams } = this;
                            
                            let newItems = items.filter(item => !selectedItems.includes(item.id));

                            cartParams.items = newItems;
                            this.items = newItems;

                            this.syncCart(cartParams);
                        }
                    })
            }
        },
        watch: {
            items() {
                this.selectedItems = [];
            },
        }
    }
</script>