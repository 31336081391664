<template>
    <b-modal 
        id="list-modal-form"
        v-model="showPopupForm"
        hide-footer
        hide-header
        centered
        body-class="p-0"
        content-class="border-0"
        size="sm"
    >
        <div class="border-bottom d-flex align-items-center justify-content-between p-2">
            <i @click="toggleListPopupForm(false)" class="fas fa-chevron-left clickable"></i>
            <span class="font-small font-sf-bold" >{{ popupFormType == 'create' ? 'Add New List' : 'Rename List' }}</span>
            <i @click="toggleListPopupForm(false)" class="far fa-times-circle clickable"></i>
        </div>
        <div class="p-3">
            <input 
                type="text" 
                class="text-center form-control bg-white border-top-0 border-left-0 border-right-0 rounded-0 border-bottom border-gray-1 mt-4"
                placeholder="Enter Name of List"
                v-model="formValues.name"
                @keydown.enter="handleSubmit"
                ref="input"
            />
            <div 
                v-if="formErrors.name"
                class="font-xsmall font-red text-center"
            >{{formErrors.name}}</div>
            <button 
                @click="handleSubmit"
                class="btn btn-sm btn-green rounded-pill font-sm btn-block mt-4"
            >
                {{ processing ? 'Saving...' : ( popupFormType == 'create' ? 'Create' : 'Save Changes' ) }}
            </button>
        </div>
    </b-modal>
</template>

<script>
    import { mapMutations, mapGetters, mapActions } from 'vuex'
    import * as types from '../../store/mutation-types';
    import { isLoggedIn } from '@helpers/session.helper';
    import validate from '@helpers/validation.helper';

    export default {
        data() {
            return {
                activeList: null,
                formValues: {
                    name: ''
                },
                formErrors: {
                    name: ''
                },
                processing: false,
            }
        },
        computed: {
            showPopupForm() {
                return this.getShowPopupForm();
            },
            popupFormType() {
                return this.getShowPopupFormType();
            },
            popupFormID() {
                return this.getPopupFormID()
            }
        },
        mounted() {
            this.$root.$on('bv::modal::show', (bvEvent, modalID) => {
				if (bvEvent.type == 'show') {
					if (modalID == 'list-modal-form') {
                        if (this.popupFormType == 'edit' && this.popupFormID) {
                            if (this.activeList && this.activeList.name) {
                                this.formValues.name = this.activeList.name;
                            }
                        }
					}
				}
			})
            this.$root.$on('bv::modal::hide', (bvEvent, modalID) => {
				if (bvEvent.type == 'hide') {
					if (modalID == 'list-modal-form') {
                        this.toggleListPopupForm(false);
                        this.formValues.name = '';
                        this.formErrors.name = '';
					}
				}
            })
        },
        methods: {
            ...mapGetters({
                getShowPopupForm: types.GET_SHOW_LIST_POPUP_FORM,
                getShowPopupFormType: types.GET_LIST_POPUP_FORM_TYPE,
                getPopupFormID: types.GET_LIST_POPUP_FORM_ID,
            }),
            ...mapMutations({
                toggleListPopupForm: types.TOGGLE_LIST_POPUP_FORM
            }),
            ...mapActions({
                findList: types.FIND_FREQUINCY_LIST,
                createList: types.CREATE_FREQUINCY_LIST,
                updateList: types.UPDATE_FREQUINCY_LIST
            }),
            getSelectedList() {
                let id = this.popupFormID;
            },
            async validateForm() {

                const schema = {
                    properties: {
                        name: {
                            type: 'string',
                            required: true,
                            allowEmpty: false,
                            pattern: /^[\w\-\s]+$/,
                            maxLength: 50,
                            messages: {
                                required: 'Please fill up this field',
                                allowEmpty: 'Please fill up this field',
                                pattern: 'Please enter alphanumeric characters',
                                maxLength: 'Must be less than 50 characters'
                            }
                        },
                    }
                }

                const validateResults = validate(this.formValues, schema, undefined, true);

                this.formErrors = validateResults.errors;

                return validateResults.valid;

            },
            async handleSubmit() {

                this.processing = true;

                if ((await this.validateForm())) {

                    
                    if (this.popupFormType == 'create') {
                        
                        this.createList({ ...this.formValues })
                            .then(res => {
                                this.processing = false;
                                this.toggleListPopupForm(false);
                            })
                            .catch(err => {
                                console.error(err.statusText);
                                this.processing = false;
                            })

                    } else {
                        
                        let params = {
                            name: this.formValues.name,
                            id: this.popupFormID
                        };

                        this.updateList({ ...params })
                            .then(res => {
                                this.processing = false;
                                this.toggleListPopupForm(false);
                            })
                            .catch(err => {
                                console.error(err.statusText);
                                this.processing = false;
                            })
                    }

                } else {
                    this.processing = false;
                    return;
                }
            }
        },
        watch: {
            popupFormID(newID) {

                let params = {
                    id: newID
                };

                this.findList({ params: params })
                    .then(res => {
                        if (res.status == 200) {
                            this.activeList = res.data.data;
                        }
                    })
                    .catch(err => {
                        console.error(err.statusText);
                    })
            },
            showPopupForm(show) {

                if (show) {
                    setTimeout(() => {
                        this.$refs.input.focus();
                    }, 500);
                }
            }
        }
    }
</script>