<template>
    <div class="shop-by-section">
        <div class="container">
            <div class="py-5">
                <h5 class="text-center font-xxlarge font-sf-medium">
                    Shop By
                </h5>
                <div class="d-flex justify-content-center font-sf-medium mb-4">
                    <button 
                        :class="['btn btn-clear font-xxlarge ', activeType == 'categories' ? 'active-type' : 'font-gray']"
                        @click="setActiveType('categories')"
                    >Categories</button>
                    <button 
                        :class="['btn btn-clear font-xxlarge ', activeType == 'brands' ? 'active-type' : 'font-gray']"
                        @click="setActiveType('brands')"
                    >Brands</button>
                </div>
                <div v-if="activeType == 'categories'" class="shop-section" >
                    <div v-if="categories" class="">
                        <div class="mx-auto mb-4 row" >
                            <div 
                                v-for="(category, index) in categories" 
                                :key="index"
                                class="col-sm-3 col-12 mb-3"
                            >
                                <div class="d-flex align-items-center justify-content-between">
                                    <router-link
                                        :to="{
                                            name: 'products',
                                            query: {
                                                category: category.slug
                                            }
                                        }"
                                    > {{ category.title }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="d-flex align-items-center justify-content-center py-5">
                            <div class="font-large">No categories found</div>
                        </div>
                    </div>
                </div>
                <div v-else class="shop-section" >
                    <div v-if="vendors" >
                        <div class="row" >
                            <div 
                                class="col-md-3 col-12 text-center text-md-left mb-3"
                                v-for="vendor in vendors" 
                                :key="vendor.id"
                            >
                                <router-link     
                                    :to="{ name: 'brands', params: { slug: vendor.slug } }"
                                    class="text-nowrap"
                                > {{ vendor.title }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div v-else >
                        <div class="d-flex align-items-center justify-content-center py-5">
                            <div class="font-large">No brands found</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { connect } from '@api';
import CategoryDropdown from '@views/home/components/categoryDropdown';

export default {
    components: {
        // CategoryDropdown
    },
    props: ['vendors'],
    data() {
        return {
            activeType: 'categories',
            zIndexes: [],
            categories: []
        }
    },
    mounted() {

        if (this.categories && this.categories.length > 0) {
            let categories = Object.keys(this.categories);
            let temp = [];

            let zIndex = 2000;
            categories.map(value => {
                temp[value] = zIndex;
                zIndex = zIndex - 100;
            })

            this.zIndexes = temp;
        }

        this.getCategories();
    },
    methods: {
        setActiveType(type) {
            this.activeType = type;
        },
        getCategories() {
            connect().get('categories/get')
                .then(res => {
                    if (res.status == 200) {
                        this.categories = res.data.data
                    }
                })
                .catch(err => {
                    if (err.data) {
                        console.error(err.data.message)
                    }
                })
        }
    }
}
</script>