<template>
	<div 
		v-if="productObj" 
		:class="`product-card h-100 clickable d-flex flex-column ${ className }`"
		@click.prevent="redirect(productObj.slug)"
		:id="'product-' + productObj.id"
	>
		<div
			class="position-relative image-container d-flex justify-content-center align-items-center px-2 pt-2 pb-0"
		>
			<Imager
				:src="productObj"
				:extract="true"
			/>
			<div 
				v-if="productObj.getDiscountPercentage(product) > 0"
				class="discount font-xsmall font-white font-sf-medium"
			>
				<div class="position-relative py-1 px-2">
					Save {{ productObj.getDiscountPercentage(product) }}%
				</div>
			</div>

			<div
				class="recommended font-xsmall py-1 px-2 bg-orange-light font-orange font-sf-medium"
				v-if="productObj.getTag('recommended') || productObj.admin_rating > 0"
			>
				Recommended
			</div>

			<div
				class="bundle font-small font-sf-bold font-yellow bg-green p-1 d-flex align-items-center justify-content-center"
				v-if="productObj.getTag('bundle')"
			>
				BUNDLE
			</div>
			<div
				class="trending font-xsmall py-1 px-2 font-white font-sf-medium d-inline-flex align-items-center"
				v-if="productObj.getTag('trending')"
			>
				<img class="img-small mr-1" src="../../assets/img/trending.png" alt="" />
				Trending
			</div>
		</div>
		<div class="px-3 pt-1 pb-3 font-xsmall flex-fill">
			<div class="d-flex justify-content-between font-medium">
				<div>
					<ProductPrice
						:product="productObj"
						:price="productObj.price"
						:originalPrice="productObj.orig_price"
					/>
					<!-- 65.25 <del class="font-gray font-sf-regular">P 130.50</del> -->
				</div>
				<button 
					class="btn btn-clear py-0 px-2 font-gray clickable"
					@click.stop="handleAddToWishlist(productObj.id)"
					v-if="productObj.variant_groups && productObj.variant_groups.length <= 0"
				>
					<i :class="` ${ wishlisted ? 'fas fa-heart font-red' : 'far fa-heart' }`"></i>
				</button>
			</div>
			<div v-if="productObj.brand" class="font-green">
				{{ productObj.brand.title }}
			</div>
			<div class="font-small font-gray mb-4">
				<div
					v-if="productObj.getTag('new')"
					class="d-inline-block bg-green font-white px-1 font-xsmall"
				>
					NEW!
				</div>
				{{ productObj.title }}
			</div>
			<div v-if="productObj.variant_groups && productObj.variant_groups.length > 0" >
				<div 
					v-for="variant_group in productObj.variant_groups" 
					:key="variant_group.id" 
				>
					<div
						v-if="variant_group.options && variant_group.options.length > 0"
					>
						<span>{{displayVariantOptions(variant_group.options)}}</span>
					</div>
				</div>
			</div>
			<div class="footer-margin"></div>
			<div 
				:class="`d-flex flex-wrap flex-xl-nowrap justify-content-between align-items-end font-gray product-card-footer`"
			>
				<div>
					<div class="mt-2">
						<div v-if="productObj.variants && productObj.variants.length <= 0" >
							<div v-if="productObj.enable_inventory > 0" >
								<span v-if="productObj.inventory > 0" >
									{{ parseInt(productObj.inventory) }} {{ productObj.unit ? (productObj.unit.short_name ? productObj.unit.short_name : productObj.unit.name) : ( productObj.inventory > 1 ? 'pcs' : 'pc' ) }} available
								</span>
								<span v-else class="font-red" >Out of stock</span>
							</div>
							<div v-else >
								Available
							</div>
						</div>
						<div v-else >
							<span class="font-gray">See more details</span>
						</div>
					</div>
				</div>
				<div v-if="
					(!productObj.age_restricted && (productObj.inventory > 0 || productObj.enable_inventory == 0) && productObj.variants.length <= 0) ||
					((productObj.age_restricted && userAge && userAge >= 21 ) && (productObj.inventory > 0 || productObj.enable_inventory == 0) && productObj.variants.length <= 0 ) 
					"
					:class="`${ windowWidth < 1366 ? 'w-100' : '' }`"
				>
					<button 
						:class="`add-to-cart-btn border-0 d-none d-xl-block ${ addingToCart ? 'with-quantity rounded-pill d-flex align-items-center font-white' : 'rounded-circle font-green' }`"
						@click.stop="toggleAddToCartBtn"
						@mouseleave="() => {
							if (addingToCart && windowWidth >= 1366) {
								handleAddToCart()
							}
						}"
					>
						<i 
							v-if="addingToCart" class="fas fa-minus pl-2 fa-lg"
							@click.stop="() => {
								changeItemQuantity('minus')
							}"
						></i>
						<input 
							type="text"
							v-if="addingToCart" 
							class="p-0 mx-2 font-large inline-quantity-input"
							v-model="quantity"
							pattern="\d*"
							maxlength="4"
							@change="handleQtyOnChange"
						>
						<i 
							:class="`fas fa-plus fa-lg ${ addingToCart ? 'pr-2' : '' }`"
							@click.stop="() => {
								if (!addingToCart) {
									toggleAddToCartBtn()
								} 
								changeItemQuantity('plus') 
							}"
						></i>
					</button>
					<div 
						class="d-block d-xl-none"
						@click.stop="() => {}"
					>
						<div class="bg-gray-1 rounded-pill my-2">
							<div class="d-flex align-items-center">
								<i 
									class="fas fa-minus pl-3 fa-lg"
									@click.stop="changeItemQuantity('minus')"
								></i>
								<input 
									type="text" 
									class="font-xlarge flex-grow-1 inline-quantity-input" 
									v-model="quantity"
									pattern="\d*"
									maxlength="4"
								>
								<i 
									class="fas fa-plus pr-3 fa-lg"
									@click.stop="changeItemQuantity('plus')"
								></i>
							</div>
						</div>
						<button 
							class="btn btn-sm btn-block btn-green rounded-pill"
							@click.stop="handleAddToCart"
							:disabled="quantity <= 0"
						>Add to Cart</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Product from '@factories/product.factory'
	import { isLoggedIn, getUser } from '@helpers/session.helper';
	import { hasCart, getCart } from '@helpers/cart.helper';
	import { mapActions, mapGetters, mapMutations } from 'vuex';
	import * as types from '../../store/mutation-types';
	import {showAlert} from '@helpers/notification.helper';


	export default {
		props: {
			product: Object,
			className: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				productObj: null,
				addingToCart: false,
				quantity: 0,
				windowWidth: 0,
				wishlisted: false
			}
		},
		mounted() {
			this.productObj = new Product(this.product);

			this.windowWidth = window.outerWidth;
			
			window.addEventListener('resize', () => {
				this.addingToCart = false;
				this.windowWidth = window.outerWidth;
			})

			if (isLoggedIn()) {
				let user = getUser();
				
				if (this.userWishlist.length > 0) {
					let exists = this.userWishlist.find(item => item.product_id == this.productObj.id);

					if (exists) {
						this.wishlisted = true;
					}
				}
			}
		},
		computed: {
			userAge() {
				return this.getUserAge();
			},
			userWishlist() {
				let wishlist = [];

				if (isLoggedIn()) {

					let user = getUser();
					wishlist = user.wishlist;
				}

				return wishlist;
			}
		},
		methods: {
			...mapGetters({
				getUserAge: types.GET_USER_AGE
			}),
			...mapActions({
				syncCart: types.SYNC_CART,
				deleteFrequincyItem: types.DELETE_FREQUINCY_LIST_ITEM
			}),
			...mapMutations({
				togglePopup: types.TOGGLE_LIST_POPUP,
				setPopupProductID: types.SET_LIST_POPUP_PRODUCT_ID,
				toggleCartNotif: types.TOGGLE_ADD_TO_CART_NOTIF,
				setCartNotifItems: types.SET_ADD_TO_CART_NOTIF_ITEMS,
				updateUser: types.UPDATE_USER
			}),
			handleQtyOnChange() {
                let { productObj, quantity } = this;

                if (productObj.enable_inventory) {
                    if (parseInt(productObj.inventory) < parseInt(quantity)) {
                        this.quantity = parseInt(productObj.inventory);
                    }
                }
            },
			displayVariantOptions(options) {

				let variantOptions = '',
					tempOptions = [];
				

				if (options.length > 0) {
					options.map((option, index) => {
						if (index < 3) {
							tempOptions.push(option.name);
						}
					})
				}

				if (tempOptions.length > 0) {
					variantOptions = tempOptions.join(', ');

					if (options.length > 3) {
						variantOptions = variantOptions + ' +';
					}
				}

				return variantOptions;
			},
			handleAddToCart() {

				let cart = getCart();
				let user = getUser();
				let selectedProduct = this.productObj;
				let { quantity } = this;
				quantity = parseInt(quantity);

				if (quantity > 0) {
					let items = (cart && cart.items && cart.items.length > 0) ? cart.items : [];
					let existingProductIndex = items.findIndex(item => item.product_id == selectedProduct.id);

					if (existingProductIndex > -1) {

						let newQuantity = items[existingProductIndex].quantity + quantity;

						if (selectedProduct.enable_inventory > 0) {
							if (items[existingProductIndex].product.inventory <= newQuantity) {
								items[existingProductIndex].quantity = items[existingProductIndex].product.inventory;
							} else {
								items[existingProductIndex].quantity = newQuantity;
							}
						} else {
							items[existingProductIndex].quantity = newQuantity;
						}

					} else {
						items.push({
							product_id: selectedProduct.id,
							quantity: quantity,
							product: selectedProduct
						})
					}

					let cartParams = {
						customer_id: user && user.customer_id ? user.customer_id : 0,
						source: 'website',
						last_device: 'laptop'
					}

					cartParams.items = items;

					this.syncCart(cartParams);
				} else {
					this.toggleAddToCartBtn(false);
				}

				let notifItems = [];
				notifItems.push({
					product: selectedProduct,
					quantity: this.quantity	
				})


				if (this.windowWidth >= 1366) {
					this.setCartNotifItems(notifItems);
					this.toggleAddToCartBtn(false);
					this.toggleCartNotif(true);
				} else {

					let swalSettings = {
						title: 'Congratulations',
						text: `${ selectedProduct.title } has been added to your cart`
					}
					
					showAlert(swalSettings)
						.then(swal => {
							this.toggleAddToCartBtn(false);
							this.quantity = 0;
						})
				}
			},
			toggleAddToCartBtn(toggle = null) {

				if (this.productObj.variants && this.productObj.variants.length > 0) {

					this.$router.replace({
						name: 'products.view',
						params: {
							slug: this.productObj.slug
						}
					})

				}

				if (toggle != null) {
					this.addingToCart = toggle;
					return;
				}
				this.addingToCart = !this.addingToCart;
			},
			changeItemQuantity(operation = 'plus') {
				if (operation == 'plus') {
					if (this.productObj.enable_inventory == 0) {
						this.quantity = parseInt(this.quantity) + 1;
					} else {
						if (this.quantity < this.productObj.inventory) {
							this.quantity = parseInt(this.quantity) + 1;
						}
					}
				} else {
					if (this.quantity != 0) {
						this.quantity = parseInt(this.quantity) - 1;
					} else {
						this.addingToCart = false;
					}
				}
			},
			handleAddToWishlist(id) {

				if (!isLoggedIn()) {
					this.$router.push({ name: 'login' });
					return;
				}

				if (this.productObj.variants && this.productObj.variants.length > 0) {

					this.$router.replace({
						name: 'products.view',
						params: {
							slug: this.productObj.slug
						}
					})

				} else {

					if (!this.wishlisted) {
						this.togglePopup();
						this.setPopupProductID([id]);
					} else {

						let swalSettings = {
							title: 'Remove item from wishlist',
							text: 'Do you want to remove this item from your wishlist',
							customClass: {
								actions: 'd-flex align-items-center px-3',
								confirmButton: 'flex-fill bg-white font-black',
								cancelButton: 'flex-fill bg-green font-white'
							}
						}

						showAlert(swalSettings, 'confirm')
							.then(swal => {
								if (swal.isConfirmed) {
									if (isLoggedIn()) {
									let user = getUser();
									
									if (user.wishlist && user.wishlist.length > 0) {
										let item = user.wishlist.find(item => item.product_id == this.productObj.id);

										if (item) {

											let params = {
												id: item.id,
												frequincy_list_id: item.frequincy_list_id,
												customer_id: user.customer_id
											};

											this.deleteFrequincyItem(params)
												.then((res) => {
													let newWishlist = user.wishlist.filter(wishlist => wishlist.id != item.id);
													user.wishlist = newWishlist;

													this.updateUser(user);

													const removeSwalSettings = {
														title: 'Item removed from your wishlist'
													}

													showAlert(removeSwalSettings);
												})
										}
									}
								}
								}
							})
					}
				}
			},
			redirect(slug) {

				let urlParams = {
					name: 'products.view',
					params: {
						slug: slug
					}
				}

				let { query } = this.$route;

				if (query && query.category) {
					urlParams.query = {
						category: query.category
					};
				}

				if (this.$route.path.includes('products')) {
					this.$router.replace(urlParams)
				} else {
					this.$router.push(urlParams)
					.catch(err => {}) 
				}

			}
		},
		watch: {
			userWishlist(newWishlist) {

				let exists = newWishlist.find(item => item.product_id == this.productObj.id);
				this.wishlisted = exists ? true : false;

			}
		}
	};
</script>

<style></style>
