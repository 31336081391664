<template>
	<div class="registration-page bg-gray-2 d-flex justify-content-center align-items-center py-5">
		<div class="bg-white p-4 login-card rounded">
			<Information
				v-if="currentStep == 1"
				:formValues="formValues"
				:formErrors="formErrors"
				:handleNext="handleNext"
				:nextDisabled="nextDisabled"
				:onGoogleAuthSuccess="onGoogleAuthSuccess"
				:onGoogleAuthFail="onGoogleAuthFail"
				:googleAuthenticating="googleAuthenticating"
				:googleLoginParams="googleLoginParams"
				:facebookAppID="facebookAppID"
				:onFacebookSDKInit="onFacebookSDKInit"
				:onFacebookAuthLogin="onFacebookAuthLogin"
				:validYears="validYears"
			/>
			<SmsVerification
				v-else-if="currentStep == 2"
				:handleNext="handleNext"
				:handleBack="handleBack"
				:nextDisabled="nextDisabled"
				:formValues="formValues"
				:smsVerified="smsVerified"
				:verificationCode="verificationCode"
				:smsError="smsError"
				:smsCode="smsCode"
				@updateSmsCode="updateSmsCode"
				:handleSend="handleSendSmsCode"
			/>
			<EmailVerification
				v-else-if="currentStep == 3"
				:handleNext="handleNext"
				:handleBack="handleBack"
				:nextDisabled="nextDisabled"
				:emailValue="formValues.email"
				:emailCode="formValues.email_code"
				:emailCodeError="emailCodeError"
				@updateEmail="updateEmailValue"
				@sendEmailCode="sendEmailCode"
				@updateEmailCode="updateEmailCode"
			/>
			<TermsAndConditions
				v-else
				:page="termsAndConditions"
				:handleRegistration="handleRegistration"
				:handleBack="handleBack"
				:nextDisabled="nextDisabled"
			/>
		</div>
	</div>
</template>

<script>
	import { connect } from '../../helpers/api.helper';
	import Information from './Information';
	import SmsVerification from './SmsVerification';
	import EmailVerification from './EmailVerification';
	import TermsAndConditions from './TermsAndConditions';
	import validate from '../../helpers/validation.helper';
	import { showAlert } from '@helpers/notification.helper';
	import { mapActions, mapMutations } from 'vuex';
	import { isLoggedIn } from '@helpers/session.helper';
	import * as types from '../../store/mutation-types';
	import { getCart } from '@helpers/cart.helper'
	import config from '../../constants/config';
	import { getPage } from '@helpers/caboodle.helper';
	import { getValidYears, validateBirthday } from '@helpers/date.helper';

	let swalSettings = {
		customClass: {
			actions: 'd-flex',
			confirmButton: 'flex-fill mx-5',  
			text: 'font-gray'
		}
	}

	export default {
		name: 'registration',
		data: () => ({
			formValues: {
				firstname: '',
				lastname: '',
				phone: '',
				birthday: '',
				gender: '',
				password: '',
				confirm_password: '',
				email: '',
				email_code: '',
				phone_verified: false,
			},
			formErrors: {
				firstname: '',
				lastname: '',
				phone: '',
				birthday: '',
				gender: '',
				password: '',
				confirm_password: '',
				email: '',
				email_code: '',
				phone_verified: false,
			},
			currentStep: 1, // 1 = information, 2 = sms, 3 = email, 4 = terms and conditions
			nextDisabled: false,
			smsCode: '',
			smsVerified: false,
			emailCode: '',
			emailCodeError: '',
			emailVerified: false,
			nextStep: 2,
			prevStep: 1,
			googleLoginParams: {
				client_id: config.GOOGLE_CLIENT_ID
			},
			googleAuthenticating: false,
			facebookAuthenticating: false,
			FB: {},
			facebookAppID: config.FB_APP_ID,
			termsAndConditions: null,
			verificationCode: '',
			smsError: ''
		}),
		computed: {
			query() {
				return this.$route.query ? this.$route.query : null
			},
			validYears() {
				return getValidYears()
			}
		},
		created() {
			this.getTermsAndConditions();
		},
		mounted() {
			if (isLoggedIn()) {
				this.$router.push({ name: 'home' })
			}
		},
		methods: {
			...mapMutations({
				setUser: types.SET_USER,
				setRegistered: types.SET_REGISTERED
			}),
			...mapActions({
				checkout: types.PROCEED_TO_CHECKOUT,
				calculateUserAge: types.CALCULATE_USER_AGE,
				syncCart: types.SYNC_CART,
				oauthLogin: types.OATH_LOGIN,
				FBInit: types.FB_INIT
			}),
			async onGoogleAuthSuccess(googleUser) {

				this.googleAuthenticating = true;

				let profile = googleUser.getBasicProfile();
				let oauth_id = googleUser.getAuthResponse().id_token;

				let params = {
					oauth_id: profile.getId(),
					fullname: profile.getName(),
					firstname: profile.getGivenName(),
					lastname: profile.getFamilyName(),
					email: profile.getEmail(),
					social_media_name: 'Google'
				};

				let res = await this.oauthLogin({...params});

				if (res.status == 200) {
					this.processUserData(res.data.data);
				} else {
					this.googleAuthenticating = false;

					if (res.data.message) {
						this.error = res.data.message;
					}
				}
			},
			onGoogleAuthFail(params) {
				console.log('fail', params);
			},
			onFacebookAuthLogin(response) {
				if (response && response.authResponse) {
					this.FB.api('/me', 'GET', { fields: 'id,name,first_name,last_name,email' }, 
						async userInfo => {
							let params = {
								oauth_id: userInfo.id,
								fullname: userInfo.name,
								firstname: userInfo.first_name,
								lastname: userInfo.last_name,
								email: userInfo.email,
								social_media_name: 'Facebook'
							};
		
							let res = await this.oauthLogin({...params});
		
							if (res.status == 200) {
								this.processUserData(res.data.data);
							} else {
								this.error = res.data.message;
							}
						}
					)
				}
			},
			onFacebookSDKInit(response) {
				this.FB = response.FB;
				this.FBInit(response);
			},
			getTermsAndConditions() {
				getPage('terms-and-conditions')
					.then(res => {
						if (res.status == 200 && res.data) {
							this.termsAndConditions = res.data.data;
						}
					})
			},
			async validateForm() {
				const schema = {
					properties: {
						firstname: {
							type: 'string',
							required: true,
							allowEmpty: false,
							messages: {
								allowEmpty: 'Please enter your first name',
								required: 'Please enter your first name',
							}
						},
						lastname: {
							type: 'string',
							required: true,
							allowEmpty: false,
							messages: {
								allowEmpty: 'Please enter your last name',
								required: 'Please enter your last name',
							}
						},
						phone: {
							type: 'string',
							required: true,
							allowEmpty: false,
							pattern: /^09[0-9]{9}$|^0?2[0-9]{7}$|^0?32[0-9]{7}$/,
							messages: {
								allowEmpty: 'Please enter your phone number',
								required: 'Please enter your phone number',
								pattern: 'Please enter a valid phone number'
							}
						},
						birthday: {
							type: 'string',
							format: 'date',
							required: true,
							allowEmpty: false,
							messages: {
								allowEmpty: 'Please enter your birthday',
								required: 'Please enter your birthday',
								format: 'Please enter a valid date format YYYY-MM-DD of your birthday'
							}
						},
						gender: {
							type: 'string',
							required: true,
							allowEmpty: false,
							messages: {
								allowEmpty: 'Please select a gender',
								required: 'Please select a gender',
							}
						},
						password: {
							type: 'string',
							required: true,
							allowEmpty: false,
							messages: {
								allowEmpty: 'Please enter your password',
								required: 'Please enter your password',
								minLength: 'Password must contain at least 8 characters'
							},
							minLength: 8,
						},
						confirm_password: {
							type: 'string',
							required: true,
							allowEmpty: false,
							messages: {
								allowEmpty: 'Please confirm your password',
								required: 'Please confirm your password',
							},
						},
					},
				};

				const validateResult = validate(this.formValues, schema, undefined, true);

				// PASSWORD VALIDATION
				if (
					!validateResult.errors.password &&
					this.formValues.password !== this.formValues.confirm_password
				) {
					validateResult.errors.confirm_password = "Password doesn't match";
					validateResult.valid = false;
				}
				// BIRTHDAY VALIDATION
				if (!validateBirthday(this.formValues.birthday)) {
					validateResult.errors.birthday = 'Please enter a valid date of your birthday. Allowed age must be 15 to 100 years old.';
					validateResult.valid = false;
				}

				this.formErrors = validateResult.errors;

				return validateResult.valid;
			},
			async handleNext() {

				let steps = [1,2,3,4];

				if (this.currentStep == 1) {
					if (!(await this.validateForm())) return;
				} else if (this.currentStep == 2) {
					if (!(await this.verifySms())) {
						this.smsError = 'Invalid Code';
						return;
					}
					this.smsVerified = true;
					this.formValues.phone_verified = true;
				} else if (this.currentStep == 3) {
					if (!this.verifyEmailCode()) return;
					this.emailVerified = true;
				}

				if (this.smsVerified) this.nextStep = 3;
				if (this.emailVerified) this.nextStep = 4;

				this.prevStep = this.currentStep;
				this.currentStep = this.nextStep;
				this.scrollToTop();
			},
			handleBack() {

				let currentStep = this.currentStep;
				this.nextStep = this.currentStep;

				if (currentStep > 3 || (this.smsVerified && this.emailVerified)) {
					this.currentStep = 1;
				} else {

					if (this.emailVerified) currentStep = 3
					if (this.smsVerified) currentStep = 2

					this.currentStep = currentStep - 1;
				}

				this.scrollToTop();
			},
			async sendEmailCode() {
				await connect()
					.post('guest/email/send-code', { ...this.formValues })
					.then(res => {
						if (res.status == 200) {
							this.emailCode = res.data.code;
						}
					})
					.catch(err => {
						console.error(err.data.message);
					})
			},
			async verifyEmail() {
				await connect()
					.post(`/verify/email`, { email: this.formValues.email, code: this.email.code })
					.then((res) => {
						if (res.status == 'success') {
							return true;
						}
					});
				return false;
			},
			verifyEmailCode() {
				this.emailCodeError = '';

				let verified = false;

				if (this.formValues.email_code == this.emailCode) {
					verified = true;
				} else {
					verified = false;
					this.emailCodeError = 'Verification code is invalid';
				}

				return verified;
			},
			updateEmailCode(value) {
				this.formValues.email_code = value;
			},
			updateEmailValue(email) {
				this.formValues.email = email
			},
			scrollToTop() {
				window.scrollTo(0, 0);
			},
			processUserData(user) {

				this.setRegistered({data: true})

				this.setUser(user);

				this.calculateUserAge(user);

				let { query } = this;

				if (query && query.redirect) {

					if (query.items && query.items.length > 0) {

						let checkoutItems = this.processCheckoutItems(query.items, query.add_ons);
						let checkoutParams = {
							items: checkoutItems
						};

						let cartParams = {
							customer_id: user && user.customer_id ? user.customer_id : 0,
							source: 'website',
							last_device: 'laptop',
							items: checkoutItems,
							reference_number: ''
						};

						this.syncCart(cartParams);
						
						this.checkout(checkoutParams)
							.then(res => {

								if (res.status == 200) {
									this.$router.push({ 
										name: 'checkout',
										query: {
											token: res.data.data.token
										}
									});
								}

							})
							.catch(err => {
								console.error(err.data.message);
							})
					}

				} else {
					this.$router.push({ 
						name: 'home'
					});
				}
			},
			handleRegistration() {
				if (!this.validateForm()) return;

				connect()
					.post(`/auth/register`, this.formValues)
					.then(res => {
						if (res.status == 200) {
							
							let user = res.data.data

							let message = res.data.message.replace('.', '<br/>');
							swalSettings.html = message;

							this.processUserData(user);
						}
					})
					.catch(err => {
						console.error(err.data.message);
					})
			},
			updateSmsCode(code) {
				this.smsCode = code;
			},
			async handleSendSmsCode() {

				this.verificationCode = Math.floor(100000 + Math.random() * 900000);
				this.phone = this.formValues.phone;

				let text = `Your phone verification code is: ${this.verificationCode}`;

				let params = {
					text,
					phone: this.phone
				};

				let response = await connect().post('mysms/send', params);

				if (response.status != 200) {
					this.smsError = 'Message sending failed';
				}

				return response.status == 200 ? true : false;
			},
			verifySms() {
				this.smsError = '';

				return this.smsCode == this.verificationCode ? true : false;
			},
			processCheckoutItems(selectedItems, selectedAddons) {

				let cart = getCart();

				let items = cart.items;

				let checkoutItems = [];

				if (selectedItems.length > 0) {

					selectedItems.map(selected => {
						
						let checkoutItem;

						let item = items.find(item => selected == item.id);

						if (item) {

							checkoutItem = {
								product_id: item.product_id,
								quantity: item.quantity,
								notes: item.notes,
								eta: item.eta
							};

							if (item.add_ons.length > 0) {

								let checkoutItemAddons = [];

								selectedAddons.map(selectedAddon => {

									let checkoutItemAddon;

									let addOn = item.add_ons.find(addOn => addOn.id == selectedAddon);

									if (addOn) {

										checkoutItemAddon = {
											addon_group: addOn.addon_group,
											qty: addOn.qty
										};

										checkoutItemAddons.push(checkoutItemAddon);
									}
								});

								if (checkoutItemAddons.length > 0) {
									checkoutItem.add_ons = checkoutItemAddons;
								}
							}

							checkoutItems.push(checkoutItem);
						}
					})
				}

				return checkoutItems;
			},
			getCheckoutData(token) {
				return connect().post('/checkout/find', { token: token })
			},
		},
		components: {
			Information,
			SmsVerification,
			EmailVerification,
			TermsAndConditions,
		},
		watch: {
		}
	};
</script>
