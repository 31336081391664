<template>
    <b-modal 
        id="social-share-popup"
        centered
    >
        <template #modal-title>
            <span class="font-sf-bold">Share</span>
        </template>
        <div class="p-4" >
            <div class="row">
                <div 
                    class="col-3 text-center clickable mb-3"
                    v-for="(social, index) in socials"
                    :key="index"
                    data-toggle="tooltip"
                    :title="social.name"
                    @click="share(social)"
                >
                    <i :class="`${ (social.name == 'Mail' ? 'fas' : 'fab') + ' ' + social.icon } fa-3x`"></i>
                    <div class="font-sf-medium font-xsmall" >{{social.name}}</div>
                </div>
            </div>
        </div>
        <template #modal-footer >
            <div class="d-flex align-items-center w-100">
                <input name="link" :value="baseURL + $route.path" ref="copyLink" id="copyLink" class="form-control form-control-static flex-grow-1" readonly />
                <button @click="copy" class="btn font-large font-gray"><i class="far fa-clipboard"></i></button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import { showAlert } from '@helpers/notification.helper';

    export default {
        props: ['show', 'product'],
        data() {
            return {
                baseURL: window.location.origin,
                socials: [
                    {
                        name: 'Facebook',
                        icon: 'fa-facebook'
                    },
                    {
                        name: 'Twitter',
                        icon: 'fa-twitter'
                    },
                    {
                        name: 'Pinterest',
                        icon: 'fa-pinterest'
                    },
                    {
                        name: 'Reddit',
                        icon: 'fa-reddit'
                    },
                    {
                        name: 'Mail',
                        icon: 'fa-envelope'
                    }
                ]
            }
        },
        methods: {
            share(social) {

                let url = '';

                let { product } = this;

                if (product) {
                    if (social.name == 'Facebook') url = `https://www.facebook.com/sharer.php?u=${window.location}`;
                    if (social.name == 'Twitter') url = `https://twitter.com/share?url=${window.location}&text=${product.title}`;
                    if (social.name == 'Pinterest') url = `https://pinterest.com/pin/create/bookmarklet/?media=${product.asset ? product.asset.path : ''}&url=${window.location}&is_video=false&description=${product.title}`
                    if (social.name == 'Reddit') url = `https://reddit.com/submit?url=${window.location}&title=${product.title}`
                    if (social.name == 'Mail') url = `mailto:?subject=${product.title}&body=${window.location}`
    
                    window.open(url);
                }
            },
            copy() {

                let copyLinkInput = document.querySelector('#copyLink');

                copyLinkInput.focus();
                copyLinkInput.select();
                copyLinkInput.setSelectionRange(0, 99999);

                document.execCommand('copy');

                showAlert({
                    title: 'Link copied to clipboard'
                })
            }
        }
    }
</script>