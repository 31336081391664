<template>
	<div class="login-page bg-gray-2 d-flex justify-content-center align-items-center py-5">
		<div class="bg-white p-4 login-card rounded">
			<div class="text-center">
				<router-link to="/">
					<img src="@assets/img/logo.png" alt="" />
				</router-link>
			</div>
			<h2 class="text-center font-sf-bold font-giantsize py-3">Log In</h2>
			<FacebookLogin
				class="btn btn-gray d-flex align-items-center justify-content-center btn-sm btn-block btn-radius-3 py-2"
				id="fb-login"
				:app-id="facebookAppID"
				@login="onFacebookAuthLogin"
				@sdk-init="onFacebookSDKInit"
			/>
			<GoogleLogin
				class="btn btn-gray d-flex align-items-center justify-content-center btn-sm btn-block btn-radius-3 py-2"
				id="google-login"
				:params="googleLoginParams"
				:onSuccess="onGoogleAuthSuccess"
				:onFailure="onGoogleAuthFail"
			>
				<i class="font-small mr-2 fab fa-google"></i> {{ googleAuthenticating ? 'Please wait...' : 'Continue with Google' }}
			</GoogleLogin>
			<div class="or-separator my-3 font-gray font-xsmall">
				<span class="position-relative px-3">
					or
				</span>
			</div>
			<div
				class="mb-3"
			>
				<input
					type="text"
					class="form-control font-xsmall font-gray"
					placeholder="Enter phone number or email"
					v-model="formValues.username"
					@keyup="() => { formErrors.username = '' }"
				/>
			</div>
			<div class="mb-1" >
				<div class="input-group append">
					<input 
						:type="`${ passwordToggled ? 'text' : 'password' }`" 
						class="form-control font-xsmall font-gray" 
						placeholder="Enter password" 
						v-model="formValues.password"
						@keydown.enter="login"
						@keyup="() => { formErrors.password = '' }"
					/>
					<div class="input-group-append">
						<span class="input-group-text">
							<i 
								:class="['fas clickable ', passwordToggled ? 'fa-eye-slash' : 'fa-eye']"
								@click="() => { passwordToggled = !passwordToggled }"
							></i>
						</span>
					</div>
				</div>
			</div>
			<div class="font-small font-red" v-if="error" >{{ error }}</div>
			<router-link 
				:to="{ name: 'password.reset' }" 
				class="font-xsmall font-green"
			>Forgot password?</router-link>
			<button 
				@click="login"
				:class="['btn btn-block btn-radius-3 mt-2 mb-5 mt-3 py-2 ', authenticating ? 'btn-white' : 'btn-green ']"
			>{{ authenticating ? 'Logging in...' : 'Login' }}</button>
			<div class="font-xsmall text-center">
				Don't have an account yet?
				<router-link to="/registration" class="font-green">Create an Account</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import validate from '@helpers/validation.helper';
import { getCart } from '@helpers/cart.helper';
import { isLoggedIn } from '@helpers/session.helper';
import * as types from '../../store/mutation-types';
import moment from 'moment';
import { connect } from '@api';
import config from '../../constants/config';

export default {
	name: 'login',
	data() {
		return {
			formValues: {
				username: '',
				password: ''
			},
			formErrors: {
				username: '',
				password: ''
			},
			authenticating: false,
			passwordToggled: false,
			error: '',
			moment: moment,
			googleLoginParams: {
				client_id: config.GOOGLE_CLIENT_ID
			},
			googleAuthenticating: false,
			facebookAuthenticating: false,
			facebookAppID: config.FB_APP_ID,
			FB: {},
		}
	},
	computed: {
		redirectPath: {
			get() {
				return this.$route.query ? this.$route.query : null;
			}
		},
		cart() {
			return getCart() ? getCart() : null
		}
	},
	mounted() {
		if (isLoggedIn()) {
			this.$router.push({ name: 'home' });
		}
	},
	methods: {
		...mapMutations({
			setUser: types.SET_USER,
			updateCart: types.UPDATE_CART,
			setUserAge: types.SET_USER_AGE,
			setRegistered: types.SET_REGISTERED
		}),
		...mapActions({
			loginUser: types.LOGIN,
			oauthLogin: types.OATH_LOGIN,
			syncCart: types.SYNC_CART,
			fetchList: types.FETCH_FREQUINCY_LIST,
			checkout: types.PROCEED_TO_CHECKOUT,
			calculateUserAge: types.CALCULATE_USER_AGE,
			FBInit: types.FB_INIT
		}),
		async onGoogleAuthSuccess(googleUser) {

			this.googleAuthenticating = true;

			let profile = googleUser.getBasicProfile();
			let oauth_id = googleUser.getAuthResponse().id_token;

			let params = {
				oauth_id: profile.getId(),
				fullname: profile.getName(),
				firstname: profile.getGivenName(),
				lastname: profile.getFamilyName(),
				email: profile.getEmail(),
				social_media_name: 'Google'
			};

			let res = await this.oauthLogin({...params});

			if (res.status == 200) {
				this.processUserData(res.data.data, res.data.registered ? true : false);
			} else {
				this.googleAuthenticating = false;

				if (res.data.message) {
					this.error = res.data.message;
				}
			}
		},
		onGoogleAuthFail(params) {
			console.log('fail', params);
		},
		onFacebookAuthLogin(response) {

			if (response && response.authResponse) {
				this.FB.api('/me', 'GET', { fields: 'id,name,first_name,last_name,email' }, 
					async userInfo => {
						let params = {
							oauth_id: userInfo.id,
							fullname: userInfo.name,
							firstname: userInfo.first_name,
							lastname: userInfo.last_name,
							email: userInfo.email,
							social_media_name: 'Facebook'
						};
	
						let res = await this.oauthLogin({...params});
	
						if (res.status == 200) {
							this.processUserData(res.data.data, res.data.registered ? true : false);
						} else {
							this.error = res.data.message;
						}
					}
				)
			}
		},
		onFacebookSDKInit(response) {
			console.log('FB Init', response);
			this.FB = response.FB;
			this.FBInit(response);
		},
		async validateForm() {
			const schema = {
				properties: {
					username: {
						type: 'string',
						required: true,
						allowEmpty: false,
						message: 'Please enter your phone or email'
					},
					password: {
						type: 'string',
						required: true,
						allowEmpty: false,
						message: 'Please enter your password'
					}
				}
			};

			const validateResults = validate(this.formValues, schema, undefined, true);

			this.formErrors = validateResults.errors;
			return validateResults.valid;
		},
		clear(type = null) {
			let fields = ['username', 'password'];
			this.error = ''
			fields.forEach(field => {
				if (type == null) {
					this.formValues[field] = '';
					this.formErrors[field] = '';
				} else {
					this[type][field] = '';
				}
			})
		},
		processUserData(user, registered = false) {

			this.setUser(user);

			if (user.customer && user.customer.birthday != '0000-00-00') {
				this.calculateUserAge(user);
			}

			let cartParams = {
				customer_id: user && user.customer_id ? user.customer_id : 0,
				source: 'website',
				last_device: 'laptop',
				items: [],
				reference_number: ''
			};

			let userCart = user.cart;
			let sessionCart = this.cart;
			let newItems = [];

			let { redirectPath } = this;

			if (sessionCart && redirectPath.items) {
				newItems = sessionCart.items;
			}

			if (userCart) {

				let userCartItems = userCart.items;

				if (userCartItems && userCartItems.length > 0) {
					
					userCartItems.map(item => {

						let existingIndex = newItems.findIndex(i => {
							return i.product_id == item.product_id
						});

						if (existingIndex > -1) {
							newItems[existingIndex].quantity += item.quantity;

							if (item.add_ons && item.add_ons.length > 0) {

								item.add_ons.map(addon => {

									if (newItems[existingIndex].add_ons && newItems[existingIndex].add_ons.length > 0) {

										let addonExists = newItems[existingIndex].add_ons.find(a => {
											return a.addon_group == item.addon_group;
										})

										if (!addonExists) {
											newItems[existingIndex].add_ons.push(item);
										}

									} else {
										newItems[existingIndex].add_ons.push(addon);
									}
								})
							}

						} else {
							newItems.push(item);
						}
					})
				}

			}

			cartParams.items = newItems;
			this.syncCart(cartParams);

			this.fetchList();
			this.clear();

			if (redirectPath && redirectPath.redirect) {

				if (redirectPath.items && redirectPath.items.length > 0) {

					let checkoutItems = this.processCheckoutItems(redirectPath.items, redirectPath.add_ons);
					let checkoutParams = {
						items: checkoutItems
					};
					
					this.checkout(checkoutParams)
						.then(res => {

							if (res.status == 200) {
								this.$router.push({ 
									name: 'checkout',
									query: {
										token: res.data.data.token
									}
								});
							}

						})
						.catch(err => {
							console.error(err.data.message);
						})
				} else if (redirectPath.token) {

					this.getCheckoutData(redirectPath.token)
						.then(res => {
							
							if (res.status == 200) {
								let orderData = res.data.data;

								if (orderData) {
									let params = {
										customer_id: user.customer_id,
										reference_number: orderData.reference_number
									}

									if (orderData.items && orderData.items.length > 0) {
										
										let newItems = [];

										orderData.items.map(item => {

											let newItem = {
												product_id: item.product.id,
												quantity: item.quantity,
												add_ons: item.add_ons
											};
											newItems.push(newItem);
										})

										params.items = newItems;
									}

									this.checkout(params)
										.then(response => {
											if (response.status == 200) {
												this.$router.push({
													name: 'checkout',
													query: {
														token: redirectPath.token
													}
												})

												return;
											}
										})
										.catch(err => {
											console.error(err);
										})
								}
							}
						})
						.catch(err => {
							console.error(err);
						})

				} else {
					this.$router.push({ path: redirectPath.redirect });
				}
			} else {
				this.$router.push({ name: 'home' })

				if (registered) {
					this.setRegistered({data: true})
				}
			}
		},
		processCheckoutItems(selectedItems, selectedAddons) {

			let cart = getCart();

			let items = cart.items;

			let checkoutItems = [];

			if (selectedItems.length > 0) {

				selectedItems.map(selected => {
					
					let checkoutItem;

					let item = items.find(item => selected == item.id);

					if (item) {

						checkoutItem = {
							product_id: item.product_id,
							quantity: item.quantity,
							notes: item.notes,
							eta: item.eta
						};

						if (item.add_ons.length > 0) {

							let checkoutItemAddons = [];

							selectedAddons.map(selectedAddon => {

								let checkoutItemAddon;

								let addOn = item.add_ons.find(addOn => addOn.id == selectedAddon);

								if (addOn) {

									checkoutItemAddon = {
										addon_group: addOn.addon_group,
										qty: addOn.qty
									};

									checkoutItemAddons.push(checkoutItemAddon);
								}
							});

							if (checkoutItemAddons.length > 0) {
								checkoutItem.add_ons = checkoutItemAddons;
							}
						}

						checkoutItems.push(checkoutItem);
					}
				})
			}

			return checkoutItems;
		},
		getCheckoutData(token) {
			return connect().post('/checkout/find', { token: token })
		},
		async login() {
			this.clear('formErrors');
			this.authenticating = true;

			if (!(await this.validateForm())) {

				this.authenticating = false;
				
				this.error = 'Invalid username and password';

				return false;
			} else {
				let authParams = {
					password: this.formValues.password,
					type: 'regular'
				};
	
				if (this.formValues.username.includes('@')) {
					authParams.email = this.formValues.username;
				} else {
					authParams.phone = this.formValues.username;
				}

				let res = this.loginUser({ ...authParams })
							.then(res => {
								if (res.status == 200) {
									let user = res.data.data;

									this.processUserData(user);
								}
							})
							.catch(err => {
								console.log('error', err.data);

								this.authenticating = false;
								this.formValues.password = '';
								
								if (err.data && err.data.message) {
									this.error = err.data.message;
								}
							}) 

				/* this.loginUser({...authParams})
						.then(res => {
	
							this.authenticating = false;
	
							if (res.status == 200) {
	
								let user = res.data.data;
								this.setUser(user);

								this.calculateUserAge(user);

								let cartParams = {
									customer_id: user && user.customer_id ? user.customer_id : 0,
									source: 'website',
									last_device: 'laptop',
									items: [],
									reference_number: ''
								};

								let userCart = user.cart;
								let sessionCart = this.cart;
								let newItems = [];

								let { redirectPath } = this;

								if (sessionCart && redirectPath.items) {
									newItems = sessionCart.items;
								}

								if (userCart) {

									let userCartItems = userCart.items;

									if (userCartItems && userCartItems.length > 0) {
										
										userCartItems.map(item => {

											let existingIndex = newItems.findIndex(i => {
												return i.product_id == item.product_id
											});

											if (existingIndex > -1) {
												newItems[existingIndex].quantity += item.quantity;

												if (item.add_ons && item.add_ons.length > 0) {

													item.add_ons.map(addon => {

														if (newItems[existingIndex].add_ons && newItems[existingIndex].add_ons.length > 0) {

															let addonExists = newItems[existingIndex].add_ons.find(a => {
																return a.addon_group == item.addon_group;
															})

															if (!addonExists) {
																newItems[existingIndex].add_ons.push(item);
															}

														} else {
															newItems[existingIndex].add_ons.push(addon);
														}
													})
												}

											} else {
												newItems.push(item);
											}
										})
									}

								}

								cartParams.items = newItems;
								this.syncCart(cartParams);

								this.fetchList();
								this.clear();

								if (redirectPath && redirectPath.redirect) {

									if (redirectPath.items && redirectPath.items.length > 0) {

										let checkoutItems = this.processCheckoutItems(redirectPath.items, redirectPath.add_ons);
										let checkoutParams = {
											items: checkoutItems
										};
										
										this.checkout(checkoutParams)
											.then(res => {

												if (res.status == 200) {
													this.$router.push({ 
														name: 'checkout',
														query: {
															token: res.data.data.token
														}
													});
												}

											})
											.catch(err => {
												console.error(err.data.message);
											})
									} else if (redirectPath.token) {

										this.getCheckoutData(redirectPath.token)
											.then(res => {
												
												if (res.status == 200) {
													let orderData = res.data.data;

													if (orderData) {
														let params = {
															customer_id: user.customer_id,
															reference_number: orderData.reference_number
														}

														if (orderData.items && orderData.items.length > 0) {
															
															let newItems = [];

															orderData.items.map(item => {

																let newItem = {
																	product_id: item.product.id,
																	quantity: item.quantity,
																	add_ons: item.add_ons
																};
																newItems.push(newItem);
															})

															params.items = newItems;
														}
	
														this.checkout(params)
															.then(response => {
																if (response.status == 200) {
																	this.$router.push({
																		name: 'checkout',
																		query: {
																			token: redirectPath.token
																		}
																	})
	
																	return;
																}
															})
															.catch(err => {
																console.error(err);
															})
													}
												}
											})
											.catch(err => {
												console.error(err);
											})

									} else {
										this.$router.push({ path: redirectPath.redirect });
									}
								} else {
									this.$router.push({ name: 'home' })
								}

							}
						})
						.catch(err => {

							console.error(err);

							this.authenticating = false;
							this.formValues.password = '';
							
							if (err.data.message) {
								this.error = err.data.message;
							}
						}) */
			}

		}
	}
};
</script>

<style></style>
