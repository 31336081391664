import { connect } from '../../helpers/api.helper';
import * as types from '../mutation-types';

const defaultState = {
    list: [],
    showPopup: false,
    popupID: null,
    showPopupForm: false,
    popupFormType: 'create',
    popupFormID: null
};

const state = defaultState;

// used to get state
const getters = {

    [types.GET_FREQUINCY_LIST]: (state) => state.list,
    
    [types.GET_SHOW_LIST_POPUP]: (state) => state.showPopup,

    [types.GET_LIST_POPUP_PRODUCT_ID]: (state) => state.popupID,

    [types.GET_SHOW_LIST_POPUP_FORM]: (state) => state.showPopupForm,

    [types.GET_LIST_POPUP_FORM_TYPE]: (state) => state.popupFormType,

    [types.GET_LIST_POPUP_FORM_ID]: (state) => state.popupFormID,

};

// same as react actions
const actions = {

    [types.FETCH_FREQUINCY_LIST]: ({commit}, params) => {
        connect().get('frequincy-list/get', params)
            .then(response => {
                if (response.status == 200) {
                    commit(types.UPDATE_FREQUINCY_LIST_STATE, { data: response.data.data });
                }
            })
            .catch(err => {
                console.error('error', err.data.message);
            })
    },

    [types.FETCH_FREQUINCY_LIST_ITEMS]: ({commit}, params) => {
        return connect().get('frequincy-list/get-items', params);
    },

    [types.FIND_FREQUINCY_LIST]: ({commit}, params) => {
        return connect().get('frequincy-list/find', params);
    },

    [types.CREATE_FREQUINCY_LIST]: ({commit, dispatch}, params) => {
        return (
            connect().post('frequincy-list/store', params)
                .then(res => {
                    if (res.status == 200) {
                        dispatch(types.FETCH_FREQUINCY_LIST)
                    }
                })
                .catch(err => {
                    console.error(err.data.message);
                })
        )
    },

    [types.UPDATE_FREQUINCY_LIST]: ({commit, dispatch}, params) => {
        return (
            connect().post('frequincy-list/update', params)
                .then(res => {
                    if (res.status == 200) {
                        dispatch(types.FETCH_FREQUINCY_LIST)
                    }
                })
                .catch(err => {
                    console.err(err.data.message);
                })
        )
    },

    [types.DELETE_FREQUINCY_LIST]: ({commit, dispatch}, params) => {
        return (
            connect().delete('frequincy-list/delete', params)
                .then(res => {
                    if (res.status == 200) {
                        dispatch(types.FETCH_FREQUINCY_LIST)
                    }
                })
                .catch(err => {
                    console.error(err.data.message);
                })
            )
    },

    [types.CLEAR_FREQUINCY_LIST_ITEMS]: ({commit, dispatch}, params) => {
        return (
            connect().delete('frequincy-list/clear-items', {...params})
                .then(res => {
                    if (res.status == 200) {
                        dispatch(types.FETCH_FREQUINCY_LIST);
                    }
                })
                .catch(err => {
                    console.error(err.data.message);
                })
        )
    },

    [types.DELETE_FREQUINCY_LIST_ITEMS]: ({commit, dispatch}, params) => {
        return connect().delete('frequincy-list/delete-items', params);
    },

    [types.CREATE_FREQUENCY_LIST_ITEM]: async ({commit, dispatch}, params) => {

        let response = await connect().post('customers/add_wish_list', params);

        if (response.status == 200) {
            dispatch(types.FETCH_FREQUINCY_LIST);
        }

        return response;
    },

    [types.UPDATE_FREQUINCY_LIST_ITEM]: async ({commit, dispatch}, params) => {
        let response = await connect().post('frequincy-list/update-item', params);

        if (response.status == 200) {
            dispatch(types.FETCH_FREQUINCY_LIST);
        }

        return response
    },

    [types.DELETE_FREQUINCY_LIST_ITEM]: ({commit, dispatch}, params) => {
        return (
            connect().post('customers/remove-wish-list', params)
                .then(res => {
                    if (res.status == 200) {
                        console.log('successfully deleted item');
                    }
                })
                .catch(err => {
                    console.error(err.data.message);
                })
        )
    }

};

// same as react reducer
const mutations = {

    [types.UPDATE_FREQUINCY_LIST_STATE] (state, payload) { state.list = [...payload.data] },

    [types.TOGGLE_LIST_POPUP] (state, payload) { 
        if (payload != null) {
            state.showPopup = payload;
        } else {
            state.showPopup = !state.showPopup;
        }
     },

    [types.SET_LIST_POPUP_PRODUCT_ID] (state, payload) { state.popupID = payload },

    [types.TOGGLE_LIST_POPUP_FORM] (state, payload) { 
        if (payload != null) {
            state.showPopupForm = payload;
        } else {
            state.showPopupForm = !state.showPopupForm 
        }
    },

    [types.SET_LIST_POPUP_FORM_TYPE] (state, payload) { state.popupFormType = payload },

    [types.SET_LIST_POPUP_FORM_ID] (state, payload) { state.popupFormID = payload }

};

export default {
    state,
    getters,
    actions,
    mutations
}