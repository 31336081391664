<template>
    <div>
        <b-modal 
            id="deposit-modal" 
            content-class="border-0"
            body-class="px-4"
            v-model="show"
            centered
            hide-header
            hide-footer
            no-close-on-backdrop
        >
            <div 
                class="deposit-form"
                @drop.prevent="handleDepositModalFileChange($event.dataTransfer.files)"
                @dragover.prevent
             >
                <div class="header d-flex align-items center">
                    <div class="flex-grow-1 text-center font-small font-sf-bold">Upload Deposit Slip</div>
                    <div>
                        <span
                            @click="toggleDepositModal"
                            class="far fa-times-circle clickable px-2"
                        ></span>
                    </div>
                </div>
                <div class="body py-3">
                    <div class="image-container d-flex align-items-center justify-content-center border border-gray clickable">
                        <Imager
                            v-if="uploadedFile"
                            className="deposit-form-image"
                            :src="uploadedFile"
                        />
                        <label 
                            v-if="!uploadedFile" 
                            for="deposit-image" 
                            class="font-white clickable mb-0 text-center file-input-overlay d-flex align-items-center justify-content-center" 
                        >
                            <div class="font-gray font-sf-bold" >
                                <span>Drag your files or <u>upload</u> here</span>
                                <br>
                                <span class="font-xxsmall font-sf-medium" >Max 8mb</span>
                            </div>
                            <input 
                                type="file" 
                                name="imageData" 
                                id="deposit-image" 
                                class="d-none" 
                                accept="image/jpg, image/jpeg, image/png" 
                                @change="handleDepositModalFileChange($event.target.files)"
                            >
                        </label>
                        <div 
                            v-if="uploadedFile" 
                            class="remove-overlay clickable font-white"
                            @click="handleRemoveUploadedFile"
                        >Remove Image</div>
                    </div>
                    <div class="font-small font-red" v-if="depositFormErrors.imageData" >{{depositFormErrors.imageData}}</div>
                    <div class="form-divider font-gray"><span>or</span></div>
                    <div class="form-group">
                        <input 
                            type="text" 
                            class="form-control"
                            v-model="depositForm.account_name"
                            placeholder="Enter account name"
                        >
                        <div class="font-small font-red" v-if="depositFormErrors.account_name" >{{depositFormErrors.account_name}}</div>
                    </div>
                    <div class="form-group">
                        <input 
                            :type="depositForm.payment_date == '' ? 'text' : 'date'" 
                            @focus="handleDateInputFocus"
                            @blur="handleDateInputLeave"
                            class="form-control"
                            v-model="depositForm.payment_date"
                            placeholder="Enter date of payment"
                            ref="date_input"
                        >
                        <div class="font-small font-red" v-if="depositFormErrors.payment_date" >{{depositFormErrors.payment_date}}</div>
                    </div>
                    <div class="form-group">
                        <input 
                            type="text" 
                            class="form-control"
                            v-model="depositForm.amount_paid"
                            placeholder="Amount paid"
                        >
                        <div class="font-small font-red" v-if="depositFormErrors.amount_paid" >{{depositFormErrors.amount_paid}}</div>
                    </div>
                    <div class="form-group">
                        <input 
                            type="text" 
                            class="form-control"
                            v-model="depositForm.reference_number"
                            placeholder="Enter reference number"
                        >
                        <div class="font-small font-red" v-if="depositFormErrors.reference_number" >{{depositFormErrors.reference_number}}</div>
                    </div>
                </div>
                <div class="footer">
                    <button 
                        class="btn btn-sm rounded-pill btn-block btn-green"
                        @click="handleDepositModalSubmit"
                        :disabled="uploading"
                    >{{ uploading ? 'Processing' : 'Submit' }}</button>
                    <div class="mt-3 text-center font-xsmall">
                        Have any problems? <router-link :to="{ name: 'contact' }" class="font-green ml-3" target="_blank" >Contact Us</router-link>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    export default {
        props: [
            'show',
            'toggleDepositModal',
            'depositForm',
            'depositFormErrors',
            'handleDepositModalFileChange',
            'handleDepositModalSubmit',
            'uploadedFile',
            'handleRemoveUploadedFile',
            'uploading'
        ],
        methods: {
            handleDateInputFocus() {
                let input = this.$refs.date_input;
                input.type = 'date';
            },
            handleDateInputLeave() {
                let input = this.$refs.date_input;
                if (this.depositForm.payment_date == '') input.type = 'text';
            }
        }
    }
</script>

<style scoped>
    .deposit-form .image-container {
        min-height: 150px;
        background-color: #bebebe;
        position: relative;
    }
    .deposit-form .image-container:hover .remove-overlay {
        opacity: 100%;
    }
    .deposit-form .image-container .file-input-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    .deposit-form .deposit-form-image {
        max-width: 100%;
        object-fit: cover;
        object-position: center;
    }
    .deposit-form .form-divider {
        width: 100%; 
        text-align: center; 
        border-bottom: 1px solid #e1e2e1; 
        line-height: 0.1em;
        margin: 10px 0 20px; 
        color: #777;
    }
    .deposit-form .form-divider span {
        background: #FFF;
        padding: 0 .5rem;
    }
    .deposit-form .image-container .remove-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0000004D;
        opacity: 0%;
        transition: opacity 0.2s;
    }
</style>