<template>
    <div class="blog-page">
        <div 
            v-if="featuredArticle && featuredArticle" 
            class="featured-blog d-flex align-items-center font-white"
        >
            <div 
                class="background-image-container" 
                v-bind:style="{ backgroundImage: featuredArticle.image && featuredArticle.image.path ? `url(${backgroundImageAsset(featuredArticle.image)})` : ''}" 
            ></div>
            <div class="container">
                <div class="col-md-5 col-12 pl-0">
                    <router-link
                        :to="{
                            name: 'bbdoit.view',
                            params: {
                                slug: featuredArticle.slug
                            }
                        }"
                        class="font-giantsize font-sf-bold text-capitalize clickable mb-2"
                    >
                        {{ featuredArticle.name }}
                    </router-link>
                    <div class="font-small my-4">{{ featuredArticle.blurb }}</div>
                    <router-link
                        :to="{
                            name: 'blog.view',
                            params: {
                                slug: featuredArticle.slug
                            }
                        }"
                        class="btn btn-green py-3 rounded-pill px-5"
                    ><span class="mx-4">Read Blog</span>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="py-4" >
            <div class="container">
                <div v-if="articles.length > 0" class="d-flex flex-column flex-sm-row align-items-center mb-4">
                    <div class="font-xlarge font-sf-medium">Other Blogs</div>
                    <div class="d-flex align-items-center flex-column flex-md-row ml-auto w-xs-100">
                        <span class="mr-2 font-xsmall font-sf-medium text-nowrap font-gray">Sort by</span>
                        <button 
                            :class="['btn btn-sm btn-white mr-1 d-flex align-items-center rounded justify-content-center justify-content-md-start w-xs-100 mb-2 mb-md-0 ', params.sort == 'latest:desc' ? 'active' : '']"
                            @click="sortBy('latest:desc')"
                        >
                            <div class="d-inline-block bg-green font-white font-xxsmall mr-1">
                                NEW
                            </div>
                            Latest
                        </button>
                        <button 
                            :class="['btn btn-sm btn-white d-flex align-items-center rounded justify-content-center justify-content-md-start w-xs-100 mb-2 mb-md-0 mr-1 ', params.sort == 'trending:desc' ? 'active' : '']"
                            @click="sortBy('trending:desc')"
                        >
                            <img class="img-small mr-1" src="@assets/img/trending.png" alt="trending-icon" />
                            Trending
                        </button>
                        <button 
                            :class="['btn btn-sm rounded w-xs-100 mb-2 mb-md-0 btn-white mr-1 ', params.sort == 'alpha:desc' || params.sort == 'alpha:asc' ? 'active' : '']"
                            @click="(params.sort != 'alpha:asc' && params.sort != 'alpha:desc') ? sortBy('alpha:asc') : (params.sort == 'alpha:asc' ? sortBy('alpha:desc') : sortBy('alpha:asc'))"
                            :style="{ whiteSpace: 'nowrap' }"
                        >
                            <span>Alphabetical {{ (params.sort != 'alpha:asc' && params.sort != 'alpha:desc') ? 'A-Z' : (params.sort == 'alpha:asc') ? 'A-Z' : 'Z-A'}}</span>
                            <span :class="`fas fa-long-arrow-alt-down ml-1`" />
                        </button>
                    </div>
                </div>
                <div v-if="articlesLoading" >
                    <div class="row row-thin">
                        <div 
                            v-for="n in 6"
                            :key="n"
                            class="col-sm-6 col-md-6 col-lg-4 col-12 mb-4"
                        >
                            <CardLoader />
                        </div>
                    </div>
                </div>
                <div v-else >
                    <div v-if="articles && articles.length > 0"  >
                        <div class="row row-thin">
                            <div 
                                class="col-sm-6 col-md-6 col-lg-4 col-12 mb-5"
                                v-for="article in articles"
                                :key="`article-${article.id}`"
                            >
                                <BlogCard
                                    :article="article"
                                />
                            </div>
                        </div>
                        <div v-if="pagination && pagination.last_page > 1" >
                            <Pagination
                                :currentPage="params.page"
                                :lastPage="pagination.last_page"
                                :params="params"
                            />
                        </div>
                    </div>
                    <div v-else class="py-5 mb-5 font-giantsize text-center font-sf-bold">No articles found</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { connect } from '@api';
    import BlogCard from '@components/blogCard';
    import { backgroundImageAsset } from '@helpers/caboodle.helper';
    import { CardLoader } from '@components/common';
    import Pagination from '@components/pagination';

    const initialParams = {
        page_size: 18,
        page: 1,
        sort: 'latest:desc',
        exclude: [],
        type: 'blog'
    }

    export default {
        components: {
            CardLoader,
            BlogCard,
            Pagination
        },
        data() {
            return {
                params: initialParams,
                articlesLoading: true,
                pagination: null,
                featuredArticle: null,
                articles: []
            }
        },
        created() {
            this.getFeaturedArticle()
				.then(res => {
					if (res.status == 200) {
                        if (res.data.data && res.data.data.length > 0) {
                            this.featuredArticle = res.data.data[0];
                            this.params.exclude.push(res.data.data[0].id);
                        }
						
						this.getArticles();
					}
				})
				.catch(err => {
					console.log(err)
				})
        },
        methods: {
            backgroundImageAsset,
            getFeaturedArticle() {
                return connect().get('/articles/featured', { params: { num_articles: 1, sort: 'latest:desc', type: 'blog' } })
            },
            getArticles() {
                this.articlesLoading = true;

                connect().get('/articles/get', { params: this.params })
                        .then(res => {
                            if (res.status == 200) {
                                this.articles = res.data.data.data;
                                this.pagination = res.data.data;
                            }
                            this.articlesLoading = false;
                        })
            },
            sortBy(sort) {
                this.params.sort = sort;
                this.getArticles();
            },
        },
    }
</script>