<template>
    <div 
        :class="`comment-card ${!isReply && 'border-bottom'} ${className}`"
    >
        <div :class="`d-flex ${!isReply ? 'py-4' : 'pt-3'}`">
            <div class="pt-2 flex-shrink-0" >
                <Avatar
                    :color="comment.avatar_color"
                    :user="comment.user"
                />
            </div>
            <div class="ml-4 flex-grow-1">
                <div class="comment-details" >
                    <span class="font-small font-sf-medium mr-3">{{ comment.user.customer.fullname }}</span>
                    <span class="font-xxsmall font-gray">Submitted {{ formattedDate }}</span>
                </div>
                <div class="comment-content font-small my-1" v-html="comment.comment" ></div>
                <div 
                    v-if="comment.parent == 0 && isLoggedIn"
                    class="font-sf-medium font-small font-green clickable"
                    @click="$emit('reply', comment)"
                > REPLY</div>
                <div v-if="comment.replies && comment.replies.length > 0">
                    <div 
                        class="font-small font-sf-medium font-green mt-1 clickable" 
                        v-b-toggle="`comment-thread-${comment.id}`"
                        @click="() => { isCollapsed = !isCollapsed }"
                    >
                        <i :class="`fas mr-1 ${isCollapsed ? 'fa-caret-up' : 'fa-caret-down'}`"></i> {{isCollapsed ? 'Hide' : 'View'}} {{ comment.replies.length }} {{ comment.replies.length > 1 ? 'replies' : 'reply' }}
                    </div>
                    <b-collapse :id="`comment-thread-${comment.id}`" >
                        <CommentItem 
                            v-for="reply in comment.replies"
                            :key="reply.id"
                            :comment="reply"
                            :isReply="true"
                        />
                    </b-collapse>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Avatar } from '@components/common';
import moment from 'moment';
import { isLoggedIn } from '@helpers/session.helper';

export default {
    name: 'CommentItem',
    components: {
        Avatar
    },
    props: {
        comment: Object,
        className: String,
        isReply: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isCollapsed: false
        }
    },
    computed: {
        isLoggedIn() {
            return isLoggedIn();
        },
        formattedDate() {
            return moment(this.comment.created_at).format('DD MMM YYYY')
        }
    },
    mounted() {
        console.log(this.comment);
    }
}
</script>