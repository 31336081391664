<template>
    <img
        :src="source"
        :class="className"
        :alt="alt"
        @click="$emit('click-image')"
    />
</template>

<script>
import { extractAssetObj } from '@helpers/caboodle.helper'

export default {
    name: 'Imager',
    props: [ "src", "extract", "extractMap", "className", "styles", "alt", "id" ],
    computed: {
        source () {
            let computedSource = this.src
            
            if (this.extract) computedSource = extractAssetObj(this.src, this.extractMap ? this.extractMap : null)
            
            return computedSource
        }
    }
}
</script>